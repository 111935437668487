<template>
  <div>
    <v-row class="pr-5 pb-3 mt-3">
      <v-col class="d-flex justify-content-start">
        <v-btn v-can="'Distributors.Create'" color="success" @click="add">
          اضافة موزع
          <i class="fa fa-plus mr-2"></i>
        </v-btn>
      </v-col>
    </v-row>
    <v-card width="98%" class="mx-auto mr-5">
      <v-data-table v-can="'Distributors.View'"
        :headers="headers"
        :items="entities"
        :options.sync="options"
        :server-items-length="total"
        :search="search"
        :items-per-page="15"
        :loading="loadingStates.table"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-plus',
          nextIcon: 'mdi-minus',
          'items-per-page-text': 'عدد الموزعين ' + total + '',
        }"
      >
        <template v-slot:[`item.branch`]="{ item }">
          <span>{{ item.branch.name }}</span>
        </template>
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="اسم الموزع"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-can="'Distributors.Update'"
            color="primary"
            elevation="2"
            class="mx-2"
            @click="edit(item.id)"
            icon
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn v-can="'Distributors.Delete'" color="red" elevation="2" icon outlined @click="del(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- Add Modal -->
    <v-dialog v-model="dialog" width="800" persistent>
      <v-card>
        <v-form v-model="isValid">
          <v-card-title class="headline">
            <i class="fa fa-pen ml-2"></i> تعديل/إضافة
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="my-0 ammar" dense>
                <v-col cols="12" sm="6">
                  <v-text-field
                    dense
                    :rules="[allRules.required, allRules.minNameLen(4)]"
                    outlined
                    label="الاسم "
                    v-model="selected.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    dense
                    outlined
                    :maxlength="10"
                    :rules="[allRules.required, allRules.length(10)]"
                    label="رقم الجوال "
                    type="number"
                    v-model="selected.mob"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-autocomplete
                    outlined
                    dense
                    :rules="[allRules.required]"
                    v-model="selected.branch"
                    :items="branches"
                    return-object
                    item-text="name"
                    :loading="loadingStates.table"
                    auto-select-first
                    label="اسم الفرع"
                  >
                    <template v-slot:selection="data">
                      <span>{{ data.item.name }}</span>
                    </template>
                    <template v-slot:item="data">
                      <span>{{ data.item.name }}</span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" :disabled="!isValid" @click="editConfirmed()"
              >حفظ</v-btn
            >

            <v-btn color="red darken-1" text @click="cancelEdit()">إلغاء</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Add Modal -->
  </div>
</template>

<script>
import BranchesApi from "../../Settings/services/BranchesService.js";
import ApiService from "../services/DistributersService.js";
export default {
  data() {
    return {
      isValid: false,
      selected: {},
      entities: [],
      branches: null,
      total: 0,
      search: "",
      options: {},
      dialog: false,
      loadingStates: {
        table: false,
      },
      headers: [
        {
          text: "رقم",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "الاسم", value: "name" },
        { text: "الفرع", value: "branch.name" },
        { text: "رقم الجوال", value: "mob" },
        { text: "مسح / تعديل", value: "actions", align: "left" },
      ],

      openDialog: false,
    };
  },
  mounted() {
    BranchesApi.query()
      .then((resp) => {
        this.branches = resp.data.items;
      })
      .catch((err) => {});
    this.getData();
  },
  methods: {
    getData() {
      this.loadingStates.table = true;
      if (this.options) this.options.searchTerm = this.search;
      ApiService.query(this.options)
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
        });
    },
    editConfirmed() {
      this.selected.branchId = this.selected.branch.id;
      ApiService.edit(this.selected)
        .then((resp) => {
          this.dialog = false;
          var message = "تم تعديل العنصر بنجاح";
          if (!this.selected.id) {
            if (this.entities.length == 0) this.entities = [resp.data];
            else this.addToArr(this.entities, resp.data);
            message = "تم إضافة العنصر بنجاح";
          } else {
            this.updateArr(this.entities, resp.data);
          }

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.dialog = false;
          console.log(err.request.response);
          this.$dialog.notify.error("خطا اثناء تعديل العنصر", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },

    cancelEdit() {
      this.dialog = false;
      this.selected = { branch: {} };
    },
    add() {
      this.selected = {};
      this.dialog = true;
    },
    edit(id) {
      this.selected = { ...this.entities.filter((e) => e.id == id)[0] };
      this.dialog = true;
    },
    del(id) {
      this.$dialog
        .info({
          text: "هل انت متاكد من حذف العنصر",
          title: "  حذف فرع  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                ApiService.delete(id)
                  .then((resp) => {
                    const index = this.entities.indexOf(
                      this.entities.filter((e) => e.id == id)[0]
                    );
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("تم مسح العنص بنجاح", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("خطا اثناء مسح العنصر", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => {});
    },
    view(id) {},
  },
  watch: {
    "selected.branch"(newval) {
      if (newval) {
        if (newval.id) {
          this.selected.branchId = newval.id;
        }
      }
    },

    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
</style>