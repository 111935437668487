<template>
  <div>
    <v-data-table v-can="'Shipments.View'" :headers="headers" :items="entities" fixed-header
      :server-items-length="total" :search="search" :items-per-page="15" :options.sync="options" class="elevation-1"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-plus',
        nextIcon: 'mdi-minus',
        'items-per-page-text': 'عدد البوالص ' + entities.length + '',
      }">
      <template v-slot:[`item.senderInfo`]="{ item }">
        <div v-if="item.senderInfo">
          <div>{{ item.senderInfo.name }}</div>
          <span v-if="
            item.senderInfo.phoneNumber != null &&
            item.senderInfo.phoneNumber != ' '
          ">
            <v-chip color="green lighten-3" label>{{ item.senderInfo.phoneNumber }}
            </v-chip>
          </span>
        </div>
      </template>
      <template v-slot:[`item.totalPrice`]="{ item }">
        <div>
          <div>{{ item.totalPrice }}</div>
        </div>
      </template>
      <template v-slot:[`item.receiverInfo`]="{ item }">
        <div v-if="item.receiverInfo">
          <div>{{ item.receiverInfo.name }}</div>
          <span v-if="
            item.receiverInfo.phoneNumber != null &&
            item.receiverInfo.phoneNumber != ' '
          ">
            <v-chip color="green lighten-3" label>{{ item.receiverInfo.phoneNumber }}
            </v-chip>
          </span>
        </div>
      </template>
      <template v-slot:[`item.serviceType.name`]="{ item }">
        <span>
          <v-chip color="blue lighten-3" label>{{ item.serviceType.name }}
          </v-chip>
        </span>
      </template>
      <template v-slot:[`item.manualNo`]="{ item }">
        <div v-if="item.manualNo != 0">
          {{ item.manualNo }}
        </div>
        <div v-else>
          <span>
            <v-chip style="font-size: smaller" color="green lighten-3" label>ادخال مباشر</v-chip>
          </span>
        </div>
      </template>
      <template v-slot:[`item.trip.trackingNumber`]="{ item }">
        <div v-if="item.trip.trackingNumber">
          <div v-if="item.trip.trackingNumber">
            {{ item.trip.trackingNumber }}
          </div>
          <div v-if="item.trip.line.name">
            <span>
              <v-chip style="font-size: smaller" color="green lighten-3" label>{{ item.trip.line.name }}
              </v-chip>
            </span>
          </div>
        </div>
      </template>
      <template v-slot:[`item.trackingNumber`]="{ item }">
        <div v-if="item.trackingNumber">
          <div v-if="item.trackingNumber">
            {{ item.trackingNumber }}
          </div>
          <div v-if="item.date">
            <span>
              <v-chip
                v-if="item.state == 1 && isDateGreater(new Date(item.date), new Date(), 3)"
                style="font-size: smaller" color="red lighten-3" label>{{ item.date | ToDate }}
              </v-chip>
              <v-chip v-else style="font-size: smaller" color="green lighten-3" label>{{ item.date | ToDate }}
              </v-chip>
            </span>
          </div>
        </div>
      </template>
      <template v-slot:[`item.items`]="{ item }">
        <div v-if="item.items">
          <div v-if="item.items[0].merchandise">
            {{ item.items[0].merchandise.name }}
          </div>
          <div v-if="item.items[0]">{{ item.items[0].quantity }}</div>
        </div>
      </template>
      <template v-slot:[`item.isInventory`]="{ item }">
        <div v-if="item.isInventoried">
          <v-chip style="font-size: smaller" color="green lighten-3" label>تم الجرد</v-chip>
        </div>
        <div v-else>
          <v-chip style="font-size: smaller" color="red lighten-3" label>{{ item.items[0].quantity }} - {{ item.inventoriedCount }}</v-chip>
        </div>
      </template>
      <template v-slot:[`item.itemprice`]="{ item }">
        <div v-if="item.items[0]">
          <div v-if="item.items[0].price">
            {{ round(item.items[0].price, 2) }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="item.duePriceReceived == false && item.duePrice > 0" v-can="'Shipments.Update'" color="success"
          class="mr-2" medium @click="duePrice(item.id, true)">
          mdi-cash-multiple</v-icon>
        <v-icon v-if="item.duePriceReceived == true && item.duePrice > 0" v-can="'Shipments.Update'" color="error"
          class="mr-2" medium @click="duePrice(item.id, false)">
          mdi-cash-multiple</v-icon>

        <v-icon v-if="item.otherServicesPriceReceived == false && item.otherServicesPrice > 0"
          v-can="'Shipments.Update'" color="success" class="mr-2" medium @click="otherServicesPrice(item.id, true)">
          mdi-package-variant</v-icon>
        <v-icon v-if="item.otherServicesPriceReceived == true && item.otherServicesPrice > 0" v-can="'Shipments.Update'"
          color="error" class="mr-2" medium @click="otherServicesPrice(item.id, false)">
          mdi-package-variant-remove</v-icon>
        <v-icon v-can="'Shipments.View'" color="orange" class="mr-2" medium @click="view(item.id)">mdi-eye</v-icon>
        <v-icon v-if="item.trip.movementState == 2" v-can="'Shipments.Print'" color="blue" class="mr-2" medium @click="print(item.id)">mdi-printer</v-icon>
        <v-icon v-if="item.paymentType == 1 && item.trip.movementState == 0" color="blue" class="mr-2" medium @click="print(item.id)">mdi-printer</v-icon>
        <v-icon color="success" class="mr-2" medium @click="edit(item.id)">mdi-pen</v-icon>
        <v-icon v-can="'Shipments.Delete'" color="error" class="mr-2" medium @click="del(item.id)">mdi-delete</v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="modals.editShipment" width="80%" class="pa-20" ref="test">
      <v-card class="pa-10">
        <h2 class="text-right">تعديل</h2>
        <AddShipment v-model="selected" :editMode="editMode" ref="editshipmentModal" @valid="valid = $event" />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn :disabled="!valid" color="primary" @click="editShipment()">حفظ</v-btn>

          <v-btn color="red darken-1" text @click="cancelEdit()">إلغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modals.printShipment">
      طباعة البوليصة
      <v-card>
        <v-card-text>
          <ShipmentBarCodeSingle :selected="selected" ref="printShipmentModal" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modals.viewShipment" width="80%" class="pa-20" ref="test" persistent>
      <v-card class="p-2">
        <ViewShipment :selected="selected" @hide="modals.viewShipment = false" ref="viewshipmentModal" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "../services/ShipmentService";

export default {
  name: "ShipmentTable",
  props: ["value", "total"],
  data() {
    return {
      loadings: {
        table: false,
      },
      modals: {
        editShipment: false,
        viewShipment: false,
        printShipment: false,
      },
      valid: false,
      showsearch: true,
      allIncludeTax: false,
      linesPricing: false,
      select: null,
      dialog: false,
      selected: {
        paymentType: 0,
        date: this.formateDateWithMoment(Date.now(), "YYYY-MM-DD"),
        senderInfo: {},
        receiverInfo: {},
        paymentType: 2,
        sender: {},
        manualNo: null,
        bookNum: null,
        senderBranch: {},
        receiverBranch: {},
        receiver: {},
        serviceTypeId: 6,
        items: [
          {
            unitId: 1,
            quantity: 1,
            size: 2,
            total: null,
            addedValueTax: null,
            includeTax: true,
          },
        ],
        netPrice: null,
        totalPrice: null,
        notice: "",
        totalPrice: null,
        addedValueTax: null,
        netPrice: null,
        insurance: null,
        otherServicesPrice: null,
        deliveryPrice: null,
        duePrice: null,
        salesman: {},
        line: {},
        lineId: {},
        salesmanId: null,
      },
      headers: [
        {
          text: "رقم",
          align: "center",
          value: "trackingNumber",
        },
        //   { text: 'التاريخ', value: 'date',align: 'center', },
        {
          text: "الرقم اليدوى",
          value: "manualNo",
          align: "center",
        },
        //   { text: 'نوع الخدمه ', value: 'serviceType',align: 'center', },
        {
          text: "الرحلة",
          value: "trip.trackingNumber",
          align: "center",
        },
        {
          text: " نوع الخدمه ",
          value: "serviceType.name",
          align: "center",
        },
        {
          text: "المرسل",
          value: "senderInfo",
          align: "center",
        },
        {
          text: " المستلم",
          value: "receiverInfo",
          align: "center",
        },
        {
          text: "الأصناف",
          value: "items",
          align: "center",
          width: "10%",
        },
        {
          text: " السعر",
          value: "itemprice",
          align: "center",
          width: "5%",
        },
        {
          text: "اجمالي السعر",
          value: "totalPrice",
          align: "center",
          width: "5%",
        },
        {
          text: "تم الجرد",
          value: "isInventory",
          align: "center",
          width: "5%",
        },
        {
          sortable: false,
          text: "تعديل / مسح",
          value: "actions",
          align: "center",
          width: "17%",
        },
      ],
      message: "",
      search: "",
      editMode: false,
      options: {},
    };
  },

  computed: {
    entities: {
      get() {
        return this.value || [];
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    isDateGreater(d1, d2, days) {
      d1 = new Date(d1);
      return +new Date(d2) > d1.setDate(d1.getDate() + (days || 0))
    },
    otherServicesPrice(id, value) {
      ApiService.otherServicesPrice(id, value)
        .then((resp) => {
          const index = this.entities.indexOf(
            this.entities.filter((e) => e.id == id)[0]
          );
          if (index > -1) {
            this.entities.splice(index, 1);
          }
          if (value == true) {
            this.message = "تم تسديد التغليف بنجاح";
            this.$dialog.notify.success(this.message, {
              position: "top-right",
              timeout: 3000,
            });
          }
          else {
            this.message = "تم الغاء تسديد التغليف بنجاح";
            this.$dialog.notify.error(this.message, {
              position: "top-right",
              timeout: 3000,
            });
          }
        })
        .catch((err) => {
          var message = err.request.response;
          this.$dialog.notify.error(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .finally(() => { });
    },
    duePrice(id, value) {
      ApiService.duePrice(id, value)
        .then((resp) => {
          const index = this.entities.indexOf(
            this.entities.filter((e) => e.id == id)[0]
          );
          if (index > -1) {
            this.entities.splice(index, 1);
          }
          if (value == true) {
            this.message = "تم تسديد المسترد بنجاح";
            this.$dialog.notify.success(this.message, {
              position: "top-right",
              timeout: 3000,
            });
          }
          else {
            this.message = "تم الغاء تسديد المسترد بنجاح";
            this.$dialog.notify.error(this.message, {
              position: "top-right",
              timeout: 3000,
            });
          }
        })
        .catch((err) => {
          var message = err.request.response;
          this.$dialog.notify.error(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .finally(() => { });
    },
    print(id) {
      let routeData = this.$router.resolve({
        name: "ShipmentReceipt",
        query: { shipmentid: id },
      });
      window.open(routeData.href, "_blank");
      //let routeData = this.$router.resolve({
      // name: "ShipmentBarCodeSingle",
      // query: { shipmentid: id },
      //});
      // window.open(routeData.href, "_blank");
    },
    del(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف العنصر",
          title: "  حذف وحدة  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                this.$emit("removed", id);
                ApiService.delete(id).then((resp) => {
                  const index = this.entities.indexOf(
                    this.entities.filter((e) => e.id == id)[0]
                  );
                  if (index > -1) {
                    this.entities.splice(index, 1);
                  }
                  this.message = "تم حذف البوليصة بنجاح";
                  this.$dialog.notify.success(this.message, {
                    position: "top-right",
                    timeout: 3000,
                  });
                });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => { });
    },

    cancelEdit() {
      this.modals.editShipment = false;
      this.selected = ApiService.getDefault();
    },
    view(id) {
      ApiService.getById(id)
        .then((resp) => {
          this.selected = resp.data;
          console.log(this.selected);
          this.modals.viewShipment = true;
        })
        .catch((resp) => { })
        .finally(() => { });
    },
    edit(id) {
      this.selected = ApiService.getDefault();
      this.selected = ApiService.setForEdit({
        ...this.entities?.filter((e) => e.id == id)[0],
      });
      this.selected = this.clone(this.selected);

      this.modals.editShipment = true;

      var vue = this;
      setTimeout((e) => {
        vue.$refs.editshipmentModal.updatePrice();
        vue.$refs.editshipmentModal.setSender(vue.selected.senderInfo);
        vue.$refs.editshipmentModal.setReceiver(vue.selected.receiverInfo);
      }, 200);
    },
    editShipment() {
      if (!this.valid)
          return;
      var newShipment = ApiService.newShipment(this.selected);
      ApiService.edit(newShipment)
        .then((resp) => {
          this.modals.editShipment = false;
          this.updateArr(this.entities, resp.data);

          this.message = "تم تعديل العنصر بنجاح";
          this.$dialog.notify.success(this.message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          var message = err.request.response;
          this.$dialog.notify.error(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .finally(() => { });
    },
  },
  watch: {
    options() {
      this.$emit("options", this.options);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
