import Vue from 'vue';
import Vuex from 'vuex';
import PermissionsService from "../features/System/services/PermissionsService.js";

Vue.use(Vuex);
import axios from 'axios';
export default new Vuex.Store({
  state: {
    printData: null,
    user: {},
    token: localStorage.getItem('token') || '',
  },
  getters: {
    printData: state => state.printData,
    companyName() {
      return localStorage.getItem('companyName');
    },
    tax() {
      return parseFloat(localStorage.getItem('tax'));
    },
    isAuthenticated: state => !!state.token,
    user() {
      var u = localStorage.getItem('user');
      if (u)
        return JSON.parse(u);
    }
  },
  mutations: {
    printData(state, payload) {
      state.printData = payload
    },
    setUser(state, resp) {
      state.user = resp.user
      localStorage.setItem('token', `Bearer ${resp.data.token}`);
      localStorage.setItem('user', JSON.stringify(resp.data.user));
      let permissions = [];
      resp.data.user.permissions.forEach((element) => {
        element.permissions
          .forEach((element) => {
            permissions.push(element.nameEn);
          });
      });
      localStorage.setItem('permissions', JSON.stringify(permissions));
    },
    logOut(state) {
      state.user = null
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('permissions');
    },
  },
  actions: {
    async login({ commit }, user) {
      await axios.post('account/login', user).then(resp => {
        commit('setUser', resp);
      })
    },
    async logOut({ commit }, user) {
      await axios.post('account/logout?id=' + `${user.id}`).then(resp => {
        commit('logOut');
      })
    }
  },
  modules: {
  },
});
