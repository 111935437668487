var enums = {};
enums.TripCostType = [
   { text: 'ايجار نقدا', value: 1 },
   { text: 'ايجار على الفرع ', value: 2 }
]
enums.TicketsClientType = [
   { text: 'فرد', value: 1 },
   { text: 'مؤسسة', value: 2 }
]
enums.TicketsType = [
   { text: 'عادى', value: 1 },
   { text: 'عاجل', value: 2 }
]
enums.TicketsServiceType = [
   { text: 'استفسار', value: 1 },
   { text: 'طلب خدمة', value: 2 },
   { text: 'شكوى', value: 3 },
   { text: 'تسويق', value: 4 }
]
enums.TicketsComplaintType = [
   { text: 'فقدان', value: 1 },
   { text: 'تلف', value: 2 },
   { text: 'اختلاف أسعار', value: 3 },
   { text: 'تأخير وصول', value: 4 }
]
enums.CarOwnership =
   [
      { value: 1, text: "مالك" },
      { value: 2, text: "مستأجر" },
   ]
enums.Sizes =
   [
      { value: 1, text: "كبير" },
      { value: 2, text: "متوسط" },
      { value: 3, text: "صغير" },
   ]

enums.RecipientType =
   [
      { value: 0, text: " لا يوجد مستلم" },
      { value: 1, text: "موزع" },
      { value: 2, text: "عميل" },
      { value: 3, text: "مخرج" },
   ]

enums.mcashingSide =
   [
      { value: 1, text: "ارجاع بوليصه" },
      { value: 2, text: "صرف لرحله" },
      { value: 3, text: "صرف لموظف" },
      { value: 4, text: "صرف لسائق" },
      { value: 5, text: "صرف لمندوب" },
      { value: 6, text: "مصروفات اخرى" },
   ]
enums.PaymentTypes =
   [
      { value: 1, text: "نقدا" },
      { value: 2, text: "الدفع عند التسليم" },
   ]
enums.PaymentPaidTypes =
   [
      //{ value: 0, text: "غير محدد" },
      { value: 2, text: "كاش" },
      { value: 1, text: "شبكة" },
      { value: 5, text: "كاش + شبكة" },
      { value: 3, text: "تحويل بنكي" },
      { value: 4, text: "تحت التحصيل" },
   ]
enums.TripMovementTypes =
   [
      { value: 1, text: "لم تغادر" },
      { value: 2, text: "تم المغادرة" },
      { value: 3, text: "تم الوصول" },
      { value: 4, text: "تم الوصول في الترانزيت" },
   ]
enums.ExpenseReceiptPaymentTypes =
   [
      { value: 1, text: "صندوق" },
      { value: 2, text: "عهدة" },
   ]
enums.Cities =
   [
      { value: 11, text: "جدة" },
      { value: 21, text: "القنفذة" },
      { value: 9, text: "جيزان" },
      { value: 10, text: "ابوعريش" },
      { value: 8, text: "صبيا" },
      { value: 44, text: "سكاكا" },
      { value: 61, text: "بيشة" },
      { value: 43, text: "الرياض" },
   ]
enums.ShipmentsReportsTypes =
   [
      { value: 1, text: "نقص" },
      { value: 2, text: "زياده" },
      { value: 3, text: "تعديل سعر" },
      { value: 4, text: "أخرى" },
   ]
enums.DiscountTypes =
   [
      { value: 1, text: "خصم تشغيلي مسموح بة" },
      { value: 2, text: "خصم مستردة داخلي" },
      { value: 3, text: "خصم مستردة خارجي" },
      { value: 4, text: "أخري" },
   ]
enums.ShipmentsReportsStatesnc =
   [
      { value: 1, text: "جارى المعالجة" },
      { value: 2, text: "تمت المعالجة" },
   ]
enums.States =
   [
      { text: 'مفتوح', icon: 'mdi-lock-open-variant', color: 'green', value: 1 },
      { text: 'مغلق', icon: 'mdi-lock', color: 'red', value: 2 },
      { text: 'مرحل', icon: 'mdi-lock-clock', color: 'orange', value: 3 },
   ]
enums.TripStates =
   [
      { text: 'مفتوحة', icon: 'mdi-lock-open-variant', color: 'green', value: 1 },
      { text: 'مغلقة', icon: 'mdi-lock', color: 'red', value: 2 },
      { text: 'مرحلة', icon: 'mdi-lock-clock', color: 'orange', value: 3 },
   ]
enums.TripStatesChange =
   [
      { text: 'اعادة فتح', icon: 'mdi-lock-open-variant', color: 'green', value: 1 },
      { text: 'غلق', icon: 'mdi-lock', color: 'red', value: 2 },
      { text: 'ترحيل', icon: 'mdi-lock-clock', color: 'orange', value: 3 },
   ]
enums.TripMovement =
   [
      { text: 'لم يتم التغير بعد', icon: 'mdi-lock-open-variant', color: 'red', value: 0 },
      { text: 'تم مغادرة الرحلة', icon: 'mdi-arrow-up', color: 'blue', value: 1 },
      { text: 'تم وصول الرحلة', icon: 'mdi-arrow-down', color: 'green', value: 2 },
      { text: 'تم الوصول في الترانزيت', icon: 'mdi-arrow-down', color: 'orange', value: 4 },
   ]
enums.ShipmentTracking =
   [
      { text: 'تم استلام الشحنة في فرع ', color: 'color: black;', value: 1 },
      { text: 'تمت مغادرة الشحنة من فرع ', color: 'color: black;', value: 2 },
      { text: 'تم وصول الشحنة إلى فرع ', color: 'color: black;', value: 3 },
      { text: 'الشحنة في الطريق للمستلم', color: 'color: blue;', value: 4 },
      { text: 'تم توصيل الشحنة بنجاح', color: 'color: green;', value: 5 },
      { text: 'فشل تسليم الشحنة - عادت إلى المستودع', color: 'color: red;', value: 6 },
      { text: 'تم وصول الشحنة إلى فرع الترانزيت ', color: 'color: black;', value: 7 },
   ]
enums.ShipmentStates =
   [
      { text: 'لم تسلم', icon: 'fas fa-calendar-minus', color: 'red', value: 0 },
      { text: 'تحت التحصيل', icon: 'fas fa-clock', color: 'orange', value: 1 },
      { text: 'سلمت ', icon: 'far fa-check-circle', color: 'green', value: 2 },
   ]
enums.RecordStates =
   [
      { text: 'مفتوح', icon: 'mdi-lock-open-variant', color: 'green', value: 1 },
      { text: 'مغلق', icon: 'mdi-lock', color: 'red', value: 2 },
      { text: 'مرحل', icon: 'mdi-lock-clock', color: 'orange', value: 3 },
   ]
enums.ShipmentsReportsStates =
   [
      { text: 'جارى المعالجة', color: 'orange', value: 1 },
      { text: 'تمت المعالجة', color: 'green', value: 2 },
   ]

export default enums;