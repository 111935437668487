<template>
  <v-app>
    <div>
      <v-row class="pr-5 pb-3 mt-3">
        <v-col class="d-flex justify-content-start">
          <v-btn color="success" @click="add">
            اضافة سيارة<i class="fa fa-plus mr-2"></i>
          </v-btn>
        </v-col>
      </v-row>
      <v-card width="98%" lass=" ma-4">
        <v-data-table
          :headers="headers"
          :items="entities"
          :options.sync="options"
          :server-items-length="total"
          :search="search"
          :items-per-page="15"
          :loading="loadingStates.table"
          class="elevation-1"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-plus',
            nextIcon: 'mdi-minus',
            'items-per-page-text': 'عدد السيارات ' + total + '',
          }"
        >
          <template v-slot:top>
            <v-row class="mx-auto mt-3 pa-5" dark dense>
              <v-col cols="12" sm="2">
                <v-text-field
                  v-model="options.Id"
                  label="كود السيارة"
                  hide-details
                  dense
                  outlined
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  v-model="options.ownerName"
                  label="اسم المالك"
                  hide-details
                  dense
                  outlined
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  v-model="options.plate"
                  label="رقم اللوحة"
                  hide-details
                  dense
                  outlined
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="2">
                <v-autocomplete
                      dense
                      outlined
                      label="النوع"
                      :items="brandList"
                      item-value="id"
                      item-text="name"
                      auto-select-first
                      v-model="options.carTypeId">
                    </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2">
                <v-autocomplete
                  outlined
                  dense
                  label="السائق"
                  :items="allDrivers"
                  item-value="id"
                  item-text="name"
                  auto-select-first
                  v-model="options.driverId"
                  v-if="allDrivers"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2">
                <v-autocomplete
                  outlined
                  dense
                  label="المسار"
                  :items="lines"
                  item-value="id"
                  item-text="name"
                  auto-select-first
                  v-model="options.lineId"
                  v-if="lines"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12">
                <v-btn color="error" @click="resetOptions"
                  >تفريغ
                  <i class="fa fa-trash fa-lg"></i>
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.driver`]="{ item }">
            <span v-if="item.driver">
              {{ item.driver.name }}
            </span>
          </template>
          <template v-slot:[`item.carType`]="{ item }">
            <span v-if="item.carType">
              {{ item.carType.name }}
            </span>
          </template>
          <template v-slot:[`item.line`]="{ item }">
            <span v-if="item.line">
              {{ item.line.name }}
            </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              color="red"
              class="ml-2"
              icon
              elevation="2"
              outlined
              @click="del(item.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>

            <v-btn color="primary" elevation="2" icon @click="edit(item.id)"
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-card>

      <!-- Add Modal -->
      <v-dialog v-model="dialog" width="800" persistent>
        <v-card>
          <v-form v-model="valid">
            <v-card-title class="headline">
              <i class="fa fa-pen ml-2"></i> تعديل/إضافة
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="my-0 ammar" dense>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      :rules="[allRules.required, allRules.length(4)]"
                      outlined
                      label="رقم اللوحة"
                      v-model="selected.plate"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      :rules="[allRules.required]"
                      outlined
                      label=" الماركة"
                      v-model="selected.mark"
                    >
                    </v-text-field>
                  </v-col>
                 <!--  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      outlined
                      label="رقم الشاسيه"
                      v-model="selected.chassis"
                    >
                    </v-text-field>
                  </v-col>-->
                </v-row>
                <v-row class="my-0 ammar" dense>
                  <v-col>
                    <v-text-field
                      dense
                      outlined
                      label="اسم المالك"
                      v-model="selected.owner"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      outlined
                      label="الموديل"
                      v-model="selected.model"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- CheckStartDate Date -->
                <!-- start
                <v-row class="my-0" dense>
                  <v-col cols="12" sm="6">
                    <DatePicker-layout
                      @myEvent="selected.checkStartDate = $event"
                      label="تاريخ الفحص"
                      :date="selected.checkStartDate | ToDate"
                      v-model="selected.checkStartDate"
                    ></DatePicker-layout>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <DatePicker-layout
                      @myEvent="selected.checkEndDate = $event"
                      label="تاريخ الانتهاء"
                      :date="selected.checkEndDate | ToDate"
                      v-model="selected.checkEndDate"
                    ></DatePicker-layout>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <DatePicker-layout
                      @myEvent="selected.docStartDate = $event"
                      label="تاريخ الاستماره"
                      :date="selected.docStartDate | ToDate"
                      v-model="selected.docStartDate"
                    ></DatePicker-layout>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <DatePicker-layout
                      @myEvent="selected.docEndDate = $event"
                      label="تاريخ الانتهاء"
                      :date="selected.docEndDate | ToDate"
                      v-model="selected.docEndDate"
                    ></DatePicker-layout>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <DatePicker-layout
                      @myEvent="selected.purDate = $event"
                      label="التاريخ "
                      :date="selected.purDate | ToDate"
                      v-model="selected.purDate"
                    ></DatePicker-layout>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      outlined
                      type="number"
                      label="الوزن"
                      v-model="selected.weight"
                    >
                    </v-text-field>
                  </v-col>
                </v-row> -->
                <!-- finished -->

                <v-row class="my-0 ammar" dense>
                  <v-col cols="12" sm="12">
                    <v-autocomplete
                      :rules="[allRules.required]"
                      dense
                      outlined
                      label="النوع"
                      :items="brandList"
                      item-value="id"
                      item-text="name"
                      auto-select-first
                      v-model="selected.carTypeId"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="my-0 ammar" dense>
                  <v-col cols="12" sm="12">
                    <v-autocomplete
                      :rules="[allRules.required]"
                      outlined
                      dense
                      label="السائق"
                      :items="allDrivers"
                      item-value="id"
                      item-text="name"
                      auto-select-first
                      v-model="selected.driverId"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="my-0 ammar" dense>
                  <v-col cols="12" sm="12">
                    <v-autocomplete
                      :rules="[allRules.required]"
                      outlined
                      dense
                      label="المسار"
                      :items="lines"
                      item-value="id"
                      item-text="name"
                      auto-select-first
                      v-model="selected.lineId"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="my-0 ammar" dense>
                  <v-textarea
                    name="input-7-1"
                    label="الملاحظات"
                    outlined
                    v-model="selected.notice"
                  ></v-textarea>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                :disabled="!valid"
                @click="editConfirmed()"
              >
                حفظ
              </v-btn>

              <v-btn
                color="red darken-1"
                class="white--text"
                @click="cancelEdit()"
              >
                إلغاء
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <!-- Add Modal -->
    </div>
  </v-app>
</template>

<script>
import ApiService from "../services/CarService.js";
import driverApi from "../services/DriverService.js";
import carApi from "../../Settings/services/CarTypesService";
import lineApi from "../services/LinesService.js";
export default {
  data() {
    return {
      lines: [],
      selected: {},
      valid: false,
      entities: [],
      total: 0,
      brandList: null,
      allDrivers: null,
      search: "",
      options: {
        Id: null,
        ownerName: null,
        plate: null,
        mark: null,
        driverId: null,
        lineId: null,
        carTypeId: null,
      },
      dialog: false,
      loadingStates: {
        table: false,
      },
      headers: [
        {
          text: "رقم",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "اللوحة", value: "plate" },
        { text: "الماركة", value: "mark" },
        { text: "الموديل", value: "model" },
        { text: "النوع", value: "carType" },
        { text: "السائق", value: "driver" },
        { text: "المالك", value: "owner" },
        { text: "المسار", value: "line" },
        { text: "مسح / تعديل", value: "actions", align: "left" },
      ],
    };
  },
  async mounted() {
    try {
      lineApi.typeHead("", true, false, false).then(resp => {
        this.lines = resp.data;
      });
      const cars = await carApi.get();
      const drivers = await driverApi.typeHead("", true, false);
      this.brandList = cars.data;
      this.allDrivers = drivers.data;
      this.getData();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    resetOptions() {
      this.options = {
        Id: null,
        ownerName: null,
        plate: null,
        mark: null,
        driverId: null,
        lineId: null,
        carTypeId: null,
      };
    },
    getdriversList(searchTerm) {
      return driverApi.typeHead(searchTerm, false, false);
    },
    getData() {
      this.loadingStates.table = true;
      if (this.options) this.options.searchTerm = this.search;
      ApiService.query(this.$clean(this.options))
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
        });
    },
    editConfirmed() {
      ApiService.edit(this.selected)
        .then((resp) => {
          this.dialog = false;
          var message = "تم تعديل العنصر بنجاح";
          if (!this.selected.id) {
            this.addToArr(this.entities, resp.data);
            message = "تم إضافة العنصر بنجاح";
          } else {
            this.updateArr(this.entities, resp.data);
          }

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.dialog = false;
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },

    cancelEdit() {
      this.dialog = false;
      this.selected = {};
    },
    add() {
      let d = this.formateDateWithMoment(new Date(), "YYYY-MM-DD");
      (this.selected = {
        checkStartDate: d,
        checkEndDate: d,
        docStartDate: d,
        docEndDate: d,
        purDate: d,
      }),
        (this.dialog = true);
    },
    edit(id) {
      this.selected = { ...this.entities.filter((e) => e.id == id)[0] };
      this.dialog = true;
    },
    del(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف العنصر",
          title: "  حذف سيارة  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                ApiService.delete(id)
                  .then((resp) => {
                    const index = this.entities.indexOf(
                      this.entities.filter((e) => e.id == id)[0]
                    );
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("تم مسح العنص بنجاح", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("خطأ اثناء مسح العنصر", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => {});
    },
    view(id) {},
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
</style>
