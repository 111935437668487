<template>
  <div>
    <v-row class="pr-5 pb-3 mt-3">
      <v-col class="d-flex justify-content-start">
        <v-btn v-can="'Countries.Create'" color="success" @click="add">
          اضافة دولة<i class="fa fa-plus mr-2"></i>
        </v-btn>
      </v-col>
    </v-row>
    <v-card width="80%" class="mx-auto mr-5">
      <v-data-table
        v-can="'Countries.View'"
        :headers="headers"
        :items="countries"
        :items-per-page="15"
        :loading="this.loadingStates.table"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-plus',
          nextIcon: 'mdi-minus',
          'items-per-page-text': 'عدد الدول ' + countries.length + '',
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-can="'Cities.View'"
            color="orange"
            class="mr-2"
            icon
            elevation="2"
            outlined
            @click="viewCities(item.id)"
          >
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn
            v-can="'Countries.Delete'"
            color="red"
            class="mr-2 ml-2"
            icon
            elevation="2"
            outlined
            @click="del(item.id)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn
            v-can="'Countries.Update'"
            color="primary"
            elevation="2"
            icon
            @click="edit(item.id)"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>

    <!-- Add Modal -->
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-form v-model="valid">
          <v-card-title class="headline">
            <i class="fa fa-pen ml-2"></i> تعديل/إضافة
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="my-0 ammar" dense>
                <v-col>
                  <v-text-field
                    dense
                    :rules="[allRules.required, allRules.minNameLen(2)]"
                    outlined
                    label="اسم الدولة"
                    v-model="country.name"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-0 ammar" dense>
                <v-textarea
                  dense
                  hide-details
                  outlined
                  name="input-7-1"
                  label="ألملاحظات"
                  v-model="country.notes"
                ></v-textarea>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" :disabled="!valid" @click="editConfirmed()">
              حفظ
            </v-btn>

            <v-btn color="red darken-1" text @click="cancelEdit()">
              إلغاء
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Add Modal -->
  </div>
</template>

<script>
import CountryService from "../../services/CountryService.js";
export default {
  data() {
    return {
      valid: false,
      country: {},
      countries: [],
      dialog: false,
      loadingStates: {
        table: false,
      },
      headers: [
        {
          text: "رقم",
          align: "start",
          value: "id",
        },
        { text: "الاسم", value: "name" },
        { text: "مسح / تعديل", value: "actions", align: "left" },
      ],
    };
  },
  mounted() {
    this.loadingStates.table = true;
    CountryService.get()
      .then((resp) => {
        this.countries = resp.data;
        this.loadingStates.table = false;
      })
      .catch((err) => {
        this.loadingStates.table = false;
      });
  },
  methods: {
    viewCities(id) {
      this.$router.push(`/settings/cities/${id}`);
    },
    editConfirmed() {
      CountryService.edit(this.country)
        .then((resp) => {
          this.dialog = false;
          var message = "تم تعديل العنصر بنجاح";
          if (!this.country.id) {
            this.countries.push(resp.data);
            message = "تم إضافة العنصر بنجاح";
          } else {
            this.updateArr(this.countries, resp.data);
          }
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.dialog = false;
          console.log(err.request.response);
          this.$dialog.notify.error("خطأ اثناء تعديل العنصر", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },

    cancelEdit() {
      this.dialog = false;
      this.country = {};
    },
    add() {
      this.country = {};
      this.dialog = true;
    },
    edit(id) {
      this.country = { ...this.countries.filter((e) => e.id == id)[0] };
      this.dialog = true;
    },
    del(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف العنصر",
          title: "  حذف دولة  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                CountryService.delete(id)
                  .then((resp) => {
                    const index = this.countries.indexOf(
                      this.countries.filter((e) => e.id == id)[0]
                    );
                    if (index > -1) {
                      this.countries.splice(index, 1);
                    }
                    this.$dialog.notify.success("تم مسح العنص بنجاح", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("خطأ اثناء مسح العنصر", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => {});
    },
    view(id) {},
  },
};
</script>

<style lang="scss" scoped>
</style>