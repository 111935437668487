import moment from "moment";

var Utils = {
  toDate: function (date) {
    return this.formatDate(date, 'YYYY-MM-DD')
  },
  formatDate: function (date, format) {
    if (!date)
      return null;
    return moment(date).format(format)
  },
};


export default Utils;