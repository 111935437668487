<template>
  <div>
    <v-tabs v-model="activeTab" fixed-tabs next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline" show-arrows center-active background-color="orange accent-1"
      color="black" class="rounded-lg" light>
      <v-tab>الاصناف</v-tab>
      <v-tab>اصناف المسارات</v-tab>
      <v-tab-item>
        <v-row class="pr-5 pb-3 mt-3">
          <v-col class="d-flex justify-content-start">
            <v-btn v-can="'Merchandises.Create'" color="success" @click="add">
              اضافة صنف<i class="fa fa-plus mr-2"></i>
            </v-btn>
          </v-col>
        </v-row>
        <v-card width="99%" class="mx-auto ma-3">
          <v-data-table v-can="'Merchandises.View'" :headers="headers" :items="entities" :options.sync="options"
            :server-items-length="total" :search="search" :items-per-page="15" :loading="loadingStates.table"
            class="elevation-1" :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-plus',
              nextIcon: 'mdi-minus',
              'items-per-page-text': 'عدد الاصناف ' + total + '',
            }">
            <template v-slot:top>
              <v-col cols="12" sm="4">
                <v-text-field v-model="search" label="كود/اسم الصنف" hide-details dense outlined class="mx-4"
                  clearable></v-text-field></v-col>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn v-can="'Merchandises.Delete'" color="red" class="ml-2" icon elevation="2" outlined
                @click="del(item.id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <v-btn v-can="'Merchandises.Update'" color="primary" elevation="2" icon
                @click="edit(item.id)"><v-icon>mdi-pencil</v-icon></v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-row class="pr-5 pb-3 mt-3">
          <v-col class="d-flex justify-content-start">
            <v-btn v-can="'Merchandises.Create'" color="success" @click="addline">
              اضافة صنف لمسار<i class="fa fa-plus mr-2"></i>
            </v-btn>
          </v-col>
        </v-row>
        <v-card width="99%" class="mx-auto ma-3">
          <v-data-table v-can="'Merchandises.View'" :headers="headersLines" :items="entitiesLines"
            :options.sync="optionsLines" :server-items-length="totalLines" :search="searchLines" :items-per-page="15"
            :loading="loadingStates.tableLines" class="elevation-1" :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-plus',
              nextIcon: 'mdi-minus',
              'items-per-page-text': 'عدد الاصناف ' + totalLines + '',
            }">
            <template v-slot:top>
              <v-row>
                <v-col cols="12" sm="3">
                  <v-text-field v-model="searchLines" label="كود/اسم الصنف" hide-details dense outlined class="mx-4"
                    clearable></v-text-field></v-col>
                <v-col cols="12" sm="3">
                  <v-autocomplete dense clearable outlined hide-details auto-select-first label="المسار" :items="lines"
                    item-value="id" item-text="name" v-model="optionsLines.lineId"></v-autocomplete>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.includeTax`]="{ item }">
              <span v-if="item.includeTax">
                <v-chip label color="info">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </v-chip>
              </span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn v-can="'Merchandises.Delete'" color="red" class="ml-2" icon elevation="2" outlined
                @click="delline(item.id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <v-btn v-can="'Merchandises.Update'" color="primary" elevation="2" icon
                @click="editline(item.id)"><v-icon>mdi-pencil</v-icon></v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <!-- Add Modal -->
    <v-dialog v-model="dialog" width="50%" persistent>
      <v-card>
        <v-form v-model="valid">
          <v-card-title class="headline">
            <i class="fa fa-pen ml-2"></i> تعديل/إضافة
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="my-0 ammar" dense>
                <v-col cols="12" sm="6">
                  <v-text-field dense :rules="[allRules.required, allRules.minNameLen(2)]" outlined label="اسم الصنف"
                    v-model="selected.name">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field type="number" outlined :rules="[allRules.required]" dense label="السعر "
                    v-model="selected.price"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-0 ammar" dense>
                <v-textarea rows="2" dense hide-details outlined name="input-7-1" label="ألملاحظات"
                  v-model="selected.notice"></v-textarea>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" :disabled="!valid" @click="editConfirmed()">
              حفظ
            </v-btn>

            <v-btn color="red darken-1" text @click="cancelEdit()">
              إلغاء
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Add Modal -->
    <!-- Add LinesModal -->
    <v-dialog v-model="linesDialog" width="50%" persistent>
      <v-card>
        <v-form v-model="valid">
          <v-card-title class="headline">
            <i class="fa fa-pen ml-2"></i> {{ dialogTitle }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="my-0 ammar" dense>
                <v-col cols="12" sm="6">
                  <v-autocomplete chips clearable deletable-chips multiple small-chips :rules="[allRules.required]"
                    dense outlined label="الصنف" :items="MerchandisesList" item-text="name" return-object
                    auto-select-first v-model="selected.merchandise" ref="num17">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" v-if="dialogTitle == 'تعديل'">
                  <v-autocomplete :rules="[allRules.required]" dense outlined label="المسار"
                    :items="lines" item-value="id" item-text="name" auto-select-first v-model="selected.lineId">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" v-else>
                  <v-autocomplete :rules="[allRules.required]" dense outlined chips clearable
                    deletable-chips multiple small-chips label="المسار" :items="lines" item-text="name" return-object
                    auto-select-first v-model="selected.line">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field type="number" outlined :rules="[allRules.required]" dense label="السعر "
                    v-model="selected.price"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-checkbox v-model="selected.includeTax" label="متضمن الضريبة" color="green darken-3"
                    :false-value="false" :true-value="true"></v-checkbox></v-col>
              </v-row>
              <v-row class="my-0 ammar" dense>
                <v-textarea rows="2" dense hide-details outlined name="input-7-1" label="ألملاحظات"
                  v-model="selected.notice"></v-textarea>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" :disabled="!valid" @click="editConfirmedline()">
              حفظ
            </v-btn>

            <v-btn color="red darken-1" text @click="cancelEditline()">
              إلغاء
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Add LinesModal -->
  </div>
</template>

<script>
import ApiService from "../services/MerchandisesService.js";
import ApiLinesService from "../services/MerchandiseLinesService.js";
import lineApi from "../services/LinesService.js";
export default {
  data() {
    return {
      valid: false,
      activeTab: 0,
      selected: {
        merchandise: [],
        line: [],
      },
      entities: [],
      entitiesLines: [],
      MerchandisesList: [],
      lines: [],
      total: 0,
      totalLines: 0,
      search: "",
      dialogTitle: "",
      options: {},
      searchLines: "",
      optionsLines: {},
      linesDialog: false,
      dialog: false,
      loadingStates: {
        table: false,
        tableLines: false,
      },
      headers: [
        {
          text: "رقم",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "اسم الصنف", value: "name" },
        { text: "السعر", value: "price" },
        { text: "الملاحظات", value: "notice" },
        { text: "مسح / تعديل", value: "actions", align: "left" },
      ],
      headersLines: [
        {
          text: "رقم",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "اسم الصنف", value: "merchandise.name" },
        { text: "المسار", value: "line.name" },
        { text: "السعر", value: "price" },
        { text: "متضمن الضريبة", value: "includeTax" },
        { text: "الملاحظات", value: "notice" },
        { text: "مسح / تعديل", value: "actions", align: "left" },
      ],
    };
  },
  async created() {
    try {
      lineApi.typeHead("", true, false, false).then(resp => {
        this.lines = resp.data;
      });
      const Merchandises = await ApiService.typeHead("", true);
      this.MerchandisesList = Merchandises.data;
    } catch (error) {
      console.log(error);
    }
  },
  mounted() {
    this.getData();
    this.getDataline();
  },
  methods: {
    merchandiseFilter(item, queryText, itemText) {
      return (
        item.id.toString().includes(queryText) || item.name.includes(queryText)
      );
    },
    getData() {
      this.loadingStates.table = true;
      if (this.options) this.options.searchTerm = this.search;
      if (this.search == null) {
        this.search = "";
        this.options.searchTerm = "";
      }
      ApiService.query(this.options)
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          console.log(err);

          this.loadingStates.table = false;
        });
    },
    getDataline() {
      console.log(this.optionsLines.lineId);
      this.loadingStates.tableLines = true;
      if (this.optionsLines) this.optionsLines.searchTerm = this.searchLines;
      if (this.searchLines == null) {
        this.searchLines = "";
        this.optionsLines.searchTerm = "";
      }
      ApiLinesService.query(this.$clean(this.optionsLines))
        .then((resp) => {
          this.entitiesLines = resp.data.items;
          this.totalLines = resp.data.total;
          this.loadingStates.tableLines = false;
        })
        .catch((err) => {
          console.log(err);

          this.loadingStates.tableLines = false;
        });
    },
    editConfirmed() {
      ApiService.edit(this.selected)
        .then((resp) => {
          this.dialog = false;
          var message = "تم تعديل العنصر بنجاح";
          if (!this.selected.id) {
            this.addToArr(this.entities, resp.data);
            message = "تم إضافة العنصر بنجاح";
          } else {
            this.updateArr(this.entities, resp.data);
          }

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.dialog = false;
          console.log(err.request.response);
          this.$dialog.notify.error("خطأ اثناء تعديل العنصر", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    editConfirmedline() {
      ApiLinesService.edit(this.selected)
        .then((resp) => {
          this.linesDialog = false;
          var message = "تم تعديل العنصر بنجاح";
          if (!this.selected.id) {
            this.addToArr(this.entitiesLines, resp.data);
            message = "تم إضافة العنصر بنجاح";
          } else {
            this.updateArr(this.entitiesLines, resp.data);
          }

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.linesDialog = false;
          console.log(err.request.response);
          this.$dialog.notify.error("خطأ اثناء تعديل العنصر", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    cancelEdit() {
      this.dialog = false;
      this.selected = {};
    },
    add() {
      this.selected = {};
      this.dialog = true;
    },
    addline() {
      this.selected = {};
      this.linesDialog = true;
      this.dialogTitle = "اضافة صنف لمسار";
    },
    cancelEditline() {
      this.linesDialog = false;
      this.selected = {};
    },
    edit(id) {
      this.selected = { ...this.entities.filter((e) => e.id == id)[0] };
      this.dialog = true;
    },
    editline(id) {
      this.selected = { ...this.entitiesLines.filter((e) => e.id == id)[0] };
      this.linesDialog = true;
      this.dialogTitle = "تعديل";
    },
    del(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف العنصر",
          title: "  حذف صنف  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                ApiService.delete(id)
                  .then((resp) => {
                    const index = this.entities.indexOf(
                      this.entities.filter((e) => e.id == id)[0]
                    );
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("تم مسح العنص بنجاح", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("خطأ اثناء مسح العنصر", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => { });
    },
    delline(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف العنصر",
          title: "  حذف صنف  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                ApiLinesService.delete(id)
                  .then((resp) => {
                    const index = this.entitiesLines.indexOf(
                      this.entitiesLines.filter((e) => e.id == id)[0]
                    );
                    if (index > -1) {
                      this.entitiesLines.splice(index, 1);
                    }
                    this.$dialog.notify.success("تم مسح العنص بنجاح", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("خطأ اثناء مسح العنصر", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => { });
    },
    view(id) { },
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    optionsLines: {
      handler() {
        this.getDataline();
      },
      deep: true,
    },
    searchLines: {
      handler() {
        this.getDataline();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
</style>