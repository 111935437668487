<template>
  <v-app>
    <v-container>
      <div class="content" v-if="printData">
        <div>
          <v-row class="" justify="center">
            <v-col cols="4" evaluate="2" class="data pa-2 text-h4 font-weight-bold text-center">
              {{ this.$store.getters.companyName }}
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row justify="space-between" class="ammar" dense>
            <v-col cols="5" class="mt-9 text-right">
              <h5 class="mb-1">حركة البوالص حسب المعطيات</h5>
            </v-col>

            <v-col cols="3" class="mt-5 text-right">
              <v-row>
                <v-col cols="4"> تاريخ الطباعة </v-col>
                <v-col cols="5" class="">{{ print.date }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4"> التوقيت </v-col>
                <v-col cols="5" class="">{{ print.time }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <v-row class="mb-5">
          <v-col cols="12 m-0 p-0 mt-5">
            <v-data-table :headers="headers" :items="shipments" :server-items-length="total" :items-per-page="-1"
              class="elevation-1" group-by="paymentType" hide-default-footer>
              <template v-slot:[`group.header`]="{ items }">
                <th :colspan="options.paymentType == null ? 8 : 2" style="height: auto; border: red solid 2px !important">
                  {{ items[0].paymentType | PaymentTypes }}
                </th>
                <th v-if="
                  items[0].paymentType == 1 && options.paymentType == null
                " colspan="1" class="text-center red--text" style="height: auto">
                  {{ reviewShipments(1).totalquantity }}
                </th>
                <th v-if="
                  items[0].paymentType == 2 && options.paymentType == null
                " colspan="1" class="text-center red--text" style="height: auto">
                  {{ reviewShipments(2).totalquantity }}
                </th>
                <th v-if="
                  items[0].paymentType == 1 && options.paymentType == null
                " colspan="1" class="text-center red--text" style="height: auto">
                  {{ reviewShipments(1).totalitemsprice }}
                </th>
                <th v-if="
                  items[0].paymentType == 2 && options.paymentType == null
                " colspan="1" class="text-center red--text" style="height: auto">
                  {{ reviewShipments(2).totalitemsprice }}
                </th>
                <th v-if="
                  items[0].paymentType == 1 && options.paymentType == null
                " colspan="1" class="text-center red--text" style="height: auto">
                  {{ reviewShipments(1).totaladdedValueTax }}
                </th>
                <th v-if="
                  items[0].paymentType == 2 && options.paymentType == null
                " colspan="1" class="text-center red--text" style="height: auto">
                  {{ reviewShipments(2).totaladdedValueTax }}
                </th>
                <th v-if="
                  items[0].paymentType == 1 && options.paymentType == null && (options.hasDuePrice == true || options.duePriceReceived == true)
                " colspan="1" class="text-center red--text" style="height: auto">
                  {{ reviewShipments(1).totalduePrice }}
                </th>
                <th v-if="
                  items[0].paymentType == 2 && options.paymentType == null && (options.hasDuePrice == true || options.duePriceReceived == true)
                " colspan="1" class="text-center red--text" style="height: auto">
                  {{ reviewShipments(2).totalduePrice }}
                </th>
                <th v-if="
                  items[0].paymentType == 1 && options.paymentType == null && options.hasOtherServicesPrice == true
                " colspan="1" class="text-center red--text" style="height: auto">
                  {{ reviewShipments(1).totalotherServicesPrice }}
                </th>
                <th v-if="
                  items[0].paymentType == 2 && options.paymentType == null && options.hasOtherServicesPrice == true
                " colspan="1" class="text-center red--text" style="height: auto">
                  {{ reviewShipments(2).totalotherServicesPrice }}
                </th>
                <th v-if="
                  items[0].paymentType == 1 && options.paymentType == null
                " colspan="1" class="text-center red--text" style="height: auto">
                  {{ reviewShipments(1).totalPrice }}
                </th>
                <th v-if="
                  items[0].paymentType == 2 && options.paymentType == null
                " colspan="1" class="text-center red--text" style="height: auto">
                  {{ reviewShipments(2).totalPrice }}
                </th>
              </template>
              <template v-slot:[`item.date`]="{ item }">
                {{ item.date | ToDate }}
              </template>
              <template v-slot:[`item.trip.trackingNumber`]="{ item }">
                <div v-if="item.trip.trackingNumber">
                  <div v-if="item.trip.line.name">
                    {{ item.trip.line.name }}
                  </div>
                  <div class="red--text" v-if="item.paymentPaidType == 0">
                   غير محدد
                  </div>
                  <div v-else>
                   {{ item.paymentPaidType | PaymentPaidTypes }}
                  </div>
                </div>
              </template>
              <template v-slot:[`item.number`]="{ item }">
                {{ shipments.indexOf(item) + 1 }}
              </template>
              <template v-slot:[`item.items`]="{ item }">
                <span v-if="item.items">
                  <span> {{ item.items[0].merchandise.name }} </span>
                </span>
              </template>
              <template v-slot:[`item.itemprice`]="{ item }">
                <div v-if="item.items[0]">
                  <div v-if="item.items[0].price">
                    {{ round(item.items[0].price, 2) }}
                  </div>
                </div>
              </template>
              <template v-slot:[`item.senderInfo`]="{ item }">
                <span v-if="item.senderInfo">
                  <span v-if="item.senderInfo">
                    {{ item.senderInfo.name }}</span>
                </span>
              </template>
              <template v-slot:[`item.receiverInfo`]="{ item }">
                <span v-if="item.receiverInfo">
                  <span v-if="item.receiverInfo.name">
                    {{ item.receiverInfo.name }}</span>
                  <br>
                  <span v-if="item.receiverInfo.phoneNumber">{{ item.receiverInfo.phoneNumber }}</span>
                </span>
              </template>
              <template slot="body.append" v-if="options.paymentType != null">
                <tr class="red--text">
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center">
                    {{ shipmentsTotalquantity }}
                  </td>
                  <td class="text-center">
                    {{ shipmentsTotalitemsprice }}
                  </td>
                  <td class="text-center">
                    {{ shipmentstotaltax }}
                  </td>
                  <td class="text-center">
                    {{ shipmentsPriceTotal }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
      <div v-if="!printData">Loading</div>
    </v-container>
  </v-app>
</template>

<script>
import ShipmentService from "../../LandShipping/services/ShipmentService";
import Enums from "../../../plugins/enums.js";
export default {
  mounted() {
    if (this.$route.query["options"]) {
      var data = JSON.parse(this.$route.query["options"]);
      var branch = JSON.parse(this.$route.query["branch"]);
      if (data) this.options = data;
      this.getDataFromAPI(branch);
    }
  },
  data() {
    return {
      shipments: [],
      printData: false,
      total: 10,
      print: {
        time: new Date().toLocaleTimeString(),
        date: this.formateDateWithMoment(Date.now(), "YYYY-MM-DD"),
      },
      options: {
        trackingNumber: null,
        sender: null,
        receiver: null,
        senderPhoneNumber: null,
        receiverPhoneNumber: null,
        serviceTypeId: null,
        employeeId: null,
        paymentType: null,
        distributorId: null,
        externalShipperId: null,
        salesmanId: null,
        date: null,
        state: -1,
        startDate: null,
        endDate: null,
        manualNoFrom: null,
        manualNoTo: null,
        manualNo: null,
        bookNumber: null,
        duePriceReceived: null,
        hasDuePrice: null,
        otherServicesPriceReceived: null,
        hasOtherServicesPrice: null,
        deliveryDate: null,
        cashPaid: null,
      },
    };
  },
  computed: {
    headers() {
      let headers = [
        {
          text: "م",
          align: "start",
          sortable: true,
          value: "number",
        },
        {
          text: "التاريخ",
          value: "date",
          align: "right",
        },
        {
          text: "الرحلة",
          value: "trip.trackingNumber",
          align: "start",
          width: "15%",
        },
        {
          text: "الرقم اليدوي",
          value: "manualNo",
          align: "right",
        },
        {
          text: "نوع الخدمة",
          value: "serviceType.name",
          align: "right",
          width: "10%",
        },
        {
          text: "المرسل",
          value: "senderInfo",
          align: "center",
          width: "12%",
        },
        {
          text: "المرسل اليه",
          value: "receiverInfo",
          align: "right",
          width: "12%",
        },
        {
          text: "الصنف",
          value: "items",
          align: "right",
          width: "8%",
        },
        {
          text: "عدد الطرود",
          value: "items[0].quantity",
          align: "right",
        },
        {
          text: "سعر الصنف ",
          value: "itemprice",
          align: "right",
        },
        {
          text: "ضريبه الصنف ",
          value: "items[0].addedValueTax",
          align: "right",
        },
        {
          text: "المسترد",
          value: "duePrice",
          align: "right",
        },
        {
          text: "قيمة الامر",
          value: "totalPrice",
          align: "right",
        },
      ];
      if (this.options.hasOtherServicesPrice || this.options.hasOtherServicesPrice == true || this.options.otherServicesPriceReceived || this.options.otherServicesPriceReceived == true) {
        headers.splice(11, 1);
        headers.push({
          text: "التغليف",
          value: "otherServicesPrice",
          align: "right",
        });
      }
      if ((!this.options.hasDuePrice || this.options.hasDuePrice == false) && (!this.options.duePriceReceived || this.options.duePriceReceived == false)) {
        headers.splice(11, 1);
        headers.push({
          text: "الملاحظات",
          value: "notice",
          align: "right",
          width: "20%"
        });
      }
      return headers;
    },
    shipmentsTotalquantity() {
      let total = this.shipments.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.items[0].quantity;
      }, 0);
      return total;
    },
    shipmentsTotalitemsprice() {
      let total = this.shipments.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.items[0].price;
      }, 0);
      return this.round(parseFloat(total));
    },
    shipmentsTotalduePrice() {
      let total = this.shipments.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.duePrice;
      }, 0);
      return this.round(parseFloat(total));
    },
    shipmentsPriceTotal() {
      let total = this.shipments.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.totalPrice;
      }, 0);
      return total;
    },
    shipmentstotaltax() {
      let total = this.shipments.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.items[0].addedValueTax;
      }, 0);
      return this.round(parseFloat(total));
    },
  },
  methods: {
    printopen() {
      setTimeout(() => {
        window.focus();
        var css = "@page { size: landscape; }",
          head = document.head || document.getElementsByTagName("head")[0],
          style = document.createElement("style");

        style.type = "text/css";
        style.media = "print";

        if (style.styleSheet) {
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);
        window.print();
      }, 100);
    },
    getDataFromAPI(isbranch) {
      this.options.itemsPerPage = -1;
      if (isbranch == true) {
        ShipmentService.clientDelivery(this.$clean(this.options)).then(
          (resp) => {
            this.shipments = resp.data.items;
            this.total = resp.data.total;
            console.log(this.shipments);
            this.printData = true;
            this.printopen();
          }
        );
      } else {
        ShipmentService.query(this.$clean(this.options)).then((resp) => {
          this.shipments = resp.data.items;
          console.log(this.shipments);
          this.printData = true;
          this.printopen();
        });
      }
    },
    reviewShipments(value) {
      let reviewShipments = this.shipments.filter((i) => {
        return i.paymentType == value;
      });
      let totalquantity = reviewShipments.reduce(function (
        accumulator,
        currentValue
      ) {
        return accumulator + currentValue.items[0].quantity;
      },
        0);
      let totalitemsprice = reviewShipments.reduce(function (
        accumulator,
        currentValue
      ) {
        return accumulator + currentValue.items[0].price;
      },
        0);
      let totaladdedValueTax = reviewShipments.reduce(function (
        accumulator,
        currentValue
      ) {
        return accumulator + currentValue.items[0].addedValueTax;
      },
        0);
      let totalPrice = reviewShipments.reduce(function (
        accumulator,
        currentValue
      ) {
        return accumulator + currentValue.totalPrice;
      },
        0);
      let totalduePrice = reviewShipments.reduce(function (
        accumulator,
        currentValue
      ) {
        return accumulator + currentValue.duePrice;
      },
        0);
      let totalotherServicesPrice = reviewShipments.reduce(function (
        accumulator,
        currentValue
      ) {
        return accumulator + currentValue.otherServicesPrice;
      },
        0);
      totalitemsprice = this.round(parseFloat(totalitemsprice));
      totaladdedValueTax = this.round(parseFloat(totaladdedValueTax));
      totalduePrice = this.round(totalduePrice);
      return {
        totalquantity,
        totalitemsprice,
        totaladdedValueTax,
        totalduePrice,
        totalotherServicesPrice,
        totalPrice,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari */
  color-adjust: exact !important;
  /*Firefox*/
}

.content {
  font-weight: 600 !important;
}

@media print {}

::v-deep .v-data-table-header {
  background-color: #8080802b;
}

::v-deep .v-data-table tr {
  font-size: x-small !important;
  height: 14px !important;
  border: black solid 2px !important;
}

::v-deep .v-data-table td {
  font-size: x-small !important;
  height: 20px !important;
  border: black solid 2px !important;
}

::v-deep .v-data-table th {
  font-size: x-small !important;
  border: black solid 2px !important;
}

.ammar div {
  margin: 0 !important;
  padding: 1px !important;
  font-weight: bold;
}

.ammar div .edit {
  margin-top: 20px !important;
}

.data {
  border: 2px solid grey;
}

.both {
  position: absolute;
}

.dashed-border {
  border: 2px dotted grey;
  padding: 10px;
}

.first {
  bottom: -32px;
  left: 33%;
}

.second {
  bottom: 33;
  left: 8%;
}

.review div {
  border: 2px solid black;
  display: inline-block;
  font-weight: bold;
}
</style>
