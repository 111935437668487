<template>
  <div>
    <div>
      <v-card
        v-can="'ShipmentClientDelivery.View'"
        width="99%"
        class="mx-auto mt-3 pa-5"
      >
        <v-data-table
          v-model="selectedPolisa"
          item-key="id"
          :headers="headers"
          :items="entities"
          fixed-header
          :server-items-length="total"
          :items-per-page="15"
          :options.sync="options"
          :loading="loadingStates.table"
          class="elevation-1"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-plus',
            nextIcon: 'mdi-minus',
            'items-per-page-text': 'عدد ارقام التواصل ' + total + '',
          }"
        >
          <template v-slot:[`item.trackingNumber`]="{ item }">
            <div v-if="item.trackingNumber">
              <div v-if="item.trackingNumber">
                {{ item.trackingNumber }}
              </div>
              <div v-if="item.date">
                <span
                  ><v-chip
                    style="font-size: smaller"
                    color="green lighten-3"
                    label
                    >{{ item.date | ToDate }}
                  </v-chip>
                </span>
              </div>
            </div>
          </template>

          <template v-slot:[`item.details`]="{ item }">
            <v-btn
              v-can="'ShipmentClientDelivery.View'"
              color="orange"
              outlined
              elevation="2"
              class="mx-2"
              @click="view(item.id)"
              icon
              ><v-icon>mdi-eye-outline</v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              v-can="'ShipmentClientDelivery.View'"
              color="green"
              outlined
              elevation="2"
              class="mx-2"
              @click="sendTo(item.id)"
              icon
              ><v-icon>mdi-phone-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.senderInfo`]="{ item }">
            <span v-if="item.senderInfo">
              <span v-if="item.senderInfo.name">
                {{ item.senderInfo.name }}</span
              >
            </span>
          </template>

          <template v-slot:[`item.receiverInfo`]="{ item }">
            <span v-if="item.receiverInfo">
              <span v-if="item.receiverInfo.name">
                {{ item.receiverInfo.name }}</span
              >
            </span>
          </template>
          <template v-slot:[`item.tripdata`]="{ item }">
            <div v-if="item.trip">
              <div v-if="item.trip.line.name">
                <span
                  ><v-chip
                    style="font-size: smaller"
                    color="green lighten-3"
                    label
                    >{{ item.trip.line.name }}
                  </v-chip>
                </span>
              </div>
            </div>
          </template>
          <template v-slot:[`item.receiverInfoPhone`]="{ item }">
            <div v-if="item.receiverInfo">
              <span
                v-if="
                  item.receiverInfo.phoneNumber != null &&
                  item.receiverInfo.phoneNumber != ' ' &&
                  item.receiverInfo.phoneNumber != ''
                "
                ><v-chip color="green lighten-3" label
                  >{{ item.receiverInfo.phoneNumber }}
                </v-chip>
              </span>
            </div>
          </template>
        </v-data-table>
      </v-card>

      <v-dialog
        v-model="modals.viewShipment"
        width="80%"
        class="pa-20"
        ref="test"
        persistent
      >
        <v-card class="p-2">
          <ViewShipment
            :selected="selected"
            @hide="modals.viewShipment = false"
            ref="viewshipmentModal"
          />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import ShipmentService from "../services/ShipmentService";
export default {
  data() {
    return {
      selectedPolisa: [],
      modals: {
        viewShipment: false,
      },
      options: {},
      selected: {},
      entities: [],
      total: 15,
      loadingStates: { table: false },
      singleSelect: false,
      loading: false,
    };
  },
  async created() {
    try {
      this.getData();
    } catch (error) {
      this.loading = false;
      console.log(error);
    }
  },
  methods: {
    view(id) {
      ShipmentService.getById(id)
        .then((resp) => {
          this.selected = resp.data;
          this.modals.viewShipment = true;
        })
        .catch((resp) => {})
        .finally(() => {});
    },
    getData() {
      this.loadingStates.table = true;
      ShipmentService.receiverCall(this.$clean(this.options))
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
        })
        .finally(() => {
          this.loadingStates.table = false;
        });
    },

    sendTo(id) {
      ShipmentService.addToCallSent(id)
        .then((resp) => {
          this.getData();
        })
        .finally(() => {
          this.loadingStates.table = false;
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  computed: {
    headers() {
      let headers = [
        {
          text: "المرسل",
          value: "senderInfo",
          align: "center",
          sortable: false,
        },
        {
          text: "المستلم",
          value: "receiverInfo",
          align: "center",
          sortable: false,
        },
        {
          text: "المسار",
          value: "tripdata",
          align: "center",
        },
        {
          text: "جوال المستلم",
          value: "receiverInfoPhone",
          align: "center",
          sortable: false,
        },
        { text: "التفاصيل", value: "details", align: "center", sortable: true },
        {
          text: "تم الأتصال",
          value: "actions",
          align: "center",
          sortable: true,
        },
      ];
      return headers;
    },
  },
};
</script>
<style lang="scss" scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 300px;
  max-height: 200px;
}
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
input[type="checkbox"][id^="myCheckbox"] {
  display: none;
}

label {
  border: 5px solid #fff;
  padding: 10px;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

label img {
  height: 40px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

:checked + label {
  border-color: rgb(17, 219, 108);
}

:checked + label:before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

:checked + label img {
  transform: scale(0.9);
  /* box-shadow: 0 0 5px #333; */
  z-index: -1;
}
</style>