<template>
  <v-text-field
    :label="label"
    class="m-0 p-0 label"
    dense
    readonly
    disabled
    outlined
    :value="value"
  >
  </v-text-field>
</template>

<script>
export default {
  props: ["label", "value"],
  mounted() {},
};
</script>

<style lang="scss" scoped>
.label {
  font-weight: bolder;
}
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black;
}
</style>