<template>
  <div>
    <v-autocomplete
      v-model="model"
      :items="items"
      :loading="isLoading"
      :hide-no-data="!strict"
      outlined
      :rules="rules"
      :search-input.sync="search"
      dense
      :item-text="text"
      :filter="customFilter"
      :placeholder="placeholder"
      :label="label"
      return-object
      clearable
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  props: [
    "text",
    "label",
    "searchFn",
    "value",
    "rules",
    "placeholder",
    "mapFn",
    "strict",
    "customFilter",
  ],

  data() {
    return {
      isLoading: false,
      search: null,
      items: [],
      oldVal: "",
      model: null,
      timeout: null,
      oldArgs: null,
      editMode: false,
    };
  },
  mounted() {
    //   this.searchFn('').then(resp=>{
    //              if(this.mapFn)
    //                 this.items=resp.data.map(e=>this.mapFn(e));
    //              else
    //              this.items=resp.data
    //              });
  },
  created() {
    // if(this.value){
    //     this.model=this.clone(this.value);
    //     if(!this.model[this.text])
    //       this.model[this.text]="";
    //     this.items.push(this.model);
    //     this.oldVal= this.clone(this.model[this.text]);
    // }
  },
  methods: {
    reset() {
      this.search = null;
    },
    setModel(model) {
      if (!model) {
        model = {};
      } else {
        this.editMode = true;
        this.model = model;
        this.items.push(model);
        this.oldVal = model[this.text];
        this.search = model[this.text];
        var self = this;
        setTimeout(() => {
          self.editMode = false;
        }, 200);
      }
    },
    debounce(fn, args, time) {
      clearTimeout(this.timeout);
      var self = this;
      this.timeout = setTimeout(() => {
        if (self.oldArgs == args) return;
        this.oldArgs = args;
        fn(args);
      }, time);
    },
    internalSearch(val) {
      if (val == this.oldVal) return;
      this.oldVal = val;
      this.isLoading = true;
      if (!this.searchFn) return;

      this.searchFn(val)
        .then((resp) => {
          if (this.mapFn) {
            this.items = resp.data.map((e) => this.mapFn(e));
          } else this.items = resp.data;
          if (!this.items) this.items = [];
          if (this.items.length == 0) {
            if (val == null) {
              this.model = {};
              return;
            }
            var obj = {};
            obj[this.text] = `${this.search}`;
            this.items.push(obj);
            this.model = this.clone(obj);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;

          this.$emit("input", this.clone(this.model));
        });
    },
    customFilter1(item, queryText, itemText) {
      const textOne = item[this.text].toLowerCase();
      const textTwo = item[this.text];
      const searchText = queryText.toLowerCase();
      var res = textOne.indexOf(searchText) > -1 || textTwo == queryText;
      return res;
    },
  },
  watch: {
    search: function (value) {
      if (this.editMode) return;
      if (
        value == this.oldVal ||
        (this.model && this.model[this.text] == value)
      )
        return;
      return this.debounce(
        this.internalSearch.bind(this),
        this.clone(value),
        250
      );
    },
    model(newVal, oldVal) {
      this.$emit("input", this.clone(this.model));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
</style>