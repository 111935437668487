<script>
import Header from "@/features/shared/components/Navigations/Header.vue";
import Footer from "@/features/shared/components/Navigations/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<template>
  <div>
    <Header />

    <v-main>
      <v-container fluid>
        <slot />
      </v-container>
    </v-main>

    <Footer />
  </div>
</template>
<style  lang="scss" >
*,
::before,
::after {
  box-sizing: border-box;
  font-weight: 600;
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1440px !important;
  }
}
</style>
