<template>
  <v-app>
    <div id="app">
      <div class="rounded-lg grey">
        <component :is="layout" class="rounded-lg white">
          <router-view />
        </component>
      </div>
    </div>
  </v-app>
</template>
<script>
import ApiService from "./features/System/services/SettingsService.js";

const signalR = require("@microsoft/signalr");

const default_layout = "default";
export default {
  data() {
    return {
      connection: "",
      //databaseBackUp: false,
    };
  },
  mounted() {
    /*if (localStorage.getItem("databaseBackUp")) {
      this.databaseBackUp = localStorage.getItem("databaseBackUp");
    }*/
  },
  created() {
    /*this.connection = new signalR.HubConnectionBuilder()
      .withUrl("https://app.al-ahmari.com.sa/printtoapp")
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.connection.on("databaseBackUp", data => {
      console.log(data);
      localStorage.setItem("databaseBackUp", data);
      this.databaseBackUp = data;
    });

    this.connection.start().catch(function (err) {
      return console.error(err);
    });

    if (localStorage.getItem("databaseBackUp")) {
      this.databaseBackUp = localStorage.getItem("databaseBackUp");
    }*/

    if (!localStorage.getItem("companyName")) {
      ApiService.getcompanyName().then((resp) => {
        localStorage.setItem("tax", resp.data.tax / 100);
        localStorage.setItem("companyName", resp.data.companyName);
        document.title = resp.data.companyName;
      });
    } else {
      document.title = localStorage.getItem("companyName");
    }
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
  methods: {},
};
</script>
<style lang="scss">
a {
  text-decoration: none !important;
}
</style>

