<script>
export default {};
</script>
<template>
  <v-container fluid>
    <slot />
  </v-container>
</template>
<style  lang="scss" >
</style>
