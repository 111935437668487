import Vue from 'vue';
import Default from "./../layouts/Default.vue";
import Dashboard from "./../layouts/Dashboard.vue";
import Print from "./../layouts/Print.vue";
import JsonExcel from "vue-json-excel";

import DatePicker from './../features/LandShipping/views/datePicker.vue';
import TimePicker from './../features/LandShipping/components/TimePicker.vue';
import ShipmentsPrintbar from './../features/LandShipping/components/ShipmentsPrintbar.vue';
import ShipmentSearch from './../features/LandShipping/components/ShipmentSearch.vue';
import AppAutoComplete from './../features/shared/components/AppAutoComplete.vue'
import AddShipment from './../features/LandShipping/components/AddShipment.vue';
import ViewShipment from './../features/LandShipping/components/ViewShipment.vue';
import RecipientInfo from './../features/LandShipping/components/RecipientInfo.vue';
import Clients from './../features/LandShipping/views/Clients.vue'

import ShipmentsTable from './../features/LandShipping/components/ShipmentsTable.vue';
import AppState from './../features/shared/components/AppState.vue';
import AppStateTrips from './../features/shared/components/AppStateTrips.vue';
import EditUser from './../features/System/components/EditUser.vue';

import TripReport from './../features/Reports/views/TripReport.vue';
import ShipmentsReport from './../features/Reports/views/ShipmentsReport.vue';
import ShipmentDriverReport from './../features/Reports/views/ShipmentDriverReport.vue';
import SalesMenReport from './../features/Reports/views/SalesMenPrint.vue';
import ShipmentBarCode from './../features/Reports/views/ShipmentBarCode.vue';
import ShipmentBarCodeSingle from './../features/Reports/views/ShipmentBarCodeSingle.vue';
import ShipmentReceipt from './../features/Reports/views/ShipmentReceipt.vue';
import DailyReportPrint from './../features/Reports/views/DailyReportPrint.vue';
import TripInvoices from './../features/Reports/views/TripInvoices.vue';
import TripReturnInvoices from './../features/Reports/views/TripReturnInvoices.vue';
import ReceiptPrint from './../features/Reports/views/ReceiptPrint.vue';
import TripActivityReport from './../features/Reports/components/TripActivityReport.vue';

import AppLabel from './../features/shared/components/AppLabel.vue';

Vue.component("default-layout", Default);
Vue.component("dashboard-layout", Dashboard);
Vue.component("print-layout", Print);
Vue.component("downloadExcel", JsonExcel);

Vue.component("DatePicker-layout", DatePicker);
Vue.component("TimePicker-layout", TimePicker);
Vue.component("ShipmentsPrintbar", ShipmentsPrintbar);
Vue.component("ShipmentSearch", ShipmentSearch);
Vue.component("AppAutoComplete", AppAutoComplete);
Vue.component("AddShipment", AddShipment);
Vue.component("ViewShipment", ViewShipment);
Vue.component("RecipientInfo", RecipientInfo);

Vue.component("ClientsSearch", Clients);

Vue.component("ShipmentsTable", ShipmentsTable);
Vue.component("AppState", AppState);
Vue.component("AppStateTrips", AppStateTrips);
Vue.component("EditUser", EditUser);

Vue.component("TripReport", TripReport);
Vue.component("ShipmentsReport", ShipmentsReport);
Vue.component("SalesMenReport", SalesMenReport);
Vue.component("ShipmentDriverReport", ShipmentDriverReport);
Vue.component("ShipmentBarCode", ShipmentBarCode);
Vue.component("ShipmentBarCodeSingle", ShipmentBarCodeSingle);
Vue.component("ShipmentReceipt", ShipmentReceipt);
Vue.component("DailyReportPrint", DailyReportPrint);
Vue.component("TripInvoices", TripInvoices);
Vue.component("TripReturnInvoices", TripReturnInvoices);
Vue.component("ReceiptPrint", ReceiptPrint);
Vue.component("TripActivityReport", TripActivityReport);
Vue.component("AppLabel", AppLabel);

