<template>
  <div>
    <div class="row justify-content-right">
      <v-col cols="9">
        <v-btn elevation="2" color="success" @click="getData">
          بحث <i class="fas fa-search fa-lg"></i>
        </v-btn>
      </v-col>
      <v-col cols="1">
        <v-btn elevation="2" color="error" @click="resetOptions">
          تفريغ <i class="fa fa-trash fa-lg"></i>
        </v-btn>
      </v-col>
    </div>
    <v-row class="pr-5 pb-3 mt-3">
      <v-col cols="1" sm="4">
        <v-autocomplete label="اسم التقرير" dense outlined required :items="filter" item-value="id" item-text="name"
          v-model="reportname" auto-select-first></v-autocomplete>
      </v-col>


      <v-col sm="2" v-if="reportname == 1">
        <DatePicker-layout @myEvent="options.startDate = $event" :date.sync="options.startDate" label="من تاريخ" clearable>
        </DatePicker-layout>
      </v-col>
      <v-col sm="2" v-if="reportname == 1">
        <DatePicker-layout @myEvent="options.endDate = $event" :date.sync="options.endDate" label="الى تاريخ" clearable>
        </DatePicker-layout>
      </v-col>
    </v-row>
    <div class="summary-wrapper d-flex flex-column" v-if="data.length > 0">
      <v-row class="justify-content-around mb-5">
        <v-col cols="8">
          <table class="summary-table">
            <thead>
              <tr>
                <th>اسم المدخل</th>
                <th>عدد البوالص</th>
                <th>عدد الاخطاء</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="emp in data" :key="emp.name">
                <td>
                  {{ emp.name }}
                </td>
                <td>
                  {{ emp.counts }}
                </td>
                <td>
                 جاري
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>


<script>
import ApiService from "../../LandShipping/services/ShipmentService";
export default {
  created() {

  },
  data() {
    return {
      filter: [
        { id: 1, name: "حركة المدخلين فى فترة" }, //date
      ],
      data: [],
      reportname: null,
      options: {
        startDate: null,
        endDate: null,
      },
      loadings: {},
    };
  },
  methods: {
    resetOptions() {
      this.options = {
        startDate: null,
        endDate: null,
      };
      this.reportname = null;
      this.data = [];
    },
    getData() {
      this.data = [];
      this.loadings = true;
      ApiService.empShipmentsCount(this.$clean(this.options))
        .then((resp) => {
          if (resp.data) {
            console.log(resp.data);
            this.data = resp.data;
          }
          this.loadings = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadings = false;
        });
    },
  },
  watch: {
    reportname(val) {
      this.options = {
        startDate: null,
        endDate: null,
      };
      this.data = [];
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari */
  color-adjust: exact !important;
  /*Firefox*/
}
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
.main-break {
  font-weight: 600 !important;
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }

  .summary-wrapper {
    height: 90vh;
    align-content: center;
  }
}

::v-deep .v-data-table-header {
  background-color: #8080802b;
}

.summary-table {
  width: 100%;
  text-align: center;
  border: black solid 3px;
  font-weight: bolder;
}

::v-deep .v-data-table {
  max-width: 98%;
}

::v-deep .v-data-table tr {
  font-size: x-small !important;
  height: 14px !important;
  border: black solid 2px !important;
}

::v-deep .v-data-table td {
  font-size: x-small !important;
  height: 20px !important;
  border: black solid 2px !important;
}

::v-deep .v-data-table th {
  font-size: x-small !important;
  border: black solid 2px !important;
}

.summary-table td,
.summary-table th {
  border: black solid 2px;
  padding: 10px 20px 10px 20px;
}

.ammar div {
  margin: 0 !important;
  padding: 1px !important;
  font-weight: bold;
}

.ammar div .edit {
  margin-top: 20px !important;
}

.data {
  border: 2px solid grey;
}

.both {
  position: absolute;
}

.dashed-border {
  border: 2px dotted grey;
  padding: 10px;
}

.first {
  bottom: -32px;
  left: 33%;
}

.second {
  bottom: 33;
  left: 8%;
}

.review div {
  border: 2px solid black;
  display: inline-block;
  font-weight: bold;
}

.bottoms span {
  position: absolute;
  padding: 8px 12px;
  top: center;
  border: black solid 1px !important;
}

.bottoms span:nth-child(1) {
  bottom: -32px;
  left: 4%;
}

.bottoms span:nth-child(2) {
  bottom: -32px;
  left: 12%;
}

.bottoms span:nth-child(3) {
  bottom: -32px;
  left: 22%;
}

.bottoms span:nth-child(4) {
  bottom: -32px;
  left: 33%;
}

.bottoms span:nth-child(5) {
  bottom: -32px;
  left: 41%;
}

.bottoms span:nth-child(6) {
  bottom: -32px;
  left: 50%;
}

.review div {
  border: 1px solid black;
  display: inline-block;
  font-weight: bold;
}</style>