<template>
  <div>
    <v-row class="pr-5 pb-3 mt-3">
      <v-col class="d-flex justify-content-start">
        <v-btn v-can="'ReceiveReceipt.Create'" color="success" @click="add">
          سند القبض<i class="fa fa-plus mr-2"></i>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">
        <v-btn v-can="'ReceiveReceipt.Update'" @click="changeStatestart()" v-if="changeStatestarts"
          class="ma-2 error">الغاء</v-btn>
        <v-btn v-can="'ReceiveReceipt.Update'" @click="changeStatestart()" v-if="!changeStatestarts"
          class="ma-2 info">ترحيل</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">
        <v-btn v-can="'ReceiveReceipt.Update'" @click="changeStatebulkopen()" class="ma-2 info" v-if="changeStatestarts"
          :disabled="selecteditems.length < 1">تغير الحالة</v-btn>
      </v-col>
    </v-row>
    <v-card width="98%" class="mx-auto">
      <v-data-table v-can="'ReceiveReceipt.View'" v-model="selecteditems" :headers="headers" :items="entities"
        :options.sync="options" :server-items-length="total" :search="search" :items-per-page="15"
        :show-select="changeStatestarts ? true : false" :loading="loadingStates.table" class="elevation-1" ref="table"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-plus',
          nextIcon: 'mdi-minus',
          'items-per-page-text': 'عدد السندات ' + total + '',
        }">
        <template v-slot:top>
          <v-row class="mx-auto mt-3 pa-5" dark dense>
            <v-col cols="12" sm="2">
              <v-text-field hide-details dense outlined v-model="options.Id" label="رقم السند"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field hide-details dense outlined v-model="options.manualNo" label="الرقم اليدوى"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field hide-details dense outlined v-model="options.triptrackingNumber"
                label="رقم الرحلة"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field hide-details dense outlined v-model="options.client" label="اسم عميل البوليصة"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field hide-details dense outlined v-model="options.recipient" label="اسم المودع"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <DatePicker-layout @myEvent="options.date = $event" :date.sync="options.date"
                label="التاريخ "></DatePicker-layout>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete dense outlined hide-details label="الفروع" :items="branches" item-value="id"
                item-text="name" v-model="options.branchId"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete dense hide-details v-model="options.state" outlined :items="enums.RecordStates"
                item-text="text" item-value="value" label="الحالة"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete dense outlined label="نوع الخدمه" :items="serviceTypes" item-value="id" item-text="name"
                auto-select-first v-model="options.serviceTypeId">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete dense hide-details v-model="options.paymentType" outlined :items="enums.PaymentTypes"
                item-text="text" item-value="value" label="نوع السداد"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <DatePicker-layout @myEvent="options.dateFrom = $event" :date.sync="options.dateFrom"
                label="التاريخ من "></DatePicker-layout>
            </v-col>
            <v-col cols="12" sm="2">
              <DatePicker-layout @myEvent="options.dateTo = $event" :date.sync="options.dateTo"
                label="التاريخ الى "></DatePicker-layout>
            </v-col>
            <v-row>
              <v-col cols="12" class="mt-0">
                <v-btn class="mx-3" color="error" @click="resetOptions">تفريغ
                  <i class="fa fa-trash fa-lg"></i>
                </v-btn>
                <v-btn @click="goToPrint" color="info" class="mx-3">
                  طباعة <v-icon>mdi-printer</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-row>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <div v-if="item.id">
            <div v-if="item.id">
              {{ item.id }}
            </div>
            <div v-if="item.createDate">
              <span><v-chip style="font-size: smaller" color="green lighten-3" label>{{ item.createDate | ToDate }}
                </v-chip>
              </span>
            </div>
          </div>
        </template>
        <template v-slot:[`item.accountNo`]="{ item }">
          <v-chip v-if="item.accountNo" label>{{ item.accountNo }}</v-chip>
          <v-chip v-if="item.accountNo == 0" label>لا يوجد</v-chip>
        </template>
        <template v-slot:[`item.recipient`]="{ item }">
          <v-chip v-if="item.recipient" label>{{ item.recipient }}</v-chip>
          <v-chip v-if="!item.recipient" label>{{ item.client }}</v-chip>
        </template>
        <template v-slot:[`item.createDate`]="{ item }">
          {{ item.createDate | ToDate }}
        </template>
        <template v-slot:[`item.shipment.paymentType`]="{ item }">
          <span v-if="item.shipment"><v-chip color="blue lighten-3" label>{{ item.shipment.paymentType | paymentTypes }}
            </v-chip>
          </span>
        </template>
        <template v-slot:[`item.shipment.serviceType`]="{ item }">
          <span v-if="item.shipment"><v-chip color="green lighten-3" label>{{ item.shipment.serviceType.name }}
            </v-chip>
          </span>
        </template>
        <template v-can="'ReceiveReceipt.Update'" v-slot:[`item.state`]="{ item }">
          <AppState :state="item.state" :options="enums.RecordStates" @click="selected = item"
            @changeState="changeState(item, $event)" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="orange" outlined elevation="2" class="ml-2" icon v-can="'ReceiveReceipt.View'"
            @click="view(item.id)"><v-icon>mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn color="blue" outlined elevation="2" class="ml-2" icon v-can="'ReceiveReceipt.View'"
            @click="print(item.id)"><v-icon>mdi-printer</v-icon>
          </v-btn>
          <v-btn color="primary" outlined elevation="2" class="ml-2" icon v-can="'ReceiveReceipt.Update'"
            @click="edit(item.id)"><v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn color="red" class="ml-2" icon elevation="2" outlined v-can="'ReceiveReceipt.Delete'"
            @click="del(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <!-- finished printing -->

    <!-- Add Modal -->
    <v-dialog v-model="dialog" width="1000" persistent>
      <v-card>
        <v-form v-model="valid">
          <v-card-title class="headline">
            <i class="fa fa-pen ml-2"></i> تعديل/إضافة
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense class="mb-2">
                <v-col cols="12" sm="12">
                  <div class="
                      hiddenradio
                      text-center
                      grey
                      lighten-3
                      pa-3
                      rounded-lg
                    ">
                    <h4 class="font-weight-bold">الحالة</h4>
                    <label v-for="(state, i) in enums.States" :key="i" class="ma-3">
                      <input :disabled="viewdialog" type="radio" name="currentState" v-model="selected.state"
                        :value="state.value" />
                      <div class="pa-3">
                        <v-icon large :color="state.color">{{
                          state.icon
                        }}</v-icon>
                      </div>
                    </label>
                    <h5 class="lg-rounded" :class="showState.color">
                      {{ showState.state }}
                    </h5>
                  </div>
                </v-col>
              </v-row>
              <v-row class="my-0 ammar" dense>
                <v-col cols="12" sm="3">
                  <v-text-field type="number" outlined dense :disabled="viewdialog" label="رقم القيد"
                    v-model="selected.accountNo" :value="selected.accountNo"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <DatePicker-layout @myEvent="selected.createDate = $event" :date="selected.createDate | ToDate"
                    v-model="selected.createDate" label="التاريخ " :disabled="viewdialog"></DatePicker-layout>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field type="number" outlined dense :disabled="viewdialog" v-model="selected.amount"
                    label="المبلغ" :value="selected.amount"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field outlined dense v-model="selected.recipient" :disabled="viewdialog" label="المودع"
                    :value="selected.recipient"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-combobox outlined :disabled="viewdialog" v-model="selected.client" dense :items="clients"
                    item-id="id" item-text="name" label="العميل" clearable></v-combobox>
                </v-col>

                <v-col cols="12" sm="3">
                  <v-autocomplete label="نوع الشحن " dense :v-model="selected.shipment ? selected.shipment.serviceTypeId : ''
                    " :disabled="viewdialog" outlined required :items="serviceTypes" item-value="id" item-text="name"
                    auto-select-first :value="selected.shipment ? selected.shipment.serviceTypeId : ''
                      "></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="3">
                  <v-text-field type="number" outlined :disabled="viewdialog" :v-model="selected.shipment ? selected.shipment.manualNo : '0'
                    " dense label="الرقم اليدوي" :value="selected.shipment ? selected.shipment.manualNo : '0'
    "></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field type="number" outlined dense :disabled="viewdialog" label="رقم البوليصة" :v-model="selected.shipment ? selected.shipment.trackingNumber : '0'
                    " :value="selected.shipment ? selected.shipment.trackingNumber : '0'
    "></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field type="number" outlined dense :v-model="selected.shipment
                      ? selected.shipment.trip.trackingNumber
                      : '0'
                    " :disabled="viewdialog" label="رقم الرحلة" :value="selected.shipment
      ? selected.shipment.trip.trackingNumber
      : '0'
    "></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-radio-group :disabled="viewdialog" :value="selected.shipment ? selected.shipment.paymentType : '0'
                    " row :v-model="selected.shipment ? selected.shipment.paymentType : '0'
    " mandatory>
                    <template v-slot:label>
                      <div class="text-right"><strong>النوع </strong></div>
                    </template>
                    <v-radio label="نقدى" :value="1"></v-radio>
                    <v-radio label="الدفع عند التسليم" :value="2"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6">
                  <v-textarea outlined dense hide-details rows="3" :disabled="viewdialog" row-height="15" label="البيان"
                    v-model="selected.description"></v-textarea>
                </v-col>

                <v-col cols="6">
                  <v-textarea outlined dense hide-details rows="3" :disabled="viewdialog" row-height="15" label="ملحوظات"
                    v-model="selected.notice"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" v-if="!viewdialog" :disabled="!valid" @click="editConfirmed()">
              حفظ
            </v-btn>

            <v-btn color="red darken-1" text @click="cancelEdit()">
              إلغاء
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Add Modal -->
    <v-dialog v-model="changestatedialog" width="50%" persistent>
      <v-card>
        <v-card-title class="headline"> تغير حالة السندات </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense justify="center">
              <v-col cols="3">
                <div class="rounded-lg pa-1 text-center grey lighten-3">
                  <h5>عدد السندات المحددة</h5>
                  <h6>{{ selecteditems.length }}</h6>
                </div>
              </v-col>
              <v-col cols="3">
                <div class="rounded-lg pa-1 text-center grey lighten-3">
                  <h5>السندات النقدى</h5>
                  <h6>
                    {{
                      selecteditems
                        .filter((i) => {
                          return i.shipment.paymentType == 1;
                        })
                        .reduce((a, b) => a + (b["amount"] || 0), 0)
                    }}
                  </h6>
                </div>
              </v-col>
              <v-col cols="3">
                <div class="rounded-lg pa-1 text-center grey lighten-3">
                  <h5>السندات عند التسليم</h5>
                  <h6>
                    {{
                      selecteditems
                        .filter((i) => {
                          return i.shipment.paymentType == 2;
                        })
                        .reduce((a, b) => a + (b["amount"] || 0), 0)
                    }}
                  </h6>
                </div>
              </v-col>
              <v-col cols="3">
                <div class="rounded-lg pa-1 text-center grey lighten-3">
                  <h5>اجمالى المحدد</h5>
                  <h6>
                    {{
                      selecteditems.reduce((a, b) => a + (b["amount"] || 0), 0)
                    }}
                  </h6>
                </div>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field outlined dense label="رقم القيد" :rules="[allRules.required, allRules.minNameLen(4)]"
                  v-model="selecteditemsManualNo" clearable></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="mb-2">
              <v-col cols="12" sm="12">
                <div class="hiddenradio text-center grey lighten-3 pa-3 rounded-lg">
                  <h4 class="font-weight-bold">الحالة</h4>
                  <label v-for="(state, i) in enums.States" :key="i" class="ma-3">
                    <input type="radio" name="currentState" v-model="selected.state" :value="state.value" />
                    <div class="pa-3">
                      <v-icon large :color="state.color">{{
                        state.icon
                      }}</v-icon>
                    </div>
                  </label>
                  <h5 class="lg-rounded" :class="showState.color">
                    {{ showState.state }}
                  </h5>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn :disabled="!selecteditemsManualNo" color="primary" @click="changeStatebulk()">
            حفظ
          </v-btn>
          <v-btn color="red darken-1" text @click="cancelStateEdit()">
            إلغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import clientsApi from "../../LandShipping/services/ClientsService.js";
import employeesApi from "/src/features/System/services/EmployeeService";
import ApiService from "../services/CatchReceiptService";
import Enums from "./../../../plugins/enums";
import ServiceTypesService from "../../Settings/services/ServiceTypesService";
import BranchesService from "../../../features/Settings/services/BranchesService.js";
export default {
  data() {
    return {
      valid: false,
      viewdialog: false,
      serviceTypes: [],
      enums: Enums,
      changeStatestarts: false,
      changestatedialog: false,
      selecteditems: [],
      selecteditemsState: 0,
      selecteditemsManualNo: null,
      clients: [],
      employees: [],
      branches: [],
      selected: {},
      cashingSide: [],
      entities: [],
      total: 0,
      search: "",
      options: {
        Id: null,
        manualNo: null,
        triptrackingNumber: null,
        client: null,
        recipient: null,
        date: null,
        branchId: null,
        state: null,
        serviceTypeId: null,
        paymentType: null,
        dateFrom: null,
        dateTo: null,
      },
      dialog: false,
      loadingStates: {
        table: false,
      },
      headers: [
        {
          text: " رقم السند",
          align: "center",
          sortable: false,
          value: "id",
        },
        {
          sortable: false,
          text: "رقم القيد",
          value: "accountNo",
          align: "center",
        },
        {
          sortable: false,
          text: "المبلغ",
          value: "amount",
          align: "center",
        },
        {
          sortable: false,
          text: "المودع",
          value: "recipient",
          align: "center",
        },
        {
          sortable: false,
          text: "نوع الخدمة",
          value: "shipment.serviceType",
          align: "center",
        },
        {
          sortable: false,
          text: "النوع ",
          value: "shipment.paymentType",
          align: "center",
        },
        {
          sortable: false,
          text: "الحاله",
          value: "state",
          align: "center",
        },
        {
          sortable: false,
          text: "مسح / تعديل",
          value: "actions",
          align: "center",
        },
      ],
    };
  },
  created() {
    try {
      const servicesType = ServiceTypesService.get();
      Promise.all([servicesType]).then((res) => {
        this.serviceTypes = res[0].data;
      });
    } catch (error) {
      console.log(error);
    }
  },
  async mounted() {
    try {
      this.getData();
      const clients = await clientsApi.typeHead("", true, false);
      this.clients = clients.data;
      const employees = await employeesApi.query();
      this.employees = employees.data.items;
      BranchesService.typeHead("", false, true).then((resp) => {
        this.branches = resp.data;
      });
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    showState() {
      let curentState;
      if (this.selected.state == 1) {
        curentState = {
          state: "مفتوح",
          color: "green--text",
          icon: "mdi-lock-open-variant",
        };
      } else if (this.selected.state == 2) {
        curentState = {
          state: "مغلق",
          color: "red--text",
          icon: "mdi-lock",
        };
      } else if (this.selected.state == 3) {
        curentState = {
          state: "مرحل",
          color: "orange--text",
          icon: "mdi-lock-clock",
        };
      } else {
        curentState = {
          state: "اختار الحالة",
          color: "black--text",
          icon: "mdi-lock-clock",
        };
      }
      return curentState;
    },
  },
  methods: {
    goToPrint() {
      let routeData = this.$router.resolve({
        name: "FinanceAllReports",
        query: {
          options: JSON.stringify(this.$clean(this.options)),
        },
      });
      window.open(routeData.href, "_blank");
    },
    resetOptions() {
      this.options = {
        Id: null,
        manualNo: null,
        triptrackingNumber: null,
        client: null,
        recipient: null,
        date: null,
        branchId: null,
        state: null,
        serviceTypeId: null,
        paymentType: null,
        dateFrom: null,
        dateTo: null,
      };
    },
    changeStatestart() {
      this.changeStatestarts = !this.changeStatestarts;
    },
    changeState(item, state) {
      item.state = state;
      ApiService.edit(item)
        .then((resp) => {
          this.updateArr(this.entities, resp.data);
        })
        .catch((resp) => { })
        .finally(() => { });
    },
    changeStatebulk() {
      let model = {
        ids: this.selecteditems.map((e) => e.id),
        state: this.selected.state,
        accountNo: this.selecteditemsManualNo,
      };
      ApiService.updateState(model)
        .then((resp) => {
          this.getData();
          this.selecteditems = [];
          this.selecteditemsManualNo = null;
          this.changestatedialog = false;
        })
        .catch((resp) => { })
        .finally(() => { });
    },
    cancelStateEdit() {
      this.selecteditems = [];
      this.selecteditemsManualNo = null;
      this.changestatedialog = false;
    },
    changeStatebulkopen() {
      this.changestatedialog = true;
    },
    getData() {
      this.loadingStates.table = true;
      if (this.options) this.options.searchTerm = this.search;
      ApiService.query(this.$clean(this.options))
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
        });
    },
    editConfirmed() {
      debugger;
      ApiService.edit(this.selected)
        .then((resp) => {
          this.dialog = false;
          var message = "تم تعديل العنصر بنجاح";
          if (!this.selected.id) {
            this.addToArr(this.entities, resp.data);
            message = "تم إضافة العنصر بنجاح";
          } else {
            this.updateArr(this.entities, resp.data);
          }
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.dialog = false;
          this.$dialog.notify.error("خطا اثناء تعديل العنصر", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    cancelEdit() {
      this.viewdialog = false;
      this.dialog = false;
      this.selected = {
        city: {},
      };
    },
    add() {
      let d = this.formateDateWithMoment(new Date(), "YYYY-MM-DD");
      this.selected = { createDate: d, state: 1 };
      this.dialog = true;
    },
    edit(id) {
      this.selected = {
        ...this.entities.filter((e) => e.id == id)[0],
      };
      this.dialog = true;
    },
    del(id) {
      this.$dialog
        .info({
          text: "هل انت متاكد من حذف العنصر",
          title: "  حذف فرع  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                ApiService.delete(id)
                  .then((resp) => {
                    const index = this.entities.indexOf(
                      this.entities.filter((e) => e.id == id)[0]
                    );
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("تم مسح العنص بنجاح", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("خطا اثناء مسح العنصر", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => { });
    },
    view(id) {
      this.selected = {
        ...this.entities.filter((e) => e.id == id)[0],
      };
      this.viewdialog = true;
      this.dialog = true;
    },
    print(id) {
      let routeData = this.$router.resolve({
        name: "ReceiptPrint",
        query: { receiptid: id, reporttype: 0 },
      });
      window.open(routeData.href, "_blank");
    },
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.hiddenradio [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}

/* IMAGE STYLES */
.hiddenradio [type="radio"]+div {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

/* CHECKED STYLES */
.hiddenradio [type="radio"]:checked+div {
  outline: 2px solid #42ac0d;
  outline-style: dashed;
}
</style>
