var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('div',[(_vm.shipments)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"rounded-lg grey lighten-3",attrs:{"cols":"3"}},[_c('h4',[_vm._v("طباعه تفاصيل الرحله")]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.printTrip}},[_c('v-icon',{attrs:{"large":"","color":"error"}},[_vm._v(" mdi-printer ")])],1)],1),_c('v-col',{staticClass:"rounded-lg mx-2 grey lighten-3",attrs:{"cols":"3"}},[_c('h4',[_vm._v("طباعه البوالص")]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.printShipments}},[_c('v-icon',{attrs:{"large":"","color":"error"}},[_vm._v(" mdi-printer ")])],1)],1),_c('v-col',{staticClass:"rounded-lg grey lighten-3",attrs:{"cols":"3"}},[_c('h4',[_vm._v("تسليم لسائق")]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.printDriver}},[_c('v-icon',{attrs:{"large":"","color":"error"}},[_vm._v(" mdi-printer ")])],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"mt-10"},[_c('v-card',{staticClass:"ma-4 mx-auto",attrs:{"max-width":"99%"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.polisa,"dark":"","options":_vm.options,"fixed-header":"","search":_vm.search,"items-per-page":15,"loading":_vm.loadingStates.table},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"بحث"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("ToDate")(item.date))+" ")]}},{key:"item.serviceTypeId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(this.entities.find(function (o) { return o.id === item.serviceTypeId; }).name)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.print(item)}}},[_vm._v("mdi-printer")])]}},{key:"item.senderId",fn:function(ref){
var item = ref.item;
return [(item.senderId)?_c('span',[(item.senderId.name)?_c('span',[_vm._v(" "+_vm._s(item.senderId.name))]):_c('span',[_vm._v(_vm._s(item.senderId))])]):_vm._e()]}},{key:"item.receiverId",fn:function(ref){
var item = ref.item;
return [(item.receiverId)?_c('span',[(item.receiverId.name)?_c('span',[_vm._v(" "+_vm._s(item.receiverId.name))]):_c('span',[_vm._v(_vm._s(item.receiverId))])]):_vm._e()]}}],null,true)})],1)],1)]),_c('v-dialog',{attrs:{"width":"99%"},model:{value:(_vm.modals.tripReport),callback:function ($$v) {_vm.$set(_vm.modals, "tripReport", $$v)},expression:"modals.tripReport"}},[_c('v-card',[_c('ShipmentBarCode')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }