import Api from '../../shared/services/api'
const baseUrl = "appsettings";
export default {
    get() {
        return Api().get(baseUrl);
    },
    getcompanyName() {
        return Api().get(baseUrl+ `/External`);
    },
    edit(entity) {
        return Api().put(baseUrl, entity);
    },
    getsmsCount() {
        return Api().get(baseUrl + `/SMS/count`);
    },
}