<template>
  <div>
    <div class="print-container">
      <v-row justify="center">
        <v-col v-can="'Trips.Print'" cols="2" class="rounded-lg grey lighten-3">
          <h4>طباعه كشف الرحله</h4>
          <v-btn icon @click="printTrip">
            <v-icon large color="error"> mdi-printer </v-icon>
          </v-btn>
        </v-col>
        <v-col v-notcan="'Trips.Print'" cols="2" class="rounded-lg grey lighten-3">
          <h4>عرض كشف الرحله</h4>
          <v-btn icon @click="viewTrip">
            <v-icon large color="error"> mdi-eye </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" class="rounded-lg mx-2 grey lighten-3">
          <h4>طباعه بوالص الرجيع</h4><!-- @click="printShipments"-->
          <v-btn icon @click="printReturnShipments">
            <v-icon large color="error"> mdi-printer </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" class="rounded-lg mx-2 grey lighten-3">
          <h4>طباعه بوالص الجرد</h4><!-- @click="printShipments"-->
          <v-btn icon @click="printShipments">
            <v-icon large color="error"> mdi-printer </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" class="rounded-lg grey lighten-3">
          <h4>طباعه تسليم لسائق </h4>
          <v-btn icon @click="printDriver">
            <v-icon large color="error"> mdi-printer </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" class="rounded-lg mx-2 grey lighten-3">
          <h4>تحميل ارقام العملاء اكسيل</h4>
          <download-excel name="ارقام العملاء.xls" :data="numbers" :fields="headersxls">
            <v-btn icon>
              <v-icon large color="error"> mdi-download </v-icon>
            </v-btn>
          </download-excel>
        </v-col>
      </v-row>
    </div>
    <!-- Trip Report Start -->
    <v-dialog v-model="modals.tripReport" persistent>
      تقرير رحلة
      <v-card>
        <v-card-text>
          <TripReport />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>


          <v-btn color="red darken-1" text @click="cancelEdit()">
            إلغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Trip Report  End -->

    <!-- Trip Report Start -->
    <v-dialog v-model="modals.shipmentsReport">
      طباعة البوالص
      <v-card>
        <v-card-text>
          <ShipmentBarCode />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Trip Report  End -->

    <!-- Trip Report Start -->
    <v-dialog v-model="modals.driverReport">
      طباعة البوالص
      <v-card>
        <v-card-text>
          <ShipmentDriverReport />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Trip Report  End -->
  </div>
</template>

<script>
export default {
  props: ["trip", "shipments", "numbers"],
  data() {
    return {
      headersxls: {
        " رقم الجوال": "number",
      },
      modals: {
        tripReport: false,
        shipmentsReport: false,
        driverReport: false,
      },
    };
  },
  methods: {
    cancelEdit() {
      window.location.reload();
    },
    viewTrip() {
      this.modals.tripReport = true;

      this.$store.commit("printData", {
        trip: this.trip,
        shipments: this.shipments,
      });
    },
    printTrip() {

      let routeData = this.$router.resolve({
        name: "TripReport",
        query: { tripId: this.trip.id },
      });
      window.open(routeData.href, "_blank");
    },
    printShipments() {
      // this.$store.commit("printData", {
      //  trip: this.trip,
      // shipments: this.shipments,
      // });
      // this.modals.shipmentsReport = true;

      let routeData = this.$router.resolve({
        name: "TripInvoices",
        query: { tripId: this.trip.id },
      });
      window.open(routeData.href, "_blank");
    },
    printReturnShipments() {
      let routeData = this.$router.resolve({
        name: "TripReturnInvoices",
        query: { tripId: this.trip.id },
      });
      window.open(routeData.href, "_blank");
    },
    printDriver() {
      //  this.modals.driverReport = true;
      // this.$store.commit("printData", {
      //   trip: this.trip,
      //   shipments: this.shipments,
      // });

      let routeData = this.$router.resolve({
        name: "ShipmentDriverReport",
        query: { tripId: this.trip.id },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style></style>
