<template>
  <div v-can="'AppSettings.View'">
    <v-row class="pr-5 mt-15">
      <v-col cols="5">
        <v-text-field
          type="text"
          dense
          outlined
          label="اسم المؤسسة"
          v-model="companyName"
          :disabled="!canedit"
          class="centered-input"
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          type="number"
          dense
          outlined
          label="القيمة المضافة"
          v-model="tax"
          :disabled="!canedit"
          suffix="%"
          class="centered-input"
          clearable
          :maxlength="2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pr-5 mt-15">
      <v-col cols="12" class="my-0 py-0">
        <v-switch
          :disabled="!canedit"
          label="تفعيل الرسائل"
          v-model="enableSMS"
        ></v-switch>
      </v-col>
      <v-col cols="3">
        <v-text-field
          :append-icon="load ? 'fas fa-redo-alt fa-spin' : 'fas fa-redo-alt'"
          @click:append="reloadsms"
          type="number"
          dense
          outlined
          label="عدد الرسائل المتبقية"
          v-model="smsCount"
          readonly
          class="centered-input"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pr-5 pb-3 mt-5">
      <v-col class="d-flex justify-content-end">
        <v-btn v-can="'AppSettings.Update'" color="info" v-if="!canedit" @click="canedit = true">
          تعديل<i class="fas fa-edit"></i>
        </v-btn>
        <v-btn v-can="'AppSettings.Update'" color="success" v-if="canedit" @click="edit()">
          حفظ<i class="fas fa-edit"></i>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ApiService from "../services/SettingsService.js";
export default {
  data() {
    return {
      canedit: false,
      load: false,
      smsCount: 0,
      companyName: "",
      tax: 0,
      enableSMS: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      ApiService.get().then((resp) => {
        this.companyName = resp.data.companyName;
        this.tax = resp.data.tax;
        this.enableSMS = resp.data.enableSMS;
        this.smsCount = resp.data.smsCount;
        localStorage.setItem('tax', this.tax / 100);
        localStorage.setItem('companyName', this.companyName);
      });
    },
    edit() {
      let model = {
        companyName: this.companyName,
        tax: this.tax,
        enableSMS: this.enableSMS,
      };
      ApiService.edit(model).then((resp) => {
        this.getData();
      });
      this.canedit = false;
    },
    reloadsms() {
      this.load = true;
      ApiService.getsmsCount().then((resp) => {
        this.smsCount = resp.data.count;
        this.load = false;
      });
    },
  },
};
</script>

<style lang="scss">
.v-input__icon--append .v-icon {
  color: rgb(22, 190, 22);
}
.label {
  font-weight: bolder;
}
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black;
}
.centered-input input {
  text-align: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* this is for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>