<template>
  <v-app>
    <div>
      <v-row class="pr-5 pb-3 mt-3">
        <v-col class="d-flex justify-content-start">
          <v-btn v-can="'DistributorCars.Create'" color="success" @click="add">
            اضافة سيارة<i class="fa fa-plus mr-2"></i>
          </v-btn>
        </v-col>
      </v-row>
      <!-- table -->
      <v-card min-width="98%" class="ma-4">
        <v-data-table
          v-can="'DistributorCars.View'"
          :headers="headers"
          :items="entities"
          :options.sync="options"
          :server-items-length="total"
          :search="search"
          :items-per-page="15"
          :single-expand="true"
          @item-expanded="loadDetails"
          :expanded.sync="expanded"
          show-expand
          :loading="loadingStates.table"
          class="elevation-1"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-plus',
            nextIcon: 'mdi-minus',
            'items-per-page-text': 'عدد عربيات التوزيع ' + total + '',
          }"
        >
          <template v-slot:top>
            <v-row class="mx-auto mt-3 pa-5" dark dense>
              <v-col cols="12" sm="2">
                <v-text-field
                  v-model="options.Id"
                  label="كود السيارة"
                  hide-details
                  dense
                  outlined
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  v-model="options.ownerName"
                  label="اسم المالك"
                  hide-details
                  dense
                  outlined
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  v-model="options.plate"
                  label="رقم اللوحة"
                  hide-details
                  dense
                  outlined
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  v-model="options.mark"
                  label="الماركة"
                  hide-details
                  dense
                  outlined
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="2">
                <v-autocomplete
                  outlined
                  dense
                  label="السائق"
                  :items="allDrivers"
                  item-value="id"
                  item-text="name"
                  auto-select-first
                  v-if="allDrivers"
                  v-model="options.driverId"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2">
                <v-autocomplete
                  outlined
                  dense
                  label="الموزع"
                  :items="distributers"
                  item-value="id"
                  item-text="name"
                  auto-select-first
                  v-if="distributers"
                  v-model="options.distributorId"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2">
                <v-autocomplete
                  outlined
                  dense
                  label="الفرع"
                  :items="allBranches"
                  item-value="id"
                  item-text="name"
                  auto-select-first
                  v-if="allBranches"
                  v-model="options.branchId"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2">
                <v-autocomplete
                  v-model="options.ownership"
                  outlined
                  dense
                  :items="carOwnershipEnum"
                  label="مستاجره/ تمليك"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="8">
                <v-btn color="error" @click="resetOptions"
                  >تفريغ
                  <i class="fa fa-trash fa-lg"></i>
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <!-- start expand -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-3">
              <v-row class="mx-auto text-center">
                <v-col cols="2" align="start" class="pr-5 mt-4">
                  <v-btn
                    v-can="'DistributorCars.Update'"
                    @click="openRepaireDialog(item.id)"
                    class="info text-capitalize"
                    >اضافه فاتوره</v-btn
                  >
                </v-col>
                <v-col cols="12" v-if="repaireDatalist.length > 0">
                  <v-card min-width="50%" class="ma-2 rounded-lg">
                    <v-simple-table dark fixed-header height="300px">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="text-center"
                              v-for="(item, n) in tableHeadersRepare"
                              :key="n"
                            >
                              {{ item.name }}
                            </th>

                            <th class="text-center">تعديل/مسح</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="red--text">
                            <td colspan="5" class="title text-center">
                              {{ sumField() }} ريال
                            </td>
                          </tr>
                          <tr v-for="(item, g) in repaireDatalist" :key="g">
                            <td>{{ item.number }}</td>
                            <td>{{ item.type }}</td>
                            <td>{{ item.amount }} ريال</td>
                            <td>{{ item.date | ToDate }}</td>
                            <td>{{ item.notice }}</td>
                            <td>
                              <v-btn
                                v-can="'DistributorCars.Update'"
                                color="warning"
                                outlined
                                elevation="2"
                                @click="openDialogForEdit(item)"
                                >تعديل<i class="far fa-edit mr-2"></i
                              ></v-btn>

                              <v-btn
                                v-can="'DistributorCars.Delete'"
                                color="red"
                                class="mr-2"
                                elevation="2"
                                outlined
                                @click="deleteRepareItem(item.id)"
                                >حذف<i class="fa fa-trash mr-2"></i
                              ></v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-row>
            </td>
          </template>
          <!-- end expand -->
          <template v-slot:[`item.insuranceEndDate`]="{ item }">
            <div v-if="new Date(item.insuranceEndDate) < new Date()" style="color: red" >{{ item.insuranceEndDate | ToDate }}</div>
            <div v-else>{{ item.insuranceEndDate | ToDate }}</div>
          </template>

          <template v-slot:[`item.docEndDate`]="{ item }">
            <div v-if="new Date(item.docEndDate) < new Date()" style="color: red" >{{ item.docEndDate | ToDate }}</div>
            <div v-else>{{ item.docEndDate | ToDate }}</div>
          </template>
           <template v-slot:[`item.inspectionEndDate`]="{ item }">
            <div v-if="new Date(item.inspectionEndDate) < new Date()" style="color: red" >{{ item.inspectionEndDate | ToDate }}</div>
            <div v-else>{{ item.inspectionEndDate | ToDate }}</div>
          </template>

          <template v-slot:[`item.owner`]="{ item }">
            <span
              ><v-chip label small> {{ item.ownership | CarOwnership }}</v-chip>
            </span>
            <span>{{ item.owner }}</span>
          </template>

          <template v-slot:[`item.plate`]="{ item }">
            <div>
              اللوحة: <span>{{ item.plate }}</span>
            </div>
            <div>
              موديل: <span>{{ item.model }}</span>
            </div>
            <div>
              نوع: <span>{{ item.carType.name }}</span>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              v-can="'DistributorCars.Delete'"
              color="red"
              class="ml-2"
              icon
              elevation="2"
              outlined
              @click="del(item.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              v-can="'DistributorCars.Update'"
              color="primary"
              elevation="2"
              icon
              @click="edit(item.id)"
              ><v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <!-- Add Modal -->
      <v-dialog v-model="dialog" width="800" persistent>
        <v-card>
          <v-form v-model="valid">
            <v-card-title class="headline">
              <i class="fa fa-pen ml-2"></i> تعديل/إضافة
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="my-0 ammar" dense>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      outlined
                      label="رقم اللوحة"
                      v-model="selected.plate"
                      :rules="[allRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      outlined
                      label="الموديل"
                      v-model="selected.model"
                      :rules="[allRules.required]"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="اسم المالك"
                      v-model="selected.owner"
                      :rules="[allRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- start owner -->
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="selected.ownership"
                      outlined
                      :rules="[allRules.required]"
                      dense
                      :items="carOwnershipEnum"
                      label="مستاجره/ تمليك"
                    ></v-autocomplete>
                  </v-col>
                  <!-- end owner -->
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      outlined
                      dense
                      :rules="[allRules.required]"
                      label="النوع"
                      :items="brandList"
                      item-value="id"
                      item-text="name"
                      auto-select-first
                      v-model="selected.carTypeId"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      :rules="[allRules.required]"
                      outlined
                      dense
                      label="الفرع"
                      :items="allBranches"
                      item-value="id"
                      item-text="name"
                      auto-select-first
                      v-model="selected.branchId"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      :rules="[allRules.required]"
                      outlined
                      dense
                      label="السائق"
                      :items="allDrivers"
                      item-value="id"
                      item-text="name"
                      auto-select-first
                      v-model="selected.driverId"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      outlined
                      dense
                      label="الموزع"
                      :items="distributers"
                      item-value="id"
                      item-text="name"
                      auto-select-first
                      v-model="selected.distributorId"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- start data -->
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="6">
                    <DatePicker-layout
                      @myEvent="selected.docEndDate = $event"
                      :date="selected.docEndDate | ToDate"
                      label="تاريخ انتهاء الاستماره "
                    ></DatePicker-layout>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <DatePicker-layout
                      @myEvent="selected.insuranceEndDate = $event"
                      :date="selected.insuranceEndDate | ToDate"
                      label="تاريخ انتهاء التامين "
                    ></DatePicker-layout>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <DatePicker-layout
                      @myEvent="selected.inspectionEndDate = $event"
                      :date="selected.inspectionEndDate | ToDate"
                      label="تاريخ انتهاء الفحص الدوري "
                    ></DatePicker-layout>
                  </v-col>
                  <!-- end date -->
                  <!-- start oil -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      outlined
                      type="number"
                      label="عداد تغير الزيت"
                      :rules="[allRules.required]"
                      v-model="selected.oilCounter"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      outlined
                      type="number"
                      label="عداد بدايه العمل"
                      v-model="selected.workCounter"
                      :rules="[allRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- end  oil -->
                </v-row>
                <v-row class="my-0 ammar" dense>
                  <v-textarea
                    name="input-7-1"
                    label="الملاحظات"
                    outlined
                    v-model="selected.notice"
                  ></v-textarea>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                :disabled="!valid"
                @click="editConfirmed()"
              >
                حفظ
              </v-btn>

              <v-btn
                color="red darken-1"
                class="white--text"
                @click="cancelEdit()"
              >
                إلغاء
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <!-- Add Modal -->
      <!-- dialog for repairig  -->
      <v-dialog v-model="repaireDialog" width="800" persistent>
        <v-card>
          <v-form v-model="validTwo">
            <v-card-title class="headline">
              <i class="fa fa-pen ml-2"></i> تعديل/إضافة
            </v-card-title>
            <v-card-text>
              <v-container>
                <!-- repairng  -->
                <v-row>
                  <v-col cols="12" class="text-center">
                    <h4 class="info--text">جدول الصيانه الدوريه</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      outlined
                      type="number"
                      label="رقم الفاتوره"
                      :rules="[allRules.required]"
                      v-model="repaireData.number"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      outlined
                      label="النوع"
                      v-model="repaireData.type"
                      :rules="[allRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      outlined
                      type="number"
                      label="المبلغ"
                      v-model="repaireData.amount"
                      :rules="[allRules.required]"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <DatePicker-layout
                      @myEvent="repaireData.date = $event"
                      :date="repaireData.date | ToDate"
                      label="تاريخ الشراء "
                    ></DatePicker-layout>
                  </v-col>
                </v-row>
                <v-row class="my-0 ammar" dense>
                  <v-textarea
                    dense
                    hide-details
                    outlined
                    name="input-7-1"
                    label="ألملاحظات"
                    v-model="repaireData.notice"
                  ></v-textarea>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" @click="addRepaire()"> حفظ </v-btn>

              <v-btn
                color="red darken-1"
                class="white--text"
                @click="cancelRepaire()"
              >
                إلغاء
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <!-- finish dialog  -->
    </div>
  </v-app>
</template>

<script>
import ApiService from "../services/DistributionCarService.js";
import carApi from "../../Settings/services/CarTypesService";
import BranchesApi from "../../Settings/services/BranchesService";
import DriverApi from "../services/DriverService.js";
import DistributersService from "../services/DistributersService";
import MaintenanceRecordsService from "../services/MaintenanceRecordsService";
import Enums from "/src/plugins/enums.js";
export default {
  data() {
    return {
      valid: false,
      validTwo: false,
      carOwnershipEnum: Enums.CarOwnership,
      owner: null,
      selected: {},
      entities: [],
      total: 0,
      expanded: [],
      singleExpand: false,
      brandList: null,
      search: "",
      allBranches: [],
      allDrivers: [],
      distributers: [],
      options: {
        Id: null,
        ownerName: null,
        plate: null,
        mark: null,
        driverId: null,
        ownership: null,
        branchId: null,
        distributorId: null,
      },
      dialog: false,
      repaireDialog: false,
      repaireData: {},
      repaireDatalist: [],
      repairId: null,
      loadingStates: {
        table: false,
      },
      tableHeadersRepare: [
        { name: "رقم الفاتورة" },
        { name: "نوع الصيانه" },
        { name: "المبلغ" },
        { name: "تاريخ الشراء" },
        { name: "المحلاحظات" },
      ],
      headers: [
        {
          text: "رقم",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "السيارة", value: "plate" },
        { text: "اسم المالك", value: "owner" },
        { text: "السائق / الموزع", value: "driver.name" },
        { text: "الفرع", value: "branch.name" },
        { text: "انتهاء الاستماره", value: "docEndDate" },
        { text: "انتهاء التامين", value: "insuranceEndDate" },
        { text: "انتهاء الفحص الدوري", value: "inspectionEndDate" },
        { text: "تغيير الزيت", value: "oilCounter" },
        { text: "بدايه العمل", value: "workCounter" },
        { text: "تعديل/مسح", value: "actions", align: "center" },
      ],
    };
  },
  async mounted() {
    try {
      this.loadingStates.table = true;
      const branches = await BranchesApi.query(this.options);
      const drivers = await DriverApi.typeHead("", true, true);
      const alldistributers = await DistributersService.typeHead("", true);
      const cars = await carApi.get();
      this.distributers = alldistributers.data;
      this.allBranches = branches.data.items;
      this.brandList = cars.data;
      this.allDrivers = drivers.data;
      this.loadingStates.table = false;
    } catch (error) {
      this.loadingStates.table = false;
      console.log(error);
    }
  },
  methods: {
    sumField() {
      return this.repaireDatalist.reduce((a, b) => a + (b["amount"] || 0), 0);
    },
    resetOptions() {
      this.options = {
        Id: null,
        ownerName: null,
        plate: null,
        mark: null,
        driverId: null,
        ownership: null,
        branchId: null,
        distributorId: null,
      };
    },
    openRepaireDialog(id) {
      let d = this.formateDateWithMoment(new Date(), "YYYY-MM-DD");
      this.repaireData = { date: d };
      this.repaireDialog = true;
      this.repaireData.carId = id;
    },
    openDialogForEdit(item) {
      this.repaireData = item;
      this.repaireDialog = true;
      this.repaireData.carId = id;
    },
    cancelRepaire() {
      this.repaireDialog = false;
      this.repairId = null;
      this.repaireData = {};
    },

    deleteRepareItem(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف العنصر",
          title: "  حذف سيارة  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                MaintenanceRecordsService.delete(id)
                  .then((resp) => {
                    const index = this.repaireDatalist.indexOf(
                      this.repaireDatalist.filter((e) => e.id == id)[0]
                    );
                    if (index > -1) {
                      this.repaireDatalist.splice(index, 1);
                    }
                    this.$dialog.notify.success("تم مسح العنص بنجاح", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("خطأ اثناء مسح العنصر", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => {});
    },
    addRepaire() {
      MaintenanceRecordsService.edit(this.repaireData)
        .then((resp) => {
          this.repaireDialog = false;
          var message = "تم تعديل العنصر بنجاح";
          if (!this.repaireData.id) {
            this.addToArr(this.repaireDatalist, resp.data);
            message = "تم إضافة العنصر بنجاح";
          } else {
            this.updateArr(this.repaireDatalist, resp.data);
          }

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.repaireDialog = false;
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    loadDetails({ item }) {
      this.repaireDatalist = [];
      MaintenanceRecordsService.getbyCarId(item.id).then((resp) => {
        this.repaireDatalist = resp.data;
      });
    },
    getData() {
      this.loadingStates.table = true;
      if (this.options) this.options.searchTerm = this.search;
      ApiService.query(this.$clean(this.options))
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
        });
    },
    editConfirmed() {
      ApiService.edit(this.selected)
        .then((resp) => {
          this.dialog = false;
          var message = "تم تعديل العنصر بنجاح";
          if (!this.selected.id) {
            this.addToArr(this.entities, resp.data);
            message = "تم إضافة العنصر بنجاح";
          } else {
            this.updateArr(this.entities, resp.data);
          }

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.dialog = false;
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    cancelEdit() {
      this.dialog = false;
      this.selected = {};
    },
    add() {
      let d = this.formateDateWithMoment(new Date(), "YYYY-MM-DD");
      this.selected = { insuranceEndDate: d, docEndDate: d, inspectionEndDate: d };
      this.dialog = true;
    },
    edit(id) {
      this.selected = { ...this.entities.filter((e) => e.id == id)[0] };

      this.dialog = true;
    },
    del(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف العنصر",
          title: "  حذف سيارة  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                ApiService.delete(id)
                  .then((resp) => {
                    const index = this.entities.indexOf(
                      this.entities.filter((e) => e.id == id)[0]
                    );
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("تم مسح العنص بنجاح", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("خطأ اثناء مسح العنصر", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => {});
    },
    view(id) {},
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
</style>