<template>
  <div>
    <div>
      <v-menu :disabled="disableMenu">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-chip
                small
                outlined
                :color="model.color"
                dark
                label
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon :color="model.color" class="pl-2" small>{{
                  model.icon
                }}</v-icon>
                <span>{{ model.text }}</span>
              </v-chip>
            </template>
            <span>الحالة</span>
          </v-tooltip>
        </template>

        <v-card class="text-center" width="200">
          <v-list>
            <v-list-item class="m-0">
              <v-list-item-content v-if="model">
                <v-list-item-title class="text-h6"
                  >تغيير الحالة من</v-list-item-title
                >
                <v-list-item-subtitle>
                  <v-chip label small outlined :color="model.color">
                    <v-icon small :color="model.color">{{ model.icon }}</v-icon
                    >{{ model.text }}
                  </v-chip>
                </v-list-item-subtitle>
                <v-list-item-subtitle> إلى: </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="m-1"></v-divider>
            <v-list-item-action class="mt-0">
              <v-list-item-title
                v-for="(opt, index) in filtered"
                :key="index"
                class="m-1"
              >
                <v-chip
                  label
                  outlined
                  :color="opt.color"
                  style="cursor: pointer"
                  @click="$emit('changeState', opt.value)"
                >
                  <v-icon :color="opt.color">{{ opt.icon }}</v-icon
                  >{{ opt.text }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-action>
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  props: ["state", "options", "disableMenu"],
  mounted() {},
  created() {},
  computed: {
    classE() {
      return {
        color: `${this.model.color} darken-2`,
      };
    },
    model() {
      var member = this.getEnumMember(this.options, +this.state);
      return member;
    },
    filtered() {
      return this.options.filter((e) => e.value != +this.state);
    },
  },
  watch: {
    options() {},
  },
};
</script>

<style>
</style>
