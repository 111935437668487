<template>
  <v-container>
    <!-- text fields -->
    <v-card class="mx-auto" max-width="500">
      <v-toolbar class="mt-3 indigo darken-4 mb-5" cards dark flat>
        <v-card-title class="mx-auto text-capitalize">
          <p class="text-h5">تسجيل الدخول</p>
        </v-card-title>
      </v-toolbar>
      <v-form v-model="valid" @submit.prevent="submit" class="pa-3 mt-5">
        <v-row align="center" justify="center" style="height: 300px">
          <v-col cols="12" md="8">
            <v-text-field
              outlined
              color="#1A237E"
              v-model="userName"
              :rules="[allRules.required]"
              label="إسم المستخدم"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field
              outlined
              color="#1A237E"
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[allRules.required]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="كلمة المرور"
              hint="مطلوب 6 أحرف على الاقل"
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field
              outlined
              color="#1A237E"
              v-model="code"
              :rules="[allRules.required]"       
              type="password" 
              name="input-10-1"
              label="كود التوثيق"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center" aling="center">
          <v-col cols="12" md="8" class="d-flex justify-center">
            <v-btn
              block
              type="submit"
              color="primary"
              :disabled="!valid"
              :loading="loading"
              >تسجيل الدخول</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    valid: false,
    show1: false,
    loading: false,
    password: "",
    userName: "",
    code: "",
  }),
  methods: {
    clear() {
      this.userName = "";
      this.password = "";
      this.code = "";
    },

    submit() {
      this.loading = true;
      this.$store
        .dispatch("login", { userName: this.userName, password: this.password, code: this.code })
        .then((resp) => {
          this.loading = false;
          window.location.href = "/dashboard";
        })
        .catch((err) => {
          this.loading = false;
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
  },
};
</script>

<style>
</style>