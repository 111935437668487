<template>
  <div>
    <v-card min-width="80%" class="mx-auto ma-3">
      <v-data-table
        v-can="'AuditLog.View'"
        :headers="headers"
        :items="entities"
        :options.sync="options"
        :server-items-length="total"
        :search="search"
        :items-per-page="100"
        :loading="loadingStates.table"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-plus',
          nextIcon: 'mdi-minus',
          'items-per-page-text': 'عدد السجلات ' + total + '',
        }"
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="6" sm="2">
              <v-autocomplete
                label=" الموظف"
                dense
                outlined
                :items="employees"
                item-value="id"
                item-text="name"
                auto-select-first
                v-model="options.employeeId"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete
                label=" النوع"
                dense
                outlined
                :items="types"
                item-value="id"
                item-text="name"
                auto-select-first
                v-model="options.typeId"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <DatePicker-layout
                @myEvent="options.date = $event"
                :date.sync="options.date"
                label="التاريخ"
              ></DatePicker-layout>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <span
            ><v-chip color="green lighten-3" label
              >{{ item.date | ToDate }}
            </v-chip>
          </span>
        </template>
        <template v-slot:[`item.time`]="{ item }">
          <span
            ><v-chip color="blue lighten-3" label
              >{{ item.date | localdate }}
            </v-chip>
          </span>
        </template>
        <template v-slot:[`item.empname`]="{ item }">
          <span>
            <div v-if="item.userId != null">
              <v-chip color="blue lighten-3" label>
                {{ getemp(item.userId) }}
              </v-chip>
            </div>
          </span>
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <span>
            <div>
              <v-chip color="blue lighten-3" label>
                {{
                  descriptiondata(
                    item.controllerName,
                    item.actionName,
                    item.parameters
                  )
                }}
              </v-chip>
            </div>
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import EmployeeService from "../services/EmployeeService.js";
import AuditLogService from "../services/AuditLogService.js";
export default {
  data() {
    return {
      entities: [],
      employees: [],
      types: [
        {
          id: 1,
          name: "اضافة",
        },
        {
          id: 2,
          name: "مسح",
        },
        {
          id: 3,
          name: "تعديل",
        },
        {
          id: 4,
          name: "ارجاع بوليصة",
        },
        {
          id: 5,
          name: "فتح رحلة",
        },
        {
          id: 6,
          name: "غلق رحلة",
        },
        {
          id: 7,
          name: "ترحيل رحلة",
        },
        {
          id: 8,
          name: "تسليم عميل",
        },
      ],
      options: {
        employeeId: null,
        date: null,
      },
      search: "",
      total: 0,
      loadingStates: {
        table: false,
      },
      headers: [
        {
          text: "التاريخ",
          value: "date",
          width: "5%",
        },
        {
          text: "الوقت",
          value: "time",
          width: "10%",
        },
        {
          text: "اسم الموظف",
          value: "empname",
          width: "5%",
        },
        {
          text: "IP السجل",
          value: "ipAddress",
          width: "15%",
        },
        {
          text: "الوصف",
          value: "description",
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  created() {
    EmployeeService.typeHead("", true).then((resp) => {
      this.employees = resp.data;
      console.log(this.employees);
    });
  },
  methods: {
    getemp(userid) {
      let emp = this.employees.filter((e) => e.id == userid)[0];
      if (emp != null) return emp.name;
    },
    descriptiondata(controllerName, actionName, parameters) {
      switch (controllerName) {
        case "Shipments":
          switch (actionName) {
            case "AddToRecipient":
              return `تسليم سجل البوليصة رقم ${
                JSON.parse(parameters).command.ShipmentsIds[0]
              } الى المستلم : ${JSON.parse(parameters).command.Name}`;
            case "Update":
              return `تعديل سجل البوليصة رقم ${
                JSON.parse(parameters).command.ManualNo
              }`;
            case "Add":
              return `إضافة سجل البوليصة رقم ${
                JSON.parse(parameters).command.ManualNo
              }`;
            case "Delete":
              return `حذف سجل البوليصة رقم ${JSON.parse(parameters).id}`;
          }
          break;
        case "Trips":
          switch (actionName) {
            case "Update":
              return `تعديل سجل الرحلة رقم ${JSON.parse(parameters).id}`;
            case "Add":
              return `إضافة سجل الرحلة رقم ${JSON.parse(parameters).id}`;
            case "Delete":
              return `حذف سجل الرحلة رقم ${JSON.parse(parameters).id}`;
            case "UpdateState":
              var state = JSON.parse(parameters).command.State;
              var text = "";
              switch (state) {
                case 1:
                  text = "اعادة فتح";
                  break;
                case 2:
                  text = "غلق";
                  break;
                case 3:
                  text = "ترحيل";
                  break;
              }
              return `${text} سجل الرحلة رقم ${JSON.parse(parameters).id}`;
            case "UpdateTripMovement":
              var state = JSON.parse(parameters).command.State;
              var text = "";
              switch (state) {
                case 1:
                  text = "تسجيل تحرك";
                  break;
                case 2:
                  text = "تسجيل وصول";
                  break;
              }
              return `${text} الرحلة رقم ${JSON.parse(parameters).id}`;
            case "ActivityReport":
              return `طباعة/عرض حركة التشغيل  بتاريخ ${this.formateDateWithMoment(
                JSON.parse(parameters).query.Date,
                "YYYY-MM-DD"
              )}`;
            case "Print":
              return `طباعة/عرض تفاصيل سجل الرحلة رقم ${
                JSON.parse(parameters).id
              }`;
          }
          break;
      }
    },
    getData() {
      this.loadingStates.table = true;
      if (this.options) this.options.searchTerm = this.search;
      AuditLogService.query(this.options)
        .then((resp) => {
          this.entities = resp.data.items.filter(
            (e) =>
              (e.controllerName == "Shipments" ||
                e.controllerName == "Trips") &&
              e.actionType != "GET"
          );
          this.total = resp.data.total;
          this.loadingStates.table = false;
          console.log(this.entities);
        })
        .catch((err) => {
          this.loadingStates.table = false;
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
</style>
