import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import Print from './print.js'
import VueHtmlToPaper from 'vue-html-to-paper';

Vue.use(VueHtmlToPaper);
Vue.use(VueSidebarMenu)
Vue.use(require('vue-moment'));
Vue.use(Print, {

  name: 'الطباعة',
  specs: [
    'fullscreen=no',
    'titlebar=no',
    'scrollbars=no'
  ],
  styles:
    [
      "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
      '/src/assets/css/trip-report.css'
    ],

  timeout: 500, // default timeout before the print window appears
  autoClose: false, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title

});