<template>
  <v-app>
    <v-container>
      <div class="content" v-if="printData">
        <div>
          <v-row class="" justify="center">
            <v-col cols="4" evaluate="2" class="data pa-2 text-h4 font-weight-bold text-center">
              {{ this.$store.getters.companyName }}
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row justify="space-between" class="ammar" dense>
            <v-col cols="5" class="mt-9 text-right">
              <h5 class="mb-1">كشف خصومات فرع {{ cityname }} </h5>
            </v-col>

            <v-col cols="3" class="mt-5 text-right">
              <v-row>
                <v-col cols="4"> تاريخ الطباعة </v-col>
                <v-col cols="5" class="">{{ print.date }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4"> التوقيت </v-col>
                <v-col cols="5" class="">{{ print.time }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <v-row class="mb-5">
          <v-col cols="12 m-0 p-0 mt-5">
            <v-data-table :headers="headers" :items="recordsDatalist" :server-items-length="total" :items-per-page="-1"
              class="elevation-1" hide-default-footer>
              <template v-slot:[`item.createDate`]="{ item }">
                {{ item.createDate | ToDate }}
              </template>
              <template v-slot:[`item.number`]="{ item }">
                {{ recordsDatalist.indexOf(item) + 1 }}
              </template>
              <template v-slot:[`item.discountType`]="{ item }">
                {{ item.discountType | DiscountTypes }}
              </template>
              <template v-slot:[`item.discountPaidType`]="{ item }">
                {{ item.discountPaidType | PaymentPaidTypes }}
              </template>
              <template v-slot:[`item.trackingNumber`]="{ item }">
                <div>
                  <div v-if="
                    item.trackingNumber != null &&
                    item.trackingNumber != ' ' &&
                    item.trackingNumber != ''
                  ">
                    {{ item.trackingNumber }}
                  </div>
                </div>
              </template>
              <template slot="body.append">
                <tr class="red--text">
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center">
                    {{ sumField() }} ريال
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
      <div v-if="!printData">Loading</div>
    </v-container>
  </v-app>
</template>

<script>
import ShipmentsDiscountsService from "../../LandShipping/services/ShipmentsDiscountsService";
import Enums from "../../../plugins/enums";
export default {
  mounted() {
    if (this.$route.query["reportId"] && this.$route.query["cityname"]) {
      this.cityname = this.$route.query["cityname"];
      this.getDataFromAPI(this.$route.query["reportId"]);
    }
  },
  data() {
    return {
      recordsDatalist: [],
      printData: false,
      total: 15,
      cityname: null,
      print: {
        time: new Date().toLocaleTimeString(),
        date: this.formateDateWithMoment(Date.now(), "YYYY-MM-DD"),
      },
      enums: Enums,
    };
  },
  computed: {
    headers() {
      let headers = [
        {
          text: "م",
          align: "start",
          sortable: true,
          value: "number",
        },
        {
          text: "التاريخ",
          value: "createDate",
          align: "right",
        },
        {
          text: "رقم البوليصة",
          value: "trackingNumber",
          align: "start",
        },
        {
          text: "قيمة الخصم",
          value: "discount",
          align: "center",
        },
        {
          text: "نوع الخصم",
          value: "discountType",
          align: "center",
        },
        {
          text: "طريقة الدفع",
          value: "discountPaidType",
          align: "center",
        },
        {
          text: "البيان",
          value: "notice",
          align: "center",
        },
      ];
      return headers;
    },
  },
  methods: {
    sumField() {
      return this.recordsDatalist.reduce((a, b) => a + (b["discount"] || 0), 0);
    },
    printopen() {
      setTimeout(() => {
        window.focus();
        var css = "@page { size: landscape; }",
          head = document.head || document.getElementsByTagName("head")[0],
          style = document.createElement("style");

        style.type = "text/css";
        style.media = "print";

        if (style.styleSheet) {
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);
        window.print();
      }, 100);
    },
    getDataFromAPI(id) {
      this.recordsDatalist = [];
      ShipmentsDiscountsService.getByReportId(id).then((resp) => {
        this.recordsDatalist = resp.data.items;
        console.log(this.recordsDatalist);
        this.total = resp.data.total;
        this.printData = true;
        this.printopen();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari */
  color-adjust: exact !important;
  /*Firefox*/
}

.content {
  font-weight: 600 !important;
}

@media print {}

::v-deep .v-data-table-header {
  background-color: #8080802b;
}

::v-deep .v-data-table tr {
  font-size: x-small !important;
  height: 14px !important;
  border: black solid 2px !important;
}

::v-deep .v-data-table td {
  font-size: x-small !important;
  height: 20px !important;
  border: black solid 2px !important;
}

::v-deep .v-data-table th {
  font-size: x-small !important;
  border: black solid 2px !important;
}

.ammar div {
  margin: 0 !important;
  padding: 1px !important;
  font-weight: bold;
}

.ammar div .edit {
  margin-top: 20px !important;
}

.data {
  border: 2px solid grey;
}

.both {
  position: absolute;
}

.dashed-border {
  border: 2px dotted grey;
  padding: 10px;
}

.first {
  bottom: -32px;
  left: 33%;
}

.second {
  bottom: 33;
  left: 8%;
}

.review div {
  border: 2px solid black;
  display: inline-block;
  font-weight: bold;
}
</style>
