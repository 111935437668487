<template>
  <v-container v-if="shipment" style="padding-top: 2px">
    <div class="main-break">
      <div style="height: 736px; font-size: large; font-weight: 600">
        <app-barcode :shipment="shipment" :trip="trip"> </app-barcode>
      </div>
      <div style="font-size: large; font-weight: 600; padding-top: 10px;">
        <app-barcode :shipment="shipment" :trip="trip"> </app-barcode>
      </div>
      <div class="pagebreak"></div>
    </div>
  </v-container>
</template>

<script>
import ApiService from "../../LandShipping/services/ShipmentService";
import AppBarcode from "./components/AppBarcode.vue";

export default {
  components: { AppBarcode },
  mounted() {
    var id = +this.$route.query["shipmentid"];
    this.getDataFromAPI(id);
  },
  data() {
    return {
      trip: {},
      shipment: null,
    };
  },
  methods: {
    getDataFromAPI(id) {
      ApiService.getById(id)
        .then((resp) => {
          console.log(resp.data);
          this.shipment = resp.data;
          this.trip = resp.data.trip;
          setTimeout(() => {
            window.focus();
            window.print();
          }, 100);
          window.onfocus = function () {
            setTimeout(function () {
              window.close();
            }, 500);
          };
        })
        .catch((resp) => {})
        .finally(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .pagebreak {
    clear: both;
    page-break-after: auto;
  }
  body {
    width: 21cm;
    height: 29.7cm;
    margin: 30mm 45mm 30mm 45mm;
    /* change the margins as you want them to be. */
  }
}

.main-break:not(:first-child) {
  padding-top: 20px !important;
  padding-bottom: 0px !important;
}

.main-break:first-child {
  padding-bottom: 1.2rem !important;
}

.ammar div {
  text-align: center !important;
}
</style>
