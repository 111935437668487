var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[(_vm.printData)?_c('div',{staticClass:"content"},[_c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"data pa-2 text-h4 font-weight-bold text-center",attrs:{"cols":"4","evaluate":"2"}},[_vm._v(" "+_vm._s(this.$store.getters.companyName)+" ")])],1)],1),_c('div',[_c('v-row',{staticClass:"ammar",attrs:{"justify":"space-between","dense":""}},[_c('v-col',{staticClass:"mt-9 text-right",attrs:{"cols":"5"}},[_c('h5',{staticClass:"mb-1"},[_vm._v("كشف خصومات فرع "+_vm._s(_vm.cityname)+" ")])]),_c('v-col',{staticClass:"mt-5 text-right",attrs:{"cols":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" تاريخ الطباعة ")]),_c('v-col',{attrs:{"cols":"5"}},[_vm._v(_vm._s(_vm.print.date))])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" التوقيت ")]),_c('v-col',{attrs:{"cols":"5"}},[_vm._v(_vm._s(_vm.print.time))])],1)],1)],1)],1),_c('v-row',{staticClass:"mb-5"},[_c('v-col',{attrs:{"cols":"12 m-0 p-0 mt-5"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.recordsDatalist,"server-items-length":_vm.total,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("ToDate")(item.createDate))+" ")]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.recordsDatalist.indexOf(item) + 1)+" ")]}},{key:"item.discountType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("DiscountTypes")(item.discountType))+" ")]}},{key:"item.discountPaidType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("PaymentPaidTypes")(item.discountPaidType))+" ")]}},{key:"item.trackingNumber",fn:function(ref){
var item = ref.item;
return [_c('div',[(
                  item.trackingNumber != null &&
                  item.trackingNumber != ' ' &&
                  item.trackingNumber != ''
                )?_c('div',[_vm._v(" "+_vm._s(item.trackingNumber)+" ")]):_vm._e()])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"red--text"},[_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.sumField())+" ريال ")])])])],2)],1)],1)],1):_vm._e(),(!_vm.printData)?_c('div',[_vm._v("Loading")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }