import Vue from 'vue';
import VueRouter from 'vue-router';

import SharedRoutes from '../features/shared/shared-routes'
import LandShippingRoutes from '../features/LandShipping/land-shipping-routes'
import SettingsRoutes from '../features/Settings/settings-routes'
import FinancesRoutes from '../features/Finances/Finances-routes'
import SystemRoutes from '../features/System/system-routes'
import ReportsRoutes from '../features/Reports/reports-routes'
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  ...SharedRoutes,
  ...LandShippingRoutes,
  ...SettingsRoutes,
  ...FinancesRoutes,
  ...SystemRoutes,
  ...ReportsRoutes
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      if (to.matched.some(record => record.meta.roles)) {
        let user = JSON.parse(localStorage.getItem('user'));
        if (!to.meta.roles.includes(user.roles[0].name)) {
          next({ name: 'Dashboard' })
        }
        else {
          next()
        }
      }
      else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('token') == null) {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  } else {
    next()
  }
})

export default router;
