<template>
  <div>
    <v-row class="pr-5 pb-3 mt-3">
      <v-col class="d-flex justify-content-start">
        <v-btn v-can="'ExpenseReceipt.Create'" color="success" @click="add">
          سند الصرف
          <i class="fa fa-plus mr-2"></i>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">
        <v-btn v-can="'ExpenseReceipt.Update'" @click="changeStatestart()" v-if="changeStatestarts"
          class="ma-2 error">الغاء</v-btn>
        <v-btn v-can="'ExpenseReceipt.Update'" @click="changeStatestart()" v-if="!changeStatestarts"
          class="ma-2 info">ترحيل</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">
        <v-btn v-can="'ExpenseReceipt.Update'" @click="changeStatebulkopen()" class="ma-2 info" v-if="changeStatestarts"
          :disabled="selecteditems.length < 1">تغير الحالة</v-btn>
      </v-col>
    </v-row>
    <v-card width="99%" class="mx-auto">
      <v-data-table v-can="'ExpenseReceipt.View'" v-model="selecteditems" :headers="headers" :items="entities"
        :options.sync="options" :server-items-length="total" :search="search" :items-per-page="15"
        :show-select="changeStatestarts ? true : false" :loading="loadingStates.table" class="elevation-1" :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-plus',
          nextIcon: 'mdi-minus',
          'items-per-page-text': 'عدد السندات ' + total + '',
        }">
        <template v-slot:top>
          <v-row class="mx-auto mt-3 pa-5" dark dense>
            <v-col cols="12" sm="2">
              <v-text-field hide-details dense outlined v-model="options.Id" label="رقم السند"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field hide-details dense outlined v-model="options.shipmentId" label="رقم البوليصة"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field hide-details dense outlined v-model="options.tripId" label="رقم الرحلة"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field hide-details dense outlined v-model="options.recipient" label="المستلم"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <DatePicker-layout @myEvent="options.date = $event" :date.sync="options.date"
                label="التاريخ "></DatePicker-layout>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete dense outlined hide-details label="الفروع" :items="branches" item-value="id"
                item-text="name" v-model="options.branchId"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete dense hide-details v-model="options.state" outlined :items="enums.RecordStates"
                item-text="text" item-value="value" label="الحالة"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete label="جهة الصرف" dense outlined :items="cashingSide" item-value="id" item-text="name"
                auto-select-first v-model="options.expenseEntity"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete label="المندوب" dense outlined :items="salesmans" item-value="id" item-text="name"
                auto-select-first v-model="options.salesmanId"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete label=" الموظف" dense outlined :items="employees" item-value="id" item-text="name"
                auto-select-first v-model="options.employeeId"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete label=" السائق" dense outlined :items="drivers" item-value="id" item-text="name"
                auto-select-first v-model="options.driverId"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete label=" المصروف" dense outlined :items="expenses" item-value="id" item-text="description"
                auto-select-first v-model="options.expenseTypeId"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete label="مصدر الصرف" dense outlined :items="enums.ExpenseReceiptPaymentTypes"
                item-value="value" item-text="text" auto-select-first v-model="options.paymentSource"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <DatePicker-layout @myEvent="options.dateFrom = $event" :date.sync="options.dateFrom"
                label="التاريخ من "></DatePicker-layout>
            </v-col>
            <v-col cols="12" sm="2">
              <DatePicker-layout @myEvent="options.dateTo = $event" :date.sync="options.dateTo"
                label="التاريخ الى "></DatePicker-layout>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn color="error" @click="resetOptions">تفريغ
                <i class="fa fa-trash fa-lg"></i>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <div v-if="item.id">
            <div v-if="item.id">
              {{ item.id }}
            </div>
            <div v-if="item.date">
              <span><v-chip style="font-size: smaller" color="green lighten-3" label>{{ item.date | ToDate }}
                </v-chip>
              </span>
            </div>
          </div>
        </template>
        <template v-slot:[`item.accountNo`]="{ item }">
          <v-chip v-if="item.accountNo" label>{{ item.accountNo }}</v-chip>
          <v-chip v-if="item.accountNo == 0" label>لا يوجد</v-chip>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | ToDate }}
        </template>
        <template v-slot:[`item.paymentSource`]="{ item }">
          <span><v-chip color="blue lighten-3" label>{{ item.paymentSource | ExpenseReceiptPaymentTypes }}
            </v-chip>
          </span>
        </template>
        <template v-slot:[`item.expenseType.description`]="{ item }">
          <span v-if="item.expenseType"><v-chip color="green lighten-3" label>{{ item.expenseType.description }}
            </v-chip>
          </span>
        </template>
        <template v-slot:[`item.expenseEntity`]="{ item }">
          <span v-if="item.expenseEntity"><v-chip color="green lighten-3" label>{{ item.expenseEntity | mcashingSide }}
            </v-chip>
          </span>
        </template>
        <template v-slot:[`item.viewname`]="{ item }">
          <span v-if="item.employee"><v-chip color="green lighten-3" label>{{ item.employee.name }}
            </v-chip>
          </span>
          <span v-if="item.driver"><v-chip color="green lighten-3" label>{{ item.driver.name }}
            </v-chip>
          </span>
          <span v-if="item.salesman"><v-chip color="green lighten-3" label>{{ item.salesman.name }}
            </v-chip>
          </span>
          <span v-if="item.salesman == null &&
            item.driver == null &&
            item.employee == null
            "><v-chip color="green lighten-3" label>{{ item.recipient }}
            </v-chip>
          </span>
        </template>
        <template v-can="'ExpenseReceipt.Update'" v-slot:[`item.state`]="{ item }">
          <AppState :state="item.state" :options="enums.RecordStates" @click="selected = item"
            @changeState="changeState(item, $event)" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="orange" outlined elevation="2" class="ml-2" icon v-can="'ExpenseReceipt.View'"
            @click="view(item.id)"><v-icon>mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn color="blue" outlined elevation="2" class="ml-2" icon v-can="'ExpenseReceipt.View'"
            @click="print(item.id)"><v-icon>mdi-printer</v-icon>
          </v-btn>
          <v-btn color="primary" outlined elevation="2" class="ml-2" icon v-can="'ExpenseReceipt.Update'"
            @click="edit(item.id)"><v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn color="red" class="ml-2" icon elevation="2" outlined v-can="'ExpenseReceipt.Delete'"
            @click="del(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <!-- Add Modal -->
    <v-dialog v-model="dialog" width="1000" persistent>
      <v-card>
        <v-form v-model="valid">
          <v-card-title class="headline">
            <i class="fa fa-pen ml-2"></i> تعديل/إضافة
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense class="mb-2">
                <v-col cols="12" sm="12">
                  <div class="
                      hiddenradio
                      text-center
                      grey
                      lighten-3
                      pa-3
                      rounded-lg
                    ">
                    <h4 class="font-weight-bold">الحالة</h4>
                    <label v-for="(state, i) in enums.States" :key="i" class="ma-3">
                      <input :disabled="viewdialog" type="radio" name="currentState" v-model="selected.state"
                        :value="state.value" />
                      <div class="pa-3">
                        <v-icon large :color="state.color">{{
                          state.icon
                        }}</v-icon>
                      </div>
                    </label>
                    <h5 class="lg-rounded" :class="showState.color">
                      {{ showState.state }}
                    </h5>
                  </div>
                </v-col>
              </v-row>
              <v-row class="my-0 ammar" dense>
                <v-col cols="12" sm="6">

                    <v-autocomplete :disabled="viewdialog" v-if="!loading" :rules="[allRules.required]" label="الفرع"
                    dense outlined required :items="branches" item-value="id" item-text="name" auto-select-first
                    v-model="selected.branchId" clearable></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <DatePicker-layout @myEvent="selected.date = $event" disabled :date="selected.date | ToDate"
                    :rules="[allRules.required]" label="التاريخ"></DatePicker-layout>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field :disabled="viewdialog" type="number" outlined dense :rules="[allRules.required]"
                    label="المبلغ" v-model="selected.amount" clearable></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field :disabled="viewdialog" outlined dense :rules="[allRules.required, allRules.minNameLen(4)]"
                    label="المستلم" v-model="selected.recipient" clearable></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete :disabled="viewdialog" v-if="!loading" :rules="[allRules.required]" label="جهة الصرف"
                    dense outlined required :items="cashingSide" item-value="id" item-text="name" auto-select-first
                    v-model="selected.expenseEntity" clearable></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete v-if="!loading" label="المندوب" dense
                    :disabled="selected.expenseEntity !== 5 || viewdialog" outlined required :items="salesmans"
                    item-value="id" item-text="name" auto-select-first v-model="selected.salesmanId"
                    clearable></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field type="number" outlined :disabled="selected.expenseEntity !== 1 || viewdialog" dense
                    label="رقم البوليصة" v-model="selected.shipmentTrackingNumber" clearable></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field type="number" outlined dense :disabled="selected.expenseEntity !== 2 || viewdialog"
                    label="رقم الرحلة" v-model="selected.tripId" clearable></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete v-if="!loading && employees" :disabled="selected.expenseEntity !== 3 || viewdialog"
                    label=" الموظف" dense outlined :items="employees" item-value="id" item-text="name" auto-select-first
                    v-model="selected.employeeId" clearable></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete v-if="!loading && drivers" :disabled="(selected.expenseEntity !== 4 &&
                    selected.expenseEntity !== 2) ||
                    viewdialog
                    " label=" السائق" dense outlined :items="drivers" item-value="id" item-text="name"
                    auto-select-first v-model="selected.driverId" clearable></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete v-if="!loading && expenses" :disabled="(selected.expenseEntity !== 6 &&
                    selected.expenseEntity !== 4 &&
                    selected.expenseEntity !== 5 &&
                    selected.expenseEntity !== 3 &&
                    selected.expenseEntity !== 2) ||
                    viewdialog
                    " label=" المصروف" dense outlined :items="expenses" item-value="id" item-text="description"
                    auto-select-first v-model="selected.expenseTypeId" clearable></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-radio-group :disabled="viewdialog" v-model="selected.paymentSource" row :rules="[allRules.required]">
                    <template v-slot:label>
                      <div class="text-right">
                        <strong>مصادر الصرف</strong>
                      </div>
                    </template>
                    <v-radio label="صندوق" :value="1"></v-radio>
                    <v-radio label="عهدة" :value="2"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6">
                  <v-textarea :disabled="viewdialog" outlined dense hide-details rows="3" row-height="15" label="البيان"
                    v-model="selected.description"></v-textarea>
                </v-col>
                <v-col cols="6">
                  <v-textarea :disabled="viewdialog" outlined dense hide-details rows="3" row-height="15" label="ملحوظات"
                    v-model="selected.notice"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-if="!viewdialog" :disabled="!valid" @click="editConfirmed()">حفظ</v-btn>
            <v-btn color="red darken-1" text @click="cancelEdit()">إلغاء</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Add Modal -->
    <v-dialog v-model="changestatedialog" width="50%" persistent>
      <v-card>
        <v-card-title class="headline"> تغير حالة السندات </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense justify="center">
              <v-col cols="5">
                <div class="rounded-lg pa-1 text-center grey lighten-3">
                  <h5>عدد السندات المحددة</h5>
                  <h6>{{ selecteditems.length }}</h6>
                </div>
              </v-col>
              <v-col cols="5">
                <div class="rounded-lg pa-1 text-center grey lighten-3">
                  <h5>اجمالى السندات المحددة</h5>
                  <h6>
                    {{
                      selecteditems.reduce((a, b) => a + (b["amount"] || 0), 0)
                    }}
                  </h6>
                </div>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field outlined dense label="رقم القيد" :rules="[allRules.required, allRules.minNameLen(4)]"
                  v-model="selecteditemsManualNo" clearable></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="mb-2">
              <v-col cols="12" sm="12">
                <div class="hiddenradio text-center grey lighten-3 pa-3 rounded-lg">
                  <h4 class="font-weight-bold">الحالة</h4>
                  <label v-for="(state, i) in enums.States" :key="i" class="ma-3">
                    <input type="radio" name="currentState" v-model="selected.state" :value="state.value" />
                    <div class="pa-3">
                      <v-icon large :color="state.color">{{
                        state.icon
                      }}</v-icon>
                    </div>
                  </label>
                  <h5 class="lg-rounded" :class="showState.color">
                    {{ showState.state }}
                  </h5>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn :disabled="!selecteditemsManualNo" color="primary" @click="changeStatebulk()">
            حفظ
          </v-btn>
          <v-btn color="red darken-1" text @click="cancelStateEdit()">
            إلغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EmployeeService from "../../System/services/EmployeeService";
import ExpensesService from "../services/ExpensesService";
import DriverService from "../../LandShipping/services/DriverService.js";
import SalesmenService from "../../LandShipping/services/SalesmenService.js";
import ApiService from "../services/ReceiptService";
import Enums from "./../../../plugins/enums";
import BranchesService from "../../../features/Settings/services/BranchesService.js";
export default {
  data() {
    return {
      valid: false,
      branches: [],
      employees: [],
      drivers: [],
      salesmans: [],
      expenses: [],
      selected: {},
      changestatedialog: false,
      changeStatestarts: false,
      selecteditems: [],
      selecteditemsState: 0,
      selecteditemsManualNo: null,
      enums: Enums,
      cashingSide: [
        { id: 1, name: "ارجاع بوليصه" },
        { id: 2, name: "صرف لرحله" },
        { id: 3, name: "صرف لموظف" },
        { id: 4, name: "صرف لسائق" },
        { id: 5, name: "صرف لمندوب" },
        { id: 6, name: "مصروفات اخرى" },
      ],
      entities: [],
      total: 0,
      search: "",
      options: {
        Id: null,
        shipmentId: null,
        tripId: null,
        recipient: null,
        date: null,
        branchId: null,
        state: null,
        expenseEntity: null,
        salesmanId: null,
        employeeId: null,
        driverId: null,
        expenseTypeId: null,
        paymentSource: null,
        dateFrom: null,
        dateTo: null,
      },
      dialog: false,
      viewdialog: false,
      loading: false,
      loadingStates: {
        table: false,
      },
      headers: [
        {
          sortable: false,
          text: "رقم السند",
          align: "center",
          value: "id",
        },
        {
          sortable: false,
          text: "رقم القيد",
          value: "accountNo",
          align: "start",
        },
        { sortable: false, text: "المبلغ", value: "amount", align: "start" },
        {
          sortable: false,
          text: "الموظف / السائق / المندوب",
          value: "viewname",
          align: "start",
          width: "20%",
        },
        {
          sortable: false,
          text: "جهة الصرف",
          value: "expenseEntity",
          align: "start",
        },
        {
          sortable: false,
          text: "مصدر الصرف",
          value: "paymentSource",
          align: "start",
        },
        {
          sortable: false,
          text: "المصروف",
          value: "expenseType.description",
          align: "start",
        },
        {
          sortable: false,
          text: "الحاله",
          value: "state",
          align: "start",
        },
        {
          sortable: false,
          text: "مسح / تعديل",
          value: "actions",
          align: "center",
          width: "35%",
        },
      ],
    };
  },
  async mounted() {
    try {
      this.getData();
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    showState() {
      let curentState;
      if (this.selected.state == 1) {
        curentState = {
          state: "مفتوح",
          color: "green--text",
          icon: "mdi-lock-open-variant",
        };
      } else if (this.selected.state == 2) {
        curentState = {
          state: "مغلق",
          color: "red--text",
          icon: "mdi-lock",
        };
      } else if (this.selected.state == 3) {
        curentState = {
          state: "مرحل",
          color: "orange--text",
          icon: "mdi-lock-clock",
        };
      } else {
        curentState = {
          state: "اختار الحالة",
          color: "black--text",
          icon: "mdi-lock-clock",
        };
      }
      return curentState;
    },
  },
  created() {
    this.getData();
    this.loading = true;
    EmployeeService.typeHead("", true).then((resp) => {
      this.employees = resp.data;
    });
    DriverService.typeHead("", true, "").then((resp) => {
      this.drivers = resp.data;
    });
    SalesmenService.typeHead("", true).then((resp) => {
      this.salesmans = resp.data;
    });
    ExpensesService.typeHead("", true).then((resp) => {
      this.expenses = resp.data;
    });
    BranchesService.typeHead("", false, true).then((resp) => {
      this.branches = resp.data;
    });
    this.loading = false;
  },
  methods: {
    resetOptions() {
      this.options = {
        Id: null,
        shipmentId: null,
        tripId: null,
        recipient: null,
        date: null,
        branchId: null,
        state: null,
        expenseEntity: null,
        salesmanId: null,
        employeeId: null,
        driverId: null,
        expenseTypeId: null,
        paymentSource: null,
        dateFrom: null,
        dateTo: null,
      };
    },
    changeStatestart() {
      this.changeStatestarts = !this.changeStatestarts;
    },
    changeState(item, state) {
      item.state = state;
      ApiService.edit(item)
        .then((resp) => {
          this.updateArr(this.entities, resp.data);
        })
        .catch((resp) => { })
        .finally(() => { });
    },
    changeStatebulk() {
      let model = {
        ids: this.selecteditems.map((e) => e.id),
        state: this.selected.state,
        accountNo: this.selecteditemsManualNo,
      };
      ApiService.updateState(model)
        .then((resp) => {
          this.getData();
          this.selecteditems = [];
          this.selecteditemsManualNo = null;
          this.changestatedialog = false;
        })
        .catch((resp) => { })
        .finally(() => { });
    },
    cancelStateEdit() {
      this.selecteditems = [];
      this.selecteditemsManualNo = null;
      this.changestatedialog = false;
    },
    changeStatebulkopen() {
      this.changestatedialog = true;
    },
    getData() {
      this.loadingStates.table = true;
      if (this.options) this.options.searchTerm = this.search;
      ApiService.query(this.$clean(this.options))
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
          console.log(this.entities);
        })
        .catch((err) => {
          this.loadingStates.table = false;
        });
    },
    editConfirmed() {
      ApiService.edit(this.selected)
        .then((resp) => {
          this.dialog = false;
          var message = "تم تعديل العنصر بنجاح";
          if (!this.selected.id) {
            //this.addToArr(this.entities, resp.data);
            message = "تم إضافة سند الصرف و بأنتظار اعتمادة";
          } else {
            this.updateArr(this.entities, resp.data);
          }

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.dialog = false;
          console.log(err.request.response);
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },

    cancelEdit() {
      this.viewdialog = false;
      this.dialog = false;
      this.selected = { city: {} };
    },
    add() {
      (this.selected = {
        state: 1,
        date: this.formatDate(Date.now()),
      }),
        (this.dialog = true);
    },
    edit(id) {
      this.selected = { ...this.entities.filter((e) => e.id == id)[0] };
      this.dialog = true;
    },
    del(id) {
      this.$dialog
        .info({
          text: "هل انت متاكد من حذف العنصر",
          title: "  حذف فرع  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                ApiService.delete(id)
                  .then((resp) => {
                    const index = this.entities.indexOf(
                      this.entities.filter((e) => e.id == id)[0]
                    );
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("تم مسح العنص بنجاح", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("خطا اثناء مسح العنصر", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => { });
    },
    view(id) {
      this.selected = {
        ...this.entities.filter((e) => e.id == id)[0],
      };
      this.viewdialog = true;
      this.dialog = true;
    },
    print(id) {
      let routeData = this.$router.resolve({
        name: "ReceiptPrint",
        query: { receiptid: id, reporttype: 1 },
      });
      window.open(routeData.href, "_blank");
    },
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>


<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}

.data {
  border: 2px solid black;
}

.hiddenradio [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
.hiddenradio [type="radio"]+div {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

/* CHECKED STYLES */
.hiddenradio [type="radio"]:checked+div {
  outline: 2px solid #42ac0d;
  outline-style: dashed;
}
</style>