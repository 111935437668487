<template>
  <v-app>
    <div v-if="printData">
      <div class="mt-16" style="height: 700px">
        <div class="mx-auto" style="width: 500px">
          <h2 class="data pa-2 text-h5 text-center">كشف تسليم رحلة لسائق</h2>
        </div>

        <div class="ma-5 mt-10 mb-16 pa-5 driver">
          <v-row class="text-center ammar">
            <v-col cols="12" sm="2" class="data"> التاريخ </v-col>
            <v-col cols="12" sm="4">{{ trip.date | ToDate }} </v-col>
            <v-col cols="12" sm="2"> نوع الرحلة </v-col>
            <v-col cols="12" sm="4"> {{ trip.serviceType.name }} </v-col>
            <v-col cols="12" sm="2"> رقم الرحلة </v-col>
            <v-col cols="12" sm="4">{{ trip.trackingNumber }}</v-col>
            <v-col cols="12" sm="2">عدد الطرود</v-col>
            <v-col cols="12" sm="4">{{ shipments.length }}</v-col>
            <v-col cols="12" sm="2">أسم السائق</v-col>
            <v-col cols="12" sm="4">{{ trip.driver.name }}</v-col>
            <v-col cols="12" sm="2">رقم الهوية</v-col>
            <v-col cols="12" sm="4">{{ trip.driver.residencyNo }}</v-col>
            <v-col cols="12" sm="2">رقم الكفيل</v-col>
            <v-col cols="12" sm="4">{{ trip.driver.sponserMob }}</v-col>
            <v-col cols="12" sm="2">رقم الجوال</v-col>
            <v-col cols="12" sm="4">{{ trip.driver.sponserMob }}</v-col>
            <v-col cols="12" sm="2">رقم اللوحة</v-col>
            <v-col cols="12" sm="4">{{ trip.car.plate }}</v-col>
            <v-col cols="12" sm="2"> نوع السيارة </v-col>
            <v-col cols="12" sm="4">{{ trip.car.carType.name }} </v-col>
            <v-col cols="12" sm="2"> من مدينة </v-col>
            <v-col cols="12" sm="4">{{ trip.line.source.name }}</v-col>
            <v-col cols="12" sm="2"> الي مدينة </v-col>
            <v-col cols="12" sm="4"> {{ trip.line.destination.name }} </v-col>
            <v-col cols="12" sm="8"> ايجار الرحلة </v-col>
            <v-col cols="12" sm="4"> {{ trip.rentAmount }} &nbsp;<a>ريال</a>&nbsp; </v-col>
            <v-col cols="12" sm="8">كود تسليم السائق</v-col>
            <v-col cols="12" sm="4"> {{ trip.driverPassCode }} </v-col>
          </v-row>
          <h4 class="mt-5 text-center">
            استلمت أنا السائق المذكور بيناته أعلاه من مؤسسة الأحمري للنقل البري حسب الكشف المرفق بهذا المستند وأتحمل
            المسؤلية
            الكامله لإيصالها حسب نوع المساء المذكور وأتعهد بمسئوليتى الكامله
            بإيصالها سليمه وغير ناقصه
          </h4>
          <div class="driverInfo pa-3 font-weight-bold mt-10 text-right">
            <p>اسم وتوقيع السائق /</p>
            <p>التاريخ/</p>
          </div>
        </div>
      </div>
      <div class="mt-16 text-center">
        <div class="mx-auto" style="width: 500px">
          <h2 class="data pa-2 text-h5 text-center">كشف تسليم رحلة لسائق</h2>
        </div>
        <div class="ma-5 pa-5 mt-10 text-center">
          <v-row class="text-center ammar">
            <v-col cols="12" sm="2" class="data"> التاريخ </v-col>
            <v-col cols="12" sm="4">{{ trip.date | ToDate }} </v-col>
            <v-col cols="12" sm="2"> نوع الرحلة </v-col>
            <v-col cols="12" sm="4"> {{ trip.serviceType.name }} </v-col>
            <v-col cols="12" sm="2"> رقم الرحلة </v-col>
            <v-col cols="12" sm="4">{{ trip.trackingNumber }}</v-col>
            <v-col cols="12" sm="2">عدد الطرود</v-col>
            <v-col cols="12" sm="4">{{ shipments.length }}</v-col>
            <v-col cols="12" sm="2">أسم السائق</v-col>
            <v-col cols="12" sm="4">{{ trip.driver.name }}</v-col>
            <v-col cols="12" sm="2">رقم الهوية</v-col>
            <v-col cols="12" sm="4">{{ trip.driver.residencyNo }}</v-col>
            <v-col cols="12" sm="2">رقم الكفيل</v-col>
            <v-col cols="12" sm="4">{{ trip.driver.sponserMob }}</v-col>
            <v-col cols="12" sm="2">رقم الجوال</v-col>
            <v-col cols="12" sm="4">{{ trip.driver.sponserMob }}</v-col>
            <v-col cols="12" sm="2">رقم اللوحة</v-col>
            <v-col cols="12" sm="4">{{ trip.car.plate }}</v-col>
            <v-col cols="12" sm="2"> نوع السيارة </v-col>
            <v-col cols="12" sm="4">{{ trip.car.carType.name }} </v-col>
            <v-col cols="12" sm="2"> من مدينة </v-col>
            <v-col cols="12" sm="4">{{ trip.line.source.name }}</v-col>
            <v-col cols="12" sm="2"> الي مدينة </v-col>
            <v-col cols="12" sm="4"> {{ trip.line.destination.name }} </v-col>
            <v-col cols="12" sm="8"> ايجار الرحلة </v-col>
            <v-col cols="12" sm="4"> {{ trip.rentAmount }} &nbsp;<a>ريال</a>&nbsp; </v-col>
            <v-col cols="12" sm="8">كود تسليم السائق</v-col>
            <v-col cols="12" sm="4"> {{ trip.driverPassCode }} </v-col>
          </v-row>
          <h4 class="mt-5 text-center">
            استلمت أنا السائق المذكور بيناته أعلاه من مؤسسة الأحمري للنقل البري حسب الكشف المرفق بهذا المستند وأتحمل
            المسؤلية
            الكامله لإيصالها حسب نوع المساء المذكور وأتعهد بمسئوليتى الكامله
            بإيصالها سليمه وغير ناقصه
          </h4>
          <div class="driverInfo pa-3 font-weight-bold mt-10 text-right">
            <p>اسم وتوقيع السائق /</p>
            <p>التاريخ /</p>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import TripsService from "./../../LandShipping/services/TripsService";
export default {
  mounted() {
    var id = +this.$route.query["tripId"];
    if (id) this.getDataFromAPI(id);
    else this.getDataFromStore();
  },
  data() {
    return {
      shipments: [],
      trip: {},
      printData: null,
    };
  },
  methods: {
    getDataFromAPI(id) {
      TripsService.printDriver(id).then((resp) => {
        this.printData = {};
        this.trip = resp.data.trip;
        this.shipments = resp.data.shipments;
        setTimeout(() => {
          window.focus();
          window.print();
        }, 100);
      }).catch((resp) => {
        this.$dialog.notify.error(resp.request.response, {
          position: "top-right",
          timeout: 3000,
        });
      })
        .finally(() => { });
    },
    getDataFromStore() {
      this.printData = this.$store.getters.printData;
      this.trip = this.printData.trip;
      this.shipments = this.printData.shipments;
    },
  },
};
</script>

<style lang="scss" scoped>
.ammar div {
  border: 1px solid black !important;
  font-weight: 900 !important;
}

.driverInfo {
  border: 2px dotted black;
  width: 300px;
}
</style>
