<template>
  <div>
    <v-row class="pr-5 pb-3 mt-3">
      <v-col class="d-flex justify-content-start">
        <v-btn v-can="'Lines.Create'" color="success" @click="add">
          اضافة مسار<i class="fa fa-plus mr-2"></i>
        </v-btn>
      </v-col>
    </v-row>
    <v-card min-width="80%" class="mx-auto ma-3">
      <v-data-table
        v-can="'Lines.View'"
        :headers="headers"
        :items="entities"
        :options.sync="options"
        :server-items-length="total"
        :search="search"
        :items-per-page="15"
        :loading="loadingStates.table"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-plus',
          nextIcon: 'mdi-minus',
          'items-per-page-text': 'عدد المسارات ' + total + '',
        }"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="اسم المسار"
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:[`item.source`]="{ item }">
          <span v-if="item.source">
            {{ item.source.name }}
          </span>
        </template>
        <template v-slot:[`item.destination`]="{ item }">
          <span v-if="item.destination">
            {{ item.destination.name }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-can="'Lines.Delete'"
            color="red"
            class="ml-2"
            icon
            elevation="2"
            outlined
            @click="del(item.id)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-btn
            v-can="'Lines.Update'"
            color="primary"
            elevation="2"
            icon
            @click="edit(item.id)"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>

    <!-- Add Modal -->
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-form v-model="valid">
          <v-card-title class="headline">
            <i class="fa fa-pen ml-2"></i> تعديل/إضافة
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="my-0 ammar" dense>
                <v-col>
                  <v-text-field
                    dense
                    label="وصف المسار"
                    :rules="[allRules.required, allRules.minNameLen(4)]"
                    v-model="selected.name"
                    outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-0 ammar" dense>
                <v-col>
                  <v-autocomplete
                    outlined
                    dense
                    :rules="[allRules.required]"
                    rounded
                    v-model="sourceBranchId"
                    :items="branches"
                    item-value="id"
                    item-text="name"
                    :loading="loadingStates.branches"
                    auto-select-first
                    label="من"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    outlined
                    dense
                    :rules="[allRules.required]"
                    rounded
                    v-model="destinationBranchId"
                    :items="branches"
                    item-value="id"
                    item-text="name"
                    :loading="loadingStates.branches"
                    auto-select-first
                    label="إلى"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="my-0 ammar" dense>
                <v-col>
                  <v-text-field
                    dense
                    label="قيمة الرحلة"
                    type="number"
                    outlined
                    v-model="selected.price"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-0 ammar" dense>
                <v-textarea
                  name="notice"
                  outlined
                  label="الملاحظات"
                  v-model="selected.notice"
                ></v-textarea>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" :disabled="!valid" @click="editConfirmed()">
              حفظ
            </v-btn>

            <v-btn color="red darken-1" text @click="cancelEdit()">
              إلغاء
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Add Modal -->
  </div>
</template>

<script>
import ApiService from "../services/LinesService.js";
import BranchesService from "../../../features/Settings/services/BranchesService.js";
export default {
  data() {
    return {
      valid: false,
      selected: {},
      entities: [],
      branches: [],
      destinationBranchId: "",
      sourceBranchId: "",
      total: 0,
      search: "",
      options: {},
      dialog: false,
      loadingStates: {
        table: false,
      },
      headers: [
        {
          text: "رقم",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "الاسم", value: "name" },
        { text: "من", value: "source" },
        { text: "الى", value: "destination" },
        { text: "ملاحظات", value: "notice" },
        { text: "مسح / تعديل", value: "actions", align: "left" },
      ],
    };
  },
  mounted() {
    BranchesService.typeHead("any", true, false).then((resp) => {
      this.branches = resp.data;
    });
    this.getData();
  },
  methods: {
    getData() {
      this.loadingStates.table = true;
      if (this.options) this.options.searchTerm = this.search;
      ApiService.query(this.options)
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          console.log(err);

          this.loadingStates.table = false;
        });
    },
    editConfirmed() {
      this.selected.destinationId = this.destinationBranchId;
      this.selected.sourceId = this.sourceBranchId;
      ApiService.edit(this.selected)
        .then((resp) => {
          this.dialog = false;
          var message = "تم تعديل العنصر بنجاح";
          if (!this.selected.id) {
            this.addToArr(this.entities, resp.data);
            message = "تم إضافة العنصر بنجاح";
          } else {
            this.updateArr(this.entities, resp.data);
          }

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.dialog = false;
          console.log(err.request.response);
          this.$dialog.notify.error("خطأ اثناء تعديل العنصر", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },

    cancelEdit() {
      this.dialog = false;
      this.selected = {};
    },
    add() {
      this.selected = {};
      this.dialog = true;
    },
    edit(id) {
      this.selected = { ...this.entities.filter((e) => e.id == id)[0] };
      this.dialog = true;
      var $vue = this;
      setTimeout(() => {
        $vue.destinationBranchId = $vue.selected.destinationId;
        $vue.sourceBranchId = $vue.selected.sourceId;
      }, 100);
    },
    del(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف العنصر",
          title: "  حذف مسار  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                ApiService.delete(id)
                  .then((resp) => {
                    const index = this.entities.indexOf(
                      this.entities.filter((e) => e.id == id)[0]
                    );
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("تم مسح العنص بنجاح", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("خطأ اثناء مسح العنصر", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => {});
    },
    view(id) {},
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
</style>