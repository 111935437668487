<template>
  <div>
    <v-row class="pr-5 pb-3 mt-5">
      <v-col class="d-flex justify-content-start">
        <v-btn v-can="'Users.Create'" color="success" @click="add">
          اضافة مستخدم<i class="fa fa-plus mr-2"></i>
        </v-btn>
      </v-col>
    </v-row>
    <v-card min-width="80%" class="mx-auto ma-3">
      <v-data-table
        v-can="'Users.View'"
        :headers="headers"
        :items="entities"
        :options.sync="options"
        :server-items-length="total"
        :search="search"
        :items-per-page="15"
        :loading="loadingStates.table"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-plus',
          nextIcon: 'mdi-minus',
          'items-per-page-text': 'عدد الموظفين ' + total + '',
        }"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="بحث"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:[`item.roles`]="{ item }">
          <div v-if="item.roles">
            <v-chip
              label
              small
              class="mr-2 text-white"
              color="blue"
              v-for="(role, index) in item.roles"
              :key="index"
            >
              {{ role.arabicName }}
            </v-chip>
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-can="'Users.Update'"
            color="primary"
            elevation="2"
            icon
            @click="edit(item.id)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            color="red"
            class="ml-2"
            icon
            elevation="2"
            outlined
            v-can="'Users.Delete'"
            @click="del(item.id)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- Add Modal -->
    <v-dialog v-model="modals.addUser" width="600" persistent>
      <v-card>
        <v-form v-model="valid" ref="form">
          <v-card-title class="headline">
            <i class="fa fa-pen ml-2"></i> تعديل/إضافة
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form v-model="valid">
                <v-row align="center" justify="center" class="my-0 ammar" dense>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      color="primary"
                      v-model="selected.name"
                      :counter="100"
                      :rules="[allRules.NameRequired, allRules.minNameLen(3)]"
                      label="الاسم "
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      type="userName"
                      color="primary"
                      v-model="selected.userName"
                      :rules="[allRules.required]"
                      aria-autocomplete="none"
                      label=" إسم المستخدم"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-autocomplete
                      :rules="[allRules.required]"
                      label="الفرع"
                      dense
                      outlined
                      required
                      :items="branches"
                      item-value="id"
                      item-text="name"
                      auto-select-first
                      v-model="selected.cityId"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="رقم الجوال"
                      ref="num14"
                      :maxlength="10"
                      :counter="10"
                      :rules="[allRules.length(10)]"
                      v-model="selected.phoneNumber"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-text-field
                      filled
                      color="primary"
                      label="الرقم السري"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1"
                      :rules="[allRules.length(6)]"
                      v-model="selected.password"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      filled
                      color="primary"
                      label="تأكيد الرقم السري"
                      :rules="[
                        allRules.length(6),
                        allRules.confirmPassword(
                          selected.confirmPassword,
                          selected.password
                        ),
                      ]"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      @click:append="show2 = !show2"
                      v-model="selected.confirmPassword"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" :disabled="!valid" @click="editConfirmed()">
              حفظ
            </v-btn>

            <v-btn color="red darken-1" text @click="cancelEdit()">
              إلغاء
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Add Modal -->
    <!-- edit Modal Start -->
    <v-dialog v-model="modals.editUser" width="800" persistent>
      <EditUser
        v-model="selected"
        @cancel="modals.editUser = false"
        @updated="userUpdated"
      />
    </v-dialog>
    <!-- edit Modal End -->
  </div>
</template>

<script>
import ApiService from "../services/EmployeeService.js";
import BranchesService from "../../Settings/services/CityService.js";
import PermissionsService from "../services/PermissionsService.js";
export default {
  data() {
    return {
      valid: false,
      selected: {},
      entities: [],
      branches: [],
      roles: [],
      modals: {
        editUser: false,
        addUser: false,
      },
      total: 0,
      show1: false,
      show2: false,
      loading: false,
      search: "",
      dates: {},
      options: {},
      dialog: false,
      loadingStates: {
        table: false,
      },
      headers: [
        {
          text: "الاسم",
          value: "name",
        },
        {
          text: "الفرع ",
          value: "city.name",
        },
        {
          text: "رقم التليفون",
          value: "phoneNumber",
        },
        {
          text: "الأدوار",
          value: "roles",
        },
        {
          text: "مسح / تعديل",
          value: "actions",
          align: "left",
        },
      ],
    };
  },
  mounted() {
    this.getData();
    ApiService.roles().then((resp) => {
      this.roles = resp.data;
    });
    BranchesService.typeHead("", true).then((resp) => {
      this.branches = resp.data;
    });
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      return new Date(date).toISOString().substr(0, 10);
    },

    userUpdated() {
      this.getData();
    },
    editUser() {
      this.loading = true;
      ApiService.edit(this.selected)
        .then((e) => {
          this.modals.editUser = false;
          this.getData();
        })
        .catch((resp) => {
          var message = JSON.stringify(resp.response.data);
          this.loading = false;
          this.$dialog.notify.error(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .finally(() => {});
    },

    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    getData() {
      this.loadingStates.table = true;
      if (this.options) this.options.searchTerm = this.search;
      ApiService.query(this.options)
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
        });
    },
    editConfirmed() {
      ApiService.add(this.selected)
        .then((resp) => {
          this.selected = {};
          this.$refs.form.resetValidation();
          this.$dialog.notify.success("تم إضافة  العنصر بنجاح", {
            position: "top-right",
            timeout: 3000,
          });
          this.modals.addUser = false;
          this.getData();
        })
        .catch((resp) => {
          var message = "";
          message = JSON.stringify(resp.response.data);
          this.loading = false;
          this.$dialog.notify.error(message, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    cancelEdit() {
      this.modals.addUser = false;
      this.selected = {};

      this.$refs.form.resetValidation();
    },
    add() {
      this.selected = {};
      this.modals.addUser = true;
    },
    edit(id) {
      this.selected = {
        ...this.entities.filter((e) => e.id == id)[0],
      };
      this.selected.roles = this.roles.map((r) => {
        return {
          name: r.name,
          arabicName: r.arabicName,
          checked: this.selected.roles.map((e) => e.name).includes(r.name),
        };
      });
      PermissionsService.userTree(this.selected.id).then((resp) => {
        const current = new Date();
        this.selected.permissionstree = resp.data.map((i) => {
          return Object.assign(i, {
            id:
              Math.floor(Math.random() * (97797 - 1 + 1)) +
              1 +
              current.getUTCMilliseconds(),
          });
        });
        this.selected.permissionstree.forEach((element) => {
          element.permissions
            .filter((e) => e.isChecked)
            .forEach((element) => {
              this.selected.permissions.push(element.id);
            });
        });
      });
      this.modals.editUser = true;
    },
    del(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف العنصر",
          title: "  حذف مستخدم  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                ApiService.delete(id)
                  .then((resp) => {
                    const index = this.entities.indexOf(
                      this.entities.filter((e) => e.id == id)[0]
                    );
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("تم مسح العنص بنجاح", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("خطأ اثناء مسح العنصر", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => {});
    },
    view(id) {},
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
</style>
