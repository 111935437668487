export default [
    {
        path: '/system/users',
        name: 'Employees',
        meta: { roles: ['SystemAdmin', 'Manager'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/Employees.vue')
    },
    {
        path: '/system/settings',
        name: 'Settings',
        meta: { roles: ['SystemAdmin'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/Settings.vue')
    },
    {
        path: '/system/AuditLog',
        name: 'AuditLog',
        meta: { roles: ['SystemAdmin', 'Manager'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/AuditLog.vue')
    }
]