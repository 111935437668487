export default [
    {
        path: '/reports/trip',
        name: 'TripReport',
        meta: { roles: ['SystemAdmin', 'Manager', 'User', 'BranchManager', 'Accountant'], requiresAuth: true, layout: 'print' },
        component: () => import('./views/TripReport.vue')
    },
    {
        path: '/reports/shipmentsActivity',
        name: 'ShipmentsReport',
        meta: { requiresAuth: true, layout: 'print' },
        component: () => import('./views/ShipmentsReport.vue')
    },
    {
        path: '/reports/shipments',
        name: 'ShipmentBarCode',
        meta: { roles: ['SystemAdmin', 'Manager', 'User', 'BranchManager'], requiresAuth: true, layout: 'print' },
        component: () => import('./views/ShipmentBarCode.vue')
    },
    {
        path: '/reports/shipmentsCityReports',
        name: 'ShipmentsCityReports',
        meta: { requiresAuth: true, layout: 'print' },
        component: () => import('./views/ShipmentsCityReports.vue')
    },
    {
        path: '/reports/ShipmentsDiscountsReports',
        name: 'ShipmentsDiscountsReports',
        meta: { requiresAuth: true, layout: 'print' },
        component: () => import('./views/ShipmentsDiscountsReports.vue')
    },
    {
        path: '/reports/singleshipment',
        name: 'ShipmentBarCodeSingle',
        meta: { requiresAuth: true, layout: 'print' },
        component: () => import('./views/ShipmentBarCodeSingle.vue')
    },
    {
        path: '/reports/shipmentReceipt',
        name: 'ShipmentReceipt',
        meta: { requiresAuth: true, layout: 'print' },
        component: () => import('./views/ShipmentReceipt.vue')
    },
    {
        path: '/reports/dailyReport',
        name: 'DailyReportPrint',
        meta: {  layout: 'print' },
        component: () => import('./views/DailyReportPrint.vue')
    },
    {
        path: '/reports/ShipmentReceiptnoprice',
        name: 'ShipmentReceiptnoprice',
        meta: { requiresAuth: true, layout: 'print' },
        component: () => import('./views/ShipmentReceiptnoprice.vue')
    },
    {
        path: '/reports/tripInvoices',
        name: 'TripInvoices',
        meta: { requiresAuth: true, layout: 'print' },
        component: () => import('./views/TripInvoices.vue')
    },
    {
        path: '/reports/tripReturnInvoices',
        name: 'TripReturnInvoices',
        meta: { requiresAuth: true, layout: 'print' },
        component: () => import('./views/TripReturnInvoices.vue')
    },
    {
        path: '/reports/receiptprint',
        name: 'ReceiptPrint',
        meta: { roles: ['SystemAdmin', 'Manager', 'BranchManager', 'Accountant'], requiresAuth: true, layout: 'print' },
        component: () => import('./views/ReceiptPrint.vue')
    },
    {
        path: '/reports/SalesMen',
        name: 'SalesMenReport',
        props: true,
        meta: { roles: ['SystemAdmin', 'Manager'], requiresAuth: true, layout: 'print' },
        component: () => import('./views/SalesMenPrint.vue')
    },
    {
        path: '/reports/driver',
        name: 'ShipmentDriverReport',
        meta: { roles: ['SystemAdmin', 'Manager', 'User', 'BranchManager'], requiresAuth: true, layout: 'print' },
        component: () => import('./views/ShipmentDriverReport.vue')
    },
    {
        path: '/reports/activity',
        name: 'TripActivityReport',
        meta: { roles: ['SystemAdmin', 'Manager', 'Accountant'], requiresAuth: true, layout: 'print' },
        component: () => import('./components/TripActivityReport.vue')
    },
    {
        path: '/reports/activities',
        name: 'activities',
        meta: { roles: ['SystemAdmin', 'Manager', 'Accountant'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('../Reports/views/TripActivityList.vue')
    },
    {
        path: '/reports/empShipmentsActivity',
        name: 'empShipmentsActivity',
        meta: { roles: ['SystemAdmin', 'Manager', 'Accountant'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('../Reports/views/EmpShipmentsActivity.vue')
    },
    {
        path: '/reports/ReportAdmin',
        name: 'ReportAdmin',
        meta: { guest: true, layout: 'print' },
        component: () => import('../Reports/views/TripActivityListAdmin.vue')
    },
]