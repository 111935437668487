<template>
  <div>
    <v-row class="pr-5 pb-3 mt-5" dense>
      <v-col class="d-flex justify-content-start">
        <v-btn color="success" @click="addnew()">
          اضافة يومية
          <i class="fa fa-plus mr-2"></i>
        </v-btn>
        <!--  -->
        <hr />
        <!--  -->
      </v-col>
    </v-row>
    <v-card min-width="98%" class="ma-4">
      <v-data-table group-by="branch.name" :headers="headers" :items="entities" :options.sync="options" fixed-header
        :server-items-length="total" :search="search" :items-per-page="15" :loading="loadingStates.table"
        class="elevation-1" :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-plus',
          nextIcon: 'mdi-minus',
          'items-per-page-text': 'عدد اليوميات ' + total + '',
        }">
        <template v-slot:[`group.header`]="{ items, isOpen, toggle }">

          <th :colspan="8" style="height: auto; border: red solid 2px !important">
            <v-icon @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
            </v-icon>
            {{ items[0].branch.name }}
          </th>
        </template>
        <template v-slot:top>
          <v-row class="mx-auto mt-3 pa-5" dark dense>
            <v-col cols="12" sm="2">
              <v-text-field dense outlined label="رقم القيد" v-model="options.id">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete dense outlined hide-details label="الفروع" :items="branches" item-value="id"
                item-text="name" v-model="options.branchId"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <DatePicker-layout @myEvent="options.date = $event" :date.sync="options.date"
                label="التاريخ "></DatePicker-layout>
            </v-col>
            <v-col cols="6" sm="6">
              <v-btn color="error" @click="resetOptions">تفريغ
                <i class="fa fa-trash fa-lg"></i>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.createDate`]="{ item }">
          <div v-if="item.createDate">
            <span>
              <v-chip style="font-size: smaller" color="green lighten-3" label>{{ item.createDate
                |
                ToDate
              }}
              </v-chip>
            </span>
          </div>
        </template>
        <template v-slot:[`item.view`]="{ item }">
          <v-btn color="orange" outlined elevation="2" class="mx-2" @click="view(item.id)" icon>
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <!-- view Modal -->
    <v-dialog v-model="dialog" width="98%" persistent>
      <div class="content">
        <v-card>
          <v-card-text>
            <v-container v-if="selected != null">
              <v-card class="pa-2" outlined tile>
                <v-btn color="primary" v-if="print == true" @click="saveReport()">
                  طباعة اليومية
                </v-btn>
                <v-form v-model="valid" ref="addTripForm" id="reportId">
                  
                  <v-card-text>
                    <div class="grid-container">
                      <div class="grid-item">
                        <table style="width: 100%">
                          <thead>
                            <tr>
                              <th>المبلغ</th>
                              <th>مدين</th>
                            </tr>
                            <tr>
                              <th>{{ selected.report.initialBalance }}</th>
                              <th>الرصيد الأفتتاحى</th>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                            <tr v-for="(item, g) in selected.report.reportTrips"
                              v-if="selected.report.reportTrips.length > 0" :key="g">
                              <th>{{ item.amount }}</th>
                              <td>رحلة رقم {{ item.trackingNumber }} - {{ item.tripName }}</td>
                            </tr>
                            <tr>
                              <th>{{ selected.report.cashShipmentsBalance }}</th>
                              <td>خالص الاجرة</td>
                            </tr>
                            <tr>
                              <th>{{ selected.report.otherIncomeBalance }}</th>
                              <td>ايرادات أخري ( كاش )</td>
                            </tr>
                            <tr>
                              <th>{{ selected.report.otherIncomeBalancePos }}</th>
                              <td>ايرادات أخري ( شبكة )</td>
                            </tr>
                            <tr>
                              <th>{{ selected.report.otherIncomeBalanceBank }}</th>
                              <td>ايرادات أخري ( تحويل بنكي )</td>
                            </tr>
                          </tbody>
                          <tfoot style="height: 67.5px">
                            <tr>
                              <th>{{ selected.report.totalDebit }}</th>
                              <th>الاجمالى</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <div class="grid-item">
                        <table style="width: 100%">
                          <thead>
                            <tr>
                              <th>المبلغ</th>
                              <th>دائن</th>
                            </tr>
                            <tr>
                              <th>النقدي اليوم</th>
                              <th>{{ selected.report.todayFundBalance }} ريال</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>{{ selected.report.fundBalance }}</th>
                              <td>الصندوق</td>
                            </tr>
                            <tr>
                              <th>{{ selected.report.warehouseBalance }}</th>
                              <td>المستودع</td>
                            </tr>
                            <tr>
                              <th>{{ selected.report.collectionBalance }}</th>
                              <td>التحصيل</td>
                            </tr>
                            <tr>
                              <th>{{ selected.report.discountsBalance }}</th>
                              <td>الخصومات</td>
                            </tr>
                            <tr>
                              <th>{{ selected.report.expensesBalance }}</th>
                              <td>المصروفات</td>
                            </tr>
                            <tr>
                              <th>{{ selected.report.tripsRentalBalance }}</th>
                              <td>ايجار الرحلات</td>
                            </tr>
                            <tr>
                              <th>{{ selected.report.posBalance }}</th>
                              <td>ايداع شبكة</td>
                            </tr>
                            <tr>
                              <th>{{ selected.report.bankTransferBalance }}</th>
                              <td>تحويل بنكي</td>
                            </tr>
                            <tr>
                              <th>{{ selected.report.bankDepositBalance }}</th>
                              <td>ايداع بنكي ( {{ selected.report.bankDepositName }} )</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <th>{{ selected.report.totalCredit }}</th>
                              <th>الاجمالى</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div class="grid-container">
                      <div class="grid-item">
                        <table style="width: 100%;">
                          <thead>
                            <tr>
                              <th colspan="2">خالص الأجرة</th>
                            </tr>
                            <tr>
                              <th colspan="1">المبلغ</th>
                              <th colspan="1">رقم السند</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, g) in selected.receiveReceipts" :key="g + 1">
                              <th>{{ item.amount }}</th>
                              <td class="content_td">
                                <p>{{ item.trackingNumber }}</p>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td id="total">{{ selected.receiveReceiptsTotal }}</td>
                              <td id="total">الاجمالى</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <div class="grid-item">
                        <table style="width: 100%;">
                          <thead>
                            <tr>
                              <th colspan="2">الخصومات</th>
                            </tr>
                            <tr>
                              <th colspan="1">المبلغ</th>
                              <th colspan="1">البيان</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, g) in selected.shipmentsDiscounts" :key="g + 1">
                              <th>{{ item.discount }}</th>
                              <td class="content_td">
                                <p>{{ item.trackingNumber }} - {{ item.description }}</p>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td id="total">{{ selected.discountsTotal }}</td>
                              <td id="total">الاجمالى</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <div class="grid-item">
                        <table style="width: 100%;">
                          <thead>
                            <tr>
                              <th colspan="2">المصروفات</th>
                            </tr>
                            <tr>
                              <th colspan="1">المبلغ</th>
                              <th colspan="1">البيان</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, g) in selected.expenseReceipts" :key="g + 1">
                              <th>{{ item.amount }}</th>
                              <td class="content_td">
                                <p>{{ item.description }}</p>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td id="total">{{ selected.expenseReceiptsTotal }}</td>
                              <td id="total">الاجمالى</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="red darken-1" text @click="cancelEdit()">
              إلغاء
            </v-btn>
          </v-card-actions></v-card>
      </div>
    </v-dialog>
    <!-- view Modal -->
    <!-- add Modal -->
    <v-dialog v-model="addDialog" width="600" persistent>
      <v-card>
        <v-form v-model="valid">
          <v-card-title class="headline">
            <i class="fa fa-pen ml-2"></i>إضافة
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="my-0 ammar" dense>
                <v-col cols="12" sm="6">
                  <v-autocomplete dense outlined :rules="[allRules.required]" v-model="selected.branchId"
                    :items="branches" item-value="id" item-text="name" auto-select-first label="الفرع">
                  </v-autocomplete>
                </v-col>
             <!--  <v-col cols="12" sm="6"> 
                  <DatePicker-layout @myEvent="selected.date = $event"
                    :date="selected.date | ToDate" :rules="[allRules.required]" label="التاريخ"></DatePicker-layout>
                </v-col>  -->
                <v-col cols="12" sm="6">
                  <v-text-field dense outlined type="text" label="ايرادات أخري ( كاش )"
                    v-model="selected.otherIncomeBalance">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field dense outlined type="text" label="ايرادات أخري ( شبكة )"
                    v-model="selected.otherIncomeBalancePos">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field dense outlined type="text" label="ايرادات أخري ( تحويل بنكي )"
                    v-model="selected.otherIncomeBalanceBank">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field dense outlined type="text" label="ايداع بنكي" v-model="selected.bankDepositBalance">
                  </v-text-field>
                </v-col>
                <!-- <v-col cols="12" sm="6">
                  <v-text-field dense outlined type="text" label="رصيد المستودع" v-model="selected.warehouseBalance">
                  </v-text-field>
                </v-col>
               -->
                <v-col cols="12" sm="6">
                  <v-autocomplete v-if="selected.bankDepositBalance != null" :rules="[allRules.required]" dense outlined
                    hide-details label="اسم البنك" :items="banksname" item-value="text" item-text="text"
                    v-model="selected.bankDepositName"></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" v-if="isView == false" :disabled="!valid" @click="add()">
              حفظ
            </v-btn>
             <v-btn color="primary" v-if="isView == true" :disabled="!valid" @click="viewReport()">
              عرض
            </v-btn>
            <v-btn color="red darken-1" text @click="addDialog = false">
              إلغاء
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- add Modal -->
  </div>
</template>

<script>
import Enums from "../../../plugins/enums";
import ApiService from "../services/BranchDailyReportService.js";
import BranchesService from "../../../features/Settings/services/BranchesService.js";
export default {
  data() {
    return {
      enums: Enums,
      valid: false,
      print: false,
      isView: false,
      branches: [],
      banksname: [],
      entities: [],
      selected: {
        date: this.formatDate(Date.now()),
        expenseReceipts: [],
        receiveReceipts: [],
        shipmentsDiscounts: [],
        report: {
          reportTrips: [],
          branch: {}
        },
      },
      total: 0,
      cuttingoff: 0,
      search: "",
      dialogTitle: "",
      options: {
        date: null,
        id: null,
        branchId: null,
      },
      dialog: false,
      addDialog: false,
      loadingStates: {
        table: false,
      },
      headers: [
        {
          text: "رقم القيد",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "التاريخ", align: "center", value: "createDate" },
        {
          text: "",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          sortable: false,
        },
        { text: "عرض اليومية", align: "center", value: "view" },
      ],
    };
  },
  created() {
    BranchesService.typeHead("", false, true).then((resp) => {
      this.branches = resp.data;
    });
    this.getData();
    this.banksname = [
      { text: "الراجحي" },
      { text: "الأهلي" },
    ];
  },

  methods: {
    addnew()
    {
      this.addDialog = true;
      this.isView = true;
    },
    saveReport() {
      let routeData = this.$router.resolve({
        name: "DailyReportPrint",
        query: { reportid: this.selected.report.id },
      });
      window.open(routeData.href, "_blank");
    },
    update(e) {
      this.cuttingoff = e.target.innerHTML;
    },
    resetOptions() {
      this.options = {
        date: null,
        id: null,
        branchId: null,
      };
    },
    view(id) {
      if (id != "") {
        ApiService.getbyReportId(id)
          .then((resp) => {
            this.selected = resp.data;
            this.dialog = true;
            this.print = true;
          })
          .catch((resp) => { })
          .finally(() => { });
      }
    },

    getData() {
      this.loadingStates.table = true;
      ApiService.query(this.$clean(this.options))
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
        })
        .finally(() => {
          this.loadingStates.table = false;
        });
    },
    editConfirmed() {
      console.log(this.cuttingoff);
    },

    cancelEdit() {
      this.dialog = false;
      this.selected = {
        expenseReceipts: [],
        receiveReceipts: [],
        shipmentsDiscounts: [],
        report: {
          reportTrips: [],
          branch: {}
        },
      };
    },
    add() {
      ApiService.add(this.selected)
        .then((resp) => {
          this.dialog = false;
          var message = "تم اضافه اليومية بنجاح";
          if (!this.selected.id) {
            this.addToArr(this.entities, resp.data);
            this.addDialog = false;
          }
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.dialog = false;
          console.log(err.request.response);
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    viewReport() {
      this.selected.isView = true;
      ApiService.add(this.selected)
        .then((resp) => {
          this.dialog = true;
          this.selected = resp.data;
          this.isView = false;
        })
        .catch((err) => {
          this.isView = false;
        });
      },
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.content_td p {
  max-width: 100%;
  max-height: 100px;

  text-overflow: ellipsis;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
  margin-right: 11.5%;
}

.grid-item {
  font-size: 30px;
  text-align: right;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

th,
td {
  padding: 5px;
  text-align: right;
}

th {
  text-align: center;
}

#total {
  text-align: center;
}

.hiddenradio [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}

.hiddenradio [type="radio"]+div {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

/* CHECKED STYLES */
.hiddenradio [type="radio"]:checked+div {
  outline: 2px solid #42ac0d;
  outline-style: dashed;
}

.text-end {
  display: inline-table !important;
}
</style>