<template>
  <v-app>
    <v-container>
      <div class="content" v-if="printData">
        <div>
          <v-row class="" justify="center">
            <v-col cols="4" evaluate="2" class="data pa-2 text-h4 font-weight-bold text-center">
              {{ this.$store.getters.companyName }}
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row justify="space-between" class="ammar" dense>
            <v-col cols="5" class="mt-9 text-right">
              <h5 class="mb-1">بيان تفصيلي بالرحلات</h5>
              <v-row>
                <v-col cols="3"> رقم الرحلة </v-col>
                <v-col cols="5" class="">{{ trip.trackingNumber }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="3"> وصف الرحلة </v-col>
                <v-col cols="5" class=""> {{ trip.line.name }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="3"> اسم السائق </v-col>
                <v-col cols="5" class="">{{ trip.driver.name }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="3"> السيارة </v-col>
                <v-col cols="5" class="">
                  {{ trip.car.plate }} {{ trip.car.mark }}</v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="mt-5 text-right">
              <v-row>
                <v-col cols="4"> تاريخ الطباعة </v-col>
                <v-col cols="5" class="">{{ print.date }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4"> التوقيت </v-col>
                <v-col cols="5" class="">{{ print.time }}</v-col>
              </v-row>
              <v-row class="mt-10">
                <v-col cols="4"> تاريخ الرحلة </v-col>
                <v-col cols="5" class="">{{ trip.date | ToDate }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="4"> ايجار السيارة </v-col>
                <v-col cols="4" class="">{{ trip.rentAmount }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <v-row class="mb-5">
          <v-col cols="12 m-0 p-0 mt-5">
            <v-data-table :headers="headers" :items="shipments" :items-per-page="-1" class="elevation-1"
              group-by="paymentType" hide-default-footer>
              <template v-slot:[`item.paymentType1`]="{ item }">
                {{ item.paymentType | PaymentTypes }}
              </template>
              <template v-slot:[`group.header`]="{ items }">
                <th colspan="2" style="height: auto; border: red solid 2px !important">
                  {{ items[0].paymentType | PaymentTypes }}
                </th>
              </template>
              <template v-slot:[`item.number`]="{ item }">
                {{ shipments.indexOf(item) + 1 }}
              </template>
              <template v-slot:[`item.items`]="{ item }">
                <span v-if="item.items">
                  <span> {{ item.items[0].merchandise.name }} </span>
                </span>
              </template>
              <template v-slot:[`item.itemprice`]="{ item }">
                <div v-if="item.items[0]">
                  <div v-if="item.items[0].price">
                    {{ round(item.items[0].price, 2) }}
                  </div>
                </div>
              </template>
              <template v-slot:[`item.senderInfo`]="{ item }">
                <span v-if="item.senderInfo">
                  <span v-if="item.senderInfo">
                    {{ item.senderInfo.name }}</span>
                </span>
              </template>
              <template v-slot:[`item.receiverInfo`]="{ item }">
                <span v-if="item.receiverInfo">
                  <span v-if="item.receiverInfo.name">
                    {{ item.receiverInfo.name }}</span>
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>

      <div class="summary-wrapper d-flex flex-column" style="padding-top: 200px;">
        <v-row class="justify-content-around mb-5">
          <v-col cols="8">
            <table class="summary-table">
              <thead>
                <tr>
                  <th>طريقة الدفع</th>
                  <th>عدد البوالص</th>
                  <th>الاجمالي</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>نقدي</td>
                  <td>{{ reviewShipments(1).leng }}</td>
                  <td>{{ this.round(reviewShipments(1).total, 2) }}</td>
                </tr>
                <tr>
                  <td>دفع عند الاستلام</td>
                  <td>{{ reviewShipments(2).leng }}</td>
                  <td>{{ this.round(reviewShipments(2).total, 2) }}</td>
                </tr>
                <tr>
                  <td>اجمالى البوالص</td>
                  <td>اجمالى الطرود</td>
                  <td>اجمالى الرحلة</td>
                </tr>
                <tr>
                  <td>
                    {{ reviewShipments(1).leng + reviewShipments(2).leng }}
                  </td>
                  <td>{{ shipmentsTotal }}</td>
                  <td>{{ this.round(shipmentsPriceTotal, 2) }}</td>
                </tr>
              </tbody>
            </table>
          </v-col>
          <v-row class="mt-5">
            <v-col cols="4" class="text-center">
              <h4>المراجع</h4>
            </v-col>
            <v-col cols="8" class="text-center">
              <h4 class="text-center">مدير التشغيل</h4>
            </v-col>
          </v-row>
        </v-row>
      </div>

      <div v-if="!printData">Loading</div>
    </v-container>
  </v-app>
</template>

<script>
import TripsService from "./../../LandShipping/services/TripsService";
export default {
  mounted() {
    var id = +this.$route.query["tripId"];
    if (id) this.getDataFromAPI(id);
    else this.getDataFromStore();
  },

  data() {
    return {
      trip: {},
      shipments: [],
      printData: {},
      print: {
        time: new Date().toLocaleTimeString(),
        date: this.formateDateWithMoment(Date.now(), "YYYY-MM-DD"),
      },
      headers: [
        {
          text: "م",
          align: "start",
          sortable: true,
          value: "number",
        },
        {
          text: "رقم البوليصة",
          value: "trackingNumber",
          align: "right",
        },
        {
          text: "الرقم اليدوي",
          value: "manualNo",
          align: "right",
        },
        {
          text: "المرسل",
          value: "senderInfo",
          align: "right",
          width: "12%",
        },
        {
          text: "المرسل اليه",
          value: "receiverInfo",
          align: "right",
          width: "12%",
        },
        {
          text: "الصنف",
          value: "items",
          align: "right",
          width: "8%",
        },
        {
          text: "طريقة السداد",
          value: "paymentType1",
          align: "right",
          width: "12%",
        },
        {
          text: "عدد الطرود",
          value: "items[0].quantity",
          align: "right",
        },
        {
          text: "سعر الصنف ",
          value: "itemprice",
          align: "right",
        },
        {
          text: "ضريبه الصنف ",
          value: "items[0].addedValueTax",
          align: "right",
        },
        {
          text: "قيمة الامر",
          value: "totalPrice",
          align: "right",
        },
        {
          text: "الملاحظات",
          value: "notice",
          align: "right",
          width: "20%",
        },
      ],
      data: [],
    };
  },
  computed: {
    shipmentsTotal() {
      return this.shipments
        .map((e) => e.items[0].quantity)
        .reduce((a, b) => {
          return a + b;
        });
    },
    shipmentsPriceTotal() {
      return this.shipments
        .map((e) => e.totalPrice)
        .reduce((a, b) => {
          return a + b;
        });
    },
  },
  methods: {
    getDataFromAPI(id) {
      TripsService.print(id).then((resp) => {
        this.printData = {};
        this.trip = resp.data.trip;
        this.shipments = resp.data.shipments;
        setTimeout(() => {
          window.focus();
          var css = "@page { size: landscape; }",
            head = document.head || document.getElementsByTagName("head")[0],
            style = document.createElement("style");

          style.type = "text/css";
          style.media = "print";

          if (style.styleSheet) {
            style.styleSheet.cssText = css;
          } else {
            style.appendChild(document.createTextNode(css));
          }

          head.appendChild(style);
          window.print();
        }, 100);
      });
    },
    getDataFromStore() {
      this.printData = this.$store.getters.printData;
      this.trip = this.printData.trip;
      this.shipments = this.printData.shipments;
    },
    reviewShipments(value) {
      let reviewShipments = this.shipments.filter((i) => {
        return i.paymentType == value;
      });
      let leng = reviewShipments.length;
      let total = reviewShipments.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.totalPrice;
      }, 0);

      return {
        total,
        leng,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari */
  color-adjust: exact !important;
  /*Firefox*/
}

.content {
  font-weight: 600 !important;
}

/*.printable { display:none; }
@media only print {
    .container { display:none !important; } 
    .printable { display:block !important; } 
}*/
@media print {
  .summary-wrapper {
    height: 90vh;
    align-content: center;
  }
}

::v-deep .v-data-table-header {
  background-color: #8080802b;
}

.summary-table {
  width: 100%;
  text-align: center;
  border: black solid 1px;
  font-weight: bolder;
}

::v-deep .v-data-table tr {
  font-size: x-small !important;
  height: 14px !important;
  border: black solid 2px !important;
}

::v-deep .v-data-table td {
  font-size: x-small !important;
  height: 20px !important;
  border: black solid 2px !important;
}

::v-deep .v-data-table th {
  font-size: x-small !important;
  border: black solid 2px !important;
}

.summary-table td,
.summary-table th {
  border: black solid 2px;
  padding: 10px 20px 10px 20px;
}

.ammar div {
  margin: 0 !important;
  padding: 1px !important;
  font-weight: bold;
}

.ammar div .edit {
  margin-top: 20px !important;
}

.data {
  border: 2px solid grey;
}

.both {
  position: absolute;
}

.dashed-border {
  border: 2px dotted grey;
  padding: 10px;
}

.first {
  bottom: -32px;
  left: 33%;
}

.second {
  bottom: 33;
  left: 8%;
}

.review div {
  border: 2px solid black;
  display: inline-block;
  font-weight: bold;
}
</style>
