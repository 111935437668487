<template>
  <div v-if="shipments" id="modalInvoice">
    <v-container v-for="(shipment, i) in shipments" :key="i">
      <div>
        <div class="receipt">
          <div class="headerTitle">الأحمري</div>
          <div id="location">{{ shipment.trackingNumber }}</div>
          <div class="flex">
            <div id="barcodetrack">
              <img style="width: 163px;height: 30px;"
                :src='"https://bwipjs-api.metafloor.com/?bcid=code128&text=" + shipment.trackingNumber' />
            </div>
          </div>
          <div style="display: flex;">
            <div style="flex: 2 1 50%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">{{ shipment.date | ToDate }} :</h2>
            </div>
            <div style="flex: 20%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">تاريخ الشحن</h2>
            </div>
          </div>
          <div style="display: flex;">
            <div style="flex: 2 1 50%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">{{ shipment.trip.arrive | ToDate }} :</h2>
            </div>
            <div style="flex: 20%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">تاريخ الوصول</h2>
            </div>
          </div>
          <div style="display: flex;">
            <div style="flex: 2 1 50%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">{{ shipment.manualNo }} :</h2>
            </div>
            <div style="flex: 20%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">الرقم اليدوي</h2>
            </div>
          </div>
          <div style="display: flex;">
            <div style="flex: 2 1 50%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">{{ shipment.trip.trackingNumber }} :</h2>
            </div>
            <div style="flex: 20%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">رقم الرحلة</h2>
            </div>
          </div>
          <div style="display: flex;">
            <div style="flex: 2 1 50%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">{{ shipment.trip.line.name }} :</h2>
            </div>
            <div style="flex: 20%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">المسار</h2>
            </div>
          </div>
          <div style="display: flex;" v-if="shipment.senderBranch == null">
            <div style="flex: 2 1 50%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">{{
                shipment.senderInfo ? shipment.senderInfo.name
                : "unknown"
              }} :</h2>
            </div>
            <div style="flex: 20%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">المرسل</h2>
            </div>
          </div>
          <div style="display: flex;" v-else>
            <div style="flex: 2 1 50%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">{{
                shipment.senderBranch ?
                shipment.senderBranch.name : "unknown"
              }} :</h2>
            </div>
            <div style="flex: 20%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">المرسل</h2>
            </div>
          </div>
          <div style="display: flex;">
            <div style="flex: 2 1 50%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">{{ shipment.senderInfo.phoneNumber }} :</h2>
            </div>
            <div style="flex: 20%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">جوال المرسل</h2>
            </div>
          </div>
          <div style="display: flex;" v-if="shipment.receiverBranch == null">
            <div style="flex: 2 1 50%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">{{
                shipment.receiverInfo ?
                shipment.receiverInfo.name : "unknown"
              }} :</h2>
            </div>
            <div style="flex: 20%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">المستلم</h2>
            </div>
          </div>
          <div style="display: flex;" v-else>
            <div style="flex: 2 1 50%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">{{
                shipment.receiverBranch ?
                shipment.receiverBranch.name : "unknown"
              }} :</h2>
            </div>
            <div style="flex: 20%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">المستلم</h2>
            </div>
          </div>
          <div style="display: flex;">
            <div style="flex: 2 1 50%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">{{ shipment.receiverInfo.phoneNumber }} :</h2>
            </div>
            <div style="flex: 20%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">جوال المستلم</h2>
            </div>
          </div>
          <div style="display: flex;">
            <div class="c" style="flex: 2 1 50%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">{{ shipment.notice.substr(0, 40) }} :</h2>
            </div>
            <div style="flex: 20%;">
              <h2 id="date" style="text-align: right; font-weight: bold;">الملاحظات</h2>
            </div>
          </div>
          <div id="bot">
            <div id="table">
              <table>
                <tr class="tabletitle">
                  <td class="Hours" style="font-size: x-large">
                    <h2>العدد</h2>
                  </td>
                  <td class="Hours" style="font-size: x-large">
                    <h2>الصنف</h2>
                  </td>
                </tr>
                <tr class="service" v-for="(item, index) in shipment.items" :key="index">
                  <td class="tableitem">
                    <p class="itemtext" style="text-align: center; font-weight: bold; font-size: x-large">
                      <span v-if="shipment.items && shipment.items.length >= 1">
                        {{ item.quantity }}
                      </span>
                    </p>
                  </td>
                  <td class="tableitem">
                    <p class="itemtext" style="padding-right: 2px; font-weight: bold; font-size: x-large">
                      <span v-if="shipment.items && shipment.items.length >= 1">{{
                        item.merchandise.name
                      }}</span>
                    </p>
                  </td>
                </tr>
              </table>
            </div>
            <!--End Table-->
          </div>
        </div>
      </div>
      <div class="pagebreak"></div>
    </v-container>
  </div>
</template>

<script>
import TripsService from "./../../LandShipping/services/TripsService";
export default {
  mounted() {
    var id = +this.$route.query["tripId"];
    if (id) this.getDataFromAPI(id);
    else this.getDataFromStore();
  },
  data() {
    return {
      trip: {},
      shipments: [],
      printData: null,
      barcodetracking: null,
    };
  },
  methods: {
    getDataFromAPI(id) {
      TripsService.printReturn(id).then((resp) => {
        this.printData = {};
        this.trip = resp.data.trip;
        this.shipments = resp.data.shipments;

        setTimeout(() => {
          const options = {
            styles: [
              "https://2024.al-ahmari.com.sa/Data/Images/ExternalShippers/styleReturn.css",
            ],
            timeout: 1000,
            autoClose: true,
            windowTitle: window.document.title,
          };
          this.$htmlToPaper("modalInvoice", options);
        }, 100);
      })
        .catch((resp) => { })
        .finally(() => { });
    },
    getDataFromStore() {
      this.printData = this.$store.getters.printData;
      this.trip = this.printData.trip;
      this.shipments = this.printData.shipments;
    },
  },
};
</script>

<style lang="css" scoped>
body {
  font-family: 'Roboto', sans-serif;
  margin: 0px;
  padding: 0px;
}

::selection {
  background: #f31544;
  color: #FFF;
}

::moz-selection {
  background: #f31544;
  color: #FFF;
}

h1 {
  font-size: 1.5em;
  color: #222;
}

h2 {
  font-size: .9em;
  text-align: center;
}

h3 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}

p {
  font-size: .7em;
  color: #666;
  line-height: 1.2em;
}

#top,
#mid,
#bot {
  /* Targets all id with 'col-' */
  border-bottom: 1px solid #EEE;
}

#top {
  min-height: 100px;
}

#mid {
  min-height: 80px;
}

#bot {
  min-height: 50px;
}

#top .logo {
  height: 60px;
  width: 60px;
  background: url(http://michaeltruong.ca/images/logo1.png) no-repeat;
  background-size: 60px 60px;
}

.clientlogo {
  float: left;
  height: 60px;
  width: 60px;
  background: url(http://michaeltruong.ca/images/client.jpg) no-repeat;
  background-size: 60px 60px;
  border-radius: 50px;
}

.info {
  display: block;
  margin-left: 0;
}

.title {
  float: right;
}

.title p {
  text-align: right;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: black solid 1px;
}

.tabletitle {
  font-size: .5em;
  background: #EEE;
}

.service {
  border-bottom: 1px solid #EEE;
}

.item {
  width: 24mm;
}

.itemtext {
  font-size: 15px;
  text-align: end;
}

#legalcopy {
  margin-top: 5mm;
}

.receipt {
  width: 48mm;
  padding-left: 18px;
  padding-right: 25px;
}

.orderNo {
  width: 100%;
  text-align: right;
  padding-bottom: 1mm;
  font-size: 8pt;
  font-weight: bold;
}

td,
th {
  border: black solid 1px;
  padding: 1px 2px 1px 2px;
}

.orderNo:empty {
  display: none;
}

.headerSubTitle {
  font-family: 'Equestria', 'Permanent Marker', cursive;
  text-align: center;
  font-size: 12pt;
}

.headerTitle {
  font-family: 'Equestria', 'Permanent Marker', cursive;
  text-align: center;
  font-size: 40pt;
  font-weight: bold;
}

#location {
  margin-top: 5pt;
  text-align: center;
  font-size: 16pt;
  font-weight: bold;
}

#date {
  margin: 5pt 0px;
  text-align: center;
  font-size: 8pt;
}

#barcode {
  display: block;
  margin: 0px auto;
}

#barcode:empty {
  display: none;
}

.watermark {
  position: absolute;
  left: 7mm;
  top: 60mm;
  width: 75mm;
  opacity: 0.1;
}

.keepIt {
  text-align: center;
  font-size: 12pt;
  font-weight: bold;
  padding-top: 7px;
  padding-bottom: 7px;
}

.keepItBody {
  text-align: center;
  font-size: 8pt;
}

.item {
  margin-bottom: 1mm;
}

.itemRow {
  display: flex;
  font-size: 8pt;
  align-items: baseline;
}

.itemRow>div {
  align-items: baseline;
}

.itemName {
  font-weight: bold;
}

.itemPrice {
  text-align: right;
  flex-grow: 1;
}

.itemColor {
  width: 10px;
  height: 100%;
  background: yellow;
  margin: 0px 2px;
  padding: 0px;
}

.itemColor:before {
  content: "\00a0";
}


.itemData2 {
  text-align: right;
  flex-grow: 1;
}

.itemData3 {
  width: 15mm;
  text-align: right;
}

.itemQuantity:before {
  content: "x";
}

.itemTaxable:after {
  content: " T";
}

.flex {
  display: flex;
  justify-content: center;
}

#qrcode {
  align-self: center;
  flex: 0 0 100px
}

.totals {
  flex-grow: 1;
  align-self: center;
  font-size: 8pt;
}

.totals .row {
  display: flex;
  text-align: right;
}

.totals .section {
  padding-top: 2mm;
}

.totalRow>div,
.total>div {
  text-align: right;
  align-items: baseline;
  font-size: 8pt;
}

.totals .col1 {
  text-align: right;
  flex-grow: 1;
}

.totals .col2 {
  width: 22mm;
}

.totals .col2:empty {
  display: none;
}

.totals .col3 {
  width: 15mm;
}

.footer {
  overflow: hidden;
  margin-top: 5mm;
  border-radius: 7px;
  width: 100%;
  background: black;
  color: white;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

.footer:empty {
  display: none;
}

.eta {
  padding: 1mm 0px;
}

.eta:empty {
  display: none;
}

.eta:before {
  content: "Estimated time order will be ready: ";
  font-size: 8pt;
  display: block;
}

.etaLabel {
  font-size: 8pt;
}

.printType {
  padding: 1mm 0px;
  width: 100%;
  background: grey;
  color: white;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

.about {
  font-size: 12pt;
  overflow: hidden;
  background: #FCEC52;
  color: #3A5743;
  border-radius: 7px;
  padding: 0px;
  position: absolute;
  width: 500px;
  text-align: center;
  left: 50%;
  margin-top: 50px;
  margin-left: -250px;
}

.arrow_box h3,
ul {
  margin: 5px;
}

.about li {
  text-align: left;
}

.arrow_box {
  position: absolute;
  background: #88b7d5;
  padding: 5px;
  margin-top: 20px;
  left: 95mm;
  top: 2;
  width: 500px;
  border: 4px solid #c2e1f5;
}

.arrow_box:after,
.arrow_box:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #88b7d5;
  border-width: 30px;
  margin-top: -30px;
}

.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-right-color: #c2e1f5;
  border-width: 36px;
  margin-top: -36px;
}
</style>
