<template>
  <div>
    <v-dialog v-model="dialog" width="50%" persistent>
      <v-card>
        <v-form v-model="valid">
          <v-card-title class="headline">طباعة تقارير الحسابات</v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense justify="center">
                <v-col cols="3" sm="6"
                  ><v-autocomplete
                    label="اسم التقرير"
                    dense
                    outlined
                    required
                    v-model="reportId"
                    :items="filter"
                    item-value="id"
                    item-text="name"
                    auto-select-first
                    :rules="[allRules.required]"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="6">
                  <v-autocomplete
                    dense
                    outlined
                    hide-details
                    label="الفرع"
                    :items="cities"
                    item-value="id"
                    item-text="name"
                    v-model="options.cityId"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <DatePicker-layout
                    @myEvent="options.dateFrom = $event"
                    :date.sync="options.dateFrom"
                    :rules="[allRules.required]"
                    label="من تاريخ"
                  >
                  </DatePicker-layout
                ></v-col>
                <v-col cols="3"
                  ><DatePicker-layout
                    @myEvent="options.dateTo = $event"
                    :date.sync="options.dateTo"
                    :rules="[allRules.required]"
                    label="الى تاريخ"
                  >
                  </DatePicker-layout
                ></v-col>
                <v-col cols="2"
                  ><v-btn elevation="2" :disabled="!valid" @click="goToPrint">
                    <i class="fas fa-print"></i> طباعة
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="red darken-1" text :to="{ name: 'CatchReceipt' }">
              إلغاء
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-container v-if="dialog != true">
      <v-col class="xlsfile" cols="12" v-if="entities.length > 0">
        <download-excel
          name="تقرير الحسابات.xls"
          :data="entities"
          :fields="headersxls"
        >
          <v-btn elevation="2" color="gray">
            حفظ اكسل <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
        </download-excel></v-col
      >
      <div class="content">
        <div>
          <v-row class="" justify="center">
            <v-col
              cols="4"
              evaluate="2"
              class="data pa-2 text-h4 font-weight-bold text-center"
            >
              {{ this.$store.getters.companyName }}
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row justify="space-between" class="ammar" dense>
            <v-col cols="5" class="mt-9 text-right">
              <h5 class="mb-1">
                {{
                  filter.find((x) => x.id === +this.$route.query["reportId"])
                    .name
                }}
              </h5>
              <v-row>
                <v-col cols="3"> فى الفترة من </v-col>
                <v-col cols="5" class="">{{ this.options.dateFrom }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="3"> الى </v-col>
                <v-col cols="5" class=""> {{ this.options.dateTo }}</v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="mt-5 text-right">
              <v-row>
                <v-col cols="4"> تاريخ الطباعة </v-col>
                <v-col cols="5" class="">{{ print.date }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4"> التوقيت </v-col>
                <v-col cols="5" class="">{{ print.time }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <v-row class="mb-5">
          <v-col cols="12 m-0 p-0 mt-5">
            <v-data-table
              :headers="headers"
              :items="entities"
              :options.sync="options"
              :server-items-length="total"
              :items-per-page="-1"
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:[`item.createDate`]="{ item }">
                {{ item.createDate | ToDate }}
              </template>
              <template v-slot:[`item.shipment.paymentType`]="{ item }">
                {{ item.shipment.paymentType == 1 ? "بوالص نقدية" : "سند قبض" }}
              </template>
              <template slot="body.append">
                <tr class="red--text">
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="title text-center">
                    {{ sumField() }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>


<script>
import ApiService from "../services/CatchReceiptService";
import CitiesService from "../../Settings/services/CityService.js";
export default {
  mounted() {
    var isprint = this.$route.query["isprint"];
    var id = this.$route.query["reportId"];
    var datef = this.$route.query["dateFrom"];
    var datet = this.$route.query["dateTo"];
    var cityId = this.$route.query["cityId"];
    if (id && isprint) {
      this.dialog = false;
      this.getDataFromAPI(id, cityId, datef, datet);
    }
  },
  data() {
    return {
      filter: [
        { id: 3, name: "محصلة صندوق شامل في فتره" },
        { id: 1, name: "محصلة صندوق من بوالص نقدية في فتره" },
        { id: 2, name: "محصلة صندوق من سندات القبض في فتره" },
      ],
      loadings: {},
      reportId: null,
      dialog: true,
      valid: false,
      entities: [],
      cities: [],
      total: 0,
      options: {
        cityId: null,
        paymentType: null,
        dateFrom: null,
        dateTo: null,
      },
      loadingStates: {
        table: false,
      },
      print: {
        time: new Date().toLocaleTimeString(),
        date: new Date().toLocaleDateString(),
      },
      headers: [
        {
          text: " رقم السند",
          align: "center",
          sortable: false,
          value: "id",
        },
        {
          text: "التاريخ",
          value: "createDate",
          sortable: false,
          align: "center",
        },
        {
          text: "النوع ",
          value: "shipment.paymentType",
          sortable: false,
          align: "center",
        },
        {
          text: "المودع",
          value: "recipient",
          sortable: false,
          align: "center",
        },
        {
          text: "الرقم اليدوى",
          value: "shipment.manualNo",
          sortable: false,
          align: "center",
        },
        {
          text: "رقم البوليصة",
          value: "shipment.trackingNumber",
          sortable: false,
          align: "center",
        },
        {
          text: "رقم الرحلة",
          value: "shipment.trip.trackingNumber",
          sortable: false,
          align: "center",
        },
        {
          text: "المبلغ",
          value: "amount",
          sortable: false,
          align: "center",
        },
      ],
      headersxls: {
        المبلغ: "amount",
        "رقم الرحلة": "shipment.trip.trackingNumber",
        "رقم البوليصة": "shipment.trackingNumber",
        "الرقم اليدوى": "shipment.manualNo",
        المودع: "recipient",
        "النوع ": {
          callback: (value) => {
            return `${
              value.shipment.paymentType == 1 ? "بوالص نقدية" : "سند قبض"
            }`;
          },
        },
        التاريخ: "createDate",
        " رقم السند": "id",
      },
    };
  },
  created() {
    CitiesService.typeHead("any", true).then((resp) => {
      this.cities = resp.data;
    });
  },
  methods: {
    sumField() {
      return this.entities.reduce((a, b) => a + (b["amount"] || 0), 0);
    },
    getDataFromAPI(id, cityId, datef, datet) {
      this.loadingStates.table = true;
      this.options.itemsPerPage = -1;
      this.options.dateFrom = datef;
      this.options.dateTo = datet;
      this.options.cityId = cityId;
      if (id != 3) this.options.paymentType = id;
      ApiService.query(this.$clean(this.options)).then((resp) => {
        this.entities = resp.data.items;

        this.total = this.entities.length;
        this.loadingStates.table = false;
        setTimeout(() => {
          window.focus();
          var css = "@page { size: portrait; }",
            head = document.head || document.getElementsByTagName("head")[0],
            style = document.createElement("style");

          style.type = "text/css";
          style.media = "print";

          if (style.styleSheet) {
            style.styleSheet.cssText = css;
          } else {
            style.appendChild(document.createTextNode(css));
          }

          head.appendChild(style);
          window.print();
        }, 100);
      });
    },
    goToPrint() {
      let routeData = this.$router.resolve({
        name: "FinanceReportsPrint",
        query: {
          isprint: true,
          reportId: this.reportId,
          dateFrom: this.options.dateFrom,
          dateTo: this.options.dateTo,
          cityId: this.options.cityId,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
* {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
  color-adjust: exact !important; /*Firefox*/
}
.content {
  font-weight: 600 !important;
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
  .xlsfile {
    display: none;
  }
}
::v-deep .v-data-table-header {
  background-color: #8080802b;
}

::v-deep .v-data-table {
  max-width: 98%;
}
::v-deep .v-data-table tr {
  height: 14px !important;
  border: black solid 2px !important;
}
::v-deep .v-data-table td {
  height: 20px !important;
  border: black solid 2px !important;
}
::v-deep .v-data-table th {
  border: black solid 2px !important;
}

.ammar div {
  margin: 0 !important;
  padding: 1px !important;
  font-weight: bold;
}

.ammar div .edit {
  margin-top: 20px !important;
}

.data {
  border: 2px solid grey;
}

.both {
  position: absolute;
}
.dashed-border {
  border: 2px dotted grey;
  padding: 10px;
}

.first {
  bottom: -32px;
  left: 33%;
}

.second {
  bottom: 33;
  left: 8%;
}

.review div {
  border: 2px solid black;
  display: inline-block;
  font-weight: bold;
}
</style>