import Api from '../../shared/services/api'
import axios from '@/plugins/axios.js'
const baseUrl = "ReceiveReceipts";
export default {

    get() {
        return Api().get(baseUrl);
    },
    typeHead(searchTerm, all) {
        return Api().get(`${baseUrl}/typeHead?${searchTerm}&all=${all}`);
    },
    byId(id) {
        return Api().get(baseUrl + `/${id}`);
    },
    queryAdmin(options) {
        const tokenStr = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1hemVuIiwiQ2l0eUlkIjoiMTEiLCJqdGkiOiJjODczNjQ1Zi02MWFjLTRlOTYtOWJiYi1jZjdiMzAzYWFiNGUiLCJzdWIiOiI1MDAxMDk0OS0xMzkzLTQ1MjEtYTEyNC1iMWViODI5YjkwZTkiLCJyb2xlIjoiVHJlYXN1cmVyIiwibmJmIjoxNjMyMDgxOTA0LCJleHAiOjE2MzQ2NzM5MDQsImlhdCI6MTYzMjA4MTkwNH0.TWMbTtgORhz0FxDaewAWj1z9V9bKylDGgPkEyCEd37c';
        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Acess-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${tokenStr}`,
                'Accept': "application/json"
            }
        })
        const qParams = new URLSearchParams(options);
        return instance.get(baseUrl + "?" + qParams.toString());
    },
    query(options) {
        const qParams = new URLSearchParams(options);
        return Api().get(baseUrl + "?" + qParams.toString());
    },
    admin(options) {
        const qParams = new URLSearchParams(options);
        return Api().get(baseUrl+ `/Admin` + "?" + qParams.toString());
    },
    updateState(state) {
        return Api().put(baseUrl + `/state`, state);
    },
    edit(entity) {
        if (entity.id)
            return Api().put(baseUrl + `/${entity.id}`, entity);
        else
            return this.add(entity);
    },
    add(entity) {
        return Api().post(baseUrl, entity);
    },
    delete(id) {
        return Api().delete(baseUrl + `/${id}`);
    }
}