import Vue from 'vue';
import Enums from './enums';
import moment from 'moment';
function getEnumMember(enumClass, enumValue) {
  for (var val in enumClass) {
    if (enumClass[val].value == enumValue) return enumClass[val];
  }
  return {};
}
Vue.filter("CarOwnership", function (enumValue) {
  var enumObj = getEnumMember(Enums.CarOwnership, enumValue);
  return enumObj.text;
});

Vue.filter("ToDate", function (date) {

  return moment(date).format('YYYY-MM-DD');
});

Vue.filter("ToTime", function (date) {

  return moment(date).format('LT');
});
Vue.filter("localdate", function (date) {
  var newDate = new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60 * 1000);
  var offset = new Date(date).getTimezoneOffset() / 60;
  var hours = new Date(date).getHours();
  newDate.setHours(hours - offset);
  return moment(newDate).format('LT');
});

Vue.filter("EnumMember", function (enumValue, enumClass) {

  return getEnumMember(enumClass, enumValue);

});
Vue.filter("PaymentTypes", function (enumValue) {
  var enumObj = getEnumMember(Enums.PaymentTypes, enumValue);
  return enumObj.text;

});
Vue.filter("PaymentPaidTypes", function (enumValue) {
  var enumObj = getEnumMember(Enums.PaymentPaidTypes, enumValue);
  return enumObj.text;

});
Vue.filter("Cities", function (enumValue) {
  var enumObj = getEnumMember(Enums.Cities, enumValue);
  return enumObj.text;
  
});
Vue.filter("ShipmentsReportsStatesnc", function (enumValue) {
  var enumObj = getEnumMember(Enums.ShipmentsReportsStatesnc, enumValue);
  return enumObj.text;

});
Vue.filter("ShipmentsReportsTypes", function (enumValue) {
  var enumObj = getEnumMember(Enums.ShipmentsReportsTypes, enumValue);
  return enumObj.text;

});
Vue.filter("mcashingSide", function (enumValue) {
  var enumObj = getEnumMember(Enums.mcashingSide, enumValue);
  return enumObj.text;

});
Vue.filter("ExpenseReceiptPaymentTypes", function (enumValue) {
  var enumObj = getEnumMember(Enums.ExpenseReceiptPaymentTypes, enumValue);
  return enumObj.text;

});
Vue.filter("Sizes", function (enumValue) {
  var enumObj = getEnumMember(Enums.Sizes, enumValue);
  return enumObj.text;

});

Vue.filter("RecipientTypes", function (enumValue) {
  var enumObj = getEnumMember(Enums.RecipientType, enumValue);
  return enumObj.text;

});

Vue.filter("DiscountTypes", function (enumValue) {
  var enumObj = getEnumMember(Enums.DiscountTypes, enumValue);
  return enumObj.text;

});
