import Api from '../../shared/services/api'

const baseUrl = "Permissions";
export default {
    get() {
        return Api().get(baseUrl);
    },
    updatePermissions(userId, Ids) {
        return Api().put(baseUrl + `/${userId}`, { permissionIds: Ids });
    },
    getbyID(userId) {
        return Api().get(baseUrl + `/${userId}`);
    },
    tree() {
        return Api().get(baseUrl + `/tree`);
    },
    userTree(userId) {
        return Api().get(baseUrl + `/${userId}/tree`);
    },
}