<template>
  <div>
    <v-row class="pr-5 pb-3 mt-3">
      <v-col class="d-flex justify-content-start">
        <v-btn v-can="'Salesmen.Create'" color="success" @click="add">
          اضافة مندوب<i class="fa fa-plus mr-2"></i>
        </v-btn>
      </v-col>
      <v-col class="d-flex justify-content-end">
        <v-btn v-can="'Salesmen.Create'" class="white--text" color="blue" @click="viewTop50">
          اعلى 50 مندوب
        </v-btn>
      </v-col>
    </v-row>

    <v-card width="98%" class="mx-auto mr-5">
      <v-data-table v-can="'Salesmen.View'" :headers="headers" :items="entities" :options.sync="options"
        :server-items-length="total" :search="search" :items-per-page="15" :loading="loadingStates.table"
        class="elevation-1" :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-plus',
          nextIcon: 'mdi-minus',
          'items-per-page-text': 'عدد المندوبين ' + total + '',
        }">
        <template v-slot:top>
          <v-row class="mx-auto mt-3 pa-5" dark dense>
            <v-col cols="12" sm="2">
              <v-text-field v-model="options.Id" label="كود المندوب" hide-details dense outlined></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field v-model="options.name" label="اسم المندوب" hide-details dense outlined></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field v-model="options.phoneNumber" label="جوال المندوب" hide-details dense outlined>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field v-model="options.IdNumber" label="هوية المندوب" hide-details dense outlined></v-text-field>
            </v-col>
            <v-col cols="6" sm="4">
              <v-btn color="error" @click="resetOptions">تفريغ
                <i class="fa fa-trash fa-lg"></i>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-can="'Salesmen.View'" color="orange" elevation="2" icon outlined @click="openPolisa(item)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-btn v-can="'Salesmen.Delete'" color="red" class="mx-2" icon elevation="2" outlined @click="del(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-btn v-can="'Salesmen.Update'" color="primary" elevation="2" icon @click="edit(item.id)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- Add Modal -->
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-form v-model="valid">
          <v-card-title class="headline">
            <i class="fa fa-pen ml-2"></i> تعديل/إضافة
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="my-0 ammar" dense>
                <v-col>
                  <v-text-field dense outlined label="اسم المندوب" :rules="[allRules.required, allRules.minNameLen(4)]"
                    v-model="selected.name">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-0 ammar" dense>
                <v-col>
                  <v-text-field dense outlined label="رقم الجوال" v-model="selected.mob" :maxlength="10">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-0 ammar" dense>
                <v-col>
                  <v-text-field dense :maxlength="10" outlined label="رقم الهوية" v-model="selected.mob2">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-0 ammar" dense>
                <v-textarea outlined name="notice" label="الملاحظات" v-model="selected.notice"></v-textarea>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" :disabled="!valid" @click="editConfirmed()">
              حفظ
            </v-btn>

            <v-btn color="red darken-1" text @click="cancelEdit()">
              إلغاء
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Add Modal -->
    <!-- polisa model start -->
    <v-dialog v-model="polisaDialog" width="99%" persistent>
      <v-card>
        <v-card-text>
          <v-container>
            <v-tabs v-model="activeTab" fixed-tabs next-icon="mdi-arrow-right-bold-box-outline"
              prev-icon="mdi-arrow-left-bold-box-outline" show-arrows center-active background-color="orange accent-1"
              color="black" class="rounded-lg" light>
              <v-tab> جميع بوالص المندوب </v-tab>
              <!-- details tab -->
              <v-tab @click="showSalesmencommesion()">جدول عمولات المندوب</v-tab>
              <v-tab-item>
                <v-container class="tab-item-wrapper">
                  <v-row>
                    <v-col cols="12" class="align-content-start d-flex align-items-center">
                      <span class="h5 pa-3 mx-auto pink--text"> </span>
                    </v-col>
                  </v-row>
                  <v-data-table v-model="selectedPolisa" item-key="id" show-select :single-select="singleSelect"
                    :headers="polisaHeaders" :items="polisaData" fixed-header :search="searchShippments"
                    :options.sync="optionsShippments" :items-per-page="25" :server-items-length="totalshipments"
                    class="elevation-1" :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-arrow-collapse-left',
                      lastIcon: 'mdi-arrow-collapse-right',
                      prevIcon: 'mdi-plus',
                      nextIcon: 'mdi-minus',
                      'items-per-page-text':
                        'عدد بوالص المندوب ' + polisaData.length + '',
                    }">
                    <template v-slot:top>
                      <v-row class="rounded-lg" dark dense>
                        <v-col cols="12" sm="4">
                          <v-text-field v-model="searchShippments" label="بحث" hide-details dense outlined class="mx-4">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                          <v-autocomplete dense outlined hide-details auto-select-first label="المسار" :items="lines"
                            item-value="id" item-text="name" v-model="optionsShippments.lineId"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="2">
                          <DatePicker-layout @myEvent="optionsShippments.startDate = $event"
                            :date.sync="optionsShippments.startDate" label="التاريخ من "></DatePicker-layout>
                        </v-col>
                        <v-col cols="12" sm="2">
                          <DatePicker-layout @myEvent="optionsShippments.endDate = $event" :date.sync="optionsShippments.endDate"
                            label="التاريخ الى "></DatePicker-layout>
                        </v-col>
                      </v-row>
                      <v-spacer></v-spacer>
                      <div>
                        <v-btn v-can="'Salesmen.Update'" @click="sendTo()" class="d-block ma-2 error" :disabled="
                          selectedPolisa.filter(
                            (e) => !e.commessionReceived
                          ) < 2
                        ">
                          اضافه عموله
                        </v-btn>
                      </div>
                    </template>
                    <template v-slot:[`item.data-table-select`]="{
                      isSelected,
                      select,
                      item,
                    }">
                      <v-simple-checkbox :ripple="false" v-if="!item.commessionReceived" color="green" :value="isSelected"
                        @input="select($event)"></v-simple-checkbox>
                    </template>
                    <template v-slot:[`item.date`]="{ item }">
                      {{ item.date | ToDate }}
                    </template>
                    <template v-slot:[`item.senderInfo`]="{ item }">
                      <div v-if="item.senderInfo">
                        <div>{{ item.senderInfo.name }}</div>
                        <div>{{ item.senderInfo.phoneNumber }}</div>
                      </div>
                    </template>
                    <template v-slot:[`item.receiverInfo`]="{ item }">
                      <div v-if="item.receiverInfo">
                        <div>{{ item.receiverInfo.name }}</div>
                        <div>{{ item.receiverInfo.phoneNumber }}</div>
                      </div>
                    </template>
                    <template v-slot:[`item.serviceType.name`]="{ item }">
                      <span>
                        <v-chip color="blue lighten-3" label>{{ item.serviceType.name }}
                        </v-chip>
                      </span>
                    </template>
                    <template v-slot:[`item.trip.trackingNumber`]="{ item }">
                      <div v-if="item.trip.trackingNumber">
                        <div v-if="item.trip.trackingNumber">
                          {{ item.trip.trackingNumber }}
                        </div>
                        <div v-if="item.trip.line.name">
                          <span>
                            <v-chip color="green lighten-3" label>{{ item.trip.line.name }}
                            </v-chip>
                          </span>
                        </div>
                      </div>
                    </template>
                    <template v-slot:[`item.items`]="{ item }">
                      <div v-if="item.items">
                        <div v-if="item.items[0].merchandise">
                          {{ item.items[0].merchandise.name }}
                        </div>
                        <div v-if="item.items[0]">
                          {{ item.items[0].quantity }}
                        </div>
                      </div>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn v-if="!item.commessionReceived" color="success" elevation="2" icon outlined
                        @click="sendTo(item)">
                        <v-icon color="green">mdi-account-switch</v-icon>
                      </v-btn>
                      <v-col v-if="item.commessionReceived" class="d-flex justify-content-start align-items-center">
                        <span> تم استلام العمولة </span>
                        <i class="far fa-check-circle mr-2" style="color: green"></i>
                      </v-col>
                    </template>
                  </v-data-table>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col cols="12" class="align-content-start d-flex align-items-center">
                      <span class="h5 pa-3 mx-auto pink--text"> </span>
                    </v-col>
                  </v-row>
                  <v-data-table :headers="commesionsheaders" :items="Allcommesions" fixed-header :items-per-page="25"
                    :server-items-length="AllcommesionsTotal" class="elevation-1" :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-arrow-collapse-left',
                      lastIcon: 'mdi-arrow-collapse-right',
                      prevIcon: 'mdi-plus',
                      nextIcon: 'mdi-minus',
                      'items-per-page-text':
                        'عدد عمولات المندوب ' + Allcommesions.length + '',
                    }">
                    <template v-slot:[`item.createDate`]="{ item }">
                      {{ item.createDate | ToDate }}
                    </template>
                    <template v-slot:[`item.time`]="{ item }">
                      {{ item.time | ToTime }}
                    </template>
                    <template v-slot:[`item.percentage`]="{ item }">
                      <span>
                        <v-chip color="green lighten-3" label>{{ item.percentage }} %
                        </v-chip>
                      </span></template>
                    <template v-slot:[`item.amount`]="{ item }">
                      <span>
                        <v-chip color="blue lighten-3" label>{{ item.amount }} ريال
                        </v-chip>
                      </span></template>
                  </v-data-table>
                </v-container>
              </v-tab-item>
            </v-tabs>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="cancelView()"> إلغاء </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- commision Dialog -->
    <v-dialog v-model="commissionDialog" width="50%" persistent>
      <v-card>
        <v-card-title class="headline"> اضافه عموله لمندوب </v-card-title>
        <v-card-text>
          <v-container v-if="!showPrint">
            <v-row dense justify="center">
              <v-col cols="4">
                <div class="rounded-lg pa-1 text-center grey lighten-3">
                  <h5>عدد البوالص</h5>
                  <h6>{{ selectedPolisa.length }}</h6>
                </div>
              </v-col>
              <v-col cols="4">
                <div class="rounded-lg grey pa-1 text-center lighten-3">
                  <h5>اجمالى الطرود</h5>
                  <h6>{{ shipmentsquantityTotal(selectedPolisa) }}</h6>
                </div>
              </v-col>
              <v-col cols="4">
                <div class="rounded-lg grey pa-1 text-center lighten-3">
                  <h5>الاجمالى</h5>
                  <h6>{{ polisasValue(selectedPolisa) }} ريال</h6>
                </div>
              </v-col>
              <v-col cols="3" class="mt-2">
                <v-text-field outlined v-model.lazy="selected.percentage" label="النسبه" class="mx-4" suffix="%"
                  @input="countPercent"></v-text-field>
              </v-col>
              <v-col cols="3" class="mt-2">
                <v-text-field v-if="selected.percentage" outlined v-model="selected.amount" label="اجمالى العمولة"
                  suffix="ريال" class="mx-4"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <div v-else class="text-center">
            <h2>طباعة سند الصرف لمندوب</h2>
            <v-row>
              <v-col cols="12">
                <v-btn icon @click="print">
                  <v-icon large color="error"> mdi-printer </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn v-if="!showPrint" :disabled="!selected.percentage" color="primary" @click="finished()">
            حفظ
          </v-btn>

          <v-btn color="red darken-1" text @click="cancelCommissionEdit()">
            إلغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Top50 Dialog -->
    <v-dialog v-model="top50Dialog" width="1000">
      <v-card>
        <v-card-title class="indigo white--text text-h5">
          اعلى 50 مندوب
        </v-card-title>
        <v-row class="pa-4" justify="space-between">
          <v-col cols="7">
            <v-treeview :items="top50data" :active.sync="active" :open.sync="open" activatable color="warning"
              open-on-click transition>
              <template v-slot:label="{ item }">
                <div v-if="item" class="treeeee">

                </div>
              </template>
              <template v-slot:prepend="{ item }">
                <v-icon v-if="!item.children">
                  mdi-account
                </v-icon>
                {{ item.name + (item.itemsCount ? ` (عدد الطرود: ${item.itemsCount})` : "") }}
              </template>
            </v-treeview>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col class="d-flex text-center">
            <v-scroll-y-transition mode="out-in">
              <v-card v-if="selectedtop50" :key="selectedtop50.id" class="pt-6 mx-auto" flat max-width="400">
                <v-treeview :items="salesManHistory">
                  <template v-slot:label="{ item }">
                    <div v-if="item" class="treeeee">
                    </div>
                  </template>
                  <template v-slot:prepend="{ item }">

                    {{ item.monthName + (item.itemsCount ? ` (عدد الطرود: ${item.itemsCount})` : "") }}
                  </template>
                </v-treeview>
              </v-card>
            </v-scroll-y-transition>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "../services/SalesmenService.js";
import ShipmentService from "../services/ShipmentService.js";
import lineApi from "../services/LinesService.js";
export default {
  data() {
    return {
      lines: [],
      activeTab: 0,
      totalShipmentsPrice: 0,
      valid: false,
      showPrint: false,
      commissionDialog: false,
      top50Dialog: false,
      singleSelect: false,
      selected: {},
      selectedPolisa: [],
      polisaDialog: false,
      polisaData: [],
      entities: [],
      total: 0,
      salesManId: null,
      salesManName: null,
      salesManNotice: null,
      totalshipments: 0,
      search: "",
      searchShippments: "",
      options: { Id: null, name: null, phoneNumber: null, IdNumber: null },
      optionsShippments: {
        startDate: null,
        endDate: null,
        lineId: null
      },
      Allcommesions: [],
      AllcommesionsTotal: 0,
      dialog: false,
      loadingStates: {
        table: false,
      },
      commesionsheaders: [
        {
          text: "م",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          align: "center",
          sortable: false,
          text: "التاريخ",
          value: "createDate",
        },
        { align: "center", sortable: false, text: "الوقت", value: "time" },
        {
          align: "center",
          sortable: false,
          text: "اجمالى العمولة",
          value: "amount",
        },
        {
          align: "center",
          sortable: false,
          text: "نسبة العمولة",
          value: "percentage",
        },
        {
          align: "center",
          sortable: false,
          text: "منشئ السند",
          value: "createdBy.name",
        },
      ],
      headers: [
        {
          text: "رقم",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "الاسم", value: "name" },
        { text: "رقم الجوال", value: "mob" },
        { text: "الهوية", value: "mob2" },
        { text: "اجمالى البوالص", value: "shipmentsCount" },
        { text: "اجمالى البوالص المسلمة", value: "shipmentsDelivered" },
        { text: "اجمالى العمولة", value: "totalCommesion" },
        { text: "الملاحظات", value: "notice" },
        { text: "مسح / تعديل", value: "actions", align: "left", width: "25%" },
      ],
      polisaHeaders: [
        {
          text: "رقم",
          align: "start",
          sortable: false,
          value: "trackingNumber",
          align: "center",
        },
        { text: "التاريخ", value: "date", align: "center", sortable: true },
        {
          text: "الرقم اليدوى",
          value: "manualNo",
          align: "center",
          sortable: true,
        },
        {
          text: "رقم الدفتر",
          value: "bookId",
          align: "center",
          sortable: true,
        },
        {
          text: " نوع الخدمه ",
          value: "serviceType.name",
          align: "center",
          sortable: true,
        },
        {
          text: "الوحده",
          value: "items[0].unit.name",
          align: "center",
          sortable: true,
        },
        {
          text: "الصنف",
          value: "items",
          align: "center",
          sortable: true,
        },
        {
          text: "الرحلة",
          value: "trip.trackingNumber",
          align: "center",
          sortable: true,
        },
        {
          text: "المرسل",
          value: "senderInfo",
          align: "center",
          sortable: true,
        },
        {
          text: "المستلم",
          value: "receiverInfo",
          align: "center",
          sortable: true,
        },
        {
          text: "اجمالي السعر",
          value: "totalPrice",
          align: "center",
          sortable: true,
        },
        { text: "الحاله / اضافه عموله", value: "actions", align: "left" },
        // { text: "الحاله", value: "state", align: "center", sortable: true },
      ],
      active: [],
      open: [],
      top50data: [],
      salesManHistory: [],
    };
  },
  mounted() {
    this.activeTab = 0;
    this.getData();
  },
  computed: {
    selectedtop50() {
      if (!this.active.length) return undefined
      const id = this.active[0]
      this.fetchsalesmanHistory(id);
      return id
    },
  },
  async created() {
    try {
      lineApi.typeHead("", true, false, false).then(resp => {
        this.lines = resp.data;
      });
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    fetchTop50() {
      this.active = [];
      this.open = [];
      this.top50data = [];
      this.salesManHistory = [];
      return ShipmentService.salesmanTop50().then((resp) => {
        this.top50data = resp.data;
      });
    },
    fetchsalesmanHistory(id) {
      return ShipmentService.salesmanHistory(id).then((resp) => {
        this.salesManHistory = resp.data;
      });
    },
    resetOptions() {
      this.options = {
        Id: null,
        name: null,
        phoneNumber: null,
        IdNumber: null,
      };
    },
    countPercent() {
      let percent = (
        (this.totalShipmentsPrice / 100) *
        this.selected.percentage
      ).toFixed(1);
      this.$set(this.selected, "amount", percent);
    },
    print() {
      let AllprintData = {
        name: this.salesManName,
        notice: this.salesManNotice,
        totalShipmentsPrice: this.selected.amount,
        quantity: this.shipmentsquantityTotal(this.selectedPolisa),
        from: this.optionsShippments.From,
        to: this.optionsShippments.To,
        Id: this.salesManId,
        count: this.selectedPolisa.length,
      };
      localStorage.setItem("AllprintData", JSON.stringify(AllprintData));
      let routeData = this.$router.resolve({
        name: "SalesMenReport",
      });
      window.open(routeData.href, "_blank");
    },
    sendTo(item) {
      if (item) {
        this.selectedPolisa = [];
        this.selectedPolisa.push(item);
      } else {
        this.selectedPolisa = this.selectedPolisa.filter(
          (e) => !e.commessionReceived
        );
      }
      if (this.selectedPolisa.length > 0) {
        this.commissionDialog = true;
      }
    },
    cancelView() {
      this.activeTab = 0;
      this.commissionDialog = false;
      this.showPrint = false;
      this.polisaDialog = false;
      this.selectedPolisa = [];
      this.selected = {};
      this.Allcommesions = [];
      this.AllcommesionsTotal = 0;
      this.totalShipmentsPrice = 0;
      this.optionsShippments = {
        startDate: null,
        endDate: null,
        lineId: null
      };
      (this.searchShippments = ""),
        (this.salesManId = null),
        (this.salesManName = null),
        (this.salesManNotice = null);
    },
    cancelCommissionEdit() {
      this.commissionDialog = false;
      this.showPrint = false;
      //this.polisaDialog= false;
      this.selectedPolisa = [];
      this.selected = {};
      this.totalShipmentsPrice = 0;
      this.getData();
    },
    finished() {
      this.showPrint = true;
      let model = {
        shhipmentIds: this.selectedPolisa.map((e) => e.id),
        amount: this.selected.amount,
        percentage: this.selected.percentage,
      };
      ApiService.addToSalesmencommesion(this.salesManId, model)
        .then((resp) => {
          this.getShippments(resp.data.salesman.id);
          this.updateArr(this.entities, resp.data.salesman);
        })
        .catch((resp) => { })
        .finally(() => { });
    },
    polisasValue(items) {
      if (items != null) {
        let tottal = items.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.totalPrice;
        }, 0);
        this.totalShipmentsPrice = tottal;
        return tottal;
      }
    },
    shipmentsquantityTotal(items) {
      if (items != null) {
        let tottal = items.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.items[0].quantity;
        }, 0);
        return tottal;
      }
    },
    showSalesmencommesion() {
      ApiService.getSalesmencommesion(this.salesManId).then((resp) => {
        this.Allcommesions = resp.data;
        this.AllcommesionsTotal = this.Allcommesions.length;
      });
    },
    getShippments(id) {
      if (this.optionsShippments)
        this.optionsShippments.searchTerm = this.searchShippments;
      ShipmentService.bySalesmanId(id, this.$clean(this.optionsShippments)).then((resp) => {
        this.polisaData = resp.data;
        this.totalshipments = resp.data.total;
      });
    },
    openPolisa(item) {
      ShipmentService.bySalesmanId(item.id).then((resp) => {
        this.polisaData = resp.data;
        this.totalshipments = resp.data.total;
        this.salesManId = item.id;
        this.salesManName = item.name;
        this.salesManNotice = item.notice;
        this.polisaDialog = true;
      });
    },
    getData() {
      this.loadingStates.table = true;
      if (this.options) this.options.searchTerm = this.search;
      ApiService.query(this.$clean(this.options))
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
        });
    },
    editConfirmed() {
      ApiService.edit(this.selected)
        .then((resp) => {
          this.dialog = false;
          var message = "تم تعديل المندوب بنجاح";
          if (!this.selected.id) {
            this.addToArr(this.entities, resp.data);
            message = "تم إضافة المندوب بنجاح";
          } else {
            this.updateArr(this.entities, resp.data);
          }

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.dialog = false;
          console.log(err.request.response);
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    cancelEdit() {
      this.dialog = false;
      this.selected = {};
    },
    add() {
      this.selected = {};
      this.dialog = true;
    },
    edit(id) {
      this.selected = { ...this.entities.filter((e) => e.id == id)[0] };
      this.dialog = true;
    },
    del(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف المندوب",
          title: "  حذف مندوب  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                ApiService.delete(id)
                  .then((resp) => {
                    const index = this.entities.indexOf(
                      this.entities.filter((e) => e.id == id)[0]
                    );
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("تم مسح المندوب بنجاح", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("خطأ اثناء مسح العنصر", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => { });
    },
    viewTop50() {
      this.fetchTop50();
      this.top50Dialog = true;
    },
  },
  watch: {
    options: {
      handler() {
        if (!this.polisaDialog) this.getData();
      },
      deep: true,
    },
    optionsShippments: {
      handler() {
        if (this.polisaDialog) this.getShippments(this.salesManId);
      },
      deep: true,
    },
    search: {
      handler() {
        if (!this.polisaDialog) this.getData();
      },
      deep: true,
    },
    searchShippments: {
      handler() {
        if (this.polisaDialog) this.getShippments(this.salesManId);
      },
      deep: true,
    },
  },
};
</script>


<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
</style>