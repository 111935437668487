export default [
    {
        path: "/finances/CatchReceipt",
        name: 'CatchReceipt',
        meta: { roles: ['SystemAdmin', 'Manager', 'Accountant'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/CatchReceipt.vue')
    },
    {
        path: "/finances/Receipt",
        name: 'Receipt',
        meta: { roles: ['SystemAdmin', 'Manager', 'Accountant', 'BranchManager'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/Receipt.vue')
    },
    {
        path: "/finances/DailyReport",
        name: 'DailyReport',
        meta: { roles: ['SystemAdmin', 'Manager', 'Accountant', 'BranchManager'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/DailyReport.vue')
    },
    {
        path: "/finances/Expenses",
        name: 'Expenses',
        meta: { roles: ['SystemAdmin', 'Manager', 'Accountant'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/Expenses.vue')
    },
    {
        path: "/finances/FinanceReports",
        name: 'FinanceReports',
        meta: { roles: ['SystemAdmin', 'Manager', 'Accountant'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/FinanceReports.vue')
    },
    {
        path: '/finances/FinanceReportsPrint',
        name: 'FinanceReportsPrint',
        meta: { roles: ['SystemAdmin', 'Manager', 'Accountant'], requiresAuth: true, layout: 'print' },
        component: () => import('./views/FinanceReports.vue')
    },
    {
        path: '/finances/FinanceAllReportsPrint',
        name: 'FinanceAllReports',
        meta: { roles: ['SystemAdmin', 'Manager', 'Accountant'], requiresAuth: true, layout: 'print' },
        component: () => import('./views/FinanceAllReports.vue')
    },
]