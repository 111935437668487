<template>
  <div>
    <v-row class="pr-5 pb-3 mt-5" dense>
      <v-col class="d-flex justify-content-start">
        <v-btn v-can="'Tickets.Create'" color="success" @click="add">
          اضافة امر خدمة
          <i class="fa fa-plus mr-2"></i>
        </v-btn>
        <!--  -->
        <hr />
        <!--  -->
      </v-col>
    </v-row>
    <v-card min-width="98%" class="ma-4">
      <v-data-table
        v-can="'Tickets.View'"
        :headers="headers"
        :items="entities"
        :options.sync="options"
        fixed-header
        :server-items-length="total"
        :search="search"
        :items-per-page="20"
        :loading="loadingStates.table"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-plus',
          nextIcon: 'mdi-minus',
          'items-per-page-text': 'عدد التزاكر المفتوحة ' + total + '',
        }"
      >
        <template v-slot:[`item.details`]="{ item }">
          <v-btn
            v-can="'Tickets.View'"
            color="orange"
            outlined
            elevation="2"
            class="mx-2"
            @click="view(item)"
            icon
            ><v-icon>mdi-eye-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:top>
          <v-row class="mx-auto mt-3 pa-5" dark dense>
            <v-col cols="12" sm="2">
              <v-text-field
                dense
                :disabled="showInfo"
                outlined
                label="رقم امر الخدمة"
                v-model="options.ticketnumber"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                dense
                :disabled="showInfo"
                outlined
                label="اسم المتصل"
                v-model="options.callername"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <DatePicker-layout
                @myEvent="options.date = $event"
                :date.sync="options.date"
                label="التاريخ "
              ></DatePicker-layout>
            </v-col>
            <v-col cols="6" sm="6">
              <v-btn color="error" @click="resetOptions"
                >تفريغ
                <i class="fa fa-trash fa-lg"></i>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | ToDate }}
        </template>
        <template v-slot:[`item.movementState`]="{ item }">
          <div>
            <v-row>
              <v-col cols="1">
                <div>
                  <v-menu>
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                            :color="getColor(item)"
                            v-bind="attrs"
                            v-on="{ ...tooltip, ...menu }"
                            elevation="2"
                            icon
                            ><v-icon>mdi-truck</v-icon></v-btn
                          >
                        </template>
                        <span>تغير وصول/مغادرة الرحلة</span>
                      </v-tooltip>
                    </template>
                    <v-card class="text-center" width="200">
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-h5"
                              >الحالة الحالية</v-list-item-title
                            >
                            <v-divider></v-divider>
                            <v-list-item-subtitle
                              v-for="(opt, index) in movement.filter(
                                (e) => e.value == item.movementState
                              )"
                              :key="index"
                              style="margin: 20px 20px 0px 0px"
                            >
                              <v-chip label outlined :color="opt.color">
                                <v-icon :color="opt.color">{{
                                  opt.icon
                                }}</v-icon
                                >{{ opt.text }}
                              </v-chip>
                            </v-list-item-subtitle>
                            <v-divider></v-divider>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item-action class="mt-0" style="margin: 20px">
                          <v-list-item-title
                            class="m-1"
                            v-if="item.movementState == 0"
                          >
                            <v-chip
                              label
                              outlined
                              color="blue"
                              style="cursor: pointer"
                              @click="changeMovement(item, 1)"
                            >
                              <v-icon color="blue">mdi-arrow-up</v-icon>تم
                              مغادرة الرحلة
                            </v-chip>
                          </v-list-item-title>
                          <v-list-item-title
                            class="m-1"
                            v-if="item.movementState == 1"
                          >
                            <v-chip
                              label
                              outlined
                              color="green"
                              style="cursor: pointer"
                              @click="changeMovement(item, 2)"
                            >
                              <v-icon color="green">mdi-arrow-down</v-icon>تم
                              وصول الرحلة
                            </v-chip>
                          </v-list-item-title>
                        </v-list-item-action>
                      </v-list>
                    </v-card>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <!-- update buttons  end -->
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-can="'Tickets.Delete'"
            color="red"
            class="ml-2"
            icon
            elevation="2"
            outlined
            @click="del(item.id)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn
            v-can="'Tickets.Update'"
            color="primary"
            elevation="2"
            icon
            @click="edit(item.id)"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <!-- Add Modal -->
    <v-dialog v-model="dialog" width="98%" persistent>
      <div>
        <v-card>
          <v-card-text style="min-height: 60vh">
            <v-container>
              <!-- tabs to the search -->
              <div class="text-center green--text">
                <v-tabs
                  fixed-tabs
                  next-icon="mdi-arrow-right-bold-box-outline"
                  prev-icon="mdi-arrow-left-bold-box-outline"
                  show-arrows
                  center-active
                  background-color="orange accent-1"
                  color="black"
                  class="rounded-lg"
                  light
                >
                  <v-tab @click="showsearch = false">اضافة خدمة</v-tab>
                  <v-tab @click="showsearch = true">البحث</v-tab>
                </v-tabs>
              </div>

              <div>
                <div v-if="showsearch">
                  <v-row class="mb-6" no-gutters>
                    <v-col>
                      <v-card class="pa-4" outlined tile>
                        <v-row class="rounded-lg mb-3" no-gutters>
                          <ShipmentSearch></ShipmentSearch>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="!showsearch">
                  <v-card>
                    <v-form v-model="valid" ref="addTripForm">
                      <v-card-text>
                        <v-container>
                          <v-row class="mb-6" no-gutters>
                            <v-col>
                              <v-card class="pa-4" outlined tile>
                                <v-row class="rounded-lg mb-3" no-gutters>
                                  <v-col cols="12" sm="6">
                                    <v-radio-group
                                      row
                                      v-model="selected.ticketType"
                                    >
                                      <a>الحالة</a>
                                      <v-radio
                                        :disabled="showInfo"
                                        v-for="(n, i) in type"
                                        mandtory
                                        :key="i"
                                        :label="`${n.text}`"
                                        :value="n.value"
                                      ></v-radio>
                                    </v-radio-group>
                                    <v-radio-group
                                      row
                                      v-model="selected.ticketClientType"
                                    >
                                      <a>نوع العميل</a>
                                      <v-radio
                                        :disabled="showInfo"
                                        v-for="(n, i) in clienttype"
                                        mandtory
                                        :key="i"
                                        :label="`${n.text}`"
                                        :value="n.value"
                                      ></v-radio>
                                    </v-radio-group>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <DatePicker-layout
                                      @myEvent="selected.date = $event"
                                      :disabled="showInfo"
                                      :date="selected.date | ToDate"
                                      :rules="[allRules.required]"
                                      label="التاريخ"
                                    ></DatePicker-layout>
                                    <TimePicker-layout
                                      @myEventtime="selected.time = $event"
                                      :disabled="showInfo"
                                      :time="selected.time"
                                      :rules="[allRules.required]"
                                      label="وقت الاتصال"
                                    ></TimePicker-layout>
                                  </v-col>
                                </v-row>
                                <v-row class="rounded-lg mb-3" dark dense>
                                  <v-col cols="12" sm="6">
                                    <v-text-field
                                      hide-details
                                      dense
                                      outlined
                                      v-model="options.trackingNumber"
                                      label="اسم المتصل"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-text-field
                                      hide-details
                                      dense
                                      outlined
                                      v-model="options.trackingNumber"
                                      label="اسم الشركة"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-text-field
                                      hide-details
                                      dense
                                      outlined
                                      v-model="options.trackingNumber"
                                      label="رقم الجوال"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-text-field
                                      hide-details
                                      dense
                                      outlined
                                      v-model="options.trackingNumber"
                                      label="رقم الهاتف"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row class="rounded-lg mb-3" dark dense>
                                  <v-col cols="12" sm="12">
                                    <v-textarea
                                      outlined
                                      name="notice"
                                      label="البيان"
                                      rows="1"
                                      row-height="10"
                                      v-model="selected.notice"
                                    ></v-textarea>
                                  </v-col>
                                </v-row>
                                <v-radio-group
                                  row
                                  v-model="selected.ticketServiceType"
                                >
                                  <a>نوع الخدمة</a>
                                  <v-radio
                                    :disabled="showInfo"
                                    v-for="(n, i) in servicetype"
                                    mandtory
                                    :key="i"
                                    :label="`${n.text}`"
                                    :value="n.value"
                                  ></v-radio>
                                </v-radio-group>
                                <v-row class="rounded-lg mb-3" dark dense>
                                  <v-col cols="12" sm="4">
                                    <v-text-field
                                      hide-details
                                      dense
                                      outlined
                                      v-model="options.trackingNumber"
                                      label="مستلم الخدمة"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="4">
                                    <DatePicker-layout
                                      @myEvent="selected.date = $event"
                                      :disabled="showInfo"
                                      :date="selected.date | ToDate"
                                      :rules="[allRules.required]"
                                      label="تاريخ الأستلام"
                                    ></DatePicker-layout>
                                  </v-col>
                                  <v-col cols="12" sm="4">
                                    <TimePicker-layout
                                      @myEventtime="selected.time = $event"
                                      :disabled="showInfo"
                                      :time="selected.time"
                                      :rules="[allRules.required]"
                                      label="وقت الأستلام"
                                    ></TimePicker-layout>
                                  </v-col>
                                </v-row>
                                <v-row class="rounded-lg mb-3" dark dense>
                                  <v-col cols="12" sm="4">
                                    <v-text-field
                                      hide-details
                                      dense
                                      outlined
                                      v-model="options.trackingNumber"
                                      label="منفذ الخدمة"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="4">
                                    <DatePicker-layout
                                      @myEvent="selected.date = $event"
                                      :disabled="showInfo"
                                      :date="selected.date | ToDate"
                                      :rules="[allRules.required]"
                                      label="تاريخ التنفيذ"
                                    ></DatePicker-layout>
                                  </v-col>
                                  <v-col cols="12" sm="4">
                                    <TimePicker-layout
                                      @myEventtime="selected.time = $event"
                                      :disabled="showInfo"
                                      :time="selected.time"
                                      :rules="[allRules.required]"
                                      label="وقت التنفيذ"
                                    ></TimePicker-layout>
                                  </v-col>
                                </v-row>
                                <v-row class="rounded-lg mb-3" dark dense>
                                  <v-col cols="12" sm="12">
                                    <v-textarea
                                      outlined
                                      name="notice"
                                      label="الملاحظات"
                                      rows="1"
                                      row-height="10"
                                      v-model="selected.notice"
                                    ></v-textarea>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                            <v-col>
                              <v-card class="pa-4" outlined tile>
                                <div
                                  v-if="selected.ticketServiceType == 2"
                                  style="
                                    text-align: center;
                                    font-weight: bolder;
                                    font-size: x-large;
                                    color: #48bf48;
                                  "
                                >
                                  <a>طلب خدمة</a>
                                  <hr />
                                  <v-row class="rounded-lg mb-3" dark dense>
                                    <v-col cols="12" sm="8">
                                      <v-autocomplete
                                        :rules="[allRules.required]"
                                        dense
                                        :disabled="showInfo"
                                        outlined
                                        label="الشاحنة"
                                        item-value="id"
                                        item-text="name"
                                        auto-select-first
                                        v-model="selected.lineId"
                                      >
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                      <v-autocomplete
                                        :rules="[allRules.required]"
                                        dense
                                        :disabled="showInfo"
                                        outlined
                                        label="نوع النشاط"
                                        item-value="id"
                                        item-text="name"
                                        auto-select-first
                                        v-model="selected.lineId"
                                      >
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="8">
                                      <v-autocomplete
                                        :rules="[allRules.required]"
                                        dense
                                        :disabled="showInfo"
                                        outlined
                                        label="المسار"
                                        item-value="id"
                                        item-text="name"
                                        auto-select-first
                                        v-model="selected.lineId"
                                      >
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                      <v-text-field
                                        hide-details
                                        dense
                                        outlined
                                        v-model="options.trackingNumber"
                                        label="السعر"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </div>
                                <div
                                  v-if="selected.ticketServiceType == 4"
                                  style="
                                    text-align: center;
                                    font-weight: bolder;
                                    font-size: x-large;
                                    color: blue;
                                  "
                                >
                                  <a>تسويق</a>
                                  <hr />
                                  <v-row class="rounded-lg mb-3" dark dense>
                                    <v-col cols="12" sm="6">
                                      <v-text-field
                                        hide-details
                                        dense
                                        outlined
                                        v-model="options.trackingNumber"
                                        label="المنشأة"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                      <v-text-field
                                        hide-details
                                        dense
                                        outlined
                                        v-model="options.trackingNumber"
                                        label="مسؤل الأتصال"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                      <v-text-field
                                        hide-details
                                        dense
                                        outlined
                                        v-model="options.trackingNumber"
                                        label="نوع النشاط"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                      <DatePicker-layout
                                        @myEvent="selected.date = $event"
                                        :disabled="showInfo"
                                        :date="selected.date | ToDate"
                                        :rules="[allRules.required]"
                                        label="تاريخ الزيارة"
                                      ></DatePicker-layout
                                    ></v-col>
                                    <v-col cols="12" sm="6">
                                      <v-text-field
                                        hide-details
                                        dense
                                        outlined
                                        v-model="options.trackingNumber"
                                        label="الجوال"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                      <v-text-field
                                        hide-details
                                        dense
                                        outlined
                                        v-model="options.trackingNumber"
                                        label="الهاتف"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                      <v-text-field
                                        hide-details
                                        dense
                                        outlined
                                        v-model="options.trackingNumber"
                                        label="البريد الألكترونى"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </div>
                                <div
                                  v-if="selected.ticketServiceType == 3"
                                  style="
                                    text-align: center;
                                    font-weight: bolder;
                                    font-size: x-large;
                                    color: red;
                                  "
                                >
                                  <a>شكوى</a>
                                  <hr />
                                  <v-row class="rounded-lg mb-3" dark dense>
                                    <v-col cols="12" sm="12">
                                      <v-radio-group
                                        row
                                        v-model="selected.ticketComplaintType"
                                      >
                                        <a>نوع الشكوى</a>
                                        <v-radio
                                          :disabled="showInfo"
                                          v-for="(n, i) in complainttype"
                                          mandtory
                                          :key="i"
                                          :label="`${n.text}`"
                                          :value="n.value"
                                        ></v-radio>
                                      </v-radio-group>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                      <v-text-field
                                        hide-details
                                        dense
                                        outlined
                                        v-model="options.trackingNumber"
                                        label="رقم البوليصة"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                      <v-text-field
                                        disabled
                                        hide-details
                                        dense
                                        outlined
                                        v-model="options.trackingNumber"
                                        label="رقم الرحلة"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                      <v-text-field
                                        disabled
                                        hide-details
                                        dense
                                        outlined
                                        v-model="options.trackingNumber"
                                        label="الحالة"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                      <v-text-field
                                        disabled
                                        hide-details
                                        dense
                                        outlined
                                        v-model="options.trackingNumber"
                                        label="المسار"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                      <DatePicker-layout
                                        @myEvent="selected.date = $event"
                                        :disabled="true"
                                        :date="selected.date | ToDate"
                                        :rules="[allRules.required]"
                                        label="تاريخ الشحن"
                                      ></DatePicker-layout
                                    ></v-col>
                                    <v-col cols="12" sm="3">
                                      <DatePicker-layout
                                        @myEvent="selected.date = $event"
                                        :disabled="true"
                                        :date="selected.date | ToDate"
                                        :rules="[allRules.required]"
                                        label="تاريخ التسليم"
                                      ></DatePicker-layout
                                    ></v-col>
                                    <v-col cols="12" sm="6">
                                      <v-text-field
                                        disabled
                                        hide-details
                                        dense
                                        outlined
                                        v-model="options.trackingNumber"
                                        label="اسم المرسل"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                      <v-text-field
                                        disabled
                                        hide-details
                                        dense
                                        outlined
                                        v-model="options.trackingNumber"
                                        label="اسم المستلم"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                      <v-text-field
                                        disabled
                                        hide-details
                                        dense
                                        outlined
                                        v-model="options.trackingNumber"
                                        label="عدد الطرود"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                      <v-text-field
                                        disabled
                                        hide-details
                                        dense
                                        outlined
                                        v-model="options.trackingNumber"
                                        label="الصنف"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                      <v-text-field
                                        disabled
                                        hide-details
                                        dense
                                        outlined
                                        v-model="options.trackingNumber"
                                        label="قيمة البوليصة"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </div>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-form>
                  </v-card>
                </div>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              v-if="!showInfo"
              :disabled="!valid"
              @click="editConfirmed()"
            >
              حفظ
            </v-btn>

            <v-btn color="red darken-1" text @click="cancelEdit()">
              إلغاء
            </v-btn>
          </v-card-actions></v-card
        >
      </div>
    </v-dialog>
    <!-- Add Modal -->
  </div>
</template>

<script>
import ShipmentService from "../services/ShipmentService.js";
import Enums from "../../../plugins/enums";

export default {
  data() {
    return {
      valid: false,
      showsearch: false,
      selected: {
        state: "",
        time: this.formateDateWithMoment(Date.now(), "hh:mm"),
        date: this.formatDate(Date.now()),
      },
      clienttype: Enums.TicketsClientType,
      type: Enums.TicketsType,
      servicetype: Enums.TicketsServiceType,
      complainttype: Enums.TicketsComplaintType,
      showInfo: false,
      entities: [],
      total: 0,
      search: "",
      dialogTitle: "",
      options: {
        date: null,
        callername: null,
        ticketnumber: null,
      },
      dialog: false,
      loadingStates: {
        table: false,
      },
      headers: [
        {
          text: "رقم الخدمة",
          align: "start",
          sortable: false,
          value: "ticketnumber",
        },
        { text: "تاريخ الاستلام", value: "date" },
        { text: "اسم المتصل", value: "callername" },
        { text: "نوع الخدمة", value: "type" },
        { text: "اسم المستلم", value: "type" },
        { text: "اسم المنفذ", value: "type" },
        { text: "ناريخ التنفيذ", value: "datef" },
        { text: "الحالة", value: "state" },
        { text: "مسح / تعديل", value: "actions", align: "center" },
      ],
    };
  },
  created() {
    this.getData();
    //LinesService.typeHead("", true).then((resp) => {
    //  this.lines = resp.data;
    // });
  },

  methods: {
    resetOptions() {
      this.options = {
        date: null,
        callername: null,
        ticketnumber: null,
      };
    },
    view(item) {
      this.showInfo = true;
      this.selected = { ...this.entities.filter((e) => e.id == item.id)[0] };
      this.dialog = true;
      this.dialogTitle = "عرض تفاصيل الأمر";
    },
    changeMovement(item, movement) {
      ApiService.updateMovement(item.id, movement)
        .then((resp) => {
          item.movementState = movement;
        })
        .catch((resp) => {})
        .finally(() => {});
    },
    getColor(item) {
      switch (item.movementState) {
        case 1:
          return "blue";
        case 2:
          return "green";
        default:
          return "red";
      }
    },
    getData() {},
    editConfirmed() {},

    cancelEdit() {
      this.showInfo = false;
      this.dialog = false;
      this.selected = {};
    },
    add() {
      this.selected = {
        state: "",
        time: this.formateDateWithMoment(Date.now(), "hh:mm"),
        date: this.formatDate(Date.now()),
      };
      console.log(this.selected);
      this.dialog = true;
      this.selected.state = 1;
      this.dialogTitle = "اضافه";
    },
    edit(id) {
      this.selected = { ...this.entities.filter((e) => e.id == id)[0] };
      this.dialog = true;
      this.dialogTitle = "تعديل";
    },
    del(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف الخدمة",
          title: "  حذف خدمة  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {},
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => {});
    },
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.hiddenradio [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}

.hiddenradio [type="radio"] + div {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
/* CHECKED STYLES */
.hiddenradio [type="radio"]:checked + div {
  outline: 2px solid #42ac0d;
  outline-style: dashed;
}
.text-end {
  display: inline-table !important;
}
</style>