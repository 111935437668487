import Api from '../../shared/services/api'

const baseUrl = "BranchDailyReport";
export default {
    query(options) {
        const qParams = new URLSearchParams(options);
        return Api().get(baseUrl + "?" + qParams.toString());
    },
    getbyReportId(id) {
        return Api().get(`${baseUrl}/${id}`);
    },
    add(entity) {
        return Api().post(baseUrl, entity);
    },
}