<template>
  <v-app>
    <div v-can="'Shipments.View'">
      <v-card width="99%" class="mx-auto mt-3 pa-5">
        <v-row class="pa-2" dense>
          <v-col cols="12" sm="5">
            <v-row class="rounded-lg mb-3" dark dense>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense outlined type="number" v-model="options.trackingNumber"
                  label="رقم السيستم"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense outlined type="number" v-model="options.bookNumber"
                  label="رقم الدفتر"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <DatePicker-layout @myEvent="options.date = $event" :date.sync="options.date"
                  label="التاريخ "></DatePicker-layout>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete dense outlined hide-details v-if="!loading" auto-select-first
                  v-model="options.distributorId" :items="distributers" item-text="name" item-value="id"
                  label="الموزع"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete dense outlined hide-details v-if="!loading" v-model="options.salesmanId"
                  :items="salesmen" item-text="name" item-value="id" label="المندوب"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete dense outlined hide-details v-if="!loading" v-model="options.externalShipperId"
                  :items="externalShippers" item-text="name" item-value="id" label="المخرج"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense v-model="options.manualNo" outlined type="number"
                  label="الرقم اليدوي"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select dense hide-details v-model="options.state" outlined :items="enums.ShipmentStates"
                  item-text="text" item-value="value" label="الحالة"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete dense hide-details v-model="options.paymentType" outlined :items="enums.PaymentTypes"
                  item-text="text" item-value="value" label="نوع السداد"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete dense v-if="!loading" v-model="options.employeeId" outlined :items="avalibaleUsers"
                  item-text="name" item-value="id" label="الموظف"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete dense v-if="!loading" v-model="options.branchId" outlined :items="branchs"
                  item-text="name" item-value="id" label="الفرع"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <DatePicker-layout @myEvent="options.updateDate = $event" :date.sync="options.updateDate"
                  label="تاريخ التعديل "></DatePicker-layout>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  hide-details
                  dense
                  outlined
                  type="number"
                  v-model="options.tripTrackingNumber"
                  label="رقم الرحله"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete dense hide-details v-model="options.paymentPaidType" outlined :items="enums.PaymentPaidTypes"
                  item-text="text" item-value="value" label="طريقة الدفع"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <v-checkbox v-model="options.cashPaid" label="خالص الأجرة" color="green darken-3"></v-checkbox>
              </v-col>

            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="mr-10">
            <v-row class="rounded-lg" dark dense>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense outlined v-model="options.sender" label="المرسل"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense outlined v-model="options.receiver" label="المستلم"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense outlined v-model="options.senderPhoneNumber"
                  label="هاتف المرسل"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense outlined v-model="options.receiverPhoneNumber"
                  label="هاتف المستلم"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-show="!loading">
                <v-autocomplete dense outlined hide-details v-if="!loading" auto-select-first label="المسار"
                  :items="lines" item-value="id" item-text="name" v-model="options.lineId"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" v-show="!loading">
                <v-autocomplete dense v-if="!loading" outlined hide-details label="الخدمه" :items="servicesTypeList"
                  item-value="id" item-text="name" auto-select-first v-model="options.serviceTypeId"></v-autocomplete>
              </v-col>
             
              <v-col cols="12" sm="6">
                <DatePicker-layout @myEvent="options.startDate = $event" :date.sync="options.startDate"
                  label="التاريخ من "></DatePicker-layout>
              </v-col>
              <v-col cols="12" sm="6">
                <DatePicker-layout @myEvent="options.endDate = $event" :date.sync="options.endDate"
                  label="التاريخ الى "></DatePicker-layout>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense outlined type="number" v-model="options.manualNoFrom"
                  label="يدوي من"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense outlined type="number" v-model="options.manualNoTo"
                  label="الي"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-checkbox v-model="options.hasOtherServicesPrice" label="التغليف" color="green darken-3"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3">
                <v-checkbox v-model="options.otherServicesPriceReceived" label="التغليف المسدد"
                  color="green darken-3"></v-checkbox>
              </v-col>

              <v-col cols="12" sm="3">
                <v-checkbox v-model="options.hasDuePrice" label="المستردات" color="green darken-3"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3">
                <v-checkbox v-model="options.duePriceReceived" label="المستردات المسددة"
                  color="green darken-3"></v-checkbox>
              </v-col>
              <v-col cols="2">
            <v-checkbox v-model="options.IsInventoried" label="لم يتم جردة" color="green darken-3"></v-checkbox>
          </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8" class="mt-0">
            <!-- <v-card-actions> -->
            <v-btn @click="searchForPolisa" class="mx-3" color="success">
              بحث
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn class="mx-3" color="error" @click="resetOptions">
              تفريغ
              <i class="fa fa-trash fa-lg"></i>
            </v-btn>
            <!-- <v-btn class='mx-6'>فاتورة </v-btn> -->
            <v-btn v-can="'Shipments.Print'" @click="goToPrint" color="info" class="mx-3">
              طباعة
              <v-icon>mdi-printer</v-icon>
            </v-btn>
            <v-btn @click="dialogTaxNum = true" color="orange" class="mx-3">
              تعديل الرقم الضريبي
              <v-icon>mdi-pen</v-icon>
            </v-btn>
            <v-btn @click="dialogPaidType = true" color="orange" class="mx-3">
              تعديل طريقه السداد 
              <v-icon>mdi-pen</v-icon>
            </v-btn>
            <!-- </v-card-actions> -->
          </v-col>
        </v-row>
        <h5 v-html="'عدد البوالص (' + total + ')'"></h5>
        <v-row>
          <v-col cols="6">
            <v-radio-group v-model="options.clientType" row mandatory>
              <v-radio label="جميع البوالص" :value="-1"></v-radio>
              <v-radio :value="1" label="بوالص عملاء"></v-radio>
              <v-radio :value="2" label="بوالص فردي "></v-radio>
            </v-radio-group>
          </v-col>

        </v-row>
      </v-card>
      <div>
        <v-card>
          <ShipmentsTable v-model="entities" :options="options" :total="total" @options="optionsUpdated" />
        </v-card>
      </div>
    </div>
    <v-dialog v-model="dialogTaxNum" width="400" persistent>
      <v-card>
        <v-form v-model="valid">
          <v-card-title class="headline yellow black--text text-capitalize text-center lighten-2">اضافة/تعديل الرقم
            الضريبي</v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="mb-3" dense>
                <v-col cols="12" sm="12">
                  <v-text-field :rules="[allRules.required]" hide-details dense outlined type="number" v-model="options.trackingNumber"
                    label="رقم السيستم"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field :rules="[allRules.required]" hide-details dense outlined type="number" v-model="options.taxNum"
                    label="الرقم الضريبي"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="pink" @click="dialogTaxNum = false">الغاء</v-btn>
            <v-btn text color="success" :disabled="!valid || submitted" @click="updateTaxNum">تاكيد</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPaidType" width="400" persistent>
      <v-card>
        <v-form v-model="valid">
          <v-card-title class="headline yellow black--text text-capitalize text-center lighten-2">تعديل طريقه السداد</v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="mb-3" dense>
                <v-col cols="12" sm="12">
                  <v-text-field :rules="[allRules.required]" hide-details dense outlined type="number" v-model="options.trackingNumber"
                    label="رقم السيستم"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-btn-toggle v-model="options.paymentPaidType" group color="success">
                        <v-btn x-large elevation="7" value="2">
                          كاش
                        </v-btn>
                        <v-btn x-large elevation="7" value="1">
                          شبكة
                        </v-btn>
                        <v-btn x-large elevation="7" value="3">
                          تحويل بنكي
                        </v-btn>
                        <v-btn x-large elevation="7" value="4">
                          تحت التحصيل
                        </v-btn>
                      </v-btn-toggle>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="pink" @click="dialogPaidType = false">الغاء</v-btn>
            <v-btn text color="success" :disabled="!valid || options.paymentPaidType == null" @click="updatePaidType">تاكيد</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import lineApi from "../services/LinesService.js";
import servicesTypesApi from "../../Settings/services/ServiceTypesService";
import ShipmentService from "../services/ShipmentService";
import EmployeeService from "../../System/services/EmployeeService";
import DistributersService from "../services/DistributersService";
import SalesmenService from "../services/SalesmenService";
import ExternalShippersService from "../services/ExternalShippersService";
import Enums from "@/plugins/enums.js";
export default {
  data() {
    return {
      avalibaleUsers: [
        {
          name: "admin",
          id: 1
        }
      ],
      distributers: [
        {
          name: "admin",
          id: 1
        }
      ],
      salesmen: [
        {
          name: "admin",
          id: 1
        }
      ],
      externalShippers: [
        {
          name: "admin",
          id: 1
        }
      ],
      loading: false,
      valid: false,
      payments: [
        {
          name: "نقدا",
          id: 0
        },
        {
          name: "الدفع عند الاستلام",
          id: 1
        }
      ],
      branchs: [
        {
          name: "جدة",
          id: 11
        },
        {
          name: "جيزان",
          id: 9
        },
        {
          name: "ابوعريش",
          id: 10
        },
        {
          name: "صبيا",
          id: 8
        },
        {
          name: "القنفذة",
          id: 21
        }
      ],
      search: "",
      total: 10,
      enums: Enums,
      dialogTaxNum: false,
      dialogPaidType: false,
      options: {
        trackingNumber: null,
        tripTrackingNumber: null,
        taxNum: null,
        sender: null,
        clientType: 0,
        receiver: null,
        senderPhoneNumber: null,
        receiverPhoneNumber: null,
        serviceTypeId: null,
        lineId: null,
        employeeId: null,
        paymentType: null,
        paymentPaidType: null,
        state: null,
        distributorId: null,
        externalShipperId: null,
        salesmanId: null,
        date: null,
        updateDate: null,
        state: -1,
        startDate: null,
        endDate: null,
        manualNoFrom: null,
        manualNoTo: null,
        manualNo: null,
        bookNumber: null,
        duePriceReceived: null,
        hasDuePrice: null,
        otherServicesPriceReceived: null,
        hasOtherServicesPrice: null,
        deliveryDate: null,
        cashPaid: null,
        branchId: null,
        IsInventoried: null
      },
      entities: [],
      loadingStates: {
        table: false
      },

      lines: [],
      servicesTypeList: []
    };
  },
  async created() {
    try {
      //const line = await lineApi.query(this.options);
      const servicesType = await servicesTypesApi.get();
      this.servicesTypeList = servicesType.data;
      lineApi.typeHead("", true, false, false).then(resp => {
        this.lines = resp.data;
      });
      EmployeeService.typeHead("", true).then(resp => {
        this.avalibaleUsers = resp.data;
      });
      DistributersService.typeHead("", true).then(resp => {
        this.distributers = resp.data;
      });
      SalesmenService.typeHead("", true).then(resp => {
        this.salesmen = resp.data;
      });
      ExternalShippersService.typeHead("", true).then(resp => {
        this.externalShippers = resp.data;
      });
    } catch (error) {
      this.loading = false;
      console.log(error);
    }
  },
  methods: {
    searchForPolisa() {
      this.getData();
    },
    goToPrint() {
      let routeData = this.$router.resolve({
        name: "ShipmentsReport",
        query: {
          options: JSON.stringify(this.$clean(this.options)),
          branch: false
        }
      });
      window.open(routeData.href, "_blank");
    },
    resetOptions() {
      this.options = {
        trackingNumber: null,
        tripTrackingNumber: null,
        taxNum: null,
        clientType: 0,
        sender: null,
        receiver: null,
        senderPhoneNumber: null,
        receiverPhoneNumber: null,
        serviceTypeId: null,
        lineId: null,
        employeeId: null,
        paymentType: null,
        paymentPaidType: null,
        state: null,
        distributorId: null,
        externalShipperId: null,
        salesmanId: null,
        date: null,
        updateDate: null,
        state: -1,
        startDate: null,
        endDate: null,
        manualNoFrom: null,
        manualNoTo: null,
        manualNo: null,
        bookNumber: null,
        duePriceReceived: null,
        hasDuePrice: null,
        otherServicesPriceReceived: null,
        hasOtherServicesPrice: null,
        deliveryDate: null,
        cashPaid: null,
        branchId: null,
        IsInventoried: null
      };
    },
    optionsUpdated(opts) {
      this.options = { ...this.options, ...opts };
    },
    updatePaidType() {
      ShipmentService.updatePaidType(this.options.trackingNumber, this.options.paymentPaidType)
        .then(resp => {
          this.$dialog.notify.success("تم تعديل طريقه السداد  بنجاح", {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch(err => {
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
      this.dialogPaidType = false;
      this.resetOptions();
    },
    updateTaxNum() {
      ShipmentService.updateTaxNum(this.options.trackingNumber, this.options.taxNum)
        .then(resp => {
          this.$dialog.notify.success("تم تعديل الرقم الضريبي بنجاح", {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch(err => {
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
      this.dialogTaxNum = false;
      this.resetOptions();
    },
    getData() {
      this.loadingStates.table = true;
      ShipmentService.query(this.$clean(this.options))
        .then(resp => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch(err => {
          this.loadingStates.table = false;
        });
    }
  },
  watch: {
    options: {
      handler() {
        this.getData();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
</style>
