<template>
  <v-app>
    <v-container>
      <!-- tabs to the search -->
      <div class="text-center green--text">
        <v-tabs fixed-tabs next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows center-active background-color="orange accent-1" color="black" class="rounded-lg" light>
          <v-tab @click="showaddpolicy = true; showsearch = false; showclients = false;">اضافة بوليصه</v-tab>
          <v-tab @click="showsearch = true; showaddpolicy = false; showclients = false;">بحث سجلات البوليصة</v-tab>
          <v-tab @click="showclients = true; showsearch = false; showaddpolicy = false;">بحث سجلات العملاء</v-tab>
        </v-tabs>
      </div>
      <div>
        <div v-if="showclients">
          <ClientsSearch></ClientsSearch>
        </div>
        <div v-if="showsearch">
          <ShipmentSearch></ShipmentSearch>
        </div>
        <div v-can="'Shipments.Create'" v-if="showaddpolicy">
          <!-- add new one  -->
          <v-card width="95%" class="ma-2 pa-3">
            <AddShipment v-model="selected" :editMode="editMode" ref="addShipmentComp" @valid="valid = $event" />
          </v-card>

          <v-row class="justify-content-end m-3 ml-5">
            <v-col class="cols" sm="2">
              <v-btn :disabled="!valid" v-can="'Shipments.Create'" color="primary" @click="addShipment()"
                :loading="loadings.addShipments">حفظ</v-btn>
            </v-col>
          </v-row>

          <!-- edit shipment  model -->

          <!-- <v-row class="pr-5 pb-3 mt-5">
                    <v-col class="d-flex justify-content-start">
                        <v-btn color="success" @click="addBulk()">
                            حفظ البوالص
                            <i class="fa fa-plus mr-2"></i>
                        </v-btn>
                    </v-col>
                </v-row> -->
          <v-row>
            <v-col>
              <ShipmentsTable v-model="entities" />
            </v-col>
          </v-row>
        </div>
      </div>
      <v-dialog v-model="addshipment" persistent width="300" lazy>
        <v-card>
          <v-card-title class="headline">
            <i class="fa fa-plus ml-2"></i> جارى اضافة البوليصة
          </v-card-title>
          <v-card-text>
            <v-progress-linear indeterminate color="primary" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogprint" width="25%" persistent>
        <v-card>
          <v-card-text>
            <div class="text-center">
              <h2>طباعه البوليصة</h2>
              <v-row>
                <v-col cols="12" sm="6">
                  <div>
                    <div>
                      مع السعر
                    </div>
                    <div>
                      <span>
                        <v-btn icon @click="print(true)">
                          <v-icon large color="error">mdi-printer</v-icon>
                        </v-btn>
                      </span>
                    </div>
                  </div>

                </v-col>
                <v-col cols="12" sm="6">
                  <div>
                    <div>
                      بدون السعر
                    </div>
                    <div>
                      <span>
                        <v-btn icon @click="print(false)">
                          <v-icon large color="error">mdi-printer</v-icon>
                        </v-btn>
                      </span>
                    </div>
                  </div>

                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="cancelEdit()">إلغاء</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import ApiService from "../services/ShipmentService";
import Localbase from "localbase";

export default {
  name: "Shipment",
  mounted() { },
  data() {
    return {
      loadings: {
        table: false,
      },
      addshipment: false,
      dialogprint: false,
      shipmentid: null,
      submitted: false,
      valid: false,
      showsearch: false,
      showclients: false,
      showaddpolicy: true,
      allIncludeTax: false,
      linesPricing: false,
      dialog: false,
      selected: ApiService.getDefault(),
      message: "",
      entities: [],
      total: 10,
      search: "",
      options: {},
      editMode: false,
    };
  },
  computed: {},
  created() {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        if (!this.valid || this.addshipment == true)
          return;
        this.addShipment();
      }
    });
  },
  methods: {
    print(price) {
      if (price) {
        let routeData = this.$router.resolve({
          name: "ShipmentReceipt",
          query: { shipmentid: this.shipmentid },
        });
        window.open(routeData.href, "_blank");
      }
      else {
        let routeDatano = this.$router.resolve({
          name: "ShipmentReceiptnoprice",
          query: { shipmentid: this.shipmentid },
        });
        window.open(routeDatano.href, "_blank");
      }
    },
    shipmentsTableOptionsUpdated(options) { },
    cancelEdit() {
      this.dialogprint = false;
      this.shipmentid = null;
      this.dialog = false;
      this.editMode = false;
    },
    prepareToEdit(item) {
      this.selected = null;
      this.selected = {
        ...item,
      };
    },
    addShipment() {
      if (!this.submitted) {
        this.$refs.addShipmentComp.validate();
        if (!this.valid)
          return;
        this.loadings.addShipment = true;
        this.submitted = true;
        this.addshipment = true;
        var newShipment = ApiService.newShipment(this.selected);
        ApiService.edit(newShipment)
          .then((resp) => {
            var message = "تم تعديل البوليصة بنجاح";
            if (!this.selected.id) {
              if (this.entities.length == 0)
                this.entities = [resp.data];
              else
                this.addToArr(this.entities, resp.data);
              message = "تم إضافة البوليصة بنجاح";
              if (this.$refs.addShipmentComp.autodataentry == true) {
                this.shipmentid = resp.data.id;
                this.dialogprint = true;
              }
            }
            else {
              this.updateArr(this.entities, resp.data);
            }
            this.resetModel();
            this.$dialog.notify.success(message, {
              position: "top-right",
              timeout: 3000,
            });
            this.addshipment = false;
            this.submitted = false;
          })
          .catch((err) => {
            console.log(err.request?.response);
            var message = err.request.response;
            this.$dialog.notify.error(message, {
              position: "top-right",
              timeout: 3000,
            });
            this.submitted = false;
            this.addshipment = false;
          })
          .finally(() => {
            this.submitted = false;
            this.addshipment = false;
          });
      }
      // if (newShipment.id) {
      //     db.collection("shipments")
      //         .doc({
      //             id: newShipment.id
      //         })
      //         .update(newShipment)
      //         .then((response) => {
      //             this.shipmentsUpdated();
      //             this.message = "تم تعديل العنصر بنجاح";
      //             this.$dialog.notify.success(this.message, {
      //                 position: "top-right",
      //                 timeout: 3000,
      //             });
      //         })
      //         .catch((error) => {
      //             console.log("There was an error, do something else.");
      //         });
      // } else {
      //     var newId = Date.now()
      //     newShipment = {
      //         ...newShipment,
      //         id: newId,
      //         tempId: newId
      //     };
      //     db.collection("shipments")
      //         .add(newShipment)
      //         .then((response) => {
      //             this.shipmentsUpdated();
      //             this.message = "تم اضافة العنصر بنجاح";
      //             this.$dialog.notify.success(this.message, {
      //                 position: "top-right",
      //                 timeout: 3000,
      //             });
      //         })
      //         .catch((error) => {
      //             console.log("There was an error, do something else.",error);
      //         });
      // }
      this.loadings.addShipment = false;
    },
    resetModel() {
      var nextManualNo = 0;
      if (this.$refs.addShipmentComp.autodataentry == false) {
        nextManualNo = +this.selected.manualNo + 1;
      }
      else {
        nextManualNo = null;
      }
      (this.selected = {
        ...ApiService.getDefault(),
        date: this.selected.date,
        lineId: this.selected.lineId,
        trip: this.clone(this.selected.trip),
        tripId: this.selected.tripId,
        line: this.clone(this.selected.line),
        serviceTypeId: this.selected.serviceTypeId,
        //paymentType: this.selected.paymentType,
      }),
        (this.selected.manualNo = nextManualNo);
      this.$refs.addShipmentComp.reset();
      this.$refs.addShipmentComp.getBookNum(nextManualNo);
    },
    addBulk() {
      var shipments = this.entities.map((e) => ApiService.newShipment(e));
      var shipmentsTotal = shipments.length;
      ApiService.bulk(shipments)
        .then((resp) => {
          var addShipmentsIds = resp.data.addShipmentsIds;
          var message = addShipmentsIds.length == shipmentsTotal
            ? "تم اضافة جميع البوالص بنجاح"
            : `تم اضافة ${addShipmentsIds.length} بوالص بنجاح`;
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          for (let i = 0; i < addShipmentsIds.length; i++) {
            // db.collection('shipments').doc({
            //         id: addShipmentsIds[i]
            //     }).delete()
            //     .then(response => {
            //         this.shipmentsUpdated();
            //     })
            //     .catch(error => {
            //         console.log('There was an error, do something else.')
            //     })
          }
        })
        .catch((resp) => {
          this.$dialog.notify.success("حدث خطأ اثناء إضافة البوالص", {
            position: "top-right",
            timeout: 3000,
          });
        })
        .finally(() => { });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
