<template>
  <v-container v-if="selected">
    <div class="content" v-if="selected" id="reportidnew">
      <v-row class="mt-3" justify="center">
        <span style="
                                          border: 2px solid black;
                                          padding: 0px 100px;
                                          font-size: 36.5307px;
                                        ">{{ this.$store.getters.companyName }}</span>
      </v-row>
      <v-row class="mt-3" justify="center">
        <v-col cols="4" class="pa-2 text-center text-capitalize">
          <div style="font-size: 31.8649px">حركة يومية فرع ( {{ selected.report.branch.name }} )</div>
        </v-col>
      </v-row>
      <v-row class="mt-3" justify="center">
        <v-col cols="4" class="pa-2 text-center text-capitalize">
          <div style="font-size: 19.9622px">#{{ selected.report.id }}</div>
        </v-col>
      </v-row>
      <v-row class="mt-3" justify="start">
        <v-col cols="6" class="text-right">
          <h5 class="larg font-weight-bolder">
            <span class="ml-5">تاريخ الطباعة : {{ Date.now() | ToDate }}</span>
          </h5>
          <h5 class="larg font-weight-bolder">
            <span class="ml-5">التاريخ : {{ selected.report.createDate | ToDate }}</span>
          </h5>
          <h5 class="larg font-weight-bolder">
            <span class="ml-5">اليوم : {{ getDayName(selected.report.createDate) }}</span>
          </h5>
        </v-col>
      </v-row>
      <div class="grid-container justify-center">
        <div class="grid-item">
          <table style="width: 100%">
            <thead>
              <tr>
                <th>المبلغ</th>
                <th>مدين</th>
              </tr>
              <tr>
                <th>{{ selected.report.initialBalance }}</th>
                <th>الرصيد الأفتتاحى</th>
              </tr>
            </thead>
            <tbody >
              <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
              <tr v-for="(item, g) in selected.report.reportTrips" v-if="selected.report.reportTrips.length > 0" :key="g">
                <th>{{ item.amount }}</th>
                <td>رحلة رقم {{ item.trackingNumber }} - {{ item.tripName }}</td>
              </tr>
              <tr>
                <th>{{ selected.report.cashShipmentsBalance }}</th>
                <td>خالص الاجرة</td>
              </tr>
              <tr>
                <th>{{ selected.report.otherIncomeBalance }}</th>
                <td>ايرادات أخري ( كاش )</td>
              </tr>
              <tr>
                <th>{{ selected.report.otherIncomeBalancePos }}</th>
                <td>ايرادات أخري ( شبكة )</td>
              </tr>
              <tr>
                <th>{{ selected.report.otherIncomeBalanceBank }}</th>
                <td>ايرادات أخري ( تحويل بنكي )</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>{{ selected.report.totalDebit }}</th>
                <th>الاجمالى</th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="grid-item">
          <table style="width: 100%">
            <thead>
              <tr>
                <th>المبلغ</th>
                <th>دائن</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{{ selected.report.fundBalance }}</th>
                <td>الصندوق</td>
              </tr>
              <tr>
                <th>{{ selected.report.warehouseBalance }}</th>
                <td>المستودع</td>
              </tr>
              <tr>
                <th>{{ selected.report.collectionBalance }}</th>
                <td>التحصيل</td>
              </tr>
              <tr>
                <th>{{ selected.report.discountsBalance }}</th>
                <td>الخصومات</td>
              </tr>
              <tr>
                <th>{{ selected.report.expensesBalance }}</th>
                <td>المصروفات</td>
              </tr>
              <tr>
                <th>{{ selected.report.tripsRentalBalance }}</th>
                <td>ايجار الرحلات</td>
              </tr>
              <tr>
                <th>{{ selected.report.posBalance }}</th>
                <td>ايداع شبكة</td>
              </tr>
              <tr>
                <th>{{ selected.report.bankTransferBalance }}</th>
                <td>تحويل بنكي</td>
              </tr>
              <tr>
                <th>{{ selected.report.bankDepositBalance }}</th>
                <td>ايداع بنكي ( {{ selected.report.bankDepositName }} )</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>{{ selected.report.totalCredit }}</th>
                <th>الاجمالى</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="grid-container justify-center">
        <div class="grid-item">
          <table style="width: 100%;">
            <thead>
              <tr>
                <th colspan="2">خالص الأجرة</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="total">{{ selected.receiveReceiptsTotal }}</td>
              </tr>
            </tbody>

          </table>
        </div>
        <div class="grid-item">
          <table style="width: 100%;">
            <thead>
              <tr>
                <th colspan="2">اجمالي الخصومات</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="total">{{ selected.discountsTotal }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="grid-item">
          <table style="width: 100%;">
            <thead>
              <tr>
                <th colspan="2">اجمالي المصروفات</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="total">{{ selected.expenseReceiptsTotal }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="grid-container justify-start">
      <div class="grid-item">
        <table style="width: 100%;">
          <thead>
            <tr>
              <th colspan="2">المصروفات</th>
            </tr>
            <tr>
              <th colspan="1">المبلغ</th>
              <th colspan="1">البيان</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, g) in selected.expenseReceipts" :key="g + 1">
              <th>{{ item.amount }}</th>
              <td class="content_td">
                <p>{{ item.description }}</p>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td id="total">{{ selected.expenseReceiptsTotal }}</td>
              <td id="total">الاجمالى</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </v-container>
</template>

<script>
import ApiService from "../../Finances/services/BranchDailyReportService.js";
export default {
  components: {},
  mounted() {
    var id = +this.$route.query["reportid"];
    if (id != 0) this.getDataFromAPI(id);
  },
  data() {
    return {
      selected: {
        expenseReceipts: [],
        receiveReceipts: [],
        shipmentsDiscounts: [],
        report: {
          reportTrips: [],
          branch: {}
        },
      },
    };
  },
  methods: {
    getDayName(dateStr) {
      var date = new Date(dateStr);
      return date.toLocaleDateString("ar-EG", { weekday: 'long' });
    },
    getDataFromAPI(id) {
      ApiService.getbyReportId(id)
        .then((resp) => {
          this.selected = resp.data;
          setTimeout(() => {
            window.focus();
            var css = "@page { size: portrait; }",
              head = document.head || document.getElementsByTagName("head")[0],
              style = document.createElement("style");
            style.type = "text/css";
            style.media = "print";
            if (style.styleSheet) {
              style.styleSheet.cssText = css;
            } else {
              style.appendChild(document.createTextNode(css));
            }
            head.appendChild(style);
            document.title = " حركة يومية فرع " + this.selected.report.branch.name + " بتاريخ " + this.formateDateWithMoment(this.selected.report.createDate, "YYYY/MM/DD");
            window.print();
          }, 100);
        })
        .catch((resp) => { })
        .finally(() => { });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari */
  color-adjust: exact !important;
  /*Firefox*/
}

.content {
  font-weight: bolder !important;
}

@media print {
  body {
    width: 21cm;
    height: 29.7cm;
    margin: 30mm 45mm 30mm 45mm;
  }
}


.content_td p {
  max-width: 100%;
  max-height: 100px;

  text-overflow: ellipsis;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}

.grid-item {
  font-size: 25px;
  text-align: right;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

th,
td {
  padding: 5px;
  text-align: right;
  font-weight: bolder;
}

th {
  text-align: center;
}

#total {
  text-align: center;
}

.hiddenradio [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}

.hiddenradio [type="radio"]+div {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

/* CHECKED STYLES */
.hiddenradio [type="radio"]:checked+div {
  outline: 2px solid #42ac0d;
  outline-style: dashed;
}

.text-end {
  display: inline-table !important;
}
</style>
