<template>
  <div>
    <div class="row justify-content-right">
      <v-col cols="9">
        <v-btn elevation="2" color="success" @click="getData">
          بحث <i class="fas fa-search fa-lg"></i>
        </v-btn>
      </v-col>
      <v-col cols="1">
        <v-btn elevation="2" color="error" @click="resetOptions">
          تفريغ <i class="fa fa-trash fa-lg"></i>
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn elevation="2" color="info" @click="goToPrint">
          طباعة <v-icon>mdi-printer</v-icon>
        </v-btn>
      </v-col>
    </div>
    <v-row class="pr-5 pb-3 mt-3">
      <v-col cols="1" sm="4">
        <v-autocomplete
          label="اسم التقرير"
          dense
          outlined
          required
          :items="filter"
          item-value="id"
          item-text="name"
          v-model="reportname"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col sm="2" v-if="reportname == 8">
        <v-text-field
          hide-details
          dense
          outlined
          type="number"
          v-model="options.trackingNumber"
          label="رقم الرحلة"
        ></v-text-field>
      </v-col>
      <v-col sm="2" v-if="reportname == 1">
        <DatePicker-layout
          @myEvent="options.date = $event"
          :date.sync="options.date"
          label="التاريخ"
          clearable
        >
        </DatePicker-layout>
      </v-col>
      <v-col cols="3" v-if="reportname == 1">
        <v-autocomplete
          dense
          outlined
          hide-details
          label="حركة الرحلات"
          :items="reporttype"
          item-value="id"
          item-text="name"
          v-model="options.reportTypeId"
        ></v-autocomplete
      ></v-col>
      <v-col
        sm="3"
        v-if="reportname == 3 || reportname == 4 || reportname == 6"
      >
        <v-autocomplete
          dense
          outlined
          hide-details
          auto-select-first
          label="المسار"
          :items="lines"
          item-value="id"
          item-text="name"
          v-model="options.lineId"
        ></v-autocomplete>
      </v-col>
      <v-col sm="2" v-if="reportname == 7">
        <v-autocomplete
          dense
          outlined
          hide-details
          label="الخدمه"
          :items="servicesTypeList"
          item-value="id"
          item-text="name"
          auto-select-first
          v-model="options.serviceTypeId"
        ></v-autocomplete>
      </v-col>
      <v-col sm="3" v-if="reportname == 5 || reportname == 6">
        <v-autocomplete
          dense
          outlined
          label="اسم السائق"
          :items="drivers"
          item-value="id"
          item-text="name"
          auto-select-first
          :filter="driverFilter"
          v-model="options.driverId"
        >
        </v-autocomplete>
      </v-col>
      <v-col sm="2" v-if="reportname !== 1 && reportname !== 8 && reportname">
        <DatePicker-layout
          @myEvent="options.dateFrom = $event"
          :date.sync="options.dateFrom"
          label="من تاريخ"
          clearable
        >
        </DatePicker-layout>
      </v-col>
      <v-col sm="2" v-if="reportname !== 1 && reportname !== 8 && reportname">
        <DatePicker-layout
          @myEvent="options.dateTo = $event"
          :date.sync="options.dateTo"
          label="الى تاريخ"
          clearable
        >
        </DatePicker-layout>
      </v-col>
    </v-row>
    <TripActivityReport disableHeader ref="report" />
  </div>
</template>


<script>
import ApiService from "../../LandShipping/services/TripsService";
import ServiceTypesService from "../../Settings/services/ServiceTypesService";
import DriverService from "../../LandShipping/services/DriverService";
import LinesService from "../../LandShipping/services/LinesService.js";
export default {
  created() {
    DriverService.typeHead("", true, "").then((resp) => {
      this.drivers = resp.data;
    });
    ServiceTypesService.typeHead("", true).then((resp) => {
      this.servicesTypeList = resp.data;
    });
    LinesService.typeHead("", true, false, false).then((resp) => {
      this.lines = resp.data;
    });
  },
  data() {
    return {
      drivers: [],
      lines: [],
      servicesTypeList: [],
      filter: [
        { id: 1, name: "حركة التشغيل بتاريخ" }, //date
        { id: 2, name: "حركة التشغيل العامة في فترة" }, //datefrom dateto
        { id: 3, name: "حركة التشغيل لمسار في فترة" }, //datefrom dateto lineid
        { id: 4, name: "حركة التشغيل التفصيلية لمسار من تاريخ" }, //datefrom dateto lineid
        { id: 5, name: "حركة التشغيل حسب السائق في فترة" }, //datefrom dateto driverid
        { id: 6, name: "حركة التشغيل لمسار حسب السائق فى فترة" }, //datefrom dateto lineid driverid
        { id: 7, name: "حركة التشغيل حسب نوع الشحن في فترة" }, //datefrom dateto servicetypeid
        //{ id: 8, name: "بيان باجمالى بوالص رحلة" }, //trackingnumber
        //{ id: 9, name: "حركة البوالص في فترة" }, //datefrom dateto
      ],
      reporttype: [
        { id: 1, name: "حركة الرحلات كاملة" },
        { id: 2, name: "حركة الرحلات الصادرة" },
        { id: 3, name: "حركة رحلات الرجيع" },
      ],
      reportname: null,
      options: {
        trackingNumber: null,
        lineId: null,
        carId: null,
        driverId: null,
        state: null,
        serviceTypeId: null,
        date: null,
        dateFrom: null,
        dateTo: null,
        reportTypeId: null,
      },
      loadings: {},
    };
  },
  methods: {
    driverFilter(item, queryText, itemText) {
      return (
        item.id.toString().includes(queryText) || item.name.includes(queryText)
      );
    },
    resetOptions() {
      this.options = {
        trackingNumber: null,
        lineId: null,
        carId: null,
        driverId: null,
        state: null,
        serviceTypeId: null,
        date: null,
        dateFrom: null,
        dateTo: null,
        reportTypeId: null,
      };
      this.reportname = null;
      this.$refs.report.reset();
    },
    goToPrint() {
      if (
        this.options.trackingNumber != null ||
        this.options.date != null ||
        this.options.lineId != null ||
        this.options.serviceTypeId != null ||
        this.options.driverId != null ||
        this.options.dateFrom != null ||
        this.options.dateTo != null
      ) {
        let routeData = this.$router.resolve({
          name: "TripActivityReport",
          query: {
            options: JSON.stringify(this.$clean(this.options)),
            reportname: JSON.stringify(this.filter[this.reportname - 1]),
            linename: this.options.lineId
              ? this.lines.find((x) => x.id === this.options.lineId).name
              : "",
            drivername: this.options.driverId
              ? this.drivers.find((x) => x.id === this.options.driverId).name
              : "",
            servicename: this.options.serviceTypeId
              ? this.servicesTypeList.find(
                  (x) => x.id === this.options.serviceTypeId
                ).name
              : "",
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
    getData() {
      this.$refs.report.reset();
      this.loadings = true;
      ApiService.activityReport(this.$clean(this.options))
        .then((resp) => {
          if (resp.data) {
            this.$refs.report.setData(resp.data);
          }
          this.loadings = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadings = false;
        });
    },
  },
  watch: {
    reportname(val) {
      this.options = {
        trackingNumber: null,
        lineId: null,
        carId: null,
        driverId: null,
        state: null,
        serviceTypeId: null,
        date: null,
        dateFrom: null,
        dateTo: null,
        reportTypeId: null,
      };
      this.$refs.report.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
</style>