import Api from '../../shared/services/api'
import moment from 'moment';
const baseUrl = "trips";
import Utils from '@/plugins/utils.js';
export default {

    get() {
        return Api().get(baseUrl);
    },

    query(options) {
        const qParams = new URLSearchParams(options);
        return Api().get(baseUrl + "?" + qParams.toString());
    },
    activityReport(entity) {
        return Api().post(baseUrl + "/ActivityReport", entity);
    },

    edit(entity) {
        if (entity.id)
            return Api().put(baseUrl + `/${entity.id}`, entity);
        else
            return this.add(entity);
    },
    add(entity) {
        return Api().post(baseUrl, entity);
    },
    posprint(entity) {
        return Api().post("http://169.254.92.35:9100/", entity);
    },
    addtripCity(entity) {
        return Api().post(baseUrl + "/AddCity", entity);
    },
    delete(id) {
        return Api().delete(baseUrl + `/${id}`);
    },
    updateState(id, state) {
        return Api().put(baseUrl + `/${id}/state`, { state });
    },
    updateMovement(id, movement, code) {
        return Api().put(baseUrl + `/${id}/Movement`, { state: movement, driverPassCode: code });
    },
    print(id) {
        return Api().get(baseUrl + `/${id}/print`);
    },
    printReturn(id) {
        return Api().get(baseUrl + `/${id}/printReturn`);
    },
    printDriver(id) {
        return Api().get(baseUrl + `/${id}/printDriver`);
    },
    formateDateWithMoment(date, formats) {
        if (!date)
            return null;
        return moment(date).format(formats)
        //  return moment(date).format('LT')
    },
    setEntity(i) {
        // 
        try {

            i.actualArrive = this.formateDateWithMoment(i.actualArrive, 'LT')
           
            i.date = this.formateDateWithMoment(i.date, 'L')
        } catch (error) {
            return i;
        }
        return i;
    }
}