
<template>
  <div>
    <v-row class="pr-5 pb-3 mt-3">
      <v-col class="d-flex justify-content-start">
        <v-btn v-can="'ExternalShippers.Create'" color="success" @click="addConductor">
          اضافة مخرج
          <i class="fa fa-plus mr-2"></i>
        </v-btn>
      </v-col>
    </v-row>
    <v-card width="98%" class="mx-auto mr-5">
      <v-data-table v-can="'ExternalShippers.View'" :headers="headers" :items="entities" :options.sync="options"
        :search="search" :server-items-length="total" :items-per-page="15" :loading="loadingStates.table"
        class="elevation-1" :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-plus',
          nextIcon: 'mdi-minus',
          'items-per-page-text': 'عدد المخرجين ' + total + '',
        }">
        <template v-slot:[`item.city`]="{ item }">
          <span v-if="item.city">
            {{ item.city.name }}
          </span>
        </template>
        <template v-slot:[`item.count`]="{ item }">
          <span v-if="item">
            <!-- {{countPolisas(item.id)}} -->
          </span>
        </template>
        <template v-slot:top>
          <v-text-field v-model="search" label="اسم المخرج" class="mx-4"></v-text-field>
        </template>
        <template v-slot:[`item.details`]="{ item }">
          <v-btn v-can="'ExternalShippers.View'" color="orange" outlined elevation="2" class="mx-2"
            @click="openUserDialog(item.id)" :id="item.id" icon>
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-can="'ExternalShippers.Update'" color="primary" elevation="2" class="mx-2" @click="edit(item.id)"
            icon outlined>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn v-can="'ExternalShippers.Delete'" color="red" elevation="2" icon outlined @click="del(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- user dialog  -->
    <v-dialog v-model="userDialog" width="98%">
      <v-card>
        <v-card-title class="headline">
          <h2 class="pa-3 mx-auto pink--text">جميع بوالص المخرج</h2>
        </v-card-title>
        <h5 style="text-align: center" v-html="'اجمالى أجرة المخرج (' + totalamount + ')'"></h5>
        <v-data-table :headers="headersPolisa" :items="entitiesPolisa" :server-items-length="entitiescount"
          :items-per-page="-1" :loading="loadingStates.table" class="elevation-1" :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-plus',
            nextIcon: 'mdi-minus',
            'items-per-page-text': 'عدد بوالص المخرج ' + entitiescount + '',
          }">
          <template v-slot:[`item.createDate`]="{ item }">
            {{ item.createDate | ToDate }}
          </template>

          <template v-slot:[`item.image`]="{ item }">
            <v-btn color="orange" outlined elevation="2" class="mx-2"
              @click="viewImg(item.externalShipperDeliveryInfo.imgUrl)" icon>
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.externalShipperDeliveryInfo.createDate`]="{ item }">
            {{ item.externalShipperDeliveryInfo.createDate | ToDate }}
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <!-- finishe the user dilaog -->
    <!-- Add Modal -->
    <v-dialog v-model="dialog" width="900" persistent>
      <v-card>
        <v-form v-model="valid">
          <v-card-title class="headline">
            <i class="fa fa-pen ml-2"></i> تعديل/إضافة
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field outlined dense label="اسم مخرج" v-model="selected.name"
                    :rules="[allRules.required, allRules.minNameLen(4)]"></v-text-field>
                </v-col>
              </v-row>
              <v-form v-model="valid2">
                <v-row class="rounded-lg grey lighten-3 mb-6">
                  <v-col cols="12" sm="5" v-if="!loadingStates.table">
                    <v-autocomplete dense :rules="[allRules.required]" outlined label="المدينه" :items="cities"
                      item-text="name" item-value="id" return-object auto-select-first v-model="selected.city.city">
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="5">
                    <v-text-field dense outlined :rules="[allRules.required, allRules.length(10)]" :maxlength="10"
                      label="رقم التلفون " v-model="selected.city.phoneNumber"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" class="mt-2">
                    <v-btn @click="addNewCity" class="success">اضافة</v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-row>
                <v-textarea outlined rows="2" label="ألملاحظات" v-model="selected.notice"></v-textarea>
              </v-row>
              <v-row>
                <h5>المدن</h5>
              </v-row>
              <v-row class="my-0 ammar text-center" dense v-if="selected.cities">
                <v-col cols="12" v-if="selected.cities.length > 0">
                  <v-data-table :headers="branchesHeaders" :items="selected.cities"
                    :server-items-length="selected.cities.length" fixed-header class="elevation-1" :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-arrow-collapse-left',
                      lastIcon: 'mdi-arrow-collapse-right',
                      prevIcon: 'mdi-plus',
                      nextIcon: 'mdi-minus',
                      'items-per-page-text':
                        'عدد الفروع  ' + selected.cities.length + '',
                    }">
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn color="red" class="ml-2" icon elevation="2" outlined @click="removeNewCity(item.id)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <v-btn color="primary" elevation="2" icon @click="editBranch(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" :disabled="!valid" @click="editConfirmed()">حفظ</v-btn>

            <v-btn color="red darken-1" text @click="cancelEdit()">إلغاء</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Add Modal -->
  </div>
</template>

<script>
import ShipmentService from "../services/ShipmentService";
import CityService from "../../Settings/services/CityService.js";
import ApiService from "../services/ExternalShippersService.js";

export default {
  mounted() {
    this.getData();
    CityService.typeHead("", true).then((resp) => {
      console.log(resp.data);
      this.cities = resp.data;
    });
  },
  data() {
    return {
      valid: false,
      valid2: false,
      country: {},
      entitiesPolisa: [],
      entitiescount: 0,
      total: 0,
      persistent: true,
      countries: [],
      cities: [],
      dialog: false,
      entities: [],
      search: "",
      options: {},
      selected: { cities: [], city: {} },
      message: null,
      userDialog: false,
      loadingStates: {
        table: false,
      },
      branchesHeaders: [
        {
          text: "اسم المدينه",
          value: "city.name",
          align: "center",
          sortable: true,
        },
        {
          text: "رقم الجوال",
          value: "phoneNumber",
          align: "center",
          sortable: true,
        },
        { text: "تعديل/مسح", value: "actions", align: "center" },
      ],
      headersPolisa: [
        {
          text: "رقم البوليصة",
          align: "start",
          sortable: false,
          value: "trackingNumber",
        },
        { text: "التاريخ", value: "createDate", align: "center" },
        {
          text: "تاريخ التسليم",
          value: "externalShipperDeliveryInfo.createDate",
          align: "center",
        },
        { text: "المرسل", value: "senderInfo.name", align: "center" },
        { text: "المرسل اليه", value: "receiverInfo.name", align: "center" },
        {
          text: "سند المخرج",
          value: "image",
          align: "center",
        },
        {
          text: "رقم سند المخرج",
          value: "externalShipperDeliveryInfo.notice",
          align: "center",
        },
        {
          text: "المدينه",
          value: "externalShipperDeliveryInfo.externalShipperCity.city.name",
          align: "center",
        },
        { text: "القيمه", value: "totalPrice", align: "center" },
        {
          text: "اجره المخرج",
          value: "externalShipperDeliveryInfo.amount",
          align: "center",
        },
      ],
      headers: [
        {
          text: "رقم",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "الاسم", value: "name", align: "center" },
        { text: " عدد البوالص ", value: "count", align: "center" },
        { text: "تفاصيل", value: "details", align: "center" },
        { text: "تعديل/مسح", value: "actions", align: "center" },
      ],
    };
  },
  methods: {
    addNewCity() {
      let data = { ...this.selected.city };
      this.selected.city.cityId = this.selected.city.city.id;
      if (this.selected.id) {
        if (this.selected.city.id) {
          ApiService.updateCity(this.selected.id, this.selected.city).then(
            (resp) => {
              let findItem = this.selected.cities.findIndex((i) => {
                return i.id == resp.id;
              });
              this.selected.cities[findItem] = resp.data;
              this.selected.city = {};
              this.$dialog.notify.success("تم تعديل الفرع بنجاح", {
                position: "top-right",
                timeout: 3000,
              });
            }
          );
        } else {
          ApiService.addCity(this.selected.id, this.selected.city).then(
            (resp) => {
              var newCity = { ...data, cityId: data.city.id };
              this.selected.cities.push(newCity);
              this.selected.city = {};

              this.$dialog.notify.success("تم إضافة فرع بنجاح", {
                position: "top-right",
                timeout: 3000,
              });
            }
          );
        }
      }
    },
    removeNewCity(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف العنصر",
          title: "  حذف فرع  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                if (this.selected.id && id) {
                  ApiService.deleteCity(this.selected.id, id).then((resp) => {
                    this.selected.cities = this.selected.cities.filter((i) => {
                      return i.id !== id;
                    });
                    this.$dialog.notify.success("تم مسح الفرع بنجاح", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
                }
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => { });
    },
    editBranch(item) {
      if (item.id) {
        this.selected.city = this.selected.cities.filter(
          (i) => i.id == item.id
        )[0];
      }
    },
    openUserDialog(id) {
      ShipmentService.byExternalShipperId(id).then((resp) => {
        console.log(resp.data);
        this.entitiesPolisa = resp.data;
        this.entitiescount = resp.data.length;
      });
      this.userDialog = true;
    },
    editConfirmed() {
      ApiService.add(this.selected).then((resp) => {
        if (!this.selected.id) {
          this.addToArr(this.entities, resp.data);
          this.selected = {};
          this.message = "تم إضافة العنصر بنجاح";
          this.$dialog.notify.success(this.message, {
            position: "top-right",
            timeout: 3000,
          });
        } else {
          this.updateArr(this.entities, resp.data);
          this.message = "تم تعديل العنصر بنجاح";
          this.$dialog.notify.success(this.message, {
            position: "top-right",
            timeout: 3000,
          });
        }
      });
    },
    cancelEdit() {
      this.dialog = false;
      this.selected = { cities: [], cities: [], city: {} };
    },
    addConductor() {
      this.selected = { cities: [], cities: [], city: {} };
      this.dialog = true;
    },
    edit(id) {
      this.selected = {
        ...this.entities.filter((e) => e.id == id)[0],
        city: {},
      };
      this.dialog = true;
    },
    del(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف العنصر",
          title: "  حذف مدينة  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                const index = this.entities.indexOf(
                  this.entities.filter((e) => e.id == id)[0]
                );
                if (index > -1) {
                  this.entities.splice(index, 1);
                }
                this.$dialog.notify.success("تم مسح العنص بنجاح", {
                  position: "top-right",
                  timeout: 3000,
                });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => { });
    },
    getData() {
      this.loadingStates.table = true;
      if (this.options) this.options.searchTerm = this.search;
      ApiService.query(this.$clean(this.options))
        .then((resp) => {
          console.log(resp.data.items);
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingStates.table = false;
        });
    },
    viewImg(img) {
      this.$dialog.info({
        width: "100%",
        title: "سند المخرج",
        text: `<img src="/${img}" style="width:100%;" alt="" />`,
        actions: {
          false: {
            text: "الغاء",
          },
        },
      });
    },
  },
  computed: {
    totalamount() {
      return this.entitiesPolisa.reduce((totalamount, item) => {
        return totalamount + item.externalShipperDeliveryInfo.amount;
      }, 0);
    },
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
</style>