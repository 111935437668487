export default [
    {
        path: '/settings/countries',
        name: 'CountyList',
        meta: { roles: ['SystemAdmin'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/Locations/CountryList.vue')
    },
    {
        path: '/settings/cities/:id',
        name: 'CityList',
        meta: { roles: ['SystemAdmin'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/Locations/CityList.vue')
    }, {
        path: '/settings/units/',
        name: 'UnitsList',
        meta: { roles: ['SystemAdmin', 'Manager'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/UnitsList.vue')
    },
    {
        path: '/settings/ServiceTypes/',
        name: 'ServiceTypesList',
        meta: { roles: ['SystemAdmin', 'Manager'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/ServiceTypesList.vue')
    },
    {
        path: '/settings/branches/',
        name: 'BranchesList',
        meta: { roles: ['SystemAdmin'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/Locations/BranchesList.vue')
    },
    {
        path: '/settings/carTypes/',
        name: 'CarTypesList',
        meta: { roles: ['SystemAdmin', 'Manager'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/CarTypesList.vue')
    },
    {
        path: '/settings/Merchandises',
        name: 'MerchandiseList',
        meta: { roles: ['SystemAdmin', 'Manager', 'User'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('../LandShipping/views/MerchandiseList.vue')
    },
]