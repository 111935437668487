<template>
  <v-container v-if="selected">
    <div class="content" v-if="selected && reportType == 0">
      <v-row class="mt-3" justify="center">
        <span
          style="
            border: 2px solid black;
            padding: 0px 100px;
            font-size: 36.5307px;
          "
          >{{ this.$store.getters.companyName }}</span
        >
      </v-row>
      <v-row class="mt-3" justify="center">
        <v-col cols="4" class="pa-2 text-center text-capitalize">
          <div style="font-size: 31.8649px">إیصال قبض</div>
          <div style="font-size: 23.3557px">Catch Receipt</div>
        </v-col>
      </v-row>
      <v-row class="mt-3" justify="center">
        <v-col cols="4" class="pa-2 text-center text-capitalize">
          <div style="font-size: 19.9622px">#{{ selected.id }}</div>
        </v-col>
      </v-row>
      <v-row class="mt-3" justify="center">
        <v-col cols="6" class="text-right">
          <p
            class="font-weight-bold grey d-inline-block"
            style="
              padding: 0px 15px;
              font-size: 23.3557px;
              font-family: sans-serif;
            "
          >
            <span class="ml-2">ریـــــال</span>
            SR
          </p>
          <br />
          <div class="d-flex justify-space-between">
            <span style="margin-right: 50px; font-size: 19.9622px">{{
              selected.amount
            }}</span>
            <span style="font-size: 19.9622px">{{ textnumber() }}</span>
          </div>
        </v-col>
        <v-col cols="6" class="text-left">
          <h5 class="larg font-weight-bolder">
            <span class="ml-5"
              >تاريخ الطباعه{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0"
              }}{{ "\xa0" }}</span
            >
            Print date :
            <span
              >{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0"
              }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0"
              }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0"
              }}{{ Date.now() | ToDate }}</span
            >
          </h5>
          <h5 class="larg font-weight-bolder">
            <span class="ml-5"
              >تاريخ الإستلام{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0"
              }}{{ "\xa0" }}</span
            >
            Receipt date :{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}
            <span
              >{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0"
              }}{{ selected.createDate | ToDate }}</span
            >
          </h5>
        </v-col>
      </v-row>

      <v-row class="mt-3">
        <v-col cols="3" class="text-right">
          <p
            class="font-weight-bold grey d-inline-block"
            style="
              padding: 0px 15px;
              font-size: 23.3557px;
              font-family: sans-serif;
            "
          >
            إستلمنا من السادة
          </p>
        </v-col>
        <v-col cols="6" class="text-center mt-10">
          <span
            style="
              border: 3px dashed black;
              padding: 5px 120px;
              font-size: 19.9622px;
            "
            >{{ selected.recipient }}</span
          >
          <p class="text-center mt-3">المبلغ المذكور أعلاه</p>
        </v-col>
        <v-col cols="3" class="text-left">
          <p
            class="font-weight-bold grey d-inline-block"
            style="
              padding: 0px 15px;
              font-size: 23.3557px;
              font-family: sans-serif;
            "
          >
            Received from
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="3" class="text-right">
          <p
            class="font-weight-bold grey d-inline-block"
            style="
              padding: 0px 15px;
              font-size: 23.3557px;
              font-family: sans-serif;
            "
          >
            وذلك مقابل
          </p>
        </v-col>
        <v-col cols="6" class="text-right mt-10">
          <div>
            <p
              style="
                padding: 0px 5px 150px 0px;
                border: 2px dashed black;
                font-size: 19.9622px;
              "
            >
              {{ selected.description }}
            </p>
          </div>
        </v-col>
        <v-col cols="3" class="text-left">
          <p
            class="font-weight-bold grey d-inline-block"
            style="
              padding: 0px 15px;
              font-size: 23.3557px;
              font-family: sans-serif;
            "
          >
            This for
          </p>
        </v-col>
      </v-row>

      <v-row class="mt-3">
        <v-col cols="2" class="text-center">
          <p class="font-weight-bold">المستلم</p>
          <p>.............................</p>
        </v-col>
        <v-col cols="2" class="text-center">
          <p class="font-weight-bold">امين الصندوق</p>
          <p>...............................</p>
        </v-col>
        <v-col cols="2" class="text-center">
          <p class="font-weight-bold">ادارة التشغيل</p>
          <p>................................</p>
        </v-col>
        <v-col cols="3" class="text-center">
          <p class="font-weight-bold">المحاسب</p>
          <p>................................</p>
        </v-col>
        <v-col cols="3" class="text-center">
          <p class="font-weight-bold">المدير العام</p>
          <p>....................................</p>
        </v-col>
      </v-row>
    </div>
    <div class="content" v-if="selected && reportType == 1">
      <v-row class="mt-3" justify="center">
        <span
          style="
            border: 2px solid black;
            padding: 0px 100px;
            font-size: 36.5307px;
          "
          >{{ this.$store.getters.companyName }}</span
        >
      </v-row>
      <v-row class="mt-3" justify="center">
        <v-col cols="4" class="pa-2 text-center text-capitalize">
          <div style="font-size: 31.8649px">اذن صرف</div>
          <div style="font-size: 23.3557px">Receipt Voucher</div>
        </v-col>
      </v-row>
      <v-row class="mt-3" justify="center">
        <v-col cols="4" class="pa-2 text-center text-capitalize">
          <div style="font-size: 19.9622px">#{{ selected.id }}</div>
        </v-col>
      </v-row>
      <v-row class="mt-3" justify="center">
        <v-col cols="6" class="text-right">
          <p
            class="font-weight-bold grey d-inline-block"
            style="
              padding: 0px 15px;
              font-size: 23.3557px;
              font-family: sans-serif;
            "
          >
            <span class="ml-2">ریـــــال</span>
            SR
          </p>
          <br />
          <div class="d-flex justify-space-between">
            <span style="margin-right: 50px; font-size: 19.9622px">{{
              selected.amount
            }}</span>
            <span style="font-size: 19.9622px">{{ textnumber() }}</span>
          </div>
        </v-col>
        <v-col cols="6" class="text-left">
          <h5 class="larg font-weight-bolder">
            <span class="ml-5"
              >تاريخ الطباعه{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0"
              }}{{ "\xa0" }}</span
            >
            Print date :
            <span
              >{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0"
              }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0"
              }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0"
              }}{{ Date.now() | ToDate }}</span
            >
          </h5>
          <h5 class="larg font-weight-bolder">
            <span class="ml-5"
              >تاريخ الصرف{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0"
              }}{{ "\xa0" }}</span
            >
            Receipt date :{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}
            <span
              >{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0"
              }}{{ selected.createDate | ToDate }}</span
            >
          </h5>
        </v-col>
      </v-row>

      <v-row class="mt-3">
        <v-col cols="3" class="text-right">
          <p
            class="font-weight-bold grey d-inline-block"
            style="
              padding: 0px 15px;
              font-size: 23.3557px;
              font-family: sans-serif;
            "
          >
            يصرف الى السادة
          </p>
        </v-col>
        <v-col cols="6" class="text-center mt-10">
          <span
            style="
              border: 3px dashed black;
              padding: 5px 120px;
              font-size: 19.9622px;
            "
            >{{ recipientname() }}</span
          >
          <p class="text-center mt-3">المبلغ المذكور أعلاه</p>
        </v-col>
        <v-col cols="3" class="text-left">
          <p
            class="font-weight-bold grey d-inline-block"
            style="
              padding: 0px 15px;
              font-size: 23.3557px;
              font-family: sans-serif;
            "
          >
            Pay To
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="3" class="text-right">
          <p
            class="font-weight-bold grey d-inline-block"
            style="
              padding: 0px 15px;
              font-size: 23.3557px;
              font-family: sans-serif;
            "
          >
            وذلك مقابل
          </p>
        </v-col>
        <v-col cols="6" class="text-right mt-10">
          <div>
            <p
              style="
                padding: 0px 5px 150px 0px;
                border: 2px dashed black;
                font-size: 19.9622px;
              "
            >
              {{ selected.description }}
            </p>
          </div>
          <div v-if="selected.expenseType">
            <p
              style="
                padding: 0px 5px 20px 0px;
                border: 2px dashed black;
                font-size: 19.9622px;
              "
            >
              {{ selected.expenseType.description }}
            </p>
          </div>
        </v-col>
        <v-col cols="3" class="text-left">
          <p
            class="font-weight-bold grey d-inline-block"
            style="
              padding: 0px 15px;
              font-size: 23.3557px;
              font-family: sans-serif;
            "
          >
            This for
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="2" class="text-center">
          <p class="font-weight-bold">المستلم</p>
          <p>.............................</p>
        </v-col>
        <v-col cols="2" class="text-center">
          <p class="font-weight-bold">امين الصندوق</p>
          <p>...............................</p>
        </v-col>
        <v-col cols="2" class="text-center">
          <p class="font-weight-bold">ادارة التشغيل</p>
          <p>................................</p>
        </v-col>
        <v-col cols="3" class="text-center">
          <p class="font-weight-bold">المحاسب</p>
          <p>................................</p>
        </v-col>
        <v-col cols="3" class="text-center">
          <p class="font-weight-bold">المدير العام</p>
          <p>....................................</p>
        </v-col>
      </v-row>

    </div>
  </v-container>
</template>

<script>
import CatchReceiptService from "../../Finances/services/CatchReceiptService";
import ReceiptService from "../../Finances/services/ReceiptService";
export default {
  components: {},
  mounted() {
    var id = +this.$route.query["receiptid"];
    this.reportType = +this.$route.query["reporttype"];
    if (id != 0) this.getDataFromAPI(id);
  },
  data() {
    return {
      selected: null,
      reportType: null,
    };
  },
  methods: {
    recipientname() {
      if (this.selected.employee != null) {
        return this.selected.employee.name;
      } else if (this.selected.driver != null) {
        return this.selected.driver.name;
      } else if (this.selected.salesman != null) {
        return this.selected.salesman.name;
      } else {
        return this.selected.recipient;
      }
    },
    textnumber() {
      let number = this.selected.amount.toString().includes(".");
      let numbers = this.Num2words(
        number
          ? this.selected.amount.toString().split(".")[0]
          : this.selected.amount.toString(),
        0
      );
      if (number) {
        numbers +=
          (
            " " +
            this.selected.amount.toString().split(".")[1] +
            "00000000000000000000"
          ).substr(0, 2) > 0
            ? " و" +
              this.Num2words(
                (
                  this.selected.amount.toString().split(".")[1] +
                  "00000000000000000000"
                ).substr(0, 2),
                1
              )
            : "";
      }
      return numbers;
    },
    Num2words(number, c1) {
      var number =
        number.length > 21
          ? number.substr(number.length - 21, number.length)
          : number;
      var c =
        c1 == 1
          ? ["هللة", "هللتان", "هللات", "هللة", 1]
          : ["ريال سعودي", "ريالان سعوديان", "ريالات سعودية", "ريالا سعودى", 0];
      const first = [
        "",
        "واحد",
        "اثنان",
        "ثلاثة",
        "أربعة",
        "خمسة",
        "ستة",
        "سبعة",
        "ثمانية",
        "تسعة",
        "عشرة",
        "أحد عشر",
        "اثنا عشر",
        "ثلاثة عشر",
        "أربعة عشر",
        "خمسة عشر",
        "ستة عشر",
        "سبعة عشر",
        "ثمانية عشر",
        "تسعة عشر",
      ];
      const first2 = [
        "",
        "إحدى",
        "اثنتان",
        "ثلاث",
        "أربع",
        "خمس",
        "ست",
        "سبع",
        "ثماني",
        "تسع",
        "عشر",
        "إحدى عشرة",
        "اثنتا عشرة",
        "ثلاث عشرة",
        "أربع عشرة",
        "خمس عشرة",
        "ست عشرة",
        "سبع عشرة",
        "ثماني عشرة",
        "تسع عشرة",
      ];
      const tens = [
        "",
        "",
        "عشرون",
        "ثلاثون",
        "أربعون",
        "خمسون",
        "ستون",
        "سبعون",
        "ثمانون",
        "تسعون",
      ];
      const ths = [
        "",
        "ألف",
        "مليون",
        "بليون",
        "تريليون",
        "كوادريليون",
        "كوينتليون",
      ];
      const ths2 = [
        "",
        "آلاف",
        "ملايين",
        "بليونات",
        "تريليونات",
        "كوادريليونات",
        "كوينتليونات",
      ];
      let words = "";
      for (let i = 0; i <= ~~(number.length / 3); i++) {
        var m = number.substr(0, number.length - i * 3).slice(-3);
        var h = ~~(m / 100);
        var t = m % 100;
        var s = i == 0 ? c[4] : 0;
        if (t !== 0) {
          if (t < 20) {
            words =
              (t > 2
                ? (s == 0 ? first[t] : first2[t]) +
                  " " +
                  (t > 10 && ths[i].length > 0
                    ? ths[i] + (words ? "" : "")
                    : ths2[i])
                : ths[i].length > 0
                ? ths[i] + (t == 1 ? "" : t == 2 && words ? "ان" : "ا")
                : t % 10 != 0
                ? ""
                : s == 0
                ? first[t]
                : first2[t]) +
              (words == ""
                ? ths[i].length > 0
                  ? " " + c[0]
                  : t == 1
                  ? c[0]
                  : t == 2
                  ? c[1]
                  : t < 11
                  ? c[2]
                  : c[3]
                : " و" + words);
          } else {
            words =
              (t % 10 == 0
                ? ""
                : (s == 0
                    ? first[t % 10]
                    : t % 10 == 8
                    ? first2[t % 10].substr(0, 4)
                    : first2[t % 10]) + " و") +
              tens[~~(t / 10)] +
              " " +
              ths[i] +
              (words && ths[i].length > 0 ? "" : "") +
              (words == ""
                ? ths[i].length > 0
                  ? " " + c[0]
                  : c[3]
                : " و" + words);
          }
        }
        if (h !== 0) {
          words =
            (h == 1
              ? "مائة"
              : h == 2
              ? "مائتا" + (t > 0 ? "ن" : "")
              : first[h].substr(0, first[h].length - (h == 8 ? 2 : 1)) +
                "مائة") +
            (t == 0 ? (ths[i].length > 0 ? " " : "") + ths[i] : "") +
            (words == "" ? " " + c[0] : " و" + words);
        }
      }
      return words;
    },
    getDataFromAPI(id) {
      var type = this.reportType == 0 ? CatchReceiptService : ReceiptService;
      type
        .byId(id)
        .then((resp) => {
          this.selected = resp.data;
          setTimeout(() => {
            window.focus();
            var css = "@page { size: portrait; }",
              head = document.head || document.getElementsByTagName("head")[0],
              style = document.createElement("style");
            style.type = "text/css";
            style.media = "print";
            if (style.styleSheet) {
              style.styleSheet.cssText = css;
            } else {
              style.appendChild(document.createTextNode(css));
            }
            head.appendChild(style);
            window.print();
          }, 100);
        })
        .catch((resp) => {})
        .finally(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
  color-adjust: exact !important; /*Firefox*/
}
.content {
  font-weight: bolder !important;
}
@media print {
  .pagebreak {
    clear: both;
    page-break-after: auto;
  }
  body {
    width: 21cm;
    height: 29.7cm;
    margin: 30mm 45mm 30mm 45mm;
  }
}
</style>
