<template>
  <div>
    <v-row class="pr-5 pb-3 mt-5">
      <v-col class="d-flex justify-content-start">
        <v-btn v-can="'Drivers.Create'" color="success" @click="add">
          اضافة سائق<i class="fa fa-plus mr-2"></i>
        </v-btn>
      </v-col>
    </v-row>
    <v-card min-width="98%" class="mx-auto ma-3">
      <v-data-table
        v-can="'Drivers.View'"
        :headers="headers"
        :items="entities"
        :options.sync="options"
        :server-items-length="total"
        :search="search"
        :items-per-page="15"
        :loading="loadingStates.table"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-plus',
          nextIcon: 'mdi-minus',
          'items-per-page-text': 'عدد السائقين ' + total + '',
        }"
      >
        <template v-slot:top>
          <v-row class="mx-auto mt-3 pa-5" dark dense>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="options.Id"
                label="كود السائق"
                hide-details
                dense
                outlined
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="options.name"
                label="اسم السائق"
                hide-details
                dense
                outlined
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="options.nationality"
                label="الجنسية"
                hide-details
                dense
                outlined
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="options.residencyNo"
                label="رقم الهوية"
                hide-details
                dense
                outlined
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="options.sponser"
                label="اسم الكفيل"
                hide-details
                dense
                outlined
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="options.sponserMob"
                label="جوال الكفيل"
                hide-details
                dense
                outlined
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                v-model="options.isInternal"
                label="سائق داخلى"
                color="green darken-3"
              ></v-checkbox
            ></v-col>
            <v-col cols="6" sm="10">
              <v-btn color="error" @click="resetOptions"
                >تفريغ
                <i class="fa fa-trash fa-lg"></i>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.isInternal`]="{ item }">
          <span v-if="item.isInternal">
            <v-chip label color="info">
              <i class="fa fa-check" aria-hidden="true"></i>
            </v-chip>
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-can="'Drivers.Delete'"
            color="red"
            class="ml-2"
            icon
            elevation="2"
            outlined
            @click="del(item.id)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-btn
            v-can="'Drivers.Update'"
            color="primary"
            elevation="2"
            icon
            @click="edit(item.id)"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>

    <!-- Add Modal -->
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-form v-model="valid">
          <v-card-title class="headline">
            <i class="fa fa-pen ml-2"></i> تعديل/إضافة
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="my-0 ammar" dense>
                <v-col cols="12" sm="8">
                  <v-text-field
                    :rules="[allRules.required, allRules.minNameLen(4)]"
                    outlined
                    dense
                    label="اسم السائق"
                    v-model="selected.name"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    outlined
                    dense
                    label="الجنسية"
                    v-model="selected.nationality"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-0 ammar" dense>
                <v-col cols="12" sm="8">
                  <v-text-field
                    outlined
                    dense
                    label="اسم الكفيل"
                    v-model="selected.sponser"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    outlined
                    dense
                    label="جوال السائق"
                    v-model="selected.sponserMob"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- ResidencyNo -->
              <v-row class="my-0 ammar" dense>
                <v-col cols="12" sm="8">
                  <v-text-field
                    outlined
                    dense
                    label="رقم الإقامة"
                    v-model="selected.residencyNo"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    outlined
                    dense
                    label="مصدرها"
                    v-model="selected.residencyIssuer"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="selected.isInternal"
                    label="سائق داخلى"
                    color="green darken-3"
                    :false-value="false"
                    :true-value="true"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <!-- Residency Date
              <v-row class="my-0" dense>
                <v-col cols="12" sm="6">
                  <DatePicker-layout
                    @myEvent="selected.residencyIss = $event"
                    label="تاريخ الاصدار"
                    :date="selected.residencyIss | ToDate"
                    v-model="selected.residencyIss"
                  ></DatePicker-layout>
                </v-col>
                <v-col cols="12" sm="6">
                  <DatePicker-layout
                    @myEvent="selected.residencyExp = $event"
                    label="تاريخ الانتهاء"
                    :date="selected.residencyExp | ToDate"
                    v-model="selected.residencyExp"
                  ></DatePicker-layout>
                </v-col>
              </v-row>
              <v-row class="my-0 ammar" dense>
                <v-col cols="12" sm="8">
                  <v-text-field
                    dense
                    outlined
                    label="رقم الرخصة"
                    v-model="selected.licenseNo"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    dense
                    outlined
                    label="مصدرها"
                    v-model="selected.licenseIssuer"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-0" dense>
                <v-col cols="12" sm="6">
                  <DatePicker-layout
                    @myEvent="selected.licenseIss = $event"
                    label="تاريخ الاصدار"
                    :date="selected.licenseIss | ToDate"
                    v-model="selected.licenseIss"
                  ></DatePicker-layout>
                </v-col>
                <v-col cols="12" sm="6">
                  <DatePicker-layout
                    @myEvent="selected.licenseExp = $event"
                    label="تاريخ الانتهاء"
                    :date="selected.licenseExp | ToDate"
                    v-model="selected.licenseExp"
                  ></DatePicker-layout>
                </v-col>
              </v-row>
              <v-row class="my-0 ammar" dense>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    label="الراتب"
                    v-model="selected.salary"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    label="عدد الساعات"
                    v-model="selected.hours"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    label="مكافأة الساعة"
                    v-model="selected.bonus"
                  >
                  </v-text-field>
                </v-col>
              </v-row> -->
              <v-row class="my-0 ammar" dense>
                <v-textarea
                  outlined
                  name="input-7-1"
                  label="ألملاحظات"
                  v-model="selected.notice"
                ></v-textarea>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" :disabled="!valid" @click="editConfirmed()">
              حفظ
            </v-btn>

            <v-btn color="red darken-1" text @click="cancelEdit()">
              إلغاء
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Add Modal -->
  </div>
</template>

<script>
import ApiService from "../services/DriverService.js";

export default {
  data() {
    return {
      valid: false,
      selected: {},
      entities: [],
      total: 0,
      search: "",
      options: {
        Id: null,
        name: null,
        nationality: null,
        sponser: null,
        sponserMob: null,
        residencyNo: null,
      },
      dialog: false,
      loadingStates: {
        table: false,
      },
      headers: [
        {
          text: "رقم",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "الاسم", value: "name" },
        { text: "الجنسية", value: "nationality" },
        { text: "الكفيل", value: "sponser" },
        { text: "جوال السائق", value: "sponserMob" },
        { text: "رقم الهوية", value: "residencyNo" },
        { text: "سائق داخلى", value: "isInternal" },
        { text: "مسح / تعديل", value: "actions", align: "left" },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    resetOptions() {
      this.options = {
        Id: null,
        name: null,
        nationality: null,
        sponser: null,
        sponserMob: null,
        residencyNo: null,
      };
    },
    formatDate(date) {
      if (!date) return "";
      return new Date(date).toISOString().substr(0, 10);
    },
    getData() {
      this.loadingStates.table = true;
      if (this.options) this.options.searchTerm = this.search;
      ApiService.query(this.$clean(this.options))
        .then((resp) => {
          this.entities = resp.data.items;
          this.entities.forEach((e) => {
            e.residencyIss = this.formatDate(e.residencyIss);
            e.residencyExp = this.formatDate(e.residencyExp);
            e.licenseIss = this.formatDate(e.licenseIss);
            e.licenseExp = this.formatDate(e.licenseExp);
          });
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
        });
    },
    editConfirmed() {
      ApiService.edit(this.selected)
        .then((resp) => {
          this.dialog = false;
          var message = "تم تعديل العنصر بنجاح";
          if (!this.selected.id) {
            this.addToArr(this.entities, resp.data);
            message = "تم إضافة العنصر بنجاح";
          } else {
            this.updateArr(this.entities, resp.data);
          }

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.dialog = false;
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },

    cancelEdit() {
      this.dialog = false;
      this.selected = {};
    },
    add() {
      let d = this.formateDateWithMoment(new Date(), "YYYY-MM-DD");
      (this.selected = {
        residencyIss: d,
        residencyExp: d,
        licenseIss: d,
        licenseExp: d,
      }),
        (this.dialog = true);
    },
    edit(id) {
      this.selected = { ...this.entities.filter((e) => e.id == id)[0] };
      this.dialog = true;
    },
    del(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف العنصر",
          title: "  حذف سائق  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                ApiService.delete(id)
                  .then((resp) => {
                    const index = this.entities.indexOf(
                      this.entities.filter((e) => e.id == id)[0]
                    );
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("تم مسح العنص بنجاح", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("خطأ اثناء مسح العنصر", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => {});
    },
    view(id) {},
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" >
</style>