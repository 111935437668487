<template>
  <div>
    <span>About</span>
    <v-alert color="orange" outlined type="success">Hello</v-alert>

    <v-btn depressed color="primary"> Primary </v-btn>
  </div>
</template>
<script>
export default {
  name: "About",
  components: {},
};
</script>
