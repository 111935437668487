<template>
  <v-footer absolute id="footer">
    <div
      id="footer"
      class="row d-flex justify-content-center align-content-center"
    >
      <span>
        Made with <i class="fas fa-heart ml-2" style="color: #e25555"></i>
      </span>
      <span class="ml-3">© All rights Reversed</span>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style >
#footer {
  height: 40px;
  background-color: black;
  color: white;
}
</style>
