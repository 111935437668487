<template>
  <div v-can="'ShipmentDelivery.View'">
    <div>
      <v-container>
        <v-row>
          <v-col cols="4">
            <v-select :items="mode" item-text="name" hide-details item-value="id" v-model="selected.mode" outlined dense
              label="نوع الاستعلام"></v-select>
          </v-col>
          <v-col cols="12" sm="4" v-if="selected.mode == 2">
            <v-autocomplete outlined hide-details dense :rules="[allRules.required]" v-model="options.distributorId"
              :items="distributers" item-text="name" item-value="id" :loading="loadingStates.table"
              label="الموزعين "></v-autocomplete>
          </v-col>
          <v-col class="d-flex justify-content-end">
            <v-btn class="white--text" color="blue" @click="viewDistBalance">
              استعلام الموزعين
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div>
      <v-row class="mr-36 mx-auto">
        <v-col cols="12">
          <v-radio-group v-model="options.state" row mandatory>
            <v-radio :value="0" label="بوالص لم تسلم"></v-radio>
            <v-radio :value="1" label="بوالص تحت التحصيل"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-card class="ma-4 mx-auto">
        <v-data-table fixed :headers="headers" v-model="selectedShipments" :single-select="singleSelect" item-key="id"
          :show-select="
            showBarcode
              ? true
              : false || options.state == 0 || selected.mode == 2
          " :items="entities" :options.sync="options" fixed-header :items-per-page="100" :server-items-length="total"
          :search="options.searchTerm" :loading="loadingStates.table" class="elevation-1" :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-plus',
            nextIcon: 'mdi-minus',
            'items-per-page-text': 'عدد البوالص ' + total + '',
          }">
          <template v-slot:top>
            <v-row>
              <v-col cols="2">
                <v-btn class="ma-2 orange" @click="useBarcode" text color="white">{{
                  showBarcode? "انهاء": "استخدام الباركود"
                }}</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-text-field dense v-model="options.searchTerm" label="بحث" class="mx-4"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="barCode" v-if="showBarcode" @keydown.enter.prevent="selectBarcode" dense
                  label=" الباركود" class="mx-4"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-autocomplete dense outlined hide-details v-if="!loadingStates.table" auto-select-first label="المسار"
                  :items="lines" item-value="id" item-text="name" v-model="options.lineId"></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <DatePicker-layout @myEvent="options.startDate = $event" :date.sync="options.startDate"
                  label="التاريخ من "></DatePicker-layout>
              </v-col>
              <v-col cols="3">
                <DatePicker-layout @myEvent="options.endDate = $event" :date.sync="options.endDate"
                  label="التاريخ الى "></DatePicker-layout>
              </v-col>
            </v-row>

            <v-spacer></v-spacer>

            <div>
              <!-- update buttons  start-->
              <v-row>
                <v-col cols="1">
                  <v-btn v-can="'ShipmentDelivery.Create'" @click="selectDistrbutor()"
                    v-if="options.state == 0 || showBarcode" class="ma-2 error"
                    :disabled="selectedShipments.length < 1">اضافه لموزع</v-btn>
                  <div v-if="selected.mode == 2 && options.state == 1">
                    <v-menu>
                      <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-can="'ShipmentDelivery.Create'" :disabled="selectedShipments.length < 1"
                              color="error" class="d-block ma-3" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                              <v-icon>mdi-lock</v-icon>تغيير الحاله
                            </v-btn>
                          </template>
                          <span>الحالة</span>
                        </v-tooltip>
                      </template>

                      <v-card class="text-center" width="500">
                        <v-list>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title class="text-h5">الحاله : <v-btn class="ma-1 white--text" color="warning">تحت التحصيل
                                </v-btn></v-list-item-title>
                              <v-divider></v-divider>
                              <v-row>
                <v-col cols="6">
                  <div class="rounded-lg pa-2 text-center grey lighten-3">
                    <h4>عدد البوالص</h4>
                    <h5>{{ selectedShipments.length }}</h5>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="rounded-lg grey pa-2 text-center lighten-3">
                    <h4>الاجمالى</h4>
                    <h5>{{ polisasValue(selectedShipments) }} ريال</h5>
                  </div>
                </v-col>
                              </v-row>
                              <v-divider></v-divider>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item-action class="mt-0" style="margin: 20px">
                            <v-list-item-title>
                              <v-btn v-can="'ShipmentDelivery.Update'" class="ma-1 white--text"
                                @click="updateDeliveryState(2)" color="success">سلمت</v-btn>
                              <v-btn v-can="'ShipmentDelivery.Update'" class="ma-1 white--text"
                                @click="updateDeliveryState(0)" color="error">لم تسلم</v-btn>
                            </v-list-item-title>
                          </v-list-item-action>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
              <!-- update buttons  end -->
            </div>
          </template>
          <template>
            <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
          </template>

          <template v-slot:[`item.state`]="{ item }">
            <AppState :state="item.state" :options="enums.ShipmentStates" :disableMenu="true" />
          </template>
          <template v-can="'ShipmentDelivery.Create'" v-slot:[`item.actions`]="{ item }">
            <v-icon color="green" @click="selectDistrbutor(item)">mdi-account-switch</v-icon>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date | ToDate }}
          </template>
          <template v-slot:[`item.senderInfo`]="{ item }">
            <span v-if="item.senderInfo">
              <span v-if="item.senderInfo">{{ item.senderInfo.name }}</span>
            </span>
          </template>
          <template v-slot:[`item.receiverInfo`]="{ item }">
            <span v-if="item.receiverInfo">
              <span v-if="item.receiverInfo.name">{{
                item.receiverInfo.name
              }}</span>
            </span>
          </template>
          <template v-slot:[`item.details`]="{ item }">
            <v-btn v-can="'ShipmentDelivery.View'" color="orange" outlined elevation="2" class="mx-2"
              @click="view(item.id)" icon><v-icon>mdi-eye-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <!-- modal start -->
      <v-dialog v-model="modals.viewShipment" width="80%" class="pa-20" ref="test" persistent>
        <v-card class="p-2">
          <ViewShipment :selected="selectedview" @hide="modals.viewShipment = false" ref="viewshipmentModal" />
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" width="50%" persistent>
        <v-card>
          <v-card-title class="headline">
            <h2 v-if="!showPrint">تسليم لموزع</h2>
          </v-card-title>
          <v-card-text>
            <v-container v-if="!showPrint">
              <v-row>
                <v-col cols="6">
                  <div class="rounded-lg pa-2 text-center grey lighten-3">
                    <h4>عدد البوالص</h4>
                    <h5>{{ selectedShipments.length }}</h5>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="rounded-lg grey pa-2 text-center lighten-3">
                    <h4>الاجمالى</h4>
                    <h5>{{ polisasValue(selectedShipments) }} ريال</h5>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete outlined hide-details dense :rules="[allRules.required]" v-model="selected.distObject"
                    :items="distributers" return-object item-text="name" item-id="id" :loading="loadingStates.table"
                    auto-select-first label="اسم الموزع"></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
            <div v-else class="text-center">
              <h2>طباعه البوالص المحددة للمندوب</h2>
              <v-row>
                <v-col cols="12">
                  <v-btn icon @click="print">
                    <v-icon large color="error">mdi-printer</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!showPrint" :disabled="!selected.distObject" color="primary"
              @click="addToDistributor()">حفظ</v-btn>
            <v-btn color="red darken-1" text @click="cancelEdit()">إلغاء</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- modal end -->
    </div>
    <!-- Balance Dialog -->
    <v-dialog v-model="distBalanceDialog" width="800">
      <v-card>
        <v-card-title class="indigo white--text text-h5">
          استعلام الموزعين
        </v-card-title>
        <v-row class="pa-4" justify="space-between">
          <!--<v-col cols="6">
            <DatePicker-layout @myEvent="options.startDate = $event" :date.sync="options.startDate"
              label="التاريخ من "></DatePicker-layout>
          </v-col>
          <v-col cols="6">
            <DatePicker-layout @myEvent="options.endDate = $event" :date.sync="options.endDate"
              label="التاريخ الى "></DatePicker-layout>
          </v-col>-->
          <v-col cols="12">
            <v-treeview :items="distBalancedata" :active.sync="active" :open.sync="open" activatable color="warning"
              open-on-click transition>
              <template v-slot:label="{ item }">
                <div v-if="item" class="treeeee">

                </div>
              </template>
              <template v-slot:prepend="{ item }">
                <v-icon v-if="!item.children">
                  mdi-account
                </v-icon>
                  <!--{{item.name + (item.countInDelivery ? ` | بوالص تحت التحصيل : ${item.countInDelivery}` : "") +(item.balanceInDelivery ? ` | اجمالى : ${item.balanceInDelivery} ريال ` : "") + (item.countDelivered ? ` | بوالص سلمت : ${item.countDelivered}` : "") +(item.balanceDelivered ? ` | اجمالى : ${item.balanceDelivered} ريال ` : "")}}-->
                {{item.name + (item.countInDelivery ? ` | بوالص تحت التحصيل : ${item.countInDelivery}` : "") + (item.balanceInDelivery ? ` | اجمالى : ${item.balanceInDelivery} ريال ` : "") }}

              </template>
            </v-treeview>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import shipmentService from "../services/ShipmentService.js";
import TripService from "../services/TripsService.js";
import DistributersService from "../services/DistributersService.js";
import LinesService from "../services/LinesService.js";
import ShipmentService from "../services/ShipmentService.js";
import Enums from "/src/plugins/enums.js";
export default {
  data() {
    return {
      modals: {
        viewShipment: false,
      },
      row: null,
      enums: Enums,
      barCode: null,
      singleSelect: false,
      selectedShipments: [],
      selected: { mode: 1 },
      selectedview: {},
      branchId: 2,
      polisaDialog: false,
      search: "",
      showPrint: false,
      polisaData: [],
      mode: [
        { id: 1, name: "تسليم" },
        { id: 2, name: "استلام من موزع" },
      ],
      distributers: [],
      lines: [],
      entities: [],
      persistent: true,
      loadingStates: { table: false },
      options: {
        state: null,
        distributorId: null,
        startDate: null,
        endDate: null,
      },
      total: 15,
      dialog: false,
      distBalanceDialog: false,
      active: [],
      open: [],
      distBalancedata: [],
      showBarcode: false,
      message: "",
    };
  },
  mounted() {
    DistributersService.typeHead("", true).then((resp) => {
      this.distributers = resp.data;
    });
    LinesService.typeHead("", true, true, false).then((resp) => {
      this.lines = resp.data;
    });
  },

  methods: {
    fetchDistBalance() {
      this.active = [];
      this.open = [];
      this.distBalancedata = [];
      return ShipmentService.distributorsBalance(this.$clean(this.options)).then((resp) => {
        this.distBalancedata = resp.data;
      });
    },
    fetchDistBranchBalance() {
      this.active = [];
      this.open = [];
      this.distBalancedata = [];
      return ShipmentService.distributorsBranchBalance().then((resp) => {
        this.distBalancedata = resp.data;
      });
    },
    viewDistBalance() {
      this.fetchDistBranchBalance();
      this.distBalanceDialog = true;
    },
    useBarcode() {
      if (this.showBarcode) {
        this.showBarcode = false;
        this.entities = [];
        this.getData();
        this.selectedShipments = [];
      } else {
        this.showBarcode = true;
        this.entities = [];
        this.total = 0;
        this.selectedShipments = [];
      }
    },
    selectBarcode() {
      shipmentService.getByTrackingNumber(this.barCode).then((resp) => {
        let Shipments = resp.data.items[0];
        if (Shipments == null) {
          this.barCode = null;
          this.message = "   البوليصة مسلمة من قبل ";
          this.$dialog.notify.error(this.message, {
            position: "top-right",
            timeout: 3000,
          });
        } else {
          let item = this.entities.find((i) => i.id == Shipments.id);
          if (item) {
            this.barCode = null;
            this.message = "  البوليصة مضافة للتسليم ";
            this.$dialog.notify.error(this.message, {
              position: "top-right",
              timeout: 3000,
            });
          } else {
            this.entities.push(Shipments);
            this.selectedShipments.push(Shipments);
            this.total = this.selectedShipments.length;
            this.barCode = null;
            this.message = "تم ادراج البوليصة بنجاح للتسليم";
            this.$dialog.notify.success(this.message, {
              position: "top-right",
              timeout: 3000,
            });
          }
        }
      });
    },
    print() {
      let routeData = this.$router.resolve({
        name: "ShipmentBarCode",
        query: { isClient: false, distributorId: this.selected.distObject.id, shipmentsIds: JSON.stringify(this.selectedShipments.map((e) => e.id)) },
      });
      window.open(routeData.href, "_blank");
    },
    polisasValue(items) {
      console.log(items);
      let tottal = items.filter(item => {
         return item.paymentType == 2
      }).reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.totalPrice;
      }, 0);
      return tottal;
    },
    cancelEdit() {
      this.dialog = false;
      this.selected.distObject = null;
      this.showPrint = false;
      this.selectedShipments = [];
    },
    getData() {
      shipmentService
        .clientDelivery(this.$clean(this.options))
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
        })
        .catch((resp) => { })
        .finally(() => { });
    },
    view(id) {
      shipmentService
        .getById(id)
        .then((resp) => {
          this.selectedview = resp.data;
          this.modals.viewShipment = true;
        })
        .catch((resp) => { })
        .finally(() => { });
    },
    async addToDistributor() {
      this.showPrint = true;
      let model = {
        distributorId: this.selected.distObject.id,
        shipmentsIds: this.selectedShipments.map((e) => e.id),
      };

      ShipmentService.addToDistributor(model)
        .then((resp) => {
          this.getData();
        })
        .catch((resp) => { })
        .finally(() => { });
    },
    selectDistrbutor(item) {
      if (item) {
        this.selectedShipments = [];
        this.selectedShipments.push(item);
      }
      this.dialog = true;
    },

    updateDeliveryState(state) {
      var model = {
        state,
        shipmentsIds: this.selectedShipments.map((e) => e.id),
      };
      ShipmentService.receiveFromDistributor(model)
        .then((resp) => {
          this.getData();
        })
        .catch()
        .finally();
    },
  },
  computed: {
    headers() {
      let headers;
      headers = [
        {
          text: "رقم",
          align: "start",
          sortable: false,
          value: "trackingNumber",
          align: "center",
        },
        { text: "التاريخ", value: "date", align: "center", sortable: true },
        {
          text: "الرقم اليدوى",
          value: "manualNo",
          align: "center",
          sortable: true,
        },

        { text: "المسار", value: "trip.line.name", align: "center" },
        {
          text: "المرسل",
          value: "senderInfo",
          align: "center",
          sortable: true,
        },
        {
          text: "المستلم",
          value: "receiverInfo",
          align: "center",
          sortable: true,
        },
        {
          text: "اجمالي السعر",
          value: "totalPrice",
          align: "center",
          sortable: true,
        },
        { text: "الحاله", value: "state", align: "center", sortable: true },
        { text: "التفاصيل", value: "details", align: "center", sortable: true },
      ];
      return headers;
    },
  },

  watch: {
    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    options: {
      handler() {
        if (this.distBalanceDialog)
        {
          //this.fetchDistBalance();
        }
        else {
          this.getData();
        }
      },
      deep: true,
    },
    "options.distributorId": {
      handler() {
        if(this.selected.mode == 2)
        this.selectedShipments = [];
      },
    },
    "selected.mode": {
      handler(newValue) {
        if (newValue == 1) {
          this.options.distributorId = 0;
        } else {
          this.options.state = 1;
          this.getData();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
</style>