export default [
    {
        path: '/shipping/cars',
        name: 'CarList',
        meta: { roles: ['SystemAdmin', 'Manager', 'BranchManager', 'User'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/CarList.vue')
    },
    {
        path: '/shipping/distribution-cars',
        name: 'DistributionCars',
        meta: { roles: ['SystemAdmin', 'Manager', 'BranchManager'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/DistributionCars.vue')
    },
    {
        path: '/shipping/distributors',
        name: 'distributors',
        meta: { roles: ['SystemAdmin', 'Manager', 'BranchManager'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/Distributors.vue')
    },
    {
        path: '/shipping/shipment',
        name: 'shipment',
        meta: { requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/Shipment.vue'),
    },
    {
        path: '/shipping/shipment-delivery',
        name: 'ShipmentDelivery',
        meta: { roles: ['SystemAdmin', 'Manager', 'BranchManager', 'User'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/ShipmentDelivery.vue'),
    },
    {
        path: '/shipping/shipment-client-delivery',
        name: 'ShipmentClientDelivery',
        meta: { requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/ShipmentClientDelivery.vue'),
    },
    {
        path: '/shipping/shipment-client-call',
        name: 'ShipmentClientCall',
        meta: { requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/ShipmentClientCall.vue'),
    },
    {
        path: '/shipping/search',
        name: 'search',
        meta: { requiresAuth: true, layout: 'dashboard' },
        component: () => import('./components/ShipmentSearch.vue'),
    },
    {
        path: '/shipping/Drivers',
        name: 'DriverList',
        meta: { roles: ['SystemAdmin', 'Manager', 'BranchManager', 'User'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/DriverList.vue')
    },
    {
        path: '/shipping/Lines',
        name: 'LineList',
        meta: { roles: ['SystemAdmin', 'Manager'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/LineList.vue')
    },
    {
        path: '/shipping/trips',
        name: 'TripsList',
        meta: { roles: ['SystemAdmin', 'Manager', 'User', 'BranchManager', 'Accountant'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/TripsList.vue')
    },
    {
        path: '/shipping/salesmen',
        name: 'SalesMen',
        meta: { roles: ['SystemAdmin', 'Manager'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/SalesMen.vue')
    },
    {
        path: '/shipping/clients',
        name: 'clients',
        meta: { roles: ['SystemAdmin', 'Manager', 'User', 'Treasurer', 'BranchManager'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/Clients.vue')
    },
    {
        path: '/shipping/external-shippers',
        name: 'ExternalShippersList',
        meta: { roles: ['SystemAdmin', 'Manager'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/ExternalShippersList.vue')
    },
    {
        path: '/shipping/books',
        name: 'books',
        meta: { roles: ['SystemAdmin', 'Manager', 'User'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/BookList.vue')
    },
    {
        path: '/shipping/tickets',
        name: 'Tickets',
        meta: { requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/Tickets.vue')
    },
    {
        path: '/shipping/shipments-report',
        name: 'ShipmentscityReport',
        meta: { roles: ['SystemAdmin', 'Manager', 'BranchManager'], requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/ShipmentsCityReport.vue')
    },
    {
        path: '/shipping/shipments-discounts',
        name: 'ShipmentsDiscounts',
        meta: {  requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/ShipmentsDiscounts.vue')
    },
]