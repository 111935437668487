import Api from '../../shared/services/api'
const baseUrl = "branchorders";
export default {
    get() {
        return Api().get(baseUrl);
    },
    edit(entity) {
        return Api().put(baseUrl + `/${entity.id}`);
    },
    add(id) {
        return Api().post(baseUrl + `?orderType=${id}`);
    },
}