import Api from '../../shared/services/api'

const baseUrl = "ShipmentsDiscounts";
export default {
    query(options) {
        const qParams = new URLSearchParams(options);
        return Api().get(baseUrl + "?" + qParams.toString());
    },
    edit(branchId, entity, records) {
        console.log(entity);
        console.log(records);
        if (entity.id)
            return Api().put(baseUrl + `/${entity.id}`, entity);
        else
            return this.add(branchId, records);
    },
    getByReportId(id) {
        return Api().get(`${baseUrl}/GetByReportId/${id}`);
    },
    add(branchId, entity) {
        return Api().post(baseUrl + "?branchId=" +`${branchId}`, entity);
    },
    delete(id) {
        return Api().delete(baseUrl + `/${id}`);
    }
}