var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.getData().name != '')?_c('section',{staticClass:"shipment-info"},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('AppLabel',{attrs:{"label":"الموظف المسلم","color":"green lighten-3","value":_vm.getData().name}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('AppLabel',{attrs:{"label":"تاريخ التسليم","value":_vm._f("ToDate")(_vm.getData().date)}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('AppLabel',{attrs:{"label":"ساعة التسليم","value":_vm._f("ToTime")(_vm.getData().date)}})],1)],1)],1):_vm._e(),(
    _vm.shipment.recipientType == 3 &&
    _vm.shipment.externalShipperDeliveryInfo != null
  )?_c('v-row',[_c('v-col',[_c('AppLabel',{attrs:{"label":"الإسم","value":_vm.shipment.externalShipperDeliveryInfo.externalShipper.name}})],1),_c('v-col',[(_vm.shipment.externalShipperDeliveryInfo.externalShipperCity.phoneNumber)?_c('AppLabel',{attrs:{"label":"رقم هاتف المدينة","value":_vm.shipment.externalShipperDeliveryInfo.externalShipperCity.phoneNumber}}):_vm._e()],1),_c('v-col',[(_vm.shipment.externalShipperDeliveryInfo.externalShipperCity.city)?_c('AppLabel',{attrs:{"label":"المدينة","value":_vm.shipment.externalShipperDeliveryInfo.externalShipperCity.city.name}}):_vm._e()],1),_c('v-col',[(_vm.shipment.externalShipperDeliveryInfo.amount)?_c('AppLabel',{attrs:{"label":"اجرة المخرج","value":_vm.shipment.externalShipperDeliveryInfo.amount}}):_vm._e()],1),_c('v-col',[(_vm.shipment.externalShipperDeliveryInfo.imgUrl)?_c('v-btn',{staticClass:"mx-3",attrs:{"color":"success"},on:{"click":function($event){return _vm.viewImg(_vm.shipment.externalShipperDeliveryInfo.imgUrl)}}},[_vm._v("عرض سند المخرج "),_c('v-icon',[_vm._v("mdi-eye-outline")])],1):_vm._e()],1)],1):_vm._e(),(
    _vm.shipment.recipientType == 2 && _vm.shipment.recipientDeliveryInfo != null
  )?_c('v-row',[_c('v-col',[_c('AppLabel',{attrs:{"label":"الإسم","value":_vm.shipment.recipientDeliveryInfo.recipient.name}})],1),_c('v-col',[_c('AppLabel',{attrs:{"label":"رقم التليفون","value":_vm.shipment.recipientDeliveryInfo.recipient.phoneNumber}})],1),_c('v-col',[_c('AppLabel',{attrs:{"label":"رقم الهوية","value":_vm.shipment.recipientDeliveryInfo.recipient.idNumber}})],1)],1):_vm._e(),(
    _vm.shipment.recipientType == 1 && _vm.shipment.distributorDeliveryInfo != null
  )?_c('v-row',[_c('v-col',[_c('AppLabel',{attrs:{"label":"الإسم","value":_vm.shipment.distributorDeliveryInfo.distributor.name}})],1),_c('v-col',[_c('AppLabel',{attrs:{"label":"الفرع","value":_vm.shipment.distributorDeliveryInfo.distributor.branch.name}})],1),_c('v-col',[_c('AppLabel',{attrs:{"label":"رقم التليفون","value":_vm.shipment.distributorDeliveryInfo.distributor.mob}})],1)],1):_vm._e(),(_vm.getData().notice != '')?_c('v-row',[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","hide-details":"","readonly":"","disabled":"","label":"الملاحظات","rows":"2"},model:{value:(_vm.getData().notice),callback:function ($$v) {_vm.$set(_vm.getData(), "notice", $$v)},expression:"getData().notice"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }