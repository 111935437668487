<template>
  <div class="container rounded-lg pa-3">
    <div>
      <div>
        <h2 class="text-center font-weight-bold black--text">
          الصفحة الرئيسية
        </h2>
        <div class="rounded-lg success">
          <div align="center">
            <div class="text-center">
              <h4 class="green--text font-weight-bold">
                <p class="rounded-lg white green--text pa-1 text-center">
                  فرع {{ data.cityName }}
                </p>
              </h4>
            </div>
          </div>
        </div>
        <v-col cols="12" class="mt-0">
          <v-btn @click="order(1)" class="mx-3" color="success"
            >طلب احبار
            <v-icon>mdi-printer</v-icon>
          </v-btn>
          <v-btn class="mx-3" color="primary" @click="order(2)"
            >طلب ورق طابعة
            <v-icon>mdi-paper</v-icon>
          </v-btn>
          <v-btn @click="order(3)" color="info" class="mx-3">
            طلب ورق بوالص <v-icon>mdi-paper</v-icon></v-btn
          >
        </v-col>
        <div class="mt-10">
          <v-row justify="center">
            <v-col cols="12" sm="5" class="ma-2 mb-3 rounded-lg primary">
              <v-row align="center">
                <v-col cols="7" class="text-center">
                  <h4 class="white--text font-weight-bold">رصيد المستودع</h4>
                  <p class="white--text caption">رصيد كل المستودع</p>
                </v-col>
                <v-col cols="3" class="mr-16 pt-0">
                  <div>
                    <p class="rounded-lg white blue--text pa-1 text-center">
                      {{ data.balance }} ريال
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="5" class="ma-2 mb-3 rounded-lg primary">
              <v-row align="center">
                <v-col cols="7" class="text-center">
                  <h4 class="white--text font-weight-bold">رصيد تحت التحصيل</h4>
                  <p class="white--text caption"> . </p>
                </v-col>
                <v-col cols="3" class="mr-16 pt-0">
                  <div>
                    <p class="rounded-lg white blue--text pa-1 text-center">
                      {{ data.balanceInDelivery }} ريال
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="5" class="ma-2 mb-3 rounded-lg primary">
              <v-row align="center">
                <v-col cols="7" class="text-center">
                  <h4 class="red--text font-weight-bold">شحنات لم تسلم</h4>
                  <p class="white--text caption">شحنات لم تسلم</p>
                </v-col>
                <v-col cols="3" class="mr-16 pt-0">
                  <div>
                    <p class="rounded-lg white red--text pa-1 text-center">
                      {{ data.pending }} شحنة
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="5" class="ma-2 mb-3 rounded-lg warning">
              <v-row align="center">
                <v-col cols="7" class="text-center">
                  <h4 class="black--text font-weight-bold">
                    شحنات تحت التحصيل
                  </h4>
                  <p class="black--text caption">شحنات تحت التحصيل</p>
                </v-col>
                <v-col cols="3" class="mr-16 pt-0">
                  <div>
                    <p class="rounded-lg white black--text pa-1 text-center">
                      {{ data.inDelivery }} شحنة
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>

          
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../services/DashboardService.js";
import OrdersService from "../../System/services/BranchOrdersService.js";
export default {
  name: "Dashboard",
  data() {
    return {
      data: [],
    };
  },
  created() {
    try {
      if (!this.$store.getters.isAuthenticated) {
        this.$router.push("/login");
      }
    } catch (error) {
      console.log(error);
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    order(id) {
      OrdersService.add(id);
    },
    getData() {
      ApiService.get().then((resp) => {
        this.data = resp.data.totals;
      });
    },
  },
};
</script>

<style>
</style>