import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from './plugins/axios'

import Mixins from './plugins/mixins';
import Filters from './plugins/filters';
import './plugins/components';
import "./plugins/vendors";
import "@/assets/global.css"

Vue.config.productionTip = false;

import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
Vue.mixin(Mixins);
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
})
Vue.directive('can', {
  bind: function (el, binding) {
    if (!JSON.parse(localStorage.getItem('permissions'))
      .includes(binding.expression
        .replace(/'/g, "")
        .replace(/"/g, ""))) {
      el.style.display = "none";
    }
  }
})
Vue.directive('notcan', {
  bind: function (el, binding) {
    if (JSON.parse(localStorage.getItem('permissions'))
      .includes(binding.expression
        .replace(/'/g, "")
        .replace(/"/g, ""))) {
      el.style.display = "none";
    }
  }
})
new Vue({
  router,
  store,
  vuetify,

  created: function () {
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logOut')
        }
        throw err;
      });
    });

    axios.interceptors.request.use(
      config => {
        const token = localStorage.getItem('token');
        config.headers['Authorization'] = token;
        return config;
      });
  },
  render: (h) => h(App)
}).$mount('#app');
