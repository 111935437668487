<template>
  <div>
    <section class="shipment-info" v-if="getData().name != ''">
      <v-row>
        <v-col cols="5">
          <AppLabel label="الموظف المسلم" color="green lighten-3" :value="getData().name" />
        </v-col>
        <v-col cols="4">
          <AppLabel label="تاريخ التسليم" :value="getData().date | ToDate" />
        </v-col>
        <v-col cols="3">
          <AppLabel label="ساعة التسليم" :value="getData().date | ToTime" />
        </v-col>
      </v-row>
    </section>
    <!-- Distributer info -->
    <v-row v-if="
      shipment.recipientType == 3 &&
      shipment.externalShipperDeliveryInfo != null
    ">
      <v-col>
        <AppLabel label="الإسم" :value="shipment.externalShipperDeliveryInfo.externalShipper.name" />
      </v-col>
      <v-col>
        <AppLabel v-if="shipment.externalShipperDeliveryInfo.externalShipperCity.phoneNumber" label="رقم هاتف المدينة"
          :value="shipment.externalShipperDeliveryInfo.externalShipperCity.phoneNumber" />
      </v-col>
      <v-col>
        <AppLabel v-if="shipment.externalShipperDeliveryInfo.externalShipperCity.city" label="المدينة"
          :value="shipment.externalShipperDeliveryInfo.externalShipperCity.city.name" />
      </v-col>
      <v-col>
        <AppLabel v-if="shipment.externalShipperDeliveryInfo.amount" label="اجرة المخرج"
          :value="shipment.externalShipperDeliveryInfo.amount" />
      </v-col>
      <v-col>
        <v-btn v-if="shipment.externalShipperDeliveryInfo.imgUrl"
          @click="viewImg(shipment.externalShipperDeliveryInfo.imgUrl)" class="mx-3" color="success">عرض سند المخرج
          <v-icon>mdi-eye-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Distributer info -->

    <!-- Client info -->

    <v-row v-if="
      shipment.recipientType == 2 && shipment.recipientDeliveryInfo != null
    ">
      <v-col>
        <AppLabel label="الإسم" :value="shipment.recipientDeliveryInfo.recipient.name" />
      </v-col>
      <v-col>
        <AppLabel label="رقم التليفون" :value="shipment.recipientDeliveryInfo.recipient.phoneNumber" />
      </v-col>
      <v-col>
        <AppLabel label="رقم الهوية" :value="shipment.recipientDeliveryInfo.recipient.idNumber" />
      </v-col>
    </v-row>
    <v-row v-if="
      shipment.recipientType == 1 && shipment.distributorDeliveryInfo != null
    ">
      <v-col>
        <AppLabel label="الإسم" :value="shipment.distributorDeliveryInfo.distributor.name" />
      </v-col>
      <v-col>
        <AppLabel label="الفرع" :value="shipment.distributorDeliveryInfo.distributor.branch.name" />
      </v-col>
      <v-col>
        <AppLabel label="رقم التليفون" :value="shipment.distributorDeliveryInfo.distributor.mob" />
      </v-col>
    </v-row>
    <v-row v-if="getData().notice != ''">
      <v-col cols="12" class="my-0 py-0">
        <v-textarea outlined hide-details readonly disabled label="الملاحظات" v-model="getData().notice" rows="2">
        </v-textarea>
      </v-col>
    </v-row>
    <!-- Client info -->
  </div>
</template>

<script>
export default {
  props: ["shipment"],
  data() {
    return {};
  },
  methods: {
    viewImg(img) {
      this.$dialog.info({
        width: "100%",
        title: "سند المخرج",
        text: `<img src="/${img}" style="width:100%;" alt="" />`,
        actions: {
          false: {
            text: "الغاء",
          },
        },
      });
    },
    getData() {
      let name = "";
      let date = null;
      let notice = "";
      if (this.shipment.recipientDeliveryInfo != null) {
        name = this.shipment.recipientDeliveryInfo.createdBy.name;
        date = this.shipment.recipientDeliveryInfo.createDate;
        notice = this.shipment.recipientDeliveryInfo.notice;
      } else if (this.shipment.externalShipperDeliveryInfo != null) {
        name = this.shipment.externalShipperDeliveryInfo.createdBy.name;
        date = this.shipment.externalShipperDeliveryInfo.createDate;
        notice =
          this.shipment.externalShipperDeliveryInfo.notice;
      } else if (this.shipment.distributorDeliveryInfo != null) {
        name = this.shipment.distributorDeliveryInfo.createdBy.name;
        date = this.shipment.distributorDeliveryInfo.createDate;
        notice = this.shipment.distributorDeliveryInfo.notice;
      }
      return { name, date, notice };
    },
  },
};
</script>
<style>
</style>