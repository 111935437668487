var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[(_vm.printData)?_c('div',{staticClass:"content"},[_c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"data pa-2 text-h4 font-weight-bold text-center",attrs:{"cols":"4","evaluate":"2"}},[_vm._v(" "+_vm._s(this.$store.getters.companyName)+" ")])],1)],1),_c('div',[_c('v-row',{staticClass:"ammar",attrs:{"justify":"space-between","dense":""}},[_c('v-col',{staticClass:"mt-9 text-right",attrs:{"cols":"5"}},[_c('h5',{staticClass:"mb-1"},[_vm._v("حركة البوالص حسب المعطيات")])]),_c('v-col',{staticClass:"mt-5 text-right",attrs:{"cols":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" تاريخ الطباعة ")]),_c('v-col',{attrs:{"cols":"5"}},[_vm._v(_vm._s(_vm.print.date))])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" التوقيت ")]),_c('v-col',{attrs:{"cols":"5"}},[_vm._v(_vm._s(_vm.print.time))])],1)],1)],1)],1),_c('v-row',{staticClass:"mb-5"},[_c('v-col',{attrs:{"cols":"12 m-0 p-0 mt-5"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.shipments,"server-items-length":_vm.total,"items-per-page":-1,"group-by":"paymentType","hide-default-footer":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
return [_c('th',{staticStyle:{"height":"auto","border":"red solid 2px !important"},attrs:{"colspan":_vm.options.paymentType == null ? 8 : 2}},[_vm._v(" "+_vm._s(_vm._f("PaymentTypes")(items[0].paymentType))+" ")]),(
                items[0].paymentType == 1 && _vm.options.paymentType == null
              )?_c('th',{staticClass:"text-center red--text",staticStyle:{"height":"auto"},attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.reviewShipments(1).totalquantity)+" ")]):_vm._e(),(
                items[0].paymentType == 2 && _vm.options.paymentType == null
              )?_c('th',{staticClass:"text-center red--text",staticStyle:{"height":"auto"},attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.reviewShipments(2).totalquantity)+" ")]):_vm._e(),(
                items[0].paymentType == 1 && _vm.options.paymentType == null
              )?_c('th',{staticClass:"text-center red--text",staticStyle:{"height":"auto"},attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.reviewShipments(1).totalitemsprice)+" ")]):_vm._e(),(
                items[0].paymentType == 2 && _vm.options.paymentType == null
              )?_c('th',{staticClass:"text-center red--text",staticStyle:{"height":"auto"},attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.reviewShipments(2).totalitemsprice)+" ")]):_vm._e(),(
                items[0].paymentType == 1 && _vm.options.paymentType == null
              )?_c('th',{staticClass:"text-center red--text",staticStyle:{"height":"auto"},attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.reviewShipments(1).totaladdedValueTax)+" ")]):_vm._e(),(
                items[0].paymentType == 2 && _vm.options.paymentType == null
              )?_c('th',{staticClass:"text-center red--text",staticStyle:{"height":"auto"},attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.reviewShipments(2).totaladdedValueTax)+" ")]):_vm._e(),(
                items[0].paymentType == 1 && _vm.options.paymentType == null && (_vm.options.hasDuePrice == true || _vm.options.duePriceReceived == true)
              )?_c('th',{staticClass:"text-center red--text",staticStyle:{"height":"auto"},attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.reviewShipments(1).totalduePrice)+" ")]):_vm._e(),(
                items[0].paymentType == 2 && _vm.options.paymentType == null && (_vm.options.hasDuePrice == true || _vm.options.duePriceReceived == true)
              )?_c('th',{staticClass:"text-center red--text",staticStyle:{"height":"auto"},attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.reviewShipments(2).totalduePrice)+" ")]):_vm._e(),(
                items[0].paymentType == 1 && _vm.options.paymentType == null && _vm.options.hasOtherServicesPrice == true
              )?_c('th',{staticClass:"text-center red--text",staticStyle:{"height":"auto"},attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.reviewShipments(1).totalotherServicesPrice)+" ")]):_vm._e(),(
                items[0].paymentType == 2 && _vm.options.paymentType == null && _vm.options.hasOtherServicesPrice == true
              )?_c('th',{staticClass:"text-center red--text",staticStyle:{"height":"auto"},attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.reviewShipments(2).totalotherServicesPrice)+" ")]):_vm._e(),(
                items[0].paymentType == 1 && _vm.options.paymentType == null
              )?_c('th',{staticClass:"text-center red--text",staticStyle:{"height":"auto"},attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.reviewShipments(1).totalPrice)+" ")]):_vm._e(),(
                items[0].paymentType == 2 && _vm.options.paymentType == null
              )?_c('th',{staticClass:"text-center red--text",staticStyle:{"height":"auto"},attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.reviewShipments(2).totalPrice)+" ")]):_vm._e()]}},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("ToDate")(item.date))+" ")]}},{key:"item.trip.trackingNumber",fn:function(ref){
              var item = ref.item;
return [(item.trip.trackingNumber)?_c('div',[(item.trip.line.name)?_c('div',[_vm._v(" "+_vm._s(item.trip.line.name)+" ")]):_vm._e(),(item.paymentPaidType == 0)?_c('div',{staticClass:"red--text"},[_vm._v(" غير محدد ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("PaymentPaidTypes")(item.paymentPaidType))+" ")])]):_vm._e()]}},{key:"item.number",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.shipments.indexOf(item) + 1)+" ")]}},{key:"item.items",fn:function(ref){
              var item = ref.item;
return [(item.items)?_c('span',[_c('span',[_vm._v(" "+_vm._s(item.items[0].merchandise.name)+" ")])]):_vm._e()]}},{key:"item.itemprice",fn:function(ref){
              var item = ref.item;
return [(item.items[0])?_c('div',[(item.items[0].price)?_c('div',[_vm._v(" "+_vm._s(_vm.round(item.items[0].price, 2))+" ")]):_vm._e()]):_vm._e()]}},{key:"item.senderInfo",fn:function(ref){
              var item = ref.item;
return [(item.senderInfo)?_c('span',[(item.senderInfo)?_c('span',[_vm._v(" "+_vm._s(item.senderInfo.name))]):_vm._e()]):_vm._e()]}},{key:"item.receiverInfo",fn:function(ref){
              var item = ref.item;
return [(item.receiverInfo)?_c('span',[(item.receiverInfo.name)?_c('span',[_vm._v(" "+_vm._s(item.receiverInfo.name))]):_vm._e(),_c('br'),(item.receiverInfo.phoneNumber)?_c('span',[_vm._v(_vm._s(item.receiverInfo.phoneNumber))]):_vm._e()]):_vm._e()]}}],null,true)},[(_vm.options.paymentType != null)?_c('template',{slot:"body.append"},[_c('tr',{staticClass:"red--text"},[_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.shipmentsTotalquantity)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.shipmentsTotalitemsprice)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.shipmentstotaltax)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.shipmentsPriceTotal)+" ")])])]):_vm._e()],2)],1)],1)],1):_vm._e(),(!_vm.printData)?_c('div',[_vm._v("Loading")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }