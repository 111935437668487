<template>
  <div>
    <v-tabs
      v-model="activeTab"
      fixed-tabs
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
      center-active
      background-color="orange accent-1"
      color="black"
      class="rounded-lg"
      light
    >
      <v-tab>تفاصيل</v-tab>
      <v-tab>التسليم</v-tab>
      <v-tab>تتبع الشحنة</v-tab>
      <!-- Details tab start -->
      <v-tab-item>
        <div class="wrapper p-3">
          <section class="shipment-info">
            <v-row>
              <v-col cols="=12" sm="3">
                <AppLabel label="رقم البوليصة " :value="selected.trackingNumber" />
              </v-col>
              <v-col cols="12" sm="9" class="d-flex justify-items-center">
                <AppState :state="selected.state" :options="enums.ShipmentStates" disableMenu />
              </v-col>
              <v-col cols="12" sm="9" class="d-flex justify-items-center">
                <v-checkbox
                  v-model="selected.isLandPrice"
                  label="تسعير ساحة"
                  color="green darken-3"
                  disabled
                  readonly
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <AppLabel
                  label="الموظف"
                  color="green lighten-3"
                  v-if="selected.createdBy"
                  :value="selected.createdBy.name"
                />
              </v-col>
              <v-col cols="4">
                <AppLabel label="التاريخ" :value="selected.createDate | ToDate" />
              </v-col>
              <v-col cols="3">
                <AppLabel label="الساعه" :value="selected.createDate | ToTime" />
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <span>أخر تعديل تم بواسطة : </span>
              <v-col cols="3">
                <AppLabel
                  label="الموظف"
                  color="green lighten-3"
                  v-if="selected.updatedBy"
                  :value="selected.updatedBy.name"
                />
              </v-col>
              <v-col cols="4">
                <AppLabel label="التاريخ" :value="selected.updateDate | ToDate" />
              </v-col>
              <v-col cols="3">
                <AppLabel label="الساعه" :value="selected.updateDate | ToTime" />
              </v-col>
            </v-row>
          </section>

          <hr />
          <v-row justify="start" align="start" dense class="mb-0">
            <v-col cols="12" sm="4">
              <AppLabel label="التاريخ" :value="selected.date | ToDate" />
            </v-col>

            <v-col cols="12" sm="4">
              <AppLabel label="نوع الخدمة" :value="selected.serviceType.name" />
            </v-col>
            <v-col cols="12" sm="4" v-if="selected.paymentPaidType">
              <AppLabel label="طريقة السداد" :value="selected.paymentPaidType | PaymentPaidTypes" />
            </v-col>
            <v-col cols="12" sm="6">
              <AppLabel label="المسار" :value="selected.trip.line.name" />
            </v-col>

            <v-col cols="12" sm="3">
              <AppLabel label="الرحلة" :value="selected.trip.trackingNumber" />
            </v-col>
            <v-col cols="12" sm="3" v-if="selected.serviceType.id == 2">
              <v-autocomplete
                disabled
                dense
                outlined
                v-model="selected.dueExternalShipperId"
                :items="dueexternalShippers"
                label="المخرج"
                item-text="name"
                item-value="id"
                auto-select-first
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <AppLabel label=" طريقة الدفع" :value="selected.paymentType | PaymentTypes" />
            </v-col>
            <v-col cols="12" sm="4 " v-if="selected.manualNo != 0">
              <AppLabel label="الرقم اليدوى" :value="selected.manualNo" />
            </v-col>
            <v-col cols="12" sm="4" v-if="selected.book != null">
              <AppLabel label="الدفتر" :value="selected.book.manualNo" />
            </v-col>
          </v-row>
          <v-row> </v-row>

          <v-row class="my-0 ammar" dense>
            <v-col cols="12" sm="5" class="d-flex">
              <span>
                <v-icon class="m-2" v-if="selected.senderInfo && selected.senderInfo.id"
                  >fa fa-user</v-icon
                >
              </span>
              <AppLabel label="المرسل" :value="selected.senderInfo.name" />
            </v-col>

            <v-col
              cols="12"
              sm="3"
              v-if="
                selected.senderInfo &&
                selected.senderInfo.branches &&
                selected.senderInfo.branches.length > 0
              "
            >
              <AppLabel label="الفرع" :value="selected.senderInfo.branch" />
            </v-col>

            <v-col cols="12" sm="3">
              <AppLabel label="رقم الجوال" :value="selected.senderInfo.phoneNumber" />
            </v-col>
            <v-col cols="12" sm="3">
              <AppLabel label="رقم هوية المرسل" :value="selected.senderInfo.idNum" />
            </v-col>
            <v-col cols="12" sm="5" class="d-flex">
              <span>
                <v-icon class="m-2" v-if="selected.receiverInfo && selected.receiverInfo.id"
                  >fa fa-user</v-icon
                >
              </span>
              <AppLabel label="المستلم" :value="selected.receiverInfo.name" />
            </v-col>
            <v-col
              cols="12"
              sm="3"
              v-if="
                selected.receiverInfo &&
                selected.receiverInfo.branches &&
                selected.receiverInfo.branches.length > 0
              "
            >
              <AppLabel label="الفرع" :value="selected.receiverInfo.branch" />
            </v-col>
            <v-col cols="12" sm="3">
              <AppLabel label="رقم الجوال" :value="selected.receiverInfo.phoneNumber" />
            </v-col>
            <v-col cols="12" sm="3">
              <AppLabel label="رقم هوية المرسل" :value="selected.receiverInfo.idNum" />
            </v-col>
            <!-- reciver -->

            <v-col cols="12" sm="6">
              <v-row>
                <v-col>
                  <AppLabel
                    v-if="selected.salesman"
                    label="المندوب"
                    :value="selected.salesman.name"
                  />
                  <AppLabel v-if="!selected.salesman" label="المندوب" value="لا يوجد مندوب" />
                </v-col>
              </v-row>
              <v-row v-if="selected.salesman && selected.commessionReceived">
                <v-col class="d-flex justify-content-start align-items-center">
                  <span> تم استلام العمولة </span>
                  <i class="far fa-check-circle mr-2" style="color: green"></i>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-textarea
                outlined
                hide-details
                readonly
                disabled
                label="الملاحظات"
                v-model="selected.notice"
                rows="2"
              >
              </v-textarea>
            </v-col>

            <!-- auto completes -->
          </v-row>
          <hr />
          <v-row>
            <v-col cols="12" class="my-0 py-0">
              <v-switch
                v-model="selected.includeTax"
                label="متضمن  الضريبه"
                disabled
                readonly
              ></v-switch>
            </v-col>
          </v-row>
          <!-- shipment items start -->

          <v-row class="my-0" dense v-for="(item, index) in selected.items" :key="index">
            <v-col cols="12" sm="2">
              <AppLabel label="الصنف" :value="item.merchandise.name" />
            </v-col>
            <v-col cols="12" sm="2">
              <AppLabel label="الوحده" :value="item.unit.name" />
            </v-col>
            <v-col cols="12" sm="2">
              <AppLabel label="الاحجام" :value="item.size | Sizes" />
            </v-col>
            <v-col cols="12" sm="1">
              <AppLabel label="الكمية" :value="item.quantity" />
            </v-col>
            <v-col cols="12" sm="1">
              <AppLabel label="السعر" :value="item.price" />
            </v-col>
            <v-col cols="12" sm="2">
              <AppLabel label="القيمة المضافة" :value="item.addedValueTax" />
            </v-col>

            <v-col cols="12" sm="2">
              <AppLabel label="المجموع" :value="itemPrice()" />
            </v-col>
          </v-row>

          <!-- shipment items end -->
          <hr />
          <!-- poilsa details -->
          <v-row class="my-0 ammar" dense>
            <v-col cols="12" sm="2">
              <AppLabel label="القيمة التقدرية للتأمين" :value="selected.insurance" />
            </v-col>
            <v-col cols="12" sm="2">
              <AppLabel label="المسترد" :value="selected.duePrice" />
            </v-col>

            <v-col cols="12" sm="3">
              <AppLabel label="التوصيل" :value="selected.deliveryPrice" />
            </v-col>
            <v-col cols="12" sm="2">
              <AppLabel label="التغليف" :value="selected.otherServicesPrice" />
            </v-col>
            <v-col cols="12" sm="3">
              <AppLabel label="القيمة المضافة" :value="selected.addedValueTax" />
            </v-col>
          </v-row>
          <hr />
          <v-row class="justify-content-end border-top-2 align-items.center">
            <v-row v-if="selected.paymentPaidType == 5">
              <v-col cols="12" sm="3">
                <AppLabel label="كاش" :value="selected.cash" />
              </v-col>
              <v-col cols="12" sm="3">
                <AppLabel label="شبكة" :value="selected.creditCard" />
              </v-col>
            </v-row>
            <v-col cols="12" sm="3">
              <AppLabel label="الصافي" :value="selected.netPrice" />
            </v-col>
            <v-col cols="12" sm="3">
              <AppLabel label="إجمالي السعر" :value="selected.totalPrice" />
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
      <!-- Details tab end -->

      <!-- Recipient tab -->
      <v-tab-item>
        <div class="wrapper p-3">
          <v-row>
            <v-col class="d-flex justify-content-start align-items-center">
              <span>نوع التسليم:</span>
              <span class="h3 m-3">{{ selected.recipientType | RecipientTypes }}</span>
            </v-col>
          </v-row>
          <RecipientInfo :shipment="selected" />
        </div>
      </v-tab-item>
      <!-- Delivery tab -->
      <!-- Tracking tab -->
      <v-tab-item>
        <div class="wrapper p-3">
          <section class="shipment-info">
            <div>
              <div class="follow-order">
                <div class="container">
                  <!--  <div class="order-progress">
                    <span class="shape">
                      <i class="fas fa-chevron-left"></i>
                      تتبع الشحنات
                    </span>
                    <div class="content">
                      <h2>معلومات الشحنة</h2>
                      <div class="steps">
                        <hr class="plain" />
                        <hr class="actly" />
                        <div class="step done step-one">
                          <div class="icon">
                            <i class="fas fa-box"></i>
                          </div>
                          <div class="title">تم الاستلام</div>
                        </div>
                        <div class="step done step-two">
                          <div class="icon">
                            <i class="fas fa-people-carry"></i>
                          </div>
                          <div class="title">مركز المعالجة</div>
                        </div>
                        <div class="step step-three">
                          <div class="icon">
                            <i class="fas fa-flag-checkered"></i>
                          </div>
                          <div class="title">تم التسليم</div>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <div class="order-statue" v-if="selected.activities.length > 0">
                    <h2
                      id="ContentPlaceHolder1_divTitle"
                      class="title"
                      :style="
                        enums.ShipmentTracking[
                          selected.activities[selected.activities.length - 1].state - 1
                        ].color
                      "
                    >
                      حالة الشحنة:
                      {{
                        enums.ShipmentTracking[
                          selected.activities[selected.activities.length - 1].state - 1
                        ].text
                      }}
                      <span v-if="selected.activities[selected.activities.length - 1].state <= 2">
                        {{ selected.trip.line.source.city.name }}
                      </span>
                      <span
                        v-else-if="selected.activities[selected.activities.length - 1].state == 3"
                      >
                        {{ selected.trip.line.destination.city.name }}
                      </span>
                      <span
                        v-else-if="selected.activities[selected.activities.length - 1].state == 7"
                      >
                        {{ selected.trip.transitCity.name }}
                      </span>
                    </h2>
                    <hr />
                    <div class="row">
                      <div class="column-one col-md-2 col-xs-6">
                        <div class="path">
                          <div id="ContentPlaceHolder1_ToCity" class="to">
                            {{ selected.trip.line.destination.city.name }}
                          </div>
                          <div class="hr">
                            <i class="fas fa-chevron-up"></i>
                          </div>
                          <div id="ContentPlaceHolder1_FromCity" class="from">
                            {{ selected.trip.line.source.city.name }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-10 col-xs-6">
                        <div class="path-date">
                          <div class="hr"></div>
                          <ul v-for="(item, i) in selected.activities.slice().reverse()" :key="i">
                            <li :style="enums.ShipmentTracking[item.state - 1].color">
                              <span v-if="item.state <= 2">
                                {{ enums.ShipmentTracking[item.state - 1].text }}
                                {{ selected.trip.line.source.city.name }}
                              </span>
                              <span v-else-if="item.state == 3">
                                {{ enums.ShipmentTracking[item.state - 1].text }}
                                {{ selected.trip.line.destination.city.name }}
                              </span>
                              <span v-else-if="item.state == 7">
                                {{ enums.ShipmentTracking[item.state - 1].text }}
                                {{ selected.trip.transitCity.name }}
                              </span>
                              <span v-else>
                                {{ enums.ShipmentTracking[item.state - 1].text }}
                              </span>
                              <br />
                              <span>
                                {{ item.date | ToDate }}
                                {{ item.date | ToTime }}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </v-tab-item>
      <!-- Tracking tab -->
    </v-tabs>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancelview()">إلغاء</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import Enums from "@/plugins/enums.js";
import ExternalShippersService from "../services/ExternalShippersService";
export default {
  props: ["selected"],
  data() {
    return {
      activeTab: 0,
      enums: Enums,
      dueexternalShippers: [],
    };
  },
  mounted() {
    this.activeTab = 0;
    ExternalShippersService.typeHead("", true).then((resp) => {
      this.dueexternalShippers = resp.data;
    });
    //if (!this.selected) this.selected = {};
  },
  methods: {
    cancelview() {
      this.$emit("hide");
      this.activeTab = 0;
    },
    itemPrice() {
      var addedValueTaxPercentage = this.$store.getters.tax;
      var price = parseFloat(this.selected.items[0].price | 0);
      var quantity = parseInt(this.selected.items[0].quantity | 0);
      var total = quantity * price;
      if (this.selected.includeTax) {
        var total = total - (total / (1 + addedValueTaxPercentage)) * addedValueTaxPercentage;
        return this.round(total, 2);
      } else {
        var addedValueTax = addedValueTaxPercentage * total;
        return this.round(total + addedValueTax, 2);
      }
    },
  },
};
</script>

<style>
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black;
}

.shipment-info {
  border-radius: 0.5rem;
  background: #f8f8f8;
  padding: 1rem;
}

input[type="checkbox"][id^="myCheckbox1"] {
  display: none;
}

.labels {
  border: 5px solid #fff;
  padding: 10px;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

label img {
  height: 40px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

:checked + label {
  border-color: rgb(17, 219, 108);
}

:checked + label:before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

:checked + label img {
  transform: scale(0.9);
  /* box-shadow: 0 0 5px #333; */
  z-index: -1;
}

.follow-order {
  text-align: start;
}

.follow-order .order-progress .shape {
  background-color: #fff;
  padding: 10px 30px;
  width: fit-content;
  height: fit-content;
  border-top-left-radius: 20px;
  font-size: 12px;
}

.follow-order .order-progress .content {
  background-color: #fff;
  padding: 50px 20px;
}

.follow-order .order-progress .content .progress-line .plain {
  color: #eeeeee;
  background-color: #eeeeee;
}

.follow-order .order-progress .content h2 {
  font-size: 15px;
}

.follow-order .order-progress .content .steps {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  background: #eeeeee;
  position: relative;
  padding: 50px;
  font-weight: 200;
}

/* .follow-order .order-progress .content .steps .progress-line {
  position: absolute;
  width: 100%;
} */

.follow-order .order-progress .content .steps hr {
  position: absolute;
  top: 50%;
  width: 100%;
  margin: 0;
  z-index: 1;
}

.follow-order .order-progress .content .steps .plain {
  background: #4b4b4b;
}

.follow-order .order-progress .content .steps .actly {
  background: #117d5d;
  height: 5px;
  width: 60%;
  left: 0;
  opacity: 1;
  overflow: initial;
}

.follow-order .order-progress .content .steps .actly::after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 50%;
  background-color: #347e5d;
  transform: translate(12px, -8px);
  z-index: 5;
}

.follow-order .order-progress .content .steps .step {
  z-index: 2;
  text-align: center;
  margin-top: 20px;
}

.follow-order .order-progress .content .steps .step .icon {
  width: 50px;
  height: 50px;
  border: 1px solid #616161;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  background-color: #eeeeee;
  font-size: 23px;
}

.follow-order .order-progress .content .steps .step.done .icon {
  background-color: #117d5d;
  color: #fff;
  border: 0;
}

.follow-order .order-progress .content .steps .step .title {
  font-size: 14px;
  transform: translateY(10px);
}

.follow-order .order-statue {
  background-color: #fff;
  padding: 20px;
}

.follow-order .order-statue h2 {
  font-size: 20px;
  font-weight: bold;
}

.follow-order .order-statue .path .to {
  width: 100px;
  height: 100px;
  line-height: 90px;
  text-align: center;
  border-radius: 50%;
  background-color: #eee;
  border: 3px solid #117d5d;
  padding: 2px;
}

.follow-order .order-statue .path .hr {
  width: 2px;
  height: 50px;
  background-color: #8e8e8e;
  margin-right: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.follow-order .order-statue .path .from {
  width: 100px;
  height: 100px;
  line-height: 90px;
  text-align: center;
  border-radius: 50%;
  background-color: #eee;
  border: 3px solid #000000;
  padding: 2px;
}

.follow-order .order-statue .path-date {
  position: relative;
  margin-right: 50px;
}

.follow-order .order-statue .path-date ul {
  color: rgb(107, 107, 107);
  padding: 1.1rem;
  position: relative;
  z-index: 2;
}

.follow-order .order-statue .path-date ul li {
  margin: 10px 0;
}

.follow-order .order-statue .path-date ul li.now {
  color: #117d5d;
}

.follow-order .order-statue .path-date .hr {
  width: 2px;
  height: 100%;
  background-color: #8e8e8e;
  position: absolute;
  right: 0;
  z-index: 1;
}

.follow-order .order-info {
  margin: 50px 0;
}

.follow-order .order-info .shape {
  background-color: #fff;
  padding: 10px 30px;
  width: fit-content;
  height: fit-content;
  border-top-left-radius: 20px;
  font-size: 12px;
}

.follow-order .order-info .the-info {
  background-color: #fff;
  padding: 50px;
}

.follow-order .order-info .the-info ul {
  list-style: none;
  color: #616161;
}

.follow-order .order-info .the-info ul li {
  margin: 10px auto;
}

.follow-order .order-info .the-info .with-border {
  border-left: 2px solid #444444;
}

@media (max-width: 998px) {
  .follow-order .order-info .the-info .with-border {
    border-left: none;
  }
}

@media (max-width: 768px) {
  .follow-order .order-statue .row .column-one {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .follow-order .order-statue .path-date {
    margin-top: 50px;
  }
}

@media (max-width: 560px) {
  .follow-order .order-progress .content .steps .step .title {
    font-size: 10px;
  }

  .follow-order .order-progress .content .steps .step {
    margin-left: 10px;
    margin-right: 10px;
  }

  .follow-order .order-info .the-info {
    padding: 50px 0;
  }
}
</style>
