<template>
  <v-app>
    <v-container>
      <v-col cols="12" v-if="trips.length > 0 && !isprint">
        <download-excel name="حركة التشغيل حسب المعطيات.xls" :data="trips" :fields="headersxls">
          <v-btn elevation="2" color="gray">
            حفظ اكسل <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
        </download-excel></v-col>
      <div class="main-break" v-if="trips.length > 0">
        <div class="header">
          <v-row class="mt-3" justify="center">
            <v-col cols="4" evaluate="2" class="data pa-2 text-h4 font-weight-bold text-center">
              {{ this.$store.getters.companyName }}
            </v-col>
          </v-row>
          <v-row justify="space-between" class="mt-n14 ammar small" dense>
            <v-col cols="5" class="mt-14 text-right" v-if="reportname">
              {{ reportname.name }}
              <span class="red--text" v-if="isprint && reportname.id == 1">{{
                options.date | ToDate
              }}</span>
              <v-col cols="12" class="font-weight-bolder" v-if="isprint && reportname.id !== 1 && reportname.id !== 8">
                من تاريخ
                <span class="red--text">
                  {{ options.dateFrom | ToDate }}
                </span>
                الى تاريخ<span class="red--text">
                  {{ options.dateTo | ToDate }}</span>
              </v-col>
              <v-col cols="12" class="font-weight-bolder" v-if="
                (isprint && reportname.id == 3) ||
                reportname.id == 4 ||
                reportname.id == 6
              ">
                للمسار
                <span class="red--text">
                  {{ linename }}
                </span>
              </v-col>
              <v-col cols="12" class="font-weight-bolder" v-if="(isprint && reportname.id == 5) || reportname.id == 6">
                للسائق
                <span class="red--text">
                  {{ drivername }}
                </span>
              </v-col>
              <v-col cols="12" class="font-weight-bolder" v-if="isprint && reportname.id == 7">
                نوع الشحن
                <span class="red--text">
                  {{ servicename }}
                </span>
              </v-col>
            </v-col>

            <v-col cols="3" class="mt-5 text-right">
              <v-row>
                <v-col cols="4"> تاريخ الطباعة </v-col>
                <v-col cols="4" class="small font-weight-light">{{
                  Date.now() | ToDate
                }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4"> التوقيت </v-col>
                <v-col cols="4" class="small font-weight-light">{{
                  Date.now() | ToTime
                }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <v-row class="mt-16 mb-10">
          <v-col cols="12" style="position: relative; width: 100%" class="m-1 p-0 pt-3 pb-3">
            <v-data-table :headers="headers" :items="trips" :items-per-page="-1" dense group-by="serviceType.name"
              class="elevation-1" hide-default-footer>
              <template v-slot:[`group.header`]="{ items }">
                <th colspan="2" style="height: auto; border: red solid 2px !important">
                  {{ items[0].serviceType.name }}
                </th>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                {{ trips.indexOf(item) + 1 }}
              </template>
              <template v-slot:[`item.shippingType`]="{ item }">
                {{ item.shippingType | ShippingTypes }}
              </template>
              <template v-slot:[`item.date`]="{ item }">
                {{ item.date | ToDate }}
              </template>
              <template v-slot:[`item.car`]="{ item }">
                {{ item.car.plate }} {{ item.car.mark }}
              </template>
              <template v-slot:[`item.shipmentsSummery.itemsCount`]="{ item }">
                <span v-if="item.shipmentsSummery">
                  {{ item.shipmentsSummery.itemsCount }}
                </span>
                <span v-else-if="item.shipmentsSummery == null">0</span>
              </template>
              <template v-slot:[`item.shipmentsSummery.priceTotal`]="{ item }">
                <span v-if="item.shipmentsSummery">
                  {{ round(item.shipmentsSummery.priceTotal, 2) }}
                </span>
                <span v-else-if="item.shipmentsSummery == null">0</span>
              </template>
              <template v-slot:[`item.shipmentsSummery.cashPrice`]="{ item }">
                <span v-if="item.shipmentsSummery">
                  {{ round(item.shipmentsSummery.cashPrice, 2) }}
                </span>
                <span v-else-if="item.shipmentsSummery == null">0</span>
              </template>
              <template v-slot:[`item.shipmentsSummery.cashOnDeliveryPrice`]="{ item }">
                <span v-if="item.shipmentsSummery">
                  {{ round(item.shipmentsSummery.cashOnDeliveryPrice, 2) }}
                </span>
                <span v-else-if="item.shipmentsSummery == null">0</span>
              </template>
              <template slot="body.append">
                <tr class="red--text">
                  <td class="title text-center"></td>
                  <td class="title text-center"></td>
                  <td class="title text-center"></td>
                  <td class="title text-center"></td>
                  <td class="title text-center"></td>
                  <td class="title text-center"></td>
                  <td class="title text-center">
                    {{ this.round(totals.priceTotal, 2) }}
                  </td>
                  <td class="title text-center">
                    {{ trips.filter(item => {
                      return item.serviceType.id == 6
                    }).reduce((a, b) => a + (b["rentAmount"] || 0), 0) }}
                  </td>
                  <td class="title text-center">
                    {{ this.round(totals.cashPrice, 2) }}
                  </td>
                  <td class="title text-center">
                    {{ this.round(totals.cashOnDeliveryPrice, 2) }}
                  </td>
                  <td class="title text-center">
                    {{ totals.itemsCount }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <div class="summary-wrapper d-flex flex-column">
          <v-row class="justify-content-around mb-5">
            <v-col cols="8">
              <table class="summary-table">
                <thead>
                  <tr>
                    <th>طريقة الدفع</th>
                    <th>عدد البوالص</th>
                    <th>الاجمالي</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>نقدي</td>
                    <td>{{ totals.cashCount }}</td>
                    <td>{{ this.round(totals.cashPrice, 2) }}</td>
                  </tr>
                  <tr>
                    <td>دفع عند الاستلام</td>
                    <td>{{ totals.cashOnDeliveryCount }}</td>
                    <td>{{ this.round(totals.cashOnDeliveryPrice, 2) }}</td>
                  </tr>
                  <tr>
                    <td>اجمالى البوالص</td>
                    <td>اجمالى الطرود</td>
                    <td>اجمالى الرحلات</td>
                  </tr>
                  <tr>
                    <td>
                      {{ totals.count }}
                    </td>
                    <td>
                      {{ totals.itemsCount }}
                    </td>
                    <td>{{ this.round(totals.priceTotal, 2) }}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import TripsService from "./../../LandShipping/services/TripsService";
export default {
  mounted() {
    this.isprint = false;
    if (this.$route.query["options"]) {
      this.isprint = true;
      this.reportname = JSON.parse(this.$route.query["reportname"]);
      this.linename = this.$route.query["linename"];
      this.drivername = this.$route.query["drivername"];
      this.servicename = this.$route.query["servicename"];
      var data = JSON.parse(this.$route.query["options"]);
      if (data) this.options = data;
      this.getDataFromAPI();
    }
  },
  data() {
    return {
      options: {
        trackingNumber: null,
        lineId: null,
        carId: null,
        driverId: null,
        state: null,
        serviceTypeId: null,
        date: null,
        dateFrom: null,
        dateTo: null,
      },
      reportname: null,
      linename: null,
      drivername: null,
      servicename: null,
      trips: [],
      totals: [],
      printData: null,
      isprint: false,
      printready: false,
      headers: [
        {
          text: "م",
          align: "start",
          sortable: false,
          value: "name",
        },
        { align: "right", text: "التاريخ", value: "date", width: "20%" },
        { align: "right", text: "رقم الرحلة", value: "trackingNumber" },
        { align: "right", text: "المسار", value: "line.name", width: "45%" },
        { align: "right", text: "السائق", value: "driver.name", width: "45%" },
        { align: "right", text: "السيارة", value: "car", width: "120px" },

        //{ text: "نوع الشحن", value: "serviceType.name", width: "1mm" },
        {
          text: "اجمالي الرحلة",
          value: "shipmentsSummery.priceTotal",
          width: "25px",
        },
        { text: "ايجار السيارة", value: "rentAmount", width: "25px" },
        {
          text: "خالص الاجرة",
          value: "shipmentsSummery.cashPrice",
          width: "25px",
        },
        {
          text: "عند التسليم",
          value: "shipmentsSummery.cashOnDeliveryPrice",
          width: "20px",
        },
        {
          text: "عدد الطرود",
          value: "shipmentsSummery.itemsCount",
          width: "25px",
        },
        //{ text: "مسترد", value: "duePrice" },
      ],
      headersxls: {
        "عدد الطرود": "shipmentsSummery.itemsCount",
        "عند التسليم": "shipmentsSummery.cashOnDeliveryPrice",
        "خالص الاجرة": "shipmentsSummery.cashPrice",
        "ايجار السيارة": "rentAmount",
        "اجمالي الرحلة": "shipmentsSummery.priceTotal",
        "نوع الشحن": "serviceType.name",
        السيارة: {
          callback: (value) => {
            return `${value.car.plate} - ${value.car.mark}`;
          },
        },
        السائق: "driver.name",
        الاتجاه: "line.name",
        "رقم الرحلة": "trackingNumber",
        التاريخ: "date",
        م: {
          callback: (value) => {
            return `${this.trips.indexOf(value) + 1}`;
          },
        },
      },
    };
  },
  computed: {},
  methods: {
    reset() {
      this.trips = [];
      this.printData = null;
      this.totals = [];
    },
    setData(data) {
      this.isprint = false;
      this.trips = data.trips;
      this.totals = data.totals;
    },
    getDataFromAPI() {
      this.isprint = true;
      TripsService.activityReport(this.$clean(this.options)).then((resp) => {
        this.trips = resp.data.trips;
        console.log(this.trips);
        this.totals = resp.data.totals;
        setTimeout(() => {
          window.focus();
          var css = "@page { size: landscape; }",
            head = document.head || document.getElementsByTagName("head")[0],
            style = document.createElement("style");

          style.type = "text/css";
          style.media = "print";

          if (style.styleSheet) {
            style.styleSheet.cssText = css;
          } else {
            style.appendChild(document.createTextNode(css));
          }

          head.appendChild(style);
          window.print();
        }, 1000);
      });
    },
    //getDataFromStore() {
    // if (this.printData != null) {
    //   this.printData = this.$store.getters.printData;
    //  this.trips = this.printData.trips;
    //   this.totals = this.printData.totals;
    //  console.log("hereeee");
    //  }
    //  },
  },
};
</script>


<style lang="scss" scoped>
* {
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari */
  color-adjust: exact !important;
  /*Firefox*/
}

.main-break {
  font-weight: 600 !important;
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }

  .summary-wrapper {
    height: 90vh;
    align-content: center;
  }
}

::v-deep .v-data-table-header {
  background-color: #8080802b;
}

.summary-table {
  width: 100%;
  text-align: center;
  border: black solid 3px;
  font-weight: bolder;
}

::v-deep .v-data-table {
  max-width: 98%;
}

::v-deep .v-data-table tr {
  font-size: x-small !important;
  height: 14px !important;
  border: black solid 2px !important;
}

::v-deep .v-data-table td {
  font-size: x-small !important;
  height: 20px !important;
  border: black solid 2px !important;
}

::v-deep .v-data-table th {
  font-size: x-small !important;
  border: black solid 2px !important;
}

.summary-table td,
.summary-table th {
  border: black solid 2px;
  padding: 10px 20px 10px 20px;
}

.ammar div {
  margin: 0 !important;
  padding: 1px !important;
  font-weight: bold;
}

.ammar div .edit {
  margin-top: 20px !important;
}

.data {
  border: 2px solid grey;
}

.both {
  position: absolute;
}

.dashed-border {
  border: 2px dotted grey;
  padding: 10px;
}

.first {
  bottom: -32px;
  left: 33%;
}

.second {
  bottom: 33;
  left: 8%;
}

.review div {
  border: 2px solid black;
  display: inline-block;
  font-weight: bold;
}

.bottoms span {
  position: absolute;
  padding: 8px 12px;
  top: center;
  border: black solid 1px !important;
}

.bottoms span:nth-child(1) {
  bottom: -32px;
  left: 4%;
}

.bottoms span:nth-child(2) {
  bottom: -32px;
  left: 12%;
}

.bottoms span:nth-child(3) {
  bottom: -32px;
  left: 22%;
}

.bottoms span:nth-child(4) {
  bottom: -32px;
  left: 33%;
}

.bottoms span:nth-child(5) {
  bottom: -32px;
  left: 41%;
}

.bottoms span:nth-child(6) {
  bottom: -32px;
  left: 50%;
}

.review div {
  border: 1px solid black;
  display: inline-block;
  font-weight: bold;
}
</style>
