var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-card',{directives:[{name:"can",rawName:"v-can",value:('ShipmentClientDelivery.View'),expression:"'ShipmentClientDelivery.View'"}],staticClass:"mx-auto mt-3 pa-5",attrs:{"width":"99%"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.entities,"fixed-header":"","server-items-length":_vm.total,"items-per-page":15,"options":_vm.options,"loading":_vm.loadingStates.table,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-plus',
          nextIcon: 'mdi-minus',
          'items-per-page-text': 'عدد ارقام التواصل ' + _vm.total + '',
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.trackingNumber",fn:function(ref){
        var item = ref.item;
return [(item.trackingNumber)?_c('div',[(item.trackingNumber)?_c('div',[_vm._v(" "+_vm._s(item.trackingNumber)+" ")]):_vm._e(),(item.date)?_c('div',[_c('span',[_c('v-chip',{staticStyle:{"font-size":"smaller"},attrs:{"color":"green lighten-3","label":""}},[_vm._v(_vm._s(_vm._f("ToDate")(item.date))+" ")])],1)]):_vm._e()]):_vm._e()]}},{key:"item.details",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('ShipmentClientDelivery.View'),expression:"'ShipmentClientDelivery.View'"}],staticClass:"mx-2",attrs:{"color":"orange","outlined":"","elevation":"2","icon":""},on:{"click":function($event){return _vm.view(item.id)}}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('ShipmentClientDelivery.View'),expression:"'ShipmentClientDelivery.View'"}],staticClass:"mx-2",attrs:{"color":"green","outlined":"","elevation":"2","icon":""},on:{"click":function($event){return _vm.sendTo(item.id)}}},[_c('v-icon',[_vm._v("mdi-phone-outline")])],1)]}},{key:"item.senderInfo",fn:function(ref){
        var item = ref.item;
return [(item.senderInfo)?_c('span',[(item.senderInfo.name)?_c('span',[_vm._v(" "+_vm._s(item.senderInfo.name))]):_vm._e()]):_vm._e()]}},{key:"item.receiverInfo",fn:function(ref){
        var item = ref.item;
return [(item.receiverInfo)?_c('span',[(item.receiverInfo.name)?_c('span',[_vm._v(" "+_vm._s(item.receiverInfo.name))]):_vm._e()]):_vm._e()]}},{key:"item.tripdata",fn:function(ref){
        var item = ref.item;
return [(item.trip)?_c('div',[(item.trip.line.name)?_c('div',[_c('span',[_c('v-chip',{staticStyle:{"font-size":"smaller"},attrs:{"color":"green lighten-3","label":""}},[_vm._v(_vm._s(item.trip.line.name)+" ")])],1)]):_vm._e()]):_vm._e()]}},{key:"item.receiverInfoPhone",fn:function(ref){
        var item = ref.item;
return [(item.receiverInfo)?_c('div',[(
                item.receiverInfo.phoneNumber != null &&
                item.receiverInfo.phoneNumber != ' ' &&
                item.receiverInfo.phoneNumber != ''
              )?_c('span',[_c('v-chip',{attrs:{"color":"green lighten-3","label":""}},[_vm._v(_vm._s(item.receiverInfo.phoneNumber)+" ")])],1):_vm._e()]):_vm._e()]}}],null,true),model:{value:(_vm.selectedPolisa),callback:function ($$v) {_vm.selectedPolisa=$$v},expression:"selectedPolisa"}})],1),_c('v-dialog',{ref:"test",staticClass:"pa-20",attrs:{"width":"80%","persistent":""},model:{value:(_vm.modals.viewShipment),callback:function ($$v) {_vm.$set(_vm.modals, "viewShipment", $$v)},expression:"modals.viewShipment"}},[_c('v-card',{staticClass:"p-2"},[_c('ViewShipment',{ref:"viewshipmentModal",attrs:{"selected":_vm.selected},on:{"hide":function($event){_vm.modals.viewShipment = false}}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }