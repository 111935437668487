<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-menu
          ref="menu2"
          v-model="menu2"
          :nudge-right="40"
          :return-value.sync="time"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              hide-details
              dense
              :label="label"
              :disabled="disabled"
              v-model="mytime"
              append-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="mytime"
            full-width
            ampm-in-title
            format="24hr"
            @click:minute="$refs.menu2.save(mytime)"
          ></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["label", "time", "disabled"],
  name: "timePicker",
  data() {
    return {
      menu2: false,
    };
  },
  computed: {
    mytime: {
      get() {
        return this.time;
      },
      set(value) {
        this.$emit("myEventtime", value);
      },
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>





