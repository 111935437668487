export default [
    {
        path: '/',
        name: 'Home',
        component: () => import('./views/Home.vue')
    },
    {
        path: '/about',
        name: 'About',

        component: () => import('./views/About.vue')
    },
    {
        path: '/Dashboard',
        name: 'Dashboard',
        meta: { requiresAuth: true, layout: 'dashboard' },
        component: () => import('./views/Dashboard.vue')
    },
    {
        path: '/contact-us',
        name: 'ContactUs',
        component: () => import('./views/ContactUs.vue')
    },
    {
        path: '/login',
        name: 'Login',
        meta: { guest: true },
        component: () => import('./views/accounts/Login.vue')
    },
]