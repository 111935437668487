<template>
  <div>
    <v-container>
      <div class="content">
        <div>
          <v-row class="" justify="center">
            <v-col
              cols="4"
              evaluate="2"
              class="data pa-2 text-h4 font-weight-bold text-center"
            >
              {{ this.$store.getters.companyName }}
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row justify="space-between" class="ammar" dense>
            <v-col cols="3" class="mt-5 text-right">
              <v-row>
                <v-col cols="4"> تاريخ الطباعة </v-col>
                <v-col cols="5" class="">{{ print.date }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4"> التوقيت </v-col>
                <v-col cols="5" class="">{{ print.time }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <v-row class="mb-5">
          <v-col cols="12 m-0 p-0 mt-5">
            <v-data-table
              :headers="headers"
              :items="entities"
              :options.sync="options"
              :server-items-length="total"
              :items-per-page="-1"
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:[`item.createDate`]="{ item }">
                {{ item.createDate | ToDate }}
              </template>
              <template v-slot:[`item.shipment.paymentType`]="{ item }">
                {{ item.shipment.paymentType == 1 ? "بوالص نقدية" : "سند قبض" }}
              </template>
              <template slot="body.append">
                <tr class="red--text">
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="title text-center">
                    {{ sumField() }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>


<script>
import ApiService from "../services/CatchReceiptService";

export default {
  mounted() {
    if (this.$route.query["options"]) {
      var data = JSON.parse(this.$route.query["options"]);
      if (data) this.options = data;
      this.getDataFromAPI();
    }
  },
  data() {
    return {
      loadings: {},
      entities: [],
      total: 0,
      options: {
        Id: null,
        manualNo: null,
        triptrackingNumber: null,
        client: null,
        recipient: null,
        date: null,
        cityId: null,
        state: null,
        serviceTypeId: null,
        paymentType: null,
        dateFrom: null,
        dateTo: null,
      },
      loadingStates: {
        table: false,
      },
      print: {
        time: new Date().toLocaleTimeString(),
        date: new Date().toLocaleDateString(),
      },
      headers: [
        {
          text: " رقم السند",
          align: "center",
          sortable: false,
          value: "id",
        },
        {
          text: "التاريخ",
          value: "createDate",
          sortable: false,
          align: "center",
        },
        {
          text: "النوع ",
          value: "shipment.paymentType",
          sortable: false,
          align: "center",
        },
        {
          text: "المودع",
          value: "recipient",
          sortable: false,
          align: "center",
        },
        {
          text: "الرقم اليدوى",
          value: "shipment.manualNo",
          sortable: false,
          align: "center",
        },
        {
          text: "رقم البوليصة",
          value: "shipment.trackingNumber",
          sortable: false,
          align: "center",
        },
        {
          text: "رقم الرحلة",
          value: "shipment.trip.trackingNumber",
          sortable: false,
          align: "center",
        },
        {
          text: "المبلغ",
          value: "amount",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  methods: {
    sumField() {
      return this.entities.reduce((a, b) => a + (b["amount"] || 0), 0);
    },
    getDataFromAPI() {
      this.options.itemsPerPage = -1;
      this.options.itemsPerPage = -1;
      ApiService.query(this.$clean(this.options)).then((resp) => {
        this.entities = resp.data.items;
        this.total = this.entities.length;
        this.loadingStates.table = false;
        setTimeout(() => {
          window.focus();
          var css = "@page { size: portrait; }",
            head = document.head || document.getElementsByTagName("head")[0],
            style = document.createElement("style");

          style.type = "text/css";
          style.media = "print";

          if (style.styleSheet) {
            style.styleSheet.cssText = css;
          } else {
            style.appendChild(document.createTextNode(css));
          }

          head.appendChild(style);
          window.print();
        }, 100);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
* {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
  color-adjust: exact !important; /*Firefox*/
}
.content {
  font-weight: 600 !important;
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
  .xlsfile {
    display: none;
  }
}
::v-deep .v-data-table-header {
  background-color: #8080802b;
}

::v-deep .v-data-table {
  max-width: 98%;
}
::v-deep .v-data-table tr {
  height: 14px !important;
  border: black solid 2px !important;
}
::v-deep .v-data-table td {
  height: 20px !important;
  border: black solid 2px !important;
}
::v-deep .v-data-table th {
  border: black solid 2px !important;
}

.ammar div {
  margin: 0 !important;
  padding: 1px !important;
  font-weight: bold;
}

.ammar div .edit {
  margin-top: 20px !important;
}

.data {
  border: 2px solid grey;
}

.both {
  position: absolute;
}
.dashed-border {
  border: 2px dotted grey;
  padding: 10px;
}

.first {
  bottom: -32px;
  left: 33%;
}

.second {
  bottom: 33;
  left: 8%;
}

.review div {
  border: 2px solid black;
  display: inline-block;
  font-weight: bold;
}
</style>