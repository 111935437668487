<template>
  <div>
    <v-card>
      <v-card-text style="min-height: 60vh">
        <v-container>
          <v-tabs
            v-model="activeTab"
            fixed-tabs
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
            center-active
            background-color="orange accent-1"
            color="black"
            class="rounded-lg"
            light
          >
            <!-- details tab -->
            <v-tab> التفاصيل </v-tab>
            <!-- details tab -->
            <v-tab>إعدادات</v-tab>
            <v-tab>الادوار</v-tab>
            <v-tab>الصلاحيات</v-tab>
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    class="align-content-start d-flex align-items-center"
                  >
                    <i class="fas fa-users-cog ml-2"></i>
                    <span class="h5"> البيانات الأساسية </span>
                  </v-col>
                </v-row>
                <v-form v-model="valid" ref="form" class="mt-2">
                  <v-row
                    align="center"
                    justify="center"
                    class="my-0 ammar"
                    dense
                  >
                    <v-col cols="12">
                      <v-text-field
                        dense
                        outlined
                        color="primary"
                        v-model="selected.name"
                        :counter="100"
                        :rules="[allRules.NameRequired, allRules.minNameLen(3)]"
                        label="الاسم "
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        dense
                        outlined
                        type="userName"
                        color="primary"
                        v-model="selected.userName"
                        :rules="[allRules.required]"
                        aria-autocomplete="none"
                        label=" إسم المستخدم"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-autocomplete
                        :rules="[allRules.required]"
                        label="الفرع"
                        dense
                        outlined
                        required
                        :items="branches"
                        item-value="id"
                        item-text="name"
                        auto-select-first
                        v-model="selected.cityId"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        outlined
                        label="رقم الجوال"
                        ref="num14"
                        :maxlength="10"
                        :counter="10"
                        :rules="[allRules.length(10)]"
                        v-model="selected.phoneNumber"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row>
                  <v-col class="d-flex justify-content-start">
                    <v-btn
                      color="green"
                      style="color: white"
                      :disabled="!valid"
                      @click="editConfirmed()"
                    >
                      حفظ
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <!-- settings Start -->
            <v-tab-item>
              <v-form v-model="updatePasswordValid" ref="updatePassword">
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      class="align-content-start d-flex align-items-center"
                    >
                      <i class="fas fa-user-lock ml-2"></i>
                      <span class="h5"> تغيير كلمة المرور </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        outlined
                        color="primary"
                        label="الرقم السري"
                        :append-icon="
                          showPassword.password ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        :type="showPassword.password ? 'text' : 'password'"
                        @click:append="
                          showPassword.password = !showPassword.password
                        "
                        aria-autocomplete="new-password"
                        :rules="[allRules.length(6)]"
                        v-model="forms.updatePassword.password"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        outlined
                        color="primary"
                        label="تأكيد الرقم السري"
                        :rules="[allRules.length(6)]"
                        :append-icon="
                          showPassword.confirmPassword
                            ? 'mdi-eye'
                            : 'mdi-eye-off'
                        "
                        :type="
                          showPassword.confirmPassword ? 'text' : 'password'
                        "
                        aria-autocomplete="new-password"
                        @click:append="
                          showPassword.confirmPassword =
                            !showPassword.confirmPassword
                        "
                        v-model="forms.updatePassword.confirmPassword"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>

                <v-row>
                  <v-col class="d-flex justify-content-start">
                    <v-btn
                      color="success"
                      :disabled="!updatePasswordValid"
                      @click="updatePassword()"
                    >
                      تغيير كلمة المرور
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
            <!-- settings End -->
            <!-- Roles Start -->
            <v-tab-item class="p-3">
              <v-row class="p-0 m-0">
                <v-col
                  cols="12"
                  class="align-content-start d-flex align-items-center p-0 m-0"
                >
                  <i class="fas fa-tags ml-2"></i>
                  <span class="h5">الأدوار</span>
                </v-col>
              </v-row>
              <v-row class="p-0 m-0">
                <v-col cols="12" class="p-0 m-0">
                  <div v-for="(role, i) in selected.roles" :key="i">
                    <v-checkbox
                      v-model="role.checked"
                      :disabled="isDisabled(role)"
                      :label="role.arabicName"
                      :value="true"
                      dense
                    >
                    </v-checkbox>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="d-flex justify-content-start">
                  <v-btn color="success" @click="updateRoles"> تعديل </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- Roles End -->
            <!-- Permissions Start -->
            <v-tab-item class="p-3">
              <v-row class="p-0 m-0">
                <v-col
                  cols="12"
                  class="align-content-start d-flex align-items-center p-0 m-0"
                >
                  <i class="fas fa-tags ml-2"></i>
                  <span class="h5">الصلاحيات</span>
                </v-col>
              </v-row>
              <v-row class="p-0 m-0">
                <v-col cols="12" class="p-0 m-0">
                  <v-treeview
                    dense
                    v-model="selected.permissions"
                    :items="selected.permissionstree"
                    selection-type="leaf"
                    item-children="permissions"
                    selectable
                    selected-color="primary"
                  >
                    <template v-slot:label="{ item }">
                      <div class="treeeee">
                        {{ item.nameAr }}
                      </div>
                    </template>
                    <template v-slot:prepend="{ item }">
                      {{ item.name }}
                    </template>
                  </v-treeview>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="d-flex justify-content-start">
                  <v-btn color="success" @click="updatePermissions">
                    تعديل
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- Permissions End -->
          </v-tabs>
        </v-container>
      </v-card-text>
      <hr />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancelview()"> إلغاء </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import BranchesService from "../../Settings/services/CityService.js";
import EmployeeService from "../services/EmployeeService.js";
import PermissionsService from "../services/PermissionsService.js";
export default {
  props: ["value"],
  mounted() {
    this.activeTab = 0;
    BranchesService.typeHead("", true).then((resp) => {
      this.branches = resp.data;
    });
  },
  data() {
    return {
      activeTab: 0,
      branches: [],
      roles: [],
      valid: false,
      updatePasswordValid: false,
      forms: {
        updatePassword: {
          password: "",
          confirmPassword: "",
        },
      },
      showPassword: {
        password: false,
        confirmPassword: false,
      },
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
    },
    rolesTree() {
      var items = this.roles.map((r, i) => {
        return { name: r, id: i };
      });

      return items;
    },
  },
  methods: {
    isDisabled(item) {
      var roles = this.value.roles.filter((r) => r.checked);
      return roles.length > 0 && !item.checked;
    },
    cancelview() {
      this.$emit("cancel");
      this.activeTab = 0;
    },
    editConfirmed() {
      EmployeeService.edit(this.selected)
        .then((e) => {
          this.$dialog.notify.success("تم تعديل الموظف بنجاح", {
            position: "top-right",
            timeout: 3000,
          });

          this.$emit("updated");
        })
        .catch((resp) => {
          var message = JSON.stringify(resp.response.data);
          this.loading = false;
          this.$dialog.notify.error(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .finally(() => {});
    },
    updatePassword() {
      EmployeeService.updatePassword(this.value.id, this.forms.updatePassword)
        .then((e) => {
          this.$dialog.notify.success("تم تعديل الموظف بنجاح", {
            position: "top-right",
            timeout: 3000,
          });
          this.$refs.form.resetValidation();
          this.forms.updatePassword = {
            password: "",
            confirmPassword: "",
          };
          this.$emit("updated");
        })
        .catch((resp) => {
          var message = JSON.stringify(resp.response.data);
          this.loading = false;
          this.$dialog.notify.error(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .finally(() => {});
    },
    updateRoles() {
      var roles = this.value.roles.filter((r) => r.checked).map((r) => r.name);
      EmployeeService.updateRoles(this.value.id, roles)
        .then((resp) => {
          this.$dialog.notify.success("تم تعديل الادوار بنجاح", {
            position: "top-right",
            timeout: 3000,
          });
          this.$emit("updated");
        })
        .catch((resp) => {
          var message = JSON.stringify(resp.response.data);
          this.loading = false;
          this.$dialog.notify.error(message, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    updatePermissions() {
      PermissionsService.updatePermissions(this.value.id, this.selected.permissions)
        .then((resp) => {
          this.$dialog.notify.success("تم تعديل الصلاحيات بنجاح", {
            position: "top-right",
            timeout: 3000,
          });
          this.$emit("updated");
        })
        .catch((resp) => {
          var message = JSON.stringify(resp.response.data);
          this.loading = false;
          this.$dialog.notify.error(message, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
  },
  watch: {
    value: {
      handler() {},
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}
.treeeee {
  font-size: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
