import Api from '../../shared/services/api'

const baseUrl = "Audits";
export default {
    get() {
        return Api().get(baseUrl);
    },
    query(options) {
        const qParams = new URLSearchParams(options);
        return Api().get(baseUrl + "?" + qParams.toString());
    },
}