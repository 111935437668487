var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pr-5 pb-3 mt-3"},[_c('v-col',{staticClass:"d-flex justify-content-start"},[_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('Cities.Create'),expression:"'Cities.Create'"}],attrs:{"color":"success"},on:{"click":_vm.add}},[_vm._v(" اضافة مدينة"),_c('i',{staticClass:"fa fa-plus mr-2"})])],1)],1),_c('v-card',{staticClass:"mx-auto mr-5",attrs:{"width":"80%"}},[_c('v-data-table',{directives:[{name:"can",rawName:"v-can",value:('Cities.View'),expression:"'Cities.View'"}],staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.entities,"items-per-page":15,"loading":_vm.loadingStates.table,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-plus',
        nextIcon: 'mdi-minus',
        'items-per-page-text': 'عدد المدن ' + _vm.entities.length + '',
      }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('Cities.Delete'),expression:"'Cities.Delete'"}],staticClass:"ml-2",attrs:{"color":"red","icon":"","elevation":"2","outlined":""},on:{"click":function($event){return _vm.del(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('Cities.Update'),expression:"'Cities.Update'"}],attrs:{"color":"primary","elevation":"2","icon":""},on:{"click":function($event){return _vm.edit(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)})],1),_c('v-dialog',{attrs:{"width":"300","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',{staticClass:"headline"},[_c('i',{staticClass:"fa fa-pen ml-2"}),_vm._v(" تعديل/إضافة ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"rules":[_vm.allRules.required],"label":"اسم المدينة"},model:{value:(_vm.selected.name),callback:function ($$v) {_vm.$set(_vm.selected, "name", $$v)},expression:"selected.name"}})],1)],1),_c('v-row',[_c('v-textarea',{attrs:{"name":"input-7-1","label":"ألملاحظات"},model:{value:(_vm.selected.notice),callback:function ($$v) {_vm.$set(_vm.selected, "notice", $$v)},expression:"selected.notice"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"primary"},on:{"click":function($event){return _vm.editConfirmed()}}},[_vm._v(" حفظ ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.cancelEdit()}}},[_vm._v(" إلغاء ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }