

<template>
  <div>
    <Header @menuBtn="menuBtnClicked" isDashboard="true"></Header>
    <v-navigation-drawer fixed app right :permanent="!$vuetify.breakpoint.xsOnly" :mini-variant.sync="mini"
      v-model="drawer">
      <template v-slot:prepend>
        <v-img src="../assets/newlogo.jpg" style="background-size: contain !important"></v-img>
      </template>

      <v-list-group no-action v-for="item in menu" :key="item.title" dark active-class="primary white--text">
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon color="error">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item active-class="info white--text" v-for="child in item.child" :key="child.title" :to="child.href">
          <v-icon v-text="child.icon"></v-icon>
          <v-list-item-title v-text="child.title"></v-list-item-title>
        </v-list-item>
      </v-list-group>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logOut" outlined plain class="red--text">
            <v-icon left>mdi-logout</v-icon>
            تسجيل الخروج</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid class="pa-3 grey lighten-3">
        <v-breadcrumbs :items="location">
          <template v-slot:divider>
            <v-icon>mdi-arrow-left</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.href" class="text-capitalize">{{
              item.name
            }}</v-breadcrumbs-item>
            <router-link :to="item.href"></router-link>
          </template>
        </v-breadcrumbs>
        <div class="rounded-lg pa-3 white" style="min-height: 690px">
          <slot />
        </div>
      </v-container>
    </v-main>

    <!-- <Footer/> -->
  </div>
</template>

<script>
import Header from "@/features/shared/components/Navigations/Header.vue";
// import Footer from '@/features/shared/components/Navigations/Footer.vue'

export default {
  components: {
    Header,
    // Footer
  },
  data() {
    return {
      drawer: true,
      mini: true,
      menu: [
        {
          title: "الشحن البرى",
          icon: "fa fa-shipping-fast",
          child: [
            {
              href: "/dashboard",
              title: "الرئيسيه",
              icon: "fa fa-home",
            },
            {
              href: "/shipping/shipment",
              title: "اضافة بوليصة",
              icon: "fa fa-scroll",
            },
            {
              href: "/shipping/search",
              title: "بحث سجلات البوليصه",
              icon: "fa fa-search",
            },
            {
              href: "/shipping/shipment-delivery",
              title: "تسليم الموزعين",
              icon: "fa fa-scroll",
            },
            {
              href: "/shipping/shipment-client-delivery",
              title: "تسليم البوليصه لعميل",
              icon: "fa fa-user-check",
            },
            {
              href: "/shipping/shipment-client-call",
              title: "التواصل مع العميل",
              icon: "fa fa-phone",
            },
            {
              href: "/shipping/trips",
              title: "الرحلات",
              icon: "fa fa-truck",
            },
            {
              href: "/shipping/shipments-report",
              title: "بلاغات الرحلات",
              icon: "fa fa-comments",
            },
            {
              href: "/shipping/shipments-discounts",
              title: "الخصومات",
              icon: "fa fa-comments",
            },
            {
              href: "/shipping/books",
              title: "الدفاتر",
              icon: "fas fa-book",
            },
            {
              href: "/shipping/drivers",
              title: "السائقين",
              icon: "fa fa-user-alt",
            },
            {
              href: "/shipping/cars",
              title: "الشاحنات المستأجره",
              icon: "fa fa-car-alt",
            },
            {
              href: "/shipping/distribution-cars",
              title: "عربات التوزيع",
              icon: "fa fa-truck-moving",
            },
            {
              href: "/shipping/external-shippers",
              title: "شحن مناطق",
              icon: "fa fa-user-cog",
            },
            {
              href: "/shipping/Distributors",
              title: "الموزعين",
              icon: "fa fa-people-arrows",
            },

            {
              href: "/shipping/salesmen",
              title: "المندوبين",
              icon: "fa fa-people-carry",
            },
            {
              href: "/shipping/clients",
              title: "العملاء",
              icon: "fa fa-user-shield",
            },
            {
              href: "/shipping/lines",
              title: "المسارات",
              icon: "fa fa-map-marked-alt",
            },
          ],
        },
        {
          title: "خدمة العملاء",
          icon: "fa fa-comments",
          child: [
            {
              icon: "fa fa-comments",
              href: "/shipping/tickets",
              title: "أمر الخدمة",
            },
          ],
        },
        {
          title: "التقارير",
          icon: "fa fa-scroll",
          child: [
            {
              icon: "fa fa-chart-line",
              href: "/reports/activities",
              title: "حركه التشغيل ",
            },
            {
              icon: "fa fa-chart-line",
              href: "/reports/empShipmentsActivity",
              title: "حركه المدخلين ",
            },
          ],
        },
        {
          title: "الاعدادات",
          icon: "fa fa-cog",
          child: [
            {
              href: "/settings/countries",
              title: "الدول",
              icon: "fa fa-globe-asia",
            },
            {
              href: "/settings/branches",
              title: "الفروع",
              icon: "fa fa-sitemap",
            },
            {
              href: "/settings/Merchandises",
              title: "ألاصناف",
              icon: "fa fa-shopping-cart",
            },
            {
              href: "/settings/carTypes",
              title: "الشاحنات",
              icon: "fa fa-car",
            },
            {
              href: "/settings/serviceTypes",
              title: "نوع الخدمة",
              icon: "fas fa-toolbox",
            },
            {
              href: "/settings/units",
              title: "الوحدات",
              icon: "fa fa-ruler-horizontal",
            },
          ],
        },
        {
          title: "الماليات",
          icon: "fa fa-money-bill-wave",
          child: [
            {
              href: "/finances/CatchReceipt",
              title: "سند قبض",
              icon: "fa fa-dollar-sign",
            },
            {
              href: "/finances/Receipt",
              title: "سند صرف",
              icon: "fa fa-money-bill",
            },
            {
              href: "/finances/DailyReport",
              title: "حركة اليومية",
              icon: "fa fa-file",
            },
            {
              href: "/finances/Expenses",
              title: "ألمصروفات ",
              icon: "fa fa-search-dollar",
            },
            {
              href: "/finances/FinanceReports",
              title: "التقارير ",
              icon: "fa fa-chart-line",
            },
          ],
        },
        {
          title: "خدمات النظام",
          icon: "fa fa-sliders-h",
          child: [
            {
              href: "/system/AuditLog",
              title: "مدقق النظام",
              icon: "fa fa-search",
            },
            {
              href: "/system/users",
              title: "الموظفين",
              icon: "fas fa-users",
            },
            {
              href: "/system/settings",
              icon: "fa fa-cog",
              title: "إعدادات النظام",
            },
          ],
        },
      ],
    };
  },
  methods: {
    logOut() {
      this.$store.dispatch("logOut", { id: this.user.id}).then(() => {
        this.$router.push("/login");
        window.location.href = "/login";
      });
    },
    menuBtnClicked(event) {
      this.drawer = !this.drawer;
      this.mini = this.drawer ? true : false;
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    location() {
      let paths = [
        {
          name: "الرئيسيه",
          href: "/dashboard",
          icon: "mdi-home",
          disabled: true,
        },
      ];
      return paths;
      let path = this.$route.path;
      if (path === "/dashboard") {
        return;
      }

      var selectedRoute = [path];
      var tempList = [];

      tempList = [
        ...tempList,
        ...this.menu.filter((item) =>
          item.child.some((i) => selectedRoute.includes(i.href))
        ),
      ];
      let withArabic = tempList[0].child.filter((item) => item.href == path);

      paths.push({ name: tempList[0].title, href: path });
      paths.push({ name: withArabic[0].title, href: path });
      return paths;
    },
  },
};
</script>


<style lang="scss" scoped></style>
